<template>
  <div class="container-fluid h-100" style="overflow: hidden; overflow-y: auto">
    <div class="card">
      <div class="card-header">
        <div v-show="errorMessage" class="alert alert-danger">
          {{ errorMessage }}
        </div>
        Trailers - VAN
        <EmptyTrailerDisplay v-if="trailers" :trailers="trailers" />
        <span class="float-right">
          Trailer Report
          <button class="btn" @click="trailerReport">
            <font-awesome-icon icon="download" />
          </button>

          Detention Report
          <button class="btn" @click="detentionReport">
            <font-awesome-icon icon="download" />
          </button>
        </span>
      </div>
      <div class="card-body">
        <div v-if="trailers" class="row">
          <div class="col-xl-4">
            <TrailerColumn :trailers="col1Vans" />
          </div>
          <div class="col-xl-4">
            <TrailerColumn :trailers="col2Vans" />
          </div>
          <div class="col-xl-4">
            <TrailerColumn :trailers="col3Vans" />
          </div>
        </div>
      </div>
    </div>
    <b-row>
      <b-col md="6">
        <b-card no-body>
          <b-card-header>Trailers - FLATBEDS</b-card-header>
          <b-card-body>
            <TrailerColumn :trailers="flatbeds" />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card no-body>
          <b-card-header>Trailers - VAN 48'</b-card-header>
          <b-card-body>
            <TrailerColumn :trailers="van48s" />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <TrailerModal />
    <LoadingSplash />
  </div>
</template>

<script>
import EmptyTrailerDisplay from "./EmptyTrailerDisplay.vue";
import TrailerColumn from "./TrailerColumn.vue";
import TrailerModal from "./TrailerModal.vue";
import LoadingSplash from "../LoadingSplash.vue";
import { mapGetters, mapActions } from "vuex";
import messaging from "../../messaging";
import { v4 as uuidv4 } from "uuid";
import reportsapi from "../../api/reportsapi";

export default {
  components: {
    EmptyTrailerDisplay,
    TrailerColumn,
    TrailerModal,
    LoadingSplash,
  },
  data() {
    return {
      id: uuidv4(),
    };
  },
  computed: {
    ...mapGetters("common", ["errorMessage", "loading"]),
    ...mapGetters("trailers", [
      "col1Vans",
      "col2Vans",
      "col3Vans",
      "trailers",
      "van48s",
      "flatbeds",
      "selectedTrailerForActions",
      "facilities",
      "facilityGroups",
      "facilityGroupLocations",
      "activeRoutes",
    ]),
  },
  methods: {
    ...mapActions("trailers", [
      "loadInitialData",
      "updateTrailer",
      "trailerAdded",
      "trailerDeleted",
      "handleDriverWorkDayRouteMutation",
      "handleTrailerStayMutation",
    ]),
    trailerMutationCallback: function (msg) {
      if (msg && msg.body) {
        msg = JSON.parse(msg.body);
        if (msg.action === "trailerCreated" && msg.current) {
          if (msg.current.scac === "QUCF") {
            this.trailerAdded(msg.current);
          }
        } else if (msg.action === "trailerDeleted" && msg.previous) {
          if (msg.previous.scac === "QUCF") {
            this.trailerDeleted(msg.previous);
          }
        } else {
          if (msg.current) {
            this.updateTrailer(msg.current);
          }
        }
      }
    },
    detentionReport() {
      window.open(
        reportsapi.getReportLink("Detention_Current", ".xlsx", {}),
        "_new",
        "",
      );
    },
    trailerReport() {
      window.open(
        reportsapi.getReportLink("TrailerBoard", ".xlsx", {}),
        "_new",
        "",
      );
    },
  },
  mounted() {
    var self = this;
    this.loadInitialData().then(function () {
      messaging.subscribe(
        "TrailersPage.TrailerMutations",
        "/topic/Topic.TrailerMutations",
        self.trailerMutationCallback,
      );
      messaging.subscribe(
        "TrailersPage.DriverWorkDayRouteMutations",
        "/topic/Topic.DriverWorkDayRouteMutations",
        self.handleDriverWorkDayRouteMutation,
      );
      messaging.subscribe(
        "TrailersPage.TrailerStayMutations",
        "/topic/Topic.TrailerStayMutations",
        self.handleTrailerStayMutation,
      );
      messaging.restart();
    });
  },
  destroyed() {
    messaging.unsubscribe("TrailersPage.TrailerMutations");
    messaging.unsubscribe("TrailersPage.DriverWorkDayRouteMutations");
    messaging.unsubscribe("TrailersPage.TrailerStayMutations");
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
