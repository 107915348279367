<template>
  <b-container fluid class="h-100">
    <b-card no-body>
      <b-card-header>
        <div v-show="errorMessage" class="alert alert-danger">
          {{ errorMessage }}
        </div>
        <b-row>
          <b-col sm="1">EDI Type:</b-col>
          <b-col>
            <b-form-select v-model="ediType">
              <b-form-select-option :value="null" disabled
                >Select EDI Type</b-form-select-option
              >
              <b-form-select-option value="handling"
                >Handling</b-form-select-option
              >
              <b-form-select-option value="detention"
                >Detention</b-form-select-option
              >
            </b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="1">Production:</b-col>
          <b-col>
            <b-form-checkbox v-model="production" />
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="1">Clear Data:</b-col>
          <b-col>
            <b-form-checkbox v-model="clearToday" />
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="1">File:</b-col>
          <b-col>
            <b-form-file v-model="file" placeholder="ex - MasterEDI210.csv" />
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="1">
            <button
              class="btn btn-primary"
              :disabled="isUploadDisabled"
              @click="run"
            >
              Upload
            </button>
          </b-col>
        </b-row>
      </b-card-header>
    </b-card>
    <LoadingSplash />
  </b-container>
</template>

<script>
import LoadingSplash from "../LoadingSplash.vue";
import { mapGetters, mapActions } from "vuex";
import ediapi from "../../api/edi";
import axios from "axios";
import { parse } from "content-disposition-attachment";

export default {
  components: {
    LoadingSplash,
  },
  data() {
    return {
      ediType: null,
      clearToday: false,
      production: true,
      file: null,
    };
  },
  computed: {
    ...mapGetters("common", ["loading", "errorMessage"]),
    isUploadDisabled() {
      return this.ediType == null || this.file == null;
    },
  },
  methods: {
    ...mapActions("common", ["setLoading", "setErrorMessage"]),
    reset() {
      this.setErrorMessage(null);
      this.resetForm();
    },
    resetForm() {
      this.ediType = null;
      this.production = true;
      this.clearToday = false;
      this.file = null;
    },
    async run() {
      this.setErrorMessage(null);
      this.setLoading(true);
      let formData = new FormData();
      formData.append("file", this.file);
      let url = await ediapi.generateRunWeeklyEdiLink(
        this.ediType,
        this.production,
        this.clearToday,
      );
      axios({
        url: url,
        method: "post",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
        responseType: "blob",
      })
        .then((response) => {
          let disposition = parse(response.headers["content-disposition"]);
          console.log(disposition);
          const aEle = document.createElement("a"); // Create a label
          const href = window.URL.createObjectURL(response.data); // Create downloaded link
          aEle.href = href;
          aEle.download = disposition.filename; // File name after download
          document.body.appendChild(aEle);
          aEle.click(); // Click to download
          document.body.removeChild(aEle); // Download complete remove element
          window.URL.revokeObjectURL(href); // Release blob object
          alert(
            `EDI Billing Package successfully generated and downloaded - ${disposition.parameters.filename}`,
          );
        })
        .catch((err) => {
          console.log(err);
          if (err.response) {
            err.response.data.text().then((msg) => {
              this.setErrorMessage(msg);
            });
          } else if (err.request) {
            this.setErrorMessage(err.message);
          }
        })
        .finally(() => {
          this.resetForm();
          this.setLoading(false);
        });
    },
  },
  mounted() {
    this.reset();
  },
  beforeDestroy() {
    this.reset();
  },
};
</script>

<style scoped></style>
