<template>
  <div class="container-fluid h-100" style="overflow: hidden; overflow-y: auto">
    <div class="card">
      <div class="card-header">
        <div v-show="errorMessage" class="alert alert-danger">
          {{ errorMessage }}
        </div>
        <div v-if="lastAdded" class="alert alert-success">
          Fork Truck Audit {{ lastAdded.truck }} by {{ lastAdded.driver }} added
          successfully
        </div>
        Add Fork Truck Audit
      </div>
      <div class="card-body">
        <form>
          <div class="form-group row">
            <label for="driver" class="col-sm-2 col-form-label">Driver</label>
            <div class="col-sm-10">
              <input
                type="text"
                v-model.trim="driver"
                id="driver"
                class="form-control text-uppercase"
                required
                placeholder="Driver"
                autofocus
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="truck" class="col-sm-2 col-form-label">Truck</label>
            <div class="col-sm-10">
              <input
                type="text"
                v-model.trim="truck"
                class="form-control text-uppercase"
                id="truck"
                required
                placeholder="Truck"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="tachHours" class="col-sm-2 col-form-label"
              >Tach Hours</label
            >
            <div class="col-sm-10">
              <input
                type="number"
                v-model.trim="tachHours"
                class="form-control text-uppercase"
                id="tachHours"
                required
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="notes" class="col-sm-2 col-form-label">Notes</label>
            <div class="col-sm-10">
              <textarea
                v-model.trim="notes"
                class="form-control text-uppercase"
                id="notes"
                required
                placeholder="Notes"
              ></textarea>
            </div>
          </div>
          <div class="form-group row">
            <button type="button" @click="add" class="btn btn-primary">
              Add Fork Truck Audit
            </button>
          </div>
        </form>
      </div>
    </div>
    <LoadingSplash />
  </div>
</template>

<script>
import LoadingSplash from "../LoadingSplash.vue";
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    LoadingSplash,
  },
  data() {
    return {
      driver: null,
      truck: null,
      tachHours: null,
      notes: null,
    };
  },
  validations() {
    let v = {
      driver: {
        required,
      },
      truck: {
        required,
      },
      tachHours: {
        required,
      },
    };
    return v;
  },
  computed: {
    ...mapGetters("common", ["loading", "errorMessage"]),
    ...mapGetters("forktruckaudit", ["lastAdded"]),
  },
  watch: {
    loading(newVal) {
      if (!newVal) {
        if (!this.errorMessage) {
          this.reset();
        }
      }
    },
  },
  destroyed() {
    this.resetStore();
  },
  methods: {
    ...mapActions("common", ["setErrorMessage"]),
    ...mapActions("forktruckaudit", ["saveForkTruckAudit", "resetStore"]),
    reset() {
      this.driver = null;
      this.truck = null;
      this.tachHours = null;
      this.notes = null;
    },
    add() {
      this.resetStore();
      this.setErrorMessage(null);
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.setErrorMessage(
          "Check that all required fields are filled out with valid data",
        );
      } else {
        const t = {
          driver: this.driver.toUpperCase(),
          truck: this.truck.toUpperCase(),
          notes: this.notes ? this.notes.toUpperCase() : null,
          tachHours: Number(this.tachHours),
        };
        this.saveForkTruckAudit(t);
      }
    },
  },
};
</script>

<style scoped></style>
