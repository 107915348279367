<template>
  <b-container fluid style="overflow: hidden; overflow-y: auto">
    <div v-show="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <b-card no-body>
      <b-card-header>
        <b-row>
          <b-col md="6">
            Drivers <span v-if="drivers != null">({{ drivers.length }})</span>
          </b-col>
          <b-col>
            <b-input-group prepend="First:">
              <b-form-input
                v-model="first"
                :formatter="nameFormat"
                trim
              ></b-form-input>
            </b-input-group>
          </b-col>
          <b-col>
            <b-input-group prepend="Last:">
              <b-form-input
                :formatter="nameFormat"
                v-model="last"
                trim
              ></b-form-input>
            </b-input-group>
          </b-col>
          <b-col md="1">
            <b-button
              :disabled="disableAdd"
              variant="light"
              size="md"
              @click="addClicked"
            >
              <font-awesome-icon title="Add new driver" icon="plus" />
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body v-if="drivers != null">
        <b-row style="border-bottom: 1px solid black">
          <b-col>Last Name</b-col>
          <b-col>First Name</b-col>
          <b-col>Active</b-col>
        </b-row>
        <b-row v-for="driver in drivers" :key="driver.id" class="row-striped">
          <b-col>{{ driver.lastName }}</b-col>
          <b-col>{{ driver.firstName }}</b-col>
          <b-col
            ><b-checkbox v-model="driver.active" @change="toggleActive(driver)"
          /></b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <LoadingSplash />
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LoadingSplash from "../LoadingSplash.vue";

export default {
  components: {
    LoadingSplash,
  },
  data() {
    return {
      first: null,
      last: null,
    };
  },
  computed: {
    ...mapGetters("common", ["errorMessage"]),
    ...mapGetters("drivers", ["drivers"]),
    disableAdd() {
      return (
        this.first == null ||
        this.first.length == 0 ||
        this.last == null ||
        this.last.length == 0
      );
    },
  },
  methods: {
    ...mapActions("drivers", ["loadDrivers", "updateDriver", "addDriver"]),
    async addClicked() {
      let d = {
        firstName: this.first,
        lastName: this.last,
        active: true,
      };
      await this.addDriver(d);
      this.first = null;
      this.last = null;
    },
    toggleActive(driver) {
      this.updateDriver(driver);
    },
    nameFormat(s) {
      if (s && s.length > 0) {
        s = `${s.substring(0, 1).toUpperCase()}${s.substring(1).toLowerCase()}`;
      }
      return s;
    },
  },
  mounted() {
    this.loadDrivers();
  },
};
</script>
