import axios from "axios";
import store from "../store";

var API_URL = null;
try {
  // eslint-disable-next-line no-undef
  API_URL = INDEX_API_URL;
} catch (e) {
  API_URL = "http://indexapi.home";
}

export default {
  async searchLog(query) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/search/log`;
    return axios.post(url, query, {
      headers: { "Content-Type": "application/json" },
    });
  },
  async search(query) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/search`;
    return axios.post(url, query, {
      headers: { "Content-Type": "application/json" },
    });
  },
  async searchLine(query) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/search/line`;
    return axios.post(url, query, {
      headers: { "Content-Type": "application/json" },
    });
  },
};
