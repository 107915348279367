<template>
  <span>
    <font-awesome-icon
      v-if="iconOnly"
      :icon="icon"
      @click="doSearch"
      :title="title"
      :variant="variant"
    />
    <b-button v-else @click="doSearch" :title="title" :variant="variant">
      <font-awesome-icon :icon="icon" />
    </b-button>
  </span>
</template>

<script>
import indexApi from "../../api/indexapi";
import { mapActions } from "vuex";

export default {
  props: {
    type: String,
    term: [String, Number],
    title: String,
    size: {
      type: String,
      default: "md",
    },
    icon: {
      type: String,
      default: "info",
    },
    variant: {
      type: String,
      default: "light",
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      log_search: `{"query":{"bool":{"filter":[{"term":{"message":"@TERM@"}},{"bool":{"should":{"bool":{"must_not":[{"term":{"message":"index"}},{"term":{"message":"activemqobjectmessage"}}]}}}}]}},"size":1000,"_source":["message","@timestamp"],"sort":[{"@timestamp":{"order":"desc"}}]}`,
      log_query: `{"query":{"bool":{"filter":[{"query_string":{"query":"@TERM@","default_field":"message"}},{"bool":{"should":{"bool":{"must_not":[{"term":{"message":"index"}},{"term":{"message":"activemqobjectmessage"}}]}}}}]}},"size":1000,"_source":["message","@timestamp"],"sort":[{"@timestamp":{"order":"desc"}}]}`,
    };
  },
  methods: {
    ...mapActions("common", ["showLoadingDuringAction"]),
    async doSearch() {
      let call = indexApi.searchLog(this.getQuery()).then((res) => {
        let list = [];
        res.data.hits.hits.forEach((hit) => {
          let r = {
            date: hit._source["@timestamp"],
            message: hit._source.message,
          };
          list.push(r);
        });
        return list;
      });
      let logs = await this.showLoadingDuringAction(call);
      let obj = {
        logs: logs,
        title: this.title,
      };
      this.$modal.show("showLogModal", obj);
    },
    getQuery() {
      let t =
        typeof this.term == "string" ? this.term.toLowerCase() : this.term;
      let q = this.log_search.replace("@TERM@", t);
      if (this.type != null) {
        if (this.type.toLowerCase() === "bol") {
          let x = `${t} OR BOL${t} OR CARADC${t}`;
          q = this.log_query.replace("@TERM@", x);
        }
        if (this.type.toLowerCase() === "manifest") {
          let x = `${t} OR QCC${t}`;
          q = this.log_query.replace("@TERM@", x);
        }
      }
      return q;
    },
  },
};
</script>
