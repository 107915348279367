<template>
  <b-container fluid class="h-100" style="overflow: hidden; overflow-y: auto">
    <div v-show="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <b-card no-body>
      <b-card-header>
        <b-row>
          <b-col md="3">
            {{ openBols.length }} Open Bills for Facility:
            <b-form-select
              id="facility-input"
              size="sm"
              style="width: auto"
              v-model="facility"
            >
              <b-form-select-option
                v-if="facility == null"
                :value="null"
                disabled
                >-- Select a Facility --</b-form-select-option
              >
              <b-form-select-option value="ADC">ADC</b-form-select-option>
              <b-form-select-option value="MTJOY">MTJOY</b-form-select-option>
              <b-form-select-option value="ATIRI">ATIRI</b-form-select-option>
              <b-form-select-option value="MTJOPEN"
                >MTJOPEN</b-form-select-option
              >
            </b-form-select>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body v-if="facility != null">
        <b-row>
          <b-col md="1">BOL</b-col>
          <b-col md="2">Date</b-col>
          <b-col>Stops</b-col>
          <b-col md="1">Carrier</b-col>
          <b-col md="1">Trailer</b-col>
          <b-col md="2">By</b-col>
          <b-col md="1">&nbsp;</b-col>
        </b-row>
        <b-row
          class="row-striped"
          v-for="bol in openBols"
          :key="bol.billOfLadingNo"
        >
          <b-col md="1">
            <router-link
              :to="{
                name: 'bol',
                params: { billOfLadingNo: bol.billOfLadingNo },
              }"
            >
              {{ bol.billOfLadingNo }}
            </router-link>
          </b-col>
          <b-col md="2">{{ displayDate(bol.dateCreated) }}</b-col>
          <b-col>
            <span v-for="stop in stops(bol.billOfLadingNo)" :key="stop.id">
              {{ stop.stopCode }}(P:{{ stop.pieces }}/W:{{ stop.weight }})
            </span>
          </b-col>
          <b-col md="1">{{ bol.carrierCode }}</b-col>
          <b-col md="1">{{ bol.trailerNo }}</b-col>
          <b-col md="2">{{ shortName(bol.modifiedBy) }}</b-col>
          <b-col md="1">
            <b-button
              v-if="loaded"
              :disabled="locked(bol.billOfLadingNo)"
              @click="deleteBolClicked(bol)"
              >X</b-button
            ></b-col
          >
        </b-row>
      </b-card-body>
    </b-card>
    <LoadingSplash />
  </b-container>
</template>

<script>
import dateutils from "../../../dateutil";
import textutils from "../../../textutils";
import { mapActions, mapGetters } from "vuex";
import LoadingSplash from "../../LoadingSplash.vue";

export default {
  components: {
    LoadingSplash,
  },
  data() {
    return {
      facility: null,
    };
  },
  computed: {
    ...mapGetters("common", ["errorMessage", "defaultFacility"]),
    ...mapGetters("openbols", ["openBols", "stops", "loaded", "locked"]),
  },
  watch: {
    facility(newVal) {
      if (newVal) {
        this.loadOpenBols(newVal);
      }
    },
  },
  methods: {
    ...mapActions("openbols", ["loadOpenBols", "deleteBol"]),
    displayDate(date) {
      return dateutils.getLocalDateTime(date, "YYYY-MM-DD hh:mma z");
    },
    shortName(name) {
      return textutils.shortUserName(name);
    },
    deleteBolClicked(bol) {
      this.deleteBol(bol);
    },
  },
  mounted() {
    this.facility = this.defaultFacility;
  },
};
</script>

<style scoped>
a {
  color: blue;
}
</style>
