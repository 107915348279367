<template>
  <b-form-row>
    <label class="col-md-1 col-form-label">Trailer:</label>
    <b-select v-if="isEdit" v-model="bol.trailerStayId" class="col-md-2">
      <template #first>
        <b-form-select-option value="" disabled
          >-- Select Trailer --</b-form-select-option
        >
      </template>
      <b-form-select-option
        v-for="stay in trailerStays"
        :key="stay.id"
        :value="stay.id"
      >
        {{ dropLabel(stay) }}
      </b-form-select-option>
    </b-select>
    <div v-else class="col-md-2 col-form-label">
      {{ bol.carrierCode }} {{ bol.trailerNo }}
      <b-button
        variant="light"
        size="md"
        @click="enableEdit"
        :disabled="!canEdit"
      >
        <font-awesome-icon title="Edit Trailer" icon="edit" />
      </b-button>
    </div>
    <party-display :code="bol.carrierCode" class="col-md-5 col-form-label" />
    <b-col v-show="bol.finalized" md="1" class="col-form-label"
      >Finalized By:</b-col
    >
    <b-col v-show="bol.finalized" md="3" class="col-form-label">{{
      bol.finalizedBy
    }}</b-col>
  </b-form-row>
</template>

<script>
import PartyDisplay from "../../common/PartyDisplay.vue";
import { yard } from "../../../api/movementapi";
import dateutil from "../../../dateutil";

export default {
  components: {
    PartyDisplay,
  },
  props: {
    bol: Object,
    overrideFinalized: Boolean,
  },
  data() {
    return {
      trailerStays: [],
      edit: false,
    };
  },
  watch: {
    "bol.trailerStayId": function (newVal) {
      let s = this.trailerStays.find((t) => t.id == newVal);
      if (s) {
        this.bol.carrierCode = s.trailer.scac;
        this.bol.trailerNo = s.trailer.id;
      } else {
        this.bol.carrierCode = null;
        this.bol.trailerNo = null;
      }
      this.edit = false;
    },
  },
  computed: {
    isEdit() {
      return this.edit && this.canEdit;
    },
    canEdit() {
      return this.bol.finalized ? this.overrideFinalized : true;
    },
  },
  methods: {
    enableEdit() {
      this.edit = true;
    },
    dropLabel(ts) {
      let label = `${ts.trailer.scac} ${ts.trailer.id}`;
      if (ts.endStay) {
        label = `${label} (${dateutil.getLocalDateTime(
          ts.endStay,
          "YYYY-MM-DD",
        )})`;
      }
      return label;
    },
    async loadStays() {
      let data = await yard
        .loadStays(this.bol.facility)
        .then((res) => res.data.payload);
      data.sort((s1, s2) => {
        let r = 0;
        if (s1 != null && s2 != null) {
          r = s1.trailer.scac.localeCompare(s2.trailer.scac);
          if (r == 0) {
            r = s1.trailer.id.localeCompare(s2.trailer.id);
          }
        }
        return r;
      });
      this.trailerStays = data;
      if (
        this.bol.trailerStayId &&
        !this.trailerStays.find((ts) => ts.id == this.bol.trailerStayId)
      ) {
        let ts = await yard
          .loadStay(this.bol.trailerStayId)
          .then((res) => res.data.payload);
        this.trailerStays.push(ts);
      }
      if (!this.bol.trailerNo) {
        this.edit = true;
      }
    },
  },
  mounted() {
    this.loadStays();
  },
};
</script>
