import {
  yard as yardApi,
  trailers as trailersApi,
  yard,
} from "../../api/movementapi";
import Vue from "vue";

function trailerSort(s1, s2) {
  let r = 0;
  if (s1 != null && s2 != null) {
    r = s1.trailer.scac.localeCompare(s2.trailer.scac);
    if (r == 0) {
      r = s1.trailer.id.localeCompare(s2.trailer.id);
    }
  }
  return r;
}

function beginDescSort(s1, s2) {
  let r = 0;
  if (s1 != null && s2 != null) {
    r = s1.beginStay.localeCompare(s2.beginStay);
  }
  return r;
}

function outboundLoadedAtDescSort(s1, s2) {
  let r = 0;
  if (s1 != null && s2 != null) {
    r = s1?.outboundLoadedAt?.localeCompare(s2?.outboundLoadedAt);
    r = r != null ? r : 0;
  }
  return r;
}

// initial state
const state = {
  selectedFacility: null,
  stays: null,
  selectedStayForActions: null,
  selectedActionPanel: null,
  facilityLocations: [],
  lastMoves: [],
  inboundDests: [],
  inboundContents: [],
  inboundOrigins: [],
  execInbDestFilter: null,
  execInbContentsFilter: null,
  execEmptyFilter: null,
  execOutFilter: null,
  execUnknownLoadedFilter: null,
  execStayDateProp: null,
  isCorrection: false,
};

const getters = {
  execInbDestFilter(state) {
    return state.execInbDestFilter;
  },
  execInbContentsFilter(state) {
    return state.execInbContentsFilter;
  },
  execOutFilter(state) {
    return state.execOutFilter;
  },
  doCheckIn(state) {
    return state.selectedFacility == "ADC" || state.selectedFacility == "MTJOY";
  },
  isCorrection(state) {
    return state.isCorrection;
  },
  execIsInbound(state) {
    return state.execInbContentsFilter || state.execInbDestFilter;
  },
  execIsOutbound(state) {
    return state.execOutFilter;
  },
  execModalTitle(state) {
    let t = "";
    if (state.inboundDests && state.inboundContents && state.inboundOrigins) {
      if (state.execInbContentsFilter) {
        t += state.inboundContents.find(
          (i) => i.id == state.execInbContentsFilter,
        )?.item;
      }
      if (state.execInbDestFilter) {
        t += state.inboundDests.find(
          (i) => i.id == state.execInbDestFilter,
        )?.item;
      }
      if (state.execEmptyFilter) {
        t += `Empty ${state.execEmptyFilter}`;
      }
      if (state.execOutFilter) {
        t += `Outbound ${state.execOutFilter}`;
      }
    }
    t += " Trailers";
    return t;
  },
  execStayDateProp(state) {
    return state.execStayDateProp;
  },
  inboundDests(state) {
    return state.inboundDests;
  },
  inboundContents(state) {
    return state.inboundContents;
  },
  inboundOrigins(state) {
    return state.inboundOrigins;
  },
  selectedFacility(state) {
    return state.selectedFacility;
  },
  facilityLocations(state) {
    return state.facilityLocations;
  },
  facilities(state) {
    return [...new Set(state.facilityLocations.map((f) => f.facility))].sort();
  },
  facilityGroups: (state) => (facility) => {
    return [
      ...new Set(
        state.facilityLocations
          .filter((f) => f.facility === facility)
          .map((f) => f.locGroup),
      ),
    ].sort();
  },
  lastMove: (state) => (stayId) => {
    return state.lastMoves.find(
      (m) => m.trailerStay != null && m.trailerStay.id === stayId,
    );
  },
  facilityGroupLocations: (state) => (facility, group) => {
    return state.facilityLocations
      .filter((f) => f.facility === facility && f.locGroup === group)
      .sort((l1, l2) => {
        let r = 0;
        if (l1 != null && l2 != null) {
          let n1 = new Number(l1.code.substring(1));
          let n2 = new Number(l2.code.substring(1));
          r = n1 - n2;
        }
        return r;
      });
  },
  stays(state) {
    return state.stays == null ? null : state.stays.sort(trailerSort);
  },
  checkedInStays: (state, getters) => {
    let r = null;
    if (state.stays) {
      if (getters.doCheckIn) {
        r = state.stays
          .filter((ts) => !ts.inboundLoaded || ts.inboundDestination != null)
          .sort(trailerSort);
      } else {
        r = state.stays;
      }
    }
    return r;
  },
  unknownLoadedStays(state) {
    return state.stays == null
      ? null
      : state.stays.filter(
          (ts) =>
            !ts.inboundLoaded &&
            ts.outboundBol == null &&
            ts.trailer.status == "LOADED",
        );
  },
  unknownLoadedStaysCount: (state, getters) => {
    return getters.unknownLoadedStays == null
      ? 0
      : getters.unknownLoadedStays.length;
  },
  inboundCount: (state, getters) => {
    return getters.loadedInboundStays == null
      ? 0
      : getters.loadedInboundStays.length;
  },
  emptyCount: (state) => {
    return state.stays == null
      ? 0
      : state.stays.filter((ts) => ts.trailer.status == "EMPTY").length;
  },
  outboundCount: (state) => {
    return state.stays == null
      ? 0
      : state.stays.filter(
          (ts) => ts.trailer.status == "LOADED" && ts.outboundLoadedAt != null,
        ).length;
  },
  loadedInboundStays: (state) => {
    return state.stays == null
      ? null
      : state.stays.filter(
          (ts) =>
            ts.inboundLoaded &&
            ts.inboundUnloadedAt == null &&
            ts.outboundLoadedAt == null,
        );
  },
  checkedInByDest: (state, getters) => (dest) => {
    return getters.loadedInboundStays == null
      ? null
      : getters.loadedInboundStays
          .filter((ts) => ts.inboundDestination == dest.id)
          .sort(beginDescSort);
  },
  checkedInByContent: (state, getters) => (obj) => {
    return getters.loadedInboundStays == null
      ? null
      : getters.loadedInboundStays
          .filter((ts) => ts.inboundContent == obj.id)
          .sort(beginDescSort);
  },
  execFilteredStays: (state, getters) => {
    let res = null;
    if (state.stays != null) {
      if (state.execInbContentsFilter || state.execInbDestFilter) {
        res = getters.loadedInboundStays
          .filter((ts) =>
            state.execInbDestFilter != null
              ? ts.inboundDestination == state.execInbDestFilter
              : true,
          )
          .filter((ts) =>
            state.execInbContentsFilter != null
              ? ts.inboundContent == state.execInbContentsFilter
              : true,
          )
          .sort(beginDescSort);
      }
      if (state.execUnknownLoadedFilter) {
        res = getters.unknownLoadedStays
          .filter((ts) => state.execUnknownLoadedFilter == ts.trailer.scac)
          .sort(trailerSort);
      }
      if (state.execEmptyFilter) {
        res = state.stays
          .filter((ts) => ts.trailer.status == "EMPTY")
          .filter((ts) => state.execEmptyFilter == ts.trailer.scac)
          .sort(trailerSort);
      }
      if (state.execOutFilter) {
        res = state.stays
          .filter(
            (ts) =>
              ts.outboundDestination != null &&
              ts.trailer.status == "LOADED" &&
              ts.outboundLoadedAt,
          )
          .filter((ts) => state.execOutFilter == ts.outboundDestination)
          .sort(outboundLoadedAtDescSort);
      }
    }
    return res;
  },
  emptyTrailerCounts(state) {
    return state.stays == null
      ? null
      : state.stays
          .filter((ts) => ts.trailer.status == "EMPTY")
          .reduce((res, ts) => {
            let x = res.find((r) => r.scac == ts.trailer.scac);
            if (!x) {
              x = { scac: ts.trailer.scac, count: 0 };
              res.push(x);
            }
            x.count += 1;
            return res;
          }, []);
  },
  outgoingTrailerCounts(state) {
    return state.stays == null
      ? null
      : state.stays
          .filter(
            (ts) =>
              ts.trailer.status == "LOADED" && ts.outboundLoadedAt != null,
          )
          .reduce((res, ts) => {
            let x = res.find((r) => r.dest == ts.outboundDestination);
            if (!x) {
              x = { dest: ts.outboundDestination, count: 0 };
              res.push(x);
            }
            x.count += 1;
            return res;
          }, []);
  },
  unknownLoadedTrailerCounts: (state, getters) => {
    return getters.unknownLoadedStays == null
      ? null
      : getters.unknownLoadedStays.reduce((res, ts) => {
          let x = res.find((r) => r.scac == ts.trailer.scac);
          if (!x) {
            x = { scac: ts.trailer.scac, count: 0 };
            res.push(x);
          }
          x.count += 1;
          return res;
        }, []);
  },
  clearedSecurityStays: (state, getters) => {
    let r = null;
    if (state.stays) {
      if (getters.doCheckIn) {
        r = state.stays
          .filter((ts) => ts.inboundLoaded && ts.inboundDestination == null)
          .sort(trailerSort);
      }
    }
    return r;
  },
  selectedStayForActions(state) {
    return state.selectedStayForActions;
  },
  selectedActionPanel(state) {
    return state.selectedActionPanel;
  },
};

const actions = {
  async setStayEmpty({ getters }) {
    return yardApi.setInboundEmpty(getters.selectedStayForActions);
  },
  async setStayLoaded({ getters }) {
    return yardApi.setInboundLoaded(getters.selectedStayForActions);
  },
  setCorrection({ commit }, isCorrection) {
    commit("setIsCorrection", isCorrection);
  },
  setExecInbDestFilter({ commit }, dest) {
    commit("setExecInbDestFilter", dest);
  },
  setExecInbContentsFilter({ commit }, content) {
    commit("setExecInbContentsFilter", content);
  },
  setExecEmptyFilter({ commit }, content) {
    commit("setExecEmptyFilter", content);
  },
  setExecOutFilter({ commit }, data) {
    commit("setExecOutFilter", data);
  },
  setExecUnknownLoadedFilter({ commit }, data) {
    commit("setExecUnknownLoadedFilter", data);
  },
  setExecStayDateProp({ commit }, data) {
    commit("setExecStayDateProp", data);
  },
  async selectFacility({ commit }, facility) {
    commit("setSelectedFacility", facility);
  },
  async findTrailer({ dispatch }, { scac, id }) {
    dispatch("common/setLoading", true, { root: true });
    return trailersApi.findTrailer(scac, id).finally(() => {
      dispatch("common/setLoading", false, { root: true });
    });
  },
  async stayEnded({ dispatch, state, commit }) {
    dispatch("common/setErrorMessage", null, { root: true });
    dispatch("common/setLoading", true, { root: true });
    return yardApi
      .stayEnded(state.selectedStayForActions)
      .catch((err) => {
        dispatch("common/handleHttpError", err, {
          root: true,
        });
      })
      .finally(() => {
        dispatch("common/setLoading", false, { root: true });
      });
  },
  async beginStay({ dispatch }, stay) {
    dispatch("common/setErrorMessage", null, { root: true });
    dispatch("common/setLoading", true, { root: true });
    return yardApi
      .beginStay(stay)
      .catch((err) => {
        dispatch("common/handleHttpError", err, {
          root: true,
        });
      })
      .finally(() => {
        dispatch("common/setLoading", false, { root: true });
      });
  },
  async updateTrailerNote({ dispatch }, { scac, id, note }) {
    dispatch("common/setErrorMessage", null, { root: true });
    dispatch("common/setLoading", true, { root: true });
    return trailersApi
      .setNote(scac, id, note)
      .catch((err) => {
        dispatch("common/handleHttpError", err, {
          root: true,
        });
      })
      .finally(() => {
        dispatch("common/setLoading", false, { root: true });
      });
  },
  async updateStay({ dispatch, getters }) {
    dispatch("common/setErrorMessage", null, { root: true });
    dispatch("common/setLoading", true, { root: true });
    return yard
      .updateStay(getters.selectedStayForActions)
      .catch((err) => {
        dispatch("common/handleHttpError", err, {
          root: true,
        });
      })
      .finally(() => {
        dispatch("common/setLoading", false, { root: true });
      });
  },
  async loadLookups({ dispatch, commit }) {
    dispatch("common/setErrorMessage", null, { root: true });
    dispatch("common/setLoading", true, { root: true });
    return trailersApi
      .loadFacilityLocations()
      .then((res) => {
        commit("setFacilityLocations", res.data.payload);
      })
      .catch((err) => {
        dispatch("common/handleHttpError", err, {
          root: true,
        });
      })
      .finally(() => {
        dispatch("common/setLoading", false, { root: true });
      });
  },
  async loadInitialData({ dispatch, getters, commit }) {
    dispatch("common/setErrorMessage", null, { root: true });
    dispatch("common/setLoading", true, { root: true });
    let inbDestsProm = yardApi
      .loadLookupInboundDests(getters.selectedFacility)
      .then((res) => commit("setInboundDests", res.data.payload));
    let inbContentsProm = yardApi
      .loadLookupInboundContents(getters.selectedFacility)
      .then((res) => commit("setInboundContents", res.data.payload));
    let inbOriginsProm = yardApi
      .loadLookupInboundOrigins(getters.selectedFacility)
      .then((res) => commit("setInboundOrigins", res.data.payload));
    let staysProm = yardApi.loadStays(getters.selectedFacility).then((res) => {
      res.data.payload.forEach((element) => {
        if (element.propsJson) {
          element.propsJson = JSON.parse(element.propsJson);
        }
      });
      commit("setStays", res.data.payload);
    });
    let movesProm = yardApi
      .loadLastMoves(getters.selectedFacility)
      .then((res) => {
        commit("setMoves", res.data.payload);
      });
    return Promise.all([
      inbDestsProm,
      inbContentsProm,
      inbOriginsProm,
      staysProm,
      movesProm,
    ])
      .catch((err) => {
        dispatch("common/handleHttpError", err, {
          root: true,
        });
      })
      .finally(() => {
        dispatch("common/setLoading", false, { root: true });
      });
  },
  async showStayActions({ commit }, { s, a }) {
    commit("setSelectedStayForActions", s);
    commit("setSelectedActionPanel", a);
  },
  async updateTrailer({ commit }, trailer) {
    commit("updateTrailer", trailer);
  },
  async stayMutated({ commit }, stayMutation) {
    commit("updateStay", stayMutation);
  },
  async spotMovementMutated({ commit }, spotMovementMutation) {
    commit("updateSpotMovement", spotMovementMutation);
  },
  async facilityLocationMutated({ commit }, mutation) {
    commit("updateFacilityLocation", mutation);
  },
  async _trailerMutation({ dispatch, commit }, promise) {
    if (getters.selectedStayForActions) {
      dispatch("common/setErrorMessage", null, { root: true });
      dispatch("common/setLoading", true, { root: true });
      promise
        .then((res) => {
          commit("updateTrailer", res.data.payload);
        })
        .catch((err) => {
          dispatch("common/handleHttpError", err, {
            root: true,
          });
        })
        .finally(() => {
          dispatch("common/setLoading", false, { root: true });
        });
    }
  },
  async updateStatus({ getters, dispatch }, status) {
    dispatch(
      "_trailerMutation",
      trailersApi.setStatus(
        getters.selectedStayForActions.trailer.scac,
        getters.selectedStayForActions.trailer.id,
        status,
      ),
    );
  },
  async updateRedTag({ getters, dispatch }, { redTag, note }) {
    dispatch(
      "_trailerMutation",
      trailersApi.setRedTag(
        getters.selectedStayForActions.trailer.scac,
        getters.selectedStayForActions.trailer.id,
        redTag,
        note,
      ),
    );
  },
  async updateYellowTag({ getters, dispatch }, { yellowTag, note }) {
    dispatch(
      "_trailerMutation",
      trailersApi.setYellowTag(
        getters.selectedStayForActions.trailer.scac,
        getters.selectedStayForActions.trailer.id,
        yellowTag,
        note,
      ),
    );
  },
  async updateFacilityLocation({ getters, dispatch }, loc) {
    dispatch(
      "_trailerMutation",
      trailersApi.setFacilityLocation(
        getters.selectedStayForActions.trailer.scac,
        getters.selectedStayForActions.trailer.id,
        loc,
      ),
    );
  },
};

const mutations = {
  setIsCorrection(state, isCorrection) {
    state.isCorrection = isCorrection;
  },
  setExecInbContentsFilter(state, data) {
    state.execInbContentsFilter = data;
  },
  setExecInbDestFilter(state, data) {
    state.execInbDestFilter = data;
  },
  setExecEmptyFilter(state, data) {
    state.execEmptyFilter = data;
  },
  setExecOutFilter(state, data) {
    state.execOutFilter = data;
  },
  setExecUnknownLoadedFilter(state, data) {
    state.execUnknownLoadedFilter = data;
  },
  setExecStayDateProp(state, data) {
    state.execStayDateProp = data;
  },
  setInboundDests(state, data) {
    state.inboundDests = data;
  },
  setInboundContents(state, data) {
    state.inboundContents = data;
  },
  setInboundOrigins(state, data) {
    state.inboundOrigins = data;
  },
  setSelectedFacility(state, facility) {
    state.selectedFacility = facility;
  },
  setStays(state, data) {
    state.stays = data;
  },
  setMoves(state, data) {
    state.lastMoves = data;
  },
  setSelectedStayForActions(state, t) {
    state.selectedStayForActions = t;
  },
  setSelectedActionPanel(state, a) {
    state.selectedActionPanel = a;
  },
  setFacilityLocations(state, data) {
    state.facilityLocations = data;
  },
  updateStay(state, stayMutation) {
    let stay = stayMutation.current;
    if (stay.propsJson) {
      stay.propsJson = JSON.parse(stay.propsJson);
    }
    if (stay.facility === state.selectedFacility) {
      if (stayMutation.action === "arrivedAtFacility") {
        let idx = state.stays.map((s) => s.id).indexOf(stay.id);
        if (idx < 0) {
          // not in list, add it
          state.stays.push(stay);
          this.$app.$toasted.info(
            `Trailer ${stay.trailer.scac} ${stay.trailer.id} arrived`,
          );
        } // otherwise it's already there
      }
      if (stayMutation.action === "departedFacility") {
        let idx = state.stays.map((s) => s.id).indexOf(stay.id);
        if (idx > -1) {
          // in the list, remove it
          state.stays.splice(idx, 1);
          this.$app.$toasted.info(
            `Trailer ${stay.trailer.scac} ${stay.trailer.id} departed`,
          );
        } // otherwise it's already not here
        // remove unneeded spot move if there is one
        idx = state.lastMoves.map((m) => m.trailerStay.id).indexOf(stay.id);
        if (idx > -1) {
          state.lastMoves.splice(idx, 1);
        }
      }
      if (stayMutation.action == "stayUpdated") {
        let idx = state.stays.map((s) => s.id).indexOf(stay.id);
        if (idx > -1) {
          let oldStay = state.stays[idx];
          Vue.set(state.stays, idx, stay);
          if (
            oldStay.inboundDestination == null &&
            stay.inboundDestination != null
          ) {
            this.$app.$toasted.info(
              `Trailer ${stay.trailer.scac} ${stay.trailer.id} checked in`,
            );
          } else {
            this.$app.$toasted.info(
              `Trailer ${stay.trailer.scac} ${stay.trailer.id} updated`,
            );
          }
        }
      }
    }
  },
  updateSpotMovement(state, spotMutation) {
    let spot = spotMutation.current
      ? spotMutation.current
      : spotMutation.previous;
    if (
      spot.facility === state.selectedFacility &&
      spotMutation.action === "completed"
    ) {
      let idx = state.lastMoves
        .map((m) => m.trailerStay.id)
        .indexOf(spot.trailerStay.id);
      if (idx > -1) {
        Vue.set(state.lastMoves, idx, spot);
      } else {
        state.lastMoves.push(spot);
      }
    }
  },
  updateFacilityLocation(state, mutation) {
    if (
      mutation.current &&
      mutation.current.facility === state.selectedFacility
    ) {
      let loc = mutation.current;
      let idx = state.stays
        .map((s) => s.trailer.facilityLocation.code)
        .indexOf(loc.code);
      if (idx > -1) {
        state.stays[idx].trailer.facilityLocation = loc;
      }
    }
  },
  updateTrailer(state, data) {
    if (data.facilityLocation) {
      if (data.facilityLocation.facility === state.selectedFacility) {
        // its in our facility lets try and find it
        let idx = state.stays
          .map((i) => i.trailer.scac + i.trailer.id)
          .indexOf(data.scac + data.id);
        if (idx > -1) {
          // already in list, update it
          let stay = state.stays[idx];
          stay.trailer = data;
          Vue.set(state.stays, idx, stay);
        } else {
          // not in list but has facility location, not good
          console.log(
            `WARNING: trailer ${data.scac} ${data.id} is at this facility but isn't in the stay list yet`,
          );
        }
      }
    } else {
      // not at a facility
      let idx = state.stays
        .map((i) => i.trailer.scac + i.trailer.id)
        .indexOf(data.scac + data.id); // look for it in our list just in case
      if (idx > -1) {
        // it's not a facility but is in our list, lets remove it
        console.log(
          `WARNING: trailer ${data.scac} ${data.id} not at any facility but its in our list, removing it`,
        );
        state.stays.splice(idx, 1);
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
