<template>
  <b-container
    fluid
    class="h-100"
    v-bind:style="{ 'max-height': tabContentHeight + 'px' }"
    style="overflow-y: auto"
  >
    <b-card no-body>
      <b-card-header>
        <div v-show="errorMessage" class="alert alert-danger">
          {{ errorMessage }}
        </div>
        <b-row>
          <b-col md="1">Yard - Facility:</b-col>
          <b-col md="1">
            <b-form-select v-model="facility">
              <b-form-select-option :value="null" disabled
                >Select Facility</b-form-select-option
              >
              <template v-for="f in facilities">
                <b-form-select-option :value="f" :key="f">{{
                  f
                }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col v-if="clearedSecurityStays">
            {{ numTrailersNotCheckedIn }} not checked in,
            {{ numTrailersCheckedIn }} checked in, {{ numTrailersTotal }} total
          </b-col>
        </b-row>
      </b-card-header>
    </b-card>
    <b-card v-if="checkedInStays">
      <b-row>
        <b-col>
          <b-row class="header">
            <b-col>Destination</b-col>
            <b-col>Inbound({{ inboundCount }})</b-col>
          </b-row>
          <b-row
            v-for="inbDest in inboundDests"
            :key="inbDest.id"
            class="row-striped"
            @click="showExecInbDestFilter(inbDest.id)"
          >
            <b-col>{{ inbDest.item }}</b-col>
            <b-col>{{ checkedInByDest(inbDest).length }}</b-col>
          </b-row>
          <b-row class="header">
            <b-col>Content</b-col>
            <b-col>Inbound({{ inboundCount }})</b-col>
          </b-row>
          <b-row
            v-for="inbDest in inboundContents"
            :key="inbDest.id"
            class="row-striped"
            @click="showExecInbContentFilter(inbDest.id)"
          >
            <b-col>{{ inbDest.item }}</b-col>
            <b-col>{{ checkedInByContent(inbDest).length }}</b-col>
          </b-row>
          <b-row v-if="unknownLoadedStaysCount > 0" class="header">
            <b-col>Unknown Loaded</b-col>
            <b-col>Count({{ unknownLoadedStaysCount }})</b-col>
          </b-row>
          <b-row
            v-for="item in unknownLoadedTrailerCounts"
            :key="item.scac"
            class="row-striped"
            @click="showExecUnknownLoadedFilter(item.scac)"
          >
            <b-col>{{ item.scac }}</b-col>
            <b-col>{{ item.count }}</b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row class="header">
            <b-col>Outbound</b-col>
            <b-col>Count({{ outboundCount }})</b-col>
          </b-row>
          <b-row
            v-for="item in outgoingTrailerCounts"
            :key="item.dest + '_out'"
            class="row-striped"
            @click="showExecOutFilter(item.dest)"
          >
            <b-col>{{ item.dest }}</b-col>
            <b-col>{{ item.count }}</b-col>
          </b-row>
          <b-row class="header">
            <b-col>Empty</b-col>
            <b-col>Count({{ emptyCount }})</b-col>
          </b-row>
          <b-row
            v-for="item in emptyTrailerCounts"
            :key="item.scac + '_emp'"
            class="row-striped"
            @click="showExecEmptyFilter(item.scac)"
          >
            <b-col>{{ item.scac }}</b-col>
            <b-col>{{ item.count }}</b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <yard-exec-trailer-list-modal />
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import messaging from "../../messaging";
import YardExecTrailerListModal from "./YardExecTrailerListModal.vue";

export default {
  components: {
    YardExecTrailerListModal,
  },
  data() {
    return {
      facility: null,
      windowHeight: window.innerHeight || 0,
    };
  },
  watch: {
    selectedFacility(newVal) {
      if (newVal) {
        this.facilitySelected();
      }
    },
    facility(newVal) {
      if (newVal) {
        this.$cookies.set("yard_facility", newVal);
        this.selectFacility(newVal);
      }
    },
  },
  computed: {
    ...mapGetters("security", ["inRole"]),
    ...mapGetters("common", ["errorMessage", "loading", "defaultFacility"]),
    ...mapGetters("yard", [
      "checkedInStays",
      "clearedSecurityStays",
      "stays",
      "selectedFacility",
      "facilities",
      "inboundOrigins",
      "inboundDests",
      "inboundContents",
      "checkedInByDest",
      "checkedInByContent",
      "emptyTrailerCounts",
      "outgoingTrailerCounts",
      "inboundCount",
      "emptyCount",
      "outboundCount",
      "unknownLoadedTrailerStays",
      "unknownLoadedTrailerCounts",
      "unknownLoadedStaysCount",
    ]),
    tabContentHeight() {
      return this.windowHeight - 100;
    },
    numTrailersNotCheckedIn() {
      return this.clearedSecurityStays.length;
    },
    numTrailersCheckedIn() {
      return this.checkedInStays.length;
    },
    numTrailersTotal() {
      return this.stays.length;
    },
  },
  methods: {
    ...mapActions("yard", [
      "selectFacility",
      "loadLookups",
      "loadInitialData",
      "updateTrailer",
      "stayMutated",
      "setExecInbDestFilter",
      "setExecInbContentsFilter",
      "setExecEmptyFilter",
      "setExecOutFilter",
      "setExecUnknownLoadedFilter",
      "setExecStayDateProp",
    ]),
    showExecInbDestFilter(filterId) {
      this.setExecInbDestFilter(filterId);
      this.setExecStayDateProp("beginStay");
      this.$bvModal.show("yard-exec-trailer-list-modal");
    },
    showExecInbContentFilter(filterId) {
      this.setExecInbContentsFilter(filterId);
      this.setExecStayDateProp("beginStay");
      this.$bvModal.show("yard-exec-trailer-list-modal");
    },
    showExecEmptyFilter(scac) {
      this.setExecEmptyFilter(scac);
      this.setExecStayDateProp("beginStay");
      this.$bvModal.show("yard-exec-trailer-list-modal");
    },
    showExecUnknownLoadedFilter(scac) {
      this.setExecUnknownLoadedFilter(scac);
      this.setExecStayDateProp("beginStay");
      this.$bvModal.show("yard-exec-trailer-list-modal");
    },
    showExecOutFilter(dest) {
      this.setExecOutFilter(dest);
      this.setExecStayDateProp("outboundLoadedAt");
      this.$bvModal.show("yard-exec-trailer-list-modal");
    },
    inbDestCount(type) {
      return this.checkedInStays.filter((s) => s.inboundDestination == type.id)
        .length;
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    handleTrailerMutations: function (msg) {
      let mutation = JSON.parse(msg.body);
      if (mutation != null && mutation.current != null) {
        this.updateTrailer(mutation.current);
      }
    },
    handleStayMutation: function (msg) {
      this.stayMutated(JSON.parse(msg.body));
    },
    subscribe() {
      messaging.subscribe(
        "YardExecSummaryPage.TrailerMutations",
        "/topic/Topic.TrailerMutations",
        this.handleTrailerMutations,
      );
      messaging.subscribe(
        "YardExecSummaryPage.TrailerStayMutations",
        "/topic/Topic.TrailerStayMutations",
        this.handleStayMutation,
      );
    },
    unsubscribe() {
      messaging.unsubscribe("YardExecSummaryPage.TrailerMutations");
      messaging.unsubscribe("YardExecSummaryPage.TrailerStayMutations");
    },
    facilitySelected() {
      let self = this;
      if (this.selectedFacility != null && this.selectedFacility.length > 0) {
        this.loadInitialData().then(function () {
          self.unsubscribe();
          self.subscribe();
          messaging.restart();
        });
      }
    },
  },
  mounted() {
    this.loadLookups().then(() => {
      this.facility = this.defaultFacility;
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  destroyed() {
    this.unsubscribe();
  },
};
</script>

<style scoped>
.header {
  background-color: blue;
  color: white;
}
</style>
