<template>
  <div>
    <h1 class="text-center">
      <span @click="selectShipment(null)" style="text-decoration: underline">
        {{ currentShipment.supplierId }} {{ currentShipment.dateShipped }}
        {{ currentShipment.trailer }} {{ currentShipment.trackingId }} ({{
          currentShipment.parcels
        }}
        of {{ currentShipment.pieces }})</span
      >
      &nbsp;
      <font-awesome-icon
        title="Show shipment in QCFS Manifest"
        @click="showShipmentInQcfsManifest"
        icon="share-square"
      />
    </h1>
    <b-tabs @activate-tab="tabChanged" :no-fade="true" id="jdmTabs">
      <b-tab title="Search Order">
        <SearchPanel :active="searchPanelActive" />
      </b-tab>
      <b-tab
        title="Ship To Summary"
        v-bind:style="{ 'max-height': tabContentHeight + 'px' }"
        style="overflow-y: scroll"
      >
        <template v-for="shipTo in shipToSummary">
          <div
            class="row"
            style="border-bottom: 1px black solid"
            :key="shipTo.shipToCode"
          >
            <div class="col-3">{{ shipTo.shipToCode }}</div>
            <div class="col-3">
              {{ shipTo.barcoded }} of {{ shipTo.pieces }}
            </div>
          </div>
        </template>
      </b-tab>
      <b-tab
        title="Orders Not Barcoded"
        v-bind:style="{ 'max-height': tabContentHeight + 'px' }"
        style="overflow-y: scroll"
      >
        <template v-for="order in ordersNotBarcoded">
          <div
            class="row"
            style="border-bottom: 1px black solid"
            :key="order.id"
          >
            <div class="col-3">{{ order.orderNo }}</div>
            <div class="col-3">
              {{
                order.quantityShipped + order.overShort - order.parcels.length
              }}
              not barcoded
            </div>
          </div>
        </template>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SearchPanel from "./SearchPanel.vue";
import { MANIFEST_URL } from "../../config";

export default {
  components: {
    SearchPanel,
  },
  data() {
    return {
      windowHeight: window.innerHeight || 0,
      searchPanelActive: false,
      manifestUrl: MANIFEST_URL,
    };
  },
  computed: {
    ...mapGetters("jdm", [
      "currentShipment",
      "shipToSummary",
      "ordersNotBarcoded",
    ]),
    tabContentHeight: function () {
      return this.windowHeight - 280 || 0;
    },
  },
  methods: {
    ...mapActions("jdm", ["selectShipment"]),
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    tabChanged(n) {
      this.searchPanelActive = n == 0;
    },
    showShipmentInQcfsManifest() {
      let u = `${this.manifestUrl}/IncomingShipmentView/Detail?incomingShipmentId=${this.currentShipment.id}`;
      window.open(u, "manifestFromMissy");
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
