<template>
  <b-container
    fluid
    class="h-100"
    v-bind:style="{ 'max-height': tabContentHeight + 'px' }"
    style="overflow-y: auto"
  >
    <b-card no-body>
      <b-card-header>
        <div v-show="errorMessage" class="alert alert-danger">
          {{ errorMessage }}
        </div>
        <b-row>
          <b-col md="1">Yard:</b-col>
          <b-col md="2">
            <b-form-select v-model="facility">
              <b-form-select-option :value="null" disabled
                >Select Facility</b-form-select-option
              >
              <template v-for="f in facilities">
                <b-form-select-option :value="f" :key="f">{{
                  f
                }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col md="9">
            <b-row class="float-right">
              <b-col cols="4" md="auto">Trailer Audit Report</b-col>
              <b-col cols="4" md="auto">
                <b-input
                  type="text"
                  :formatter="upper"
                  trim
                  placeholder="SCAC"
                  v-model="auditScac"
                />
              </b-col>
              <b-col cols="4" md="auto">
                <b-input
                  type="text"
                  :formatter="upper"
                  trim
                  placeholder="Trailer No"
                  v-model="auditId"
                />
              </b-col>
              <b-col cols="4" md="auto">
                <button
                  class="btn"
                  :disabled="auditScac == null || auditId == null"
                  title="Download Excel"
                  @click="trailerReport(facility, auditScac, auditId, '.xlsx')"
                >
                  <font-awesome-icon icon="file-excel" />
                </button>
                <button
                  class="btn"
                  title="Download PDF"
                  :disabled="auditScac == null || auditId == null"
                  @click="trailerReport(facility, auditScac, auditId, '.pdf')"
                >
                  <font-awesome-icon icon="file-pdf" />
                </button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-header>
    </b-card>
    <b-card no-body v-if="inYardSecurity && selectedFacility != null">
      <b-card-header>New Arrival</b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="auto">Carrier:</b-col>
          <b-col md="1">
            <b-input
              type="text"
              :formatter="upper"
              :lazy="true"
              trim
              v-model="checkInScac"
            />
          </b-col>
          <b-col cols="12" md="auto">Trailer No:</b-col>
          <b-col md="2">
            <b-input
              type="text"
              :formatter="upper"
              @blur="tryLoadType"
              :lazy="true"
              trim
              v-model="checkInId"
            />
          </b-col>
          <b-col cols="12" md="auto">Type:</b-col>
          <b-col md>
            <b-form-select v-model="checkInType">
              <b-form-select-option disabled :value="null"
                >Select Type</b-form-select-option
              >
              <b-form-select-option value="VAN">VAN</b-form-select-option>
              <b-form-select-option value="VAN48">VAN48</b-form-select-option>
              <b-form-select-option value="FLAT">FLAT</b-form-select-option>
              <b-form-select-option value="STEP">STEP</b-form-select-option>
              <b-form-select-option value="CONTAINER"
                >CONTAINER</b-form-select-option
              >
            </b-form-select>
          </b-col>
          <b-col cols="12" md="auto">Status:</b-col>
          <b-col md>
            <b-form-select v-model="checkInStatus">
              <b-form-select-option disabled :value="null"
                >Select Status</b-form-select-option
              >
              <b-form-select-option value="EMPTY">EMPTY</b-form-select-option>
              <b-form-select-option value="LOADED">LOADED</b-form-select-option>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="11">
            <b-row no-gutters>
              <b-col v-if="showProps" md="1">From:</b-col>
              <b-col v-if="showProps" md="auto">
                <b-form-select v-model="checkInFrom">
                  <b-form-select-option disabled :value="null"
                    >Select From</b-form-select-option
                  >
                  <b-form-select-option value="PDC">PDC</b-form-select-option>
                  <b-form-select-option value="DAVENPORT WORKS"
                    >DAVENPORT WORKS</b-form-select-option
                  >
                  <b-form-select-option value="CMC">CMC</b-form-select-option>
                  <b-form-select-option value="WWL"
                    >WWL (CONTAINERS)</b-form-select-option
                  >
                  <b-form-select-option value="MTJOY"
                    >MT JOY (QCCD)</b-form-select-option
                  >
                  <b-form-select-option value="UNKNOWN"
                    >UNKNOWN</b-form-select-option
                  >
                </b-form-select>
              </b-col>
              <b-col v-if="showCheckInNote" md="1">Note:</b-col>
              <b-col v-if="showCheckInNote" md="auto">
                <b-input
                  type="text"
                  :formatter="upper"
                  trim
                  v-model="checkInNote"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col md="1">
            <button
              :disabled="!validCheckIn"
              type="button"
              class="btn btn-primary"
              @click="emitCheckIn()"
            >
              Admit
            </button>
            &nbsp;
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body v-if="stays && doCheckIn">
      <b-card-header>
        Cleared Security ({{ clearedSecurityStays.length }})
        <button
          class="btn btn-primary"
          :disabled="clearFiltersDisabled"
          @click="clearFilters"
        >
          Clear Filters
        </button>
        <span class="float-right">
          Trailer Age Report
          <button
            class="btn"
            title="Download Excel"
            @click="yardReport('.xlsx')"
          >
            <font-awesome-icon icon="file-excel" />
          </button>
          <button class="btn" title="Download PDF" @click="yardReport('.pdf')">
            <font-awesome-icon icon="file-pdf" />
          </button>
        </span>
      </b-card-header>
      <b-card-body style="padding-left: 0px; padding-right: 0px">
        <b-row style="padding-bottom: 10px">
          <b-col>
            <b-row>
              <b-col>
                <b-input
                  placeholder="Filter Carrier"
                  type="text"
                  :formatter="upper"
                  trim
                  v-model="carrierFilter"
                  size="5"
                />
              </b-col>
              <b-col>
                <b-input
                  placeholder="Filter Trailer"
                  type="text"
                  :formatter="upper"
                  trim
                  v-model="trailerFilter"
                  size="5"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col md="1">
            <b-input
              placeholder="Filter Type"
              type="text"
              :formatter="upper"
              trim
              v-model="typeFilter"
              size="5"
            />
          </b-col>
          <b-col md="1">
            <b-input
              placeholder="Filter Loc"
              type="text"
              :formatter="upper"
              trim
              v-model="locFilter"
              size="5"
            />
          </b-col>
          <b-col md="1" style="text-align: center">
            <span :class="{ statusSelected: statusFilter == 'L' }">
              <span
                class="statusDisplay LOADED"
                @click="statusFilter = 'L'"
                title="LOADED"
                :style="{
                  paddingTop: '0px',
                  paddingRight: '8px',
                  paddingBottom: '0px',
                  paddingLeft: '8px',
                }"
                >&nbsp;</span
              > </span
            >&nbsp;
            <span :class="{ statusSelected: statusFilter == 'E' }">
              <span
                class="statusDisplay EMPTY"
                @click="statusFilter = 'E'"
                title="EMPTY"
                :style="{
                  paddingTop: '0px',
                  paddingRight: '8px',
                  paddingBottom: '0px',
                  paddingLeft: '8px',
                }"
                >&nbsp;</span
              > </span
            >&nbsp;
            <span :class="{ statusSelected: statusFilter == null }">
              <span
                class="statusDisplay EMPTY"
                @click="statusFilter = null"
                title="ALL"
                :style="{
                  paddingTop: '0px',
                  paddingRight: '8px',
                  paddingBottom: '0px',
                  paddingLeft: '8px',
                }"
              >
                <b>*</b>
              </span>
            </span>
          </b-col>
          <b-col md="2">Updated</b-col>
          <b-col md="2">Actions</b-col>
        </b-row>
        <b-row
          v-for="stay in filteredClearedSecurityStays"
          class="row-striped"
          :key="stay.id"
        >
          <!--<b-col md="1" class="mycol">{{stay.trailer.scac}}</b-col>-->
          <b-col
            v-if="inDispatch"
            @click="emitStaySelected(stay, 'checkin')"
            class="mycolaction"
          >
            {{ stay.trailer.scac }} {{ stay.trailer.id }}
            <span style="font-size: smaller" v-show="isTagged(stay.trailer)">
              (
              <font-awesome-icon
                icon="tag"
                :class="{
                  redTag: stay.trailer.redTag,
                  yellowTag: stay.trailer.yellowTag,
                }"
              />
              {{ stay.trailer.tagNote }}
              )
            </span>
            <em v-if="stay.trailer.note" style="font-size: smaller">{{
              stay.trailer.note
            }}</em>
            <em v-if="stay.propsJson" style="font-size: smaller">
              [{{ parseDeereFrom(stay) }}]
            </em>
            <div v-if="stay.outboundBol && inRole('Manifest Clerk')">
              BOL:
              <router-link
                :to="{
                  name: 'bol',
                  params: { billOfLadingNo: stay.outboundBol },
                }"
              >
                {{ stay.outboundBol }}
              </router-link>
            </div>
          </b-col>
          <b-col v-else md="2" class="mycol">
            {{ stay.trailer.scac }} {{ stay.trailer.id }}
            <span style="font-size: smaller" v-show="isTagged(stay.trailer)">
              (
              <font-awesome-icon
                icon="tag"
                :class="{
                  redTag: stay.trailer.redTag,
                  yellowTag: stay.trailer.yellowTag,
                }"
              />
              {{ stay.trailer.tagNote }}
              )
            </span>
            <em v-if="stay.trailer.note" style="font-size: smaller"
              >({{ stay.trailer.note }})</em
            >
          </b-col>
          <b-col md="1" class="mycol">{{ stay.trailer.type }}</b-col>
          <b-col
            md="1"
            v-if="inYardSpot"
            @click="emitStaySelected(stay, 'location')"
            class="mycolaction"
          >
            {{
              stay.trailer.facilityLocation != null
                ? stay.trailer.facilityLocation.code
                : null
            }}
            <i
              style="font-size: smaller"
              v-if="
                stay.trailer.facilityLocation != null &&
                stay.trailer.facilityLocation.note != null &&
                stay.trailer.facilityLocation.note.length > 0
              "
              >({{ stay.trailer.facilityLocation.note }})</i
            >
          </b-col>
          <b-col md="1" v-else class="mycol">{{
            stay.trailer.facilityLocation != null
              ? stay.trailer.facilityLocation.code
              : null
          }}</b-col>
          <b-col
            v-if="inDispatch"
            md="1"
            @click="emitStaySelected(stay, 'status', true)"
            class="mycol mycolaction"
            style="text-align: center"
          >
            <span
              class="statusDisplay"
              :class="stay.trailer.status"
              :title="stay.trailer.status"
              :style="{
                paddingTop: '0px',
                paddingRight: '8px',
                paddingBottom: '0px',
                paddingLeft: '8px',
              }"
              >&nbsp;</span
            >
          </b-col>
          <b-col
            v-else
            md="1"
            class="mycol mycolaction"
            style="text-align: center"
          >
            <span
              class="statusDisplay"
              :class="stay.trailer.status"
              :title="stay.trailer.status"
              :style="{
                paddingTop: '0px',
                paddingRight: '8px',
                paddingBottom: '0px',
                paddingLeft: '8px',
              }"
              >&nbsp;</span
            >
          </b-col>
          <b-col md="2" class="mycol" :title="stay.trailer.lastUpdatedBy"
            >{{ formatDate(stay.trailer.lastUpdatedAt) }}
            {{
              stay.trailer.lastUpdatedBy
                .substring(0, stay.trailer.lastUpdatedBy.indexOf("@"))
                .toUpperCase()
            }}
          </b-col>
          <b-col md="2" class="mycol">
            <button
              v-if="inDispatch"
              type="button"
              class="btn btn-primary"
              title="Check Trailer Into Facility"
              @click="emitStaySelected(stay, 'checkin')"
            >
              <font-awesome-icon icon="check" />
            </button>
            <button
              class="btn"
              title="Download Excel Trailer Audit"
              @click="
                trailerReport(
                  facility,
                  stay.trailer.scac,
                  stay.trailer.id,
                  '.xlsx',
                )
              "
            >
              <font-awesome-icon icon="file-excel" />
            </button>
            <button
              class="btn"
              title="Download PDF Trailer Audit"
              @click="
                trailerReport(
                  facility,
                  stay.trailer.scac,
                  stay.trailer.id,
                  '.pdf',
                )
              "
            >
              <font-awesome-icon icon="file-pdf" />
            </button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
      no-body
      v-if="stays && (inYardSecurity || inYardSpot || inDispatch)"
    >
      <b-card-header>
        Checked In ({{ checkedInStays.length }})
        <button
          class="btn btn-primary"
          :disabled="clearFiltersDisabled"
          @click="clearFilters"
        >
          Clear Filters
        </button>
        <span class="float-right">
          Trailer Age Report
          <button
            class="btn"
            title="Download Excel"
            @click="yardReport('.xlsx')"
          >
            <font-awesome-icon icon="file-excel" />
          </button>
          <button class="btn" title="Download PDF" @click="yardReport('.pdf')">
            <font-awesome-icon icon="file-pdf" />
          </button>
        </span>
      </b-card-header>
      <b-card-body style="padding-left: 0px; padding-right: 0px">
        <b-row style="padding-bottom: 10px">
          <b-col md="1">
            <b-input
              placeholder="Filter Carrier"
              type="text"
              :formatter="upper"
              trim
              v-model="carrierFilter"
              size="5"
            />
          </b-col>
          <b-col md="1">
            <b-input
              placeholder="Filter Trailer"
              type="text"
              :formatter="upper"
              trim
              v-model="trailerFilter"
              size="5"
            />
          </b-col>
          <b-col md="1">
            <b-input
              placeholder="Filter Type"
              type="text"
              :formatter="upper"
              trim
              v-model="typeFilter"
              size="5"
            />
          </b-col>
          <b-col md="1">
            <b-input
              placeholder="Filter Loc"
              type="text"
              :formatter="upper"
              trim
              v-model="locFilter"
              size="5"
            />
          </b-col>
          <b-col md="1" style="text-align: center">
            <span :class="{ statusSelected: statusFilter == 'L' }">
              <span
                class="statusDisplay LOADED"
                @click="statusFilter = 'L'"
                title="LOADED"
                :style="{
                  paddingTop: '0px',
                  paddingRight: '8px',
                  paddingBottom: '0px',
                  paddingLeft: '8px',
                }"
                >&nbsp;</span
              > </span
            >&nbsp;
            <span :class="{ statusSelected: statusFilter == 'E' }">
              <span
                class="statusDisplay EMPTY"
                @click="statusFilter = 'E'"
                title="EMPTY"
                :style="{
                  paddingTop: '0px',
                  paddingRight: '8px',
                  paddingBottom: '0px',
                  paddingLeft: '8px',
                }"
                >&nbsp;</span
              > </span
            >&nbsp;
            <span :class="{ statusSelected: statusFilter == null }">
              <span
                class="statusDisplay EMPTY"
                @click="statusFilter = null"
                title="ALL"
                :style="{
                  paddingTop: '0px',
                  paddingRight: '8px',
                  paddingBottom: '0px',
                  paddingLeft: '8px',
                }"
              >
                <b>*</b>
              </span>
            </span>
          </b-col>
          <b-col md>Last Move</b-col>
          <b-col md="2">Updated</b-col>
          <b-col md="2">Actions</b-col>
        </b-row>
        <b-row
          v-for="stay in filteredCheckedInStays"
          class="row-striped"
          :key="stay.id"
        >
          <!--<b-col md="1" class="mycol">{{stay.trailer.scac}}</b-col>-->
          <b-col
            v-if="inYardSpot || inDispatch"
            md="2"
            @click="emitStaySelected(stay, 'redTag')"
            class="mycolaction"
          >
            {{ stay.trailer.scac }} {{ stay.trailer.id }}
            <span style="font-size: smaller" v-show="isTagged(stay.trailer)">
              (
              <font-awesome-icon
                icon="tag"
                :class="{
                  redTag: stay.trailer.redTag,
                  yellowTag: stay.trailer.yellowTag,
                }"
              />
              {{ stay.trailer.tagNote }}
              )
            </span>
            <em v-if="stay.trailer.note" style="font-size: smaller">{{
              stay.trailer.note
            }}</em>
            <em v-if="stay.propsJson" style="font-size: smaller">
              [{{ parseDeereFrom(stay) }}]
            </em>
            <template v-if="inRole('Manifest Clerk') || inDispatch">
              <div style="font-size: smaller" v-if="showOutboundInfo(stay)">
                BOL:
                <router-link
                  class="clickable"
                  :to="{
                    name: 'bol',
                    params: { billOfLadingNo: stay.outboundBol },
                  }"
                >
                  {{ stay.outboundBol }} {{ stay.outboundDestination }}
                </router-link>
              </div>
              <div style="font-size: smaller" v-if="showInboundInfo(stay)">
                Origin: {{ getLookupValue(inboundOrigins, stay.inboundOrigin) }}
              </div>
              <div style="font-size: smaller" v-if="showInboundInfo(stay)">
                Dest:
                {{ getLookupValue(inboundDests, stay.inboundDestination) }}
              </div>
              <div style="font-size: smaller" v-if="showInboundInfo(stay)">
                Content:
                {{ getLookupValue(inboundContents, stay.inboundContent) }}
              </div>
            </template>
          </b-col>
          <b-col v-else md="2" class="mycol">
            {{ stay.trailer.scac }} {{ stay.trailer.id }}
            <span style="font-size: smaller" v-show="isTagged(stay.trailer)">
              (
              <font-awesome-icon
                icon="tag"
                :class="{
                  redTag: stay.trailer.redTag,
                  yellowTag: stay.trailer.yellowTag,
                }"
              />
              {{ stay.trailer.tagNote }}
              )
            </span>
            <em v-if="stay.trailer.note" style="font-size: smaller"
              >({{ stay.trailer.note }})</em
            >
          </b-col>
          <b-col md="1" class="mycol">{{ stay.trailer.type }}</b-col>
          <b-col
            md="1"
            v-if="inYardSpot"
            @click="emitStaySelected(stay, 'location')"
            class="mycolaction"
          >
            {{
              stay.trailer.facilityLocation != null
                ? stay.trailer.facilityLocation.code
                : null
            }}
            <i
              style="font-size: smaller"
              v-if="
                stay.trailer.facilityLocation != null &&
                stay.trailer.facilityLocation.note != null &&
                stay.trailer.facilityLocation.note.length > 0
              "
              >({{ stay.trailer.facilityLocation.note }})</i
            >
          </b-col>
          <b-col md="1" v-else class="mycol">{{
            stay.trailer.facilityLocation != null
              ? stay.trailer.facilityLocation.code
              : null
          }}</b-col>
          <b-col
            md="1"
            @click="emitStaySelected(stay, 'status')"
            class="mycol mycolaction"
            v-if="inRole('Dispatch') || inRole('Yard Spot')"
            style="text-align: center"
          >
            <span
              class="statusDisplay"
              :class="stay.trailer.status"
              :title="stay.trailer.status"
              :style="{
                paddingTop: '0px',
                paddingRight: '8px',
                paddingBottom: '0px',
                paddingLeft: '8px',
              }"
              >&nbsp;</span
            >
          </b-col>
          <b-col
            md="1"
            class="mycol mycolaction"
            style="text-align: center"
            v-else
          >
            <span
              class="statusDisplay"
              :class="stay.trailer.status"
              :title="stay.trailer.status"
              :style="{
                paddingTop: '0px',
                paddingRight: '8px',
                paddingBottom: '0px',
                paddingLeft: '8px',
              }"
              >&nbsp;</span
            >
          </b-col>
          <b-col md class="mycol">
            <span v-if="lastMove(stay.id) != null">{{
              lastMoveDesc(stay.id)
            }}</span>
          </b-col>
          <b-col md="2" class="mycol" :title="stay.trailer.lastUpdatedBy"
            >{{ formatDate(stay.trailer.lastUpdatedAt) }}
            {{
              stay.trailer.lastUpdatedBy
                .substring(0, stay.trailer.lastUpdatedBy.indexOf("@"))
                .toUpperCase()
            }}
          </b-col>
          <b-col md="2" class="mycol">
            <button
              v-if="inYardSecurity"
              type="button"
              class="btn btn-primary"
              title="Check Trailer Out Of Facility"
              @click="emitStaySelected(stay, 'checkOut')"
            >
              <font-awesome-icon icon="angle-double-right" />
            </button>
            <button
              class="btn"
              title="Download Excel Trailer Audit"
              @click="
                trailerReport(
                  facility,
                  stay.trailer.scac,
                  stay.trailer.id,
                  '.xlsx',
                )
              "
            >
              <font-awesome-icon icon="file-excel" />
            </button>
            <button
              class="btn"
              title="Download PDF Trailer Audit"
              @click="
                trailerReport(
                  facility,
                  stay.trailer.scac,
                  stay.trailer.id,
                  '.pdf',
                )
              "
            >
              <font-awesome-icon icon="file-pdf" />
            </button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <TrailerModal />
    <LoadingSplash />
  </b-container>
</template>

<script>
import TrailerModal from "./TrailerModal.vue";
import LoadingSplash from "../LoadingSplash.vue";
import { mapGetters, mapActions } from "vuex";
import messaging from "../../messaging";
import dateutil from "../../dateutil";
import textutils from "../../textutils";
import reportsapi from "../../api/reportsapi";

export default {
  components: {
    LoadingSplash,
    TrailerModal,
  },
  data() {
    return {
      checkInScac: null,
      checkInId: null,
      checkInType: null,
      checkInStatus: null,
      checkInFrom: null,
      checkInNote: null,
      facility: null,
      windowHeight: window.innerHeight || 0,
      carrierFilter: null,
      trailerFilter: null,
      typeFilter: null,
      statusFilter: null,
      locFilter: null,
      auditScac: null,
      auditId: null,
      systemTrailer: null,
      showProps: false,
      showCheckInNote: false,
    };
  },
  computed: {
    ...mapGetters("security", ["inRole"]),
    ...mapGetters("common", ["errorMessage", "loading", "defaultFacility"]),
    ...mapGetters("yard", [
      "stays",
      "checkedInStays",
      "clearedSecurityStays",
      "selectedFacility",
      "selectedStayForActions",
      "facilities",
      "facilityGroups",
      "facilityGroupLocations",
      "lastMove",
      "inboundOrigins",
      "inboundDests",
      "inboundContents",
      "doCheckIn",
    ]),
    clearFiltersDisabled: function () {
      return (
        this.carrierFilter == null &&
        this.trailerFilter == null &&
        this.typeFilter == null &&
        this.locFilter == null &&
        this.statusFilter == null
      );
    },
    inYardSecurity: function () {
      return this.inRole("Yard Security");
    },
    inYardSpot: function () {
      return this.inRole("Yard Spot");
    },
    inDispatch: function () {
      return this.inRole("Dispatch");
    },
    validCheckIn: function () {
      return (
        this.checkInScac != null &&
        this.checkInId != null &&
        this.checkInType != null &&
        this.checkInStatus != null &&
        (this.showProps ? this.checkInFrom != null : true)
      );
    },
    tabContentHeight: function () {
      //return this.windowHeight - (this.inYardSecurity ? 430 : 330) || 0;
      return this.windowHeight - 100;
    },
    filteredStays: function () {
      let filtered = this.stays;
      if (this.trailerFilter) {
        filtered = filtered.filter((s) =>
          s.trailer.id.startsWith(this.trailerFilter),
        );
      }
      if (this.typeFilter) {
        filtered = filtered.filter((s) =>
          s.trailer.type != null
            ? s.trailer.type.startsWith(this.typeFilter)
            : false,
        );
      }
      if (this.locFilter) {
        filtered = filtered.filter((s) =>
          s.trailer.facilityLocation != null
            ? s.trailer.facilityLocation.code.startsWith(this.locFilter)
            : false,
        );
      }
      if (this.carrierFilter) {
        filtered = filtered.filter((s) =>
          s.trailer.scac.startsWith(this.carrierFilter),
        );
      }
      if (this.statusFilter) {
        filtered = filtered.filter((s) =>
          s.trailer.status.startsWith(this.statusFilter),
        );
      }
      return filtered;
    },
    filteredCheckedInStays: function () {
      let filtered = this.checkedInStays;
      if (this.trailerFilter) {
        filtered = filtered.filter((s) =>
          s.trailer.id.startsWith(this.trailerFilter),
        );
      }
      if (this.typeFilter) {
        filtered = filtered.filter((s) =>
          s.trailer.type != null
            ? s.trailer.type.startsWith(this.typeFilter)
            : false,
        );
      }
      if (this.locFilter) {
        filtered = filtered.filter((s) =>
          s.trailer.facilityLocation != null
            ? s.trailer.facilityLocation.code.startsWith(this.locFilter)
            : false,
        );
      }
      if (this.carrierFilter) {
        filtered = filtered.filter((s) =>
          s.trailer.scac.startsWith(this.carrierFilter),
        );
      }
      if (this.statusFilter) {
        filtered = filtered.filter((s) =>
          s.trailer.status.startsWith(this.statusFilter),
        );
      }
      return filtered;
    },
    filteredClearedSecurityStays: function () {
      let filtered = this.clearedSecurityStays;
      if (this.trailerFilter) {
        filtered = filtered.filter((s) =>
          s.trailer.id.startsWith(this.trailerFilter),
        );
      }
      if (this.typeFilter) {
        filtered = filtered.filter((s) =>
          s.trailer.type != null
            ? s.trailer.type.startsWith(this.typeFilter)
            : false,
        );
      }
      if (this.locFilter) {
        filtered = filtered.filter((s) =>
          s.trailer.facilityLocation != null
            ? s.trailer.facilityLocation.code.startsWith(this.locFilter)
            : false,
        );
      }
      if (this.carrierFilter) {
        filtered = filtered.filter((s) =>
          s.trailer.scac.startsWith(this.carrierFilter),
        );
      }
      if (this.statusFilter) {
        filtered = filtered.filter((s) =>
          s.trailer.status.startsWith(this.statusFilter),
        );
      }
      return filtered;
    },
  },
  watch: {
    selectedFacility(newVal) {
      if (newVal) {
        this.facilitySelected();
      }
    },
    facility(newVal) {
      if (newVal) {
        this.$cookies.set("yard_facility", newVal);
        this.selectFacility(newVal);
      }
    },
    statusFilter(newVal) {
      if (newVal) {
        this.statusFilter = newVal.toUpperCase();
      }
    },
    checkInType(newVal) {
      if (this.systemTrailer != null) {
        this.systemTrailer.trailerGroup = this.lookupTrailerGroup(newVal);
        this.systemTrailer.type = newVal;
      }
    },
  },
  methods: {
    ...mapActions("yard", [
      "selectFacility",
      "loadInitialData",
      "loadLookups",
      "updateTrailer",
      "stayMutated",
      "spotMovementMutated",
      "facilityLocationMutated",
      "beginStay",
      "showStayActions",
      "findTrailer",
      "updateTrailerNote",
      "setCorrection",
    ]),
    showInboundInfo(stay) {
      return (
        stay.inboundLoaded && stay.inboundUnloadedAt == null && this.doCheckIn
      );
    },
    showOutboundInfo(stay) {
      return (
        !this.showInboundInfo(stay) &&
        stay.outboundBol &&
        stay.outboundDestination &&
        this.doCheckIn
      );
    },
    getLookupValue(arr, id) {
      let v = null;
      let s = arr.find((a) => a.id == id);
      if (s) {
        v = s.item;
      }
      return v;
    },
    parseDeereFrom(stay) {
      let r = "";
      if (stay.propsJson) {
        r = stay.propsJson.deereFrom;
      }
      return r;
    },
    isTagged(trailer) {
      return trailer.redTag || trailer.yellowTag;
    },
    lastMoveDesc(stayId) {
      let result = null;
      let move = this.lastMove(stayId);
      if (move != null) {
        result = `${move.from.code} to ${move.to.code} [${
          move.trailerStatus
        }] ${move.note != null ? move.note : ""}`;
      }
      return result;
    },
    upper(val) {
      return textutils.toUpper(val);
    },
    tryLoadType() {
      this.findTrailer({ scac: this.checkInScac, id: this.checkInId })
        .then((res) => {
          let t = res.data.payload;
          if (t != null) {
            this.systemTrailer = t;
            this.checkInType = t.type;
            if (this.showCheckInNote) {
              this.checkInNote = t.note;
            }
          } else {
            this.systemTrailer = null;
          }
        })
        .catch(() => {
          this.systemTrailer = null;
          this.checkInType = null;
        });
    },
    lookupTrailerGroup(type) {
      let group = type;
      if (type == "VAN48") {
        group = "VAN";
      }
      if (type === "FLAT") {
        group = "FLATBED";
      }
      if (type === "STEP") {
        group = "FLATBED";
      }
      return group;
    },
    handleTrailerMutations: function (msg) {
      let mutation = JSON.parse(msg.body);
      if (mutation != null && mutation.current != null) {
        this.updateTrailer(mutation.current);
      }
    },
    handleStayMutation: function (msg) {
      this.stayMutated(JSON.parse(msg.body));
    },
    handleSpotMovementMutations: function (msg) {
      this.spotMovementMutated(JSON.parse(msg.body));
    },
    handleFacilityLocationMutations: function (msg) {
      this.facilityLocationMutated(JSON.parse(msg.body));
    },
    emitStaySelected(stay, action, isCorrection = false) {
      this.setCorrection(isCorrection);
      this.showStayActions({ s: stay, a: action });
    },
    async emitCheckIn() {
      let t = null;
      if (this.systemTrailer != null) {
        this.systemTrailer.status = this.checkInStatus;
        t = this.systemTrailer;
      } else {
        t = {
          scac: this.checkInScac,
          id: this.checkInId,
          status: this.checkInStatus,
          trailerGroup: this.lookupTrailerGroup(this.checkInType),
          type: this.checkInType,
          note: this.checkInNote,
        };
      }
      let stay = {
        facility: this.selectedFacility,
        trailer: t,
      };
      if (this.checkInFrom) {
        let o = { deereFrom: this.checkInFrom };
        stay.propsJson = JSON.stringify(o);
      }
      if (this.checkInNote && this.systemTrailer) {
        await this.updateTrailerNote({
          scac: this.checkInScac,
          id: this.checkInId,
          note: this.checkInNote,
        });
      }
      await this.beginStay(stay).then(() => {
        this.checkInScac = null;
        this.checkInId = null;
        this.checkInStatus = null;
        this.checkInType = null;
        this.systemTrailer = null;
        this.checkInFrom = null;
        this.checkInNote = null;
      });
    },
    clearFilters() {
      this.carrierFilter = null;
      this.trailerFilter = null;
      this.typeFilter = null;
      this.locFilter = null;
      this.statusFilter = null;
    },
    facilitySelected: function () {
      let self = this;
      this.showCheckInNote =
        this.selectedFacility &&
        (this.selectedFacility == "ADC" || this.selectedFacility == "MTJDRAY");
      this.showProps =
        this.selectedFacility && this.selectedFacility == "MTJDRAY";
      if (this.selectedFacility != null && this.selectedFacility.length > 0) {
        this.loadInitialData().then(function () {
          self.unsubscribe();
          self.subscribe();
          messaging.restart();
        });
      }
    },
    formatDate(d) {
      return dateutil.getLocalDateTime(d, "MM-DD hh:mm a");
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    yardReport(extension) {
      window.open(
        reportsapi.getReportLink("TrailersOnPremiseAge", extension, {
          facility: this.facility,
        }),
        "_new",
        "",
      );
    },
    trailerReport(facility, scac, id, extension) {
      window.open(
        reportsapi.getReportLink(
          "TrailerAuditLog",
          extension,
          {
            facility: this.facility,
            TrailerCarrierCode: scac,
            TrailerNo: id,
          },
          true,
        ),
        "_new",
        "",
      );
      this.auditScac = null;
      this.auditId = null;
    },
    subscribe() {
      messaging.subscribe(
        "YardPage.TrailerMutations",
        "/topic/Topic.TrailerMutations",
        this.handleTrailerMutations,
      );
      messaging.subscribe(
        "YardPage.TrailerStayMutations",
        "/topic/Topic.TrailerStayMutations",
        this.handleStayMutation,
      );
      messaging.subscribe(
        "YardPage.SpotMovementMutations",
        "/topic/Topic.SpotMovementMutations",
        this.handleSpotMovementMutations,
      );
      messaging.subscribe(
        "YardPage.FacilityLocationMutations",
        "/topic/Topic.FacilityLocationMutations",
        this.handleFacilityLocationMutations,
      );
    },
    unsubscribe() {
      messaging.unsubscribe("YardPage.TrailerMutations");
      messaging.unsubscribe("YardPage.TrailerStayMutations");
      messaging.unsubscribe("YardPage.SpotMovementMutations");
      messaging.unsubscribe("YardPage.FacilityLocationMutations");
    },
  },
  mounted() {
    this.loadLookups().then(() => {
      this.facility = this.defaultFacility;
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  destroyed() {
    this.unsubscribe();
  },
};
</script>

<style scoped>
div.row {
  margin-left: 0px;
  margin-right: 0px;
}

.row-striped:nth-of-type(odd) {
  background-color: #efefef;
}

.row-striped:nth-of-type(even) {
  background-color: #ffffff;
}

.mycol {
  border-left: 1px lightgrey solid;
  border-right: 1px lightgrey solid;
}

.row-striped {
  border: 1px lightgrey solid;
}

.mycolaction:hover {
  background-color: lightgreen;
}

.row-striped:hover {
  background-color: lightblue;
}

.LOADED {
  background-color: black;
}

.EMPTY {
  background-color: transparent;
}

span.statusDisplay {
  border: 1px solid black;
}

.statusSelected {
  border: 4px solid lightgreen;
}
</style>
