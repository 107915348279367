<template>
  <div class="container-fluid h-100">
    <div class="jumbotron center">
      <h1 class="display-4">QCFS Missy</h1>
      <p class="lead">
        Report any issues to
        <a href="mailto:it@qcfreightservice.com">QCFS IT</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    makeToast() {
      this.$toasted.show("Toast body content", {
        type: "error",
        duration: 5000,
        fullWidth: true,
        position: "top-center",
      });
    },
  },
};
</script>
