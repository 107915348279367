import { bolApi, stopApi, manifestApi } from "../../api/manifest";
import Vue from "vue";

const state = {
  bol: null,
  stops: [],
  manifests: [],
  manifestSummaries: [],
};

const getters = {
  bol(state) {
    return state.bol;
  },
  stops(state) {
    state.stops.sort((a, b) => a.stopNo - b.stopNo);
    return state.stops;
  },
  manifests: (state) => (stopId) => {
    let filtered = state.manifests.filter((m) => m.stopId === stopId);
    filtered.sort((a, b) => a.manifestNo - b.manifestNo);
    return filtered;
  },
  manifestSummaries: (state) => {
    return state.manifestSummaries;
  },
  stopPieces: (state) => (stopId) => {
    let filtered = state.manifestSummaries.filter((m) => m.stopId === stopId);
    return filtered.map((m) => m.pieces).reduce((a, b) => a + b, 0);
  },
  stopWeight: (state) => (stopId) => {
    let filtered = state.manifestSummaries.filter((m) => m.stopId === stopId);
    return filtered.map((m) => m.weight).reduce((a, b) => a + b, 0);
  },
  stopIsHazmat: (state) => (stopId) => {
    let filtered = state.manifestSummaries.filter((m) => m.stopId === stopId);
    return filtered.map((m) => m.isHazmat).reduce((a, b) => a || b, false);
  },
  bolPieces: (state) => {
    return state.manifestSummaries
      .map((m) => m.pieces)
      .reduce((a, b) => a + b, 0);
  },
  bolWeight: (state) => {
    return state.manifestSummaries
      .map((m) => m.weight)
      .reduce((a, b) => a + b, 0);
  },
  bolIsHazmat: (state) => {
    return state.manifestSummaries
      .map((m) => m.isHazmat)
      .reduce((a, b) => a || b, false);
  },
  bolIsCanadian: (state) => {
    return state.manifestSummaries
      .map((m) => m.isCanadian)
      .reduce((a, b) => a || b, false);
  },
};

const actions = {
  async loadBol({ dispatch, commit }, bolNo) {
    commit("resetBol", null);
    if (bolNo != null) {
      let call = bolApi.loadBol(bolNo).then((res) => {
        commit("setBol", res.data);
        dispatch("loadStops");
      });
      return dispatch("common/showLoadingDuringAction", call, { root: true });
    }
  },
  async loadStops({ commit, getters, dispatch }) {
    bolApi
      .loadStopsForBol(getters.bol.billOfLadingNo)
      .then((res) => {
        res.data.payload.forEach((stop) => {
          commit("addStop", stop);
          dispatch("loadManifests", stop.id);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async loadManifests({ commit, dispatch }, stopId) {
    stopApi
      .loadManifests(stopId)
      .then((res) => {
        res.data.payload.forEach((manifest) => {
          commit("addManifest", manifest);
          dispatch("loadManifestSummary", manifest);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async loadManifestSummary({ commit }, manifest) {
    let summary = {
      manifestNo: manifest.manifestNo,
      stopId: manifest.stopId,
      pieces: 0,
      weight: 0,
      isHazmat: false,
    };
    return Promise.all([
      manifestApi.loadPieces(manifest.manifestNo),
      manifestApi.loadWeight(manifest.manifestNo),
      manifestApi.loadIsHazmat(manifest.manifestNo),
      manifestApi.loadIsCanadian(manifest.manifestNo),
    ]).then((res) => {
      summary.pieces = Number(res[0].data);
      summary.weight = Number(res[1].data);
      summary.isHazmat = res[2].data;
      summary.isCanadian = res[3].data;
      commit("addManifestSummary", summary);
    });
  },
  async createBol({ dispatch }, { bol, shipToCode, manifestNo }) {
    let call = bolApi.createBol(bol, shipToCode, manifestNo).then((res) => {
      dispatch("loadBol", res.data.payload.billOfLadingNo);
      return res.data.payload;
    });
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  async updateBol({ getters, commit, dispatch }) {
    let call = bolApi.updateBol(getters.bol).then((res) => {
      commit("setBol", res.data.payload);
      return res.data.payload;
    });
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  async finalizeBol({ getters, commit, dispatch }) {
    let call = bolApi.finalizeBol(getters.bol).then((res) => {
      commit("setBol", res.data.payload);
      return res.data.payload;
    });
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  async deleteBol({ getters, commit, dispatch }) {
    let call = bolApi.deleteBol(getters.bol).then((res) => {
      commit("resetBol", null);
      return res.data.payload;
    });
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  async createStop({ commit, dispatch }, { stop, manifestNo }) {
    let call = stopApi.createStop(stop, manifestNo).then((res) => {
      commit("addStop", res.data.payload);
      return res.data.payload;
    });
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  async updateStop({ commit, dispatch }, stop) {
    let call = stopApi.updateStop(stop).then((res) => {
      commit("updateStop", res.data.payload);
      return res.data.payload;
    });
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  async deleteStop({ commit, dispatch }, stop) {
    let call = stopApi.deleteStop(stop.id).then((res) => {
      commit("removeStop", res.data.payload);
      return res.data.payload;
    });
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  async moveStopDown({ commit, dispatch }, stop) {
    let call = stopApi.moveStopDown(stop.id).then((res) => {
      commit("updateStop", res.data.payload);
      return res.data.payload;
    });
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  async moveStopUp({ commit, dispatch }, stop) {
    let call = stopApi.moveStopUp(stop.id).then((res) => {
      commit("updateStop", res.data.payload);
      return res.data.payload;
    });
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  async removeManifestFromStop({ commit, dispatch }, manifest) {
    let call = manifestApi.removeFromStop(manifest.manifestNo).then((res) => {
      commit("removeManifest", res.data.payload);
      commit("removeManifestSummary", res.data.payload);
      return res.data.payload;
    });
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  async assignManifestToStop({ commit, dispatch }, { manifest, stop }) {
    let call = manifestApi
      .assignToStop(manifest.manifestNo, stop.id)
      .then((res) => {
        commit("addManifest", res.data.payload);
        dispatch("loadManifestSummary", res.data.payload);
        return res.data.payload;
      });
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  handleBolMutation({ commit, getters, rootGetters }, msg) {
    let mutation = JSON.parse(msg.body);
    if (mutation != null && mutation.current != null && getters.bol != null) {
      let bol = mutation.current;
      if (bol.billOfLadingNo === getters.bol.billOfLadingNo) {
        commit("setBol", bol);
        let user = rootGetters["security/userName"];
        if (user != mutation.by) {
          this.$app.$toasted.info(`BOL ${bol.billOfLadingNo} updated`);
        }
      }
    }
  },
  handleStopMutation({ commit, getters, rootGetters }, msg) {
    let mutation = JSON.parse(msg.body);
    if (mutation != null && getters.bol != null) {
      let user = rootGetters["security/userName"];
      if (mutation.current == null) {
        // removed
        if (mutation.previous.billOfLadingNo === getters.bol.billOfLadingNo) {
          commit("removeStop", mutation.previous);
          if (user != mutation.by) {
            this.$app.$toasted.info(
              `Stop ${mutation.previous.id} ${mutation.previous.stopCode} removed`,
            );
          }
        }
      } else {
        // some kind of update
        if (mutation.current.billOfLadingNo === getters.bol.billOfLadingNo) {
          let stop = mutation.current;
          let idx = getters.stops.map((r) => r.id).indexOf(stop.id);
          if (idx > -1) {
            commit("updateStop", stop);
            if (user != mutation.by) {
              this.$app.$toasted.info(
                `Stop ${stop.id} ${stop.stopNo} ${stop.stopCode} updated`,
              );
            }
          } else {
            commit("addStop", stop);
            if (user != mutation.by) {
              this.$app.$toasted.info(
                `Stop ${stop.id} ${stop.stopNo} ${stop.stopCode} added`,
              );
            }
          }
        }
      }
    }
  },
  handleManifestMutation({ commit, dispatch, getters, rootGetters }, msg) {
    let mutation = JSON.parse(msg.body);
    if (mutation != null && getters.bol != null) {
      let user = rootGetters["security/userName"];
      if (mutation.current == null) {
        let stop = getters.stops.find((s) => s.id === mutation.previous.stopId);
        if (stop) {
          commit("removeManifest", mutation.previous);
          commit("removeManifestSummary", mutation.previous);
          if (user != mutation.by) {
            this.$app.$toasted.info(
              `Manifest ${mutation.previous.manifestNo} removed from Stop ${stop.id} ${stop.stopNo} ${stop.stopCode}`,
            );
          }
        }
      } else {
        if (mutation.action === "ManifestAssignedToStop") {
          let manifest = mutation.current;
          let stop = getters.stops.find((s) => s.id === manifest.stopId);
          if (stop) {
            commit("addManifest", manifest);
            dispatch("loadManifestSummary", manifest);
            if (user != mutation.by) {
              this.$app.$toasted.info(
                `Manifest ${manifest.manifestNo} assigned to Stop ${stop.id} ${stop.stopNo} ${stop.stopCode}`,
              );
            }
          }
        }
        if (mutation.action === "ManifestRemovedFromStop") {
          let manifest = mutation.previous;
          let stop = getters.stops.find((s) => s.id === manifest.stopId);
          if (stop) {
            commit("removeManifest", manifest);
            commit("removeManifestSummary", manifest);
            if (user != mutation.by) {
              this.$app.$toasted.info(
                `Manifest ${manifest.manifestNo} removed from Stop ${stop.id} ${stop.stopNo} ${stop.stopCode}`,
              );
            }
          }
        }
      }
    }
  },
};

const mutations = {
  resetBol(state, t) {
    state.bol = t;
    state.stops = [];
    state.manifests = [];
    state.manifestSummaries = [];
  },
  setBol(state, bol) {
    state.bol = bol;
  },
  addStop(state, s) {
    let idx = state.stops.map((r) => r.id).indexOf(s.id);
    if (idx < 0) {
      state.stops.push(s);
    }
  },
  removeStop(state, s) {
    let idx = state.stops.map((r) => r.id).indexOf(s.id);
    if (idx > -1) {
      state.stops.splice(idx, 1);
    }
  },
  updateStop(state, s) {
    state.stops.forEach(function (item, i) {
      if (item.id === s.id) {
        Vue.set(state.stops, i, s);
      }
    });
  },
  addManifest(state, s) {
    let idx = state.manifests.map((r) => r.manifestNo).indexOf(s.manifestNo);
    if (idx < 0) {
      state.manifests.push(s);
    }
  },
  updateManifest(state, m) {
    let idx = state.manifests.map((r) => r.manifestNo).indexOf(m.manifestNo);
    if (idx > -1) {
      Vue.set(state.manifests, idx, m);
    }
  },
  removeManifest(state, m) {
    let idx = state.manifests.map((r) => r.manifestNo).indexOf(m.manifestNo);
    if (idx > -1) {
      state.manifests.splice(idx, 1);
    }
  },
  addManifestSummary(state, s) {
    let idx = state.manifestSummaries
      .map((r) => r.manifestNo)
      .indexOf(s.manifestNo);
    if (idx < 0) {
      state.manifestSummaries.push(s);
    }
  },
  removeManifestSummary(state, s) {
    let idx = state.manifestSummaries
      .map((r) => r.manifestNo)
      .indexOf(s.manifestNo);
    if (idx > -1) {
      state.manifestSummaries.splice(idx, 1);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
