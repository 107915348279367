<template>
  <b-col md="3">
    <b-card no-body>
      <b-card-header>
        <b-row>
          <b-col
            >Orders <small v-if="showManifest">[Manifest]</small>
            <small>(Pcs/Coded/Scanned)</small></b-col
          >
        </b-row>
      </b-card-header>
      <b-list-group
        flush
        v-bind:style="{ 'max-height': tabContentHeight - 60 + 'px' }"
        style="overflow-y: auto"
      >
        <b-list-group-item
          :ref="'order_' + line.id"
          v-for="line in lines"
          :key="line.id"
          :active="isCurrentOrder(line)"
          :class="{
            notFullyStaged: isNotStaged(line) && !isCurrentOrder(line),
          }"
          @click="setCurrentLine(line)"
        >
          <b-col>
            {{ line.orderNo }}
            <span v-if="showManifest">
              <span v-if="line.manifestNo != null">{{ line.manifestNo }}</span>
              <span v-else>-</span>
            </span>
            ({{ line.pieces }}/{{ lineParcelCount(line.id) }}/{{
              lineParcelCountVerified(line.id)
            }})
          </b-col>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </b-col>
</template>

<script>
export default {
  props: {
    lines: Array,
    isNotStaged: Function,
    isCurrentOrder: Function,
    setCurrentLine: Function,
    lineParcelCount: Function,
    lineParcelCountVerified: Function,
    currentLine: Object,
    showManifest: Boolean,
  },
  data() {
    return {
      windowHeight: window.innerHeight || 0,
    };
  },
  watch: {
    currentLine(v) {
      if (v && v.id) {
        let el = this.$refs[`order_${v.id}`][0];
        if (el) {
          el.scrollIntoView({
            block: "center",
            inline: "nearest",
            behavior: "smooth",
          });
        }
      }
    },
  },
  computed: {
    tabContentHeight: function () {
      return this.windowHeight - 100;
    },
  },
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight;
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style scoped>
.selectedOrder {
  background-color: blue;
  color: white;
}
.notFullyStaged {
  background-color: yellow;
}
.orderEntry {
  border: solid lightgray 1px;
}
</style>
