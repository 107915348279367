<template>
  <div>
    <div
      v-if="
        selectedStayForActions &&
        selectedStayForActions.trailer.facilityLocation != null
      "
    >
      <h5>Facility Location</h5>
      <select disabled v-model="selectedFacility" class="form-control">
        <option :value="null" disabled>Select Facility</option>
        <option v-for="f in facilities" :key="f">{{ f }}</option>
      </select>
      <select
        :disabled="!selectedFacility"
        v-model="selectedFacilityGroup"
        class="form-control"
      >
        <option :value="null" disabled>Select Group</option>
        <option v-for="g in facilityGroups(selectedFacility)" :key="g">
          {{ g }}
        </option>
      </select>
      <select
        :disabled="!selectedFacilityGroup"
        v-model="selectedFacilityLocation"
        class="form-control"
      >
        <option :value="null" disabled>Select Location</option>
        <option
          v-for="l in facilityGroupLocations(
            selectedFacility,
            selectedFacilityGroup,
          )"
          :key="l.code"
          :value="l"
        >
          {{ l.code }}
        </option>
      </select>
    </div>
    <br />
    <button
      @click="saveLocation"
      type="button"
      :disabled="!selectedFacilityLocation"
      class="btn btn-primary"
    >
      Update Location
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters("yard", [
      "selectedStayForActions",
      "facilities",
      "facilityGroups",
      "facilityGroupLocations",
    ]),
  },
  data() {
    return {
      selectedFacility: null,
      selectedFacilityGroup: null,
      selectedFacilityLocation: null,
    };
  },
  watch: {
    selectedStayForActions(newVal) {
      if (!newVal) {
        this.resetData();
      } else {
        if (newVal.facility) {
          this.selectedFacility = newVal.facility;
        }
      }
    },
  },
  mounted() {
    if (this.selectedStayForActions) {
      this.selectedFacility = this.selectedStayForActions.facility;
    }
  },
  methods: {
    ...mapActions("yard", [
      "showStayActions",
      "updateFacilityLocation",
      "setErrorMessage",
    ]),
    close() {
      this.showStayActions({ t: null, a: null });
    },
    saveLocation() {
      if (this.selectedFacilityLocation) {
        this.updateFacilityLocation(this.selectedFacilityLocation);
      }
      this.resetData();
      this.close();
    },
    resetData() {
      this.selectedFacility = null;
      this.selectedFacilityGroup = null;
      this.selectedFacilityLocation = null;
    },
  },
};
</script>
