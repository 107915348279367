import axios from "axios";
import store from "../store";

var API_URL = null;
try {
  // eslint-disable-next-line no-undef
  API_URL = QCFSSYSTEM_API_URL;
} catch (e) {
  API_URL = "http://localhost:18181/api";
}

const manifest = {
  async emailShippingDocuments(shipmentId) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/incomingshipmentheader/${shipmentId}/emailShipmentDocuments`;
    return axios.post(url);
  },
};

const headers = {
  generatePickListLink(shipmentId) {
    let url = `${API_URL}/incomingshipmentheader/${shipmentId}/picklist`;
    return url;
  },
};

const base = {
  userName() {
    return store.getters["security/userName"]
      ? store.getters["security/userName"]
      : "anonymous@qcfreightservice.com";
  },
  apiUrl() {
    return API_URL;
  },
};

export { base, manifest, headers };
