<template>
  <b-modal
    id="search-bol-modal"
    ref="form"
    title="Find Bill of Lading"
    @show="resetModal"
    @hidden="resetModal"
    @ok="okClicked"
    @shown="shown"
  >
    <b-form ref="searchBolModalForm" @submit.stop.prevent="handleSubmit">
      <b-form-group
        label="BOL #:"
        label-for="bol-input"
        invalid-feedback="BOL # is required"
        :state="bolState"
      >
        <b-form-input
          id="bol-input"
          ref="searchBolInput"
          v-model="bolNo"
          :state="bolState"
          required
          number
          trim
        ></b-form-input>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      bolNo: null,
      bolState: null,
    };
  },
  methods: {
    ...mapActions("bol", ["loadBol"]),
    checkFormValidity() {
      const valid = this.$refs.searchBolModalForm.checkValidity();
      this.bolState = valid;
      return valid;
    },
    resetModal() {
      this.bolNo = null;
      this.bolState = null;
    },
    okClicked(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.loadBol(this.bolNo);
      this.$nextTick(() => {
        this.$bvModal.hide("search-bol-modal");
      });
    },
    shown() {
      this.$refs.searchBolInput.focus();
    },
  },
};
</script>
