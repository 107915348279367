<template>
  <div>
    <div class="card">
      <div class="card-header">Add Bundle</div>
      <div class="card-body">
        <form>
          <div class="form-group row">
            <label for="bundle" class="col-sm-1 col-form-label">Bundle</label>
            <div class="col-sm-2">
              <b-form-input
                type="text"
                :formatter="upper"
                v-model.trim="addBundle"
                id="addBundle"
                placeholder="Bundle"
              />
            </div>
            <label class="col-sm-2" for="addOutside">Outside?</label>
            <b-checkbox class="col-sm-1" v-model="addOutside" id="addOutside" />
            <label for="addProtect" class="col-sm-2 col-form-label"
              >Protect Desc</label
            >
            <div class="col-sm-3">
              <b-form-input
                type="text"
                :formatter="upper"
                v-model.trim="addProtect"
                id="addProtect"
              />
            </div>
            <div class="col-sm-1">
              <button
                type="button"
                @click="doAdd"
                class="btn btn-primary"
                :disabled="disableAdd"
              >
                Add
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { bundleDefinition } from "../../api/bundleinventory";
import { mapGetters, mapActions } from "vuex";
import textutils from "../../textutils";

export default {
  data() {
    return {
      addBundle: null,
      addOutside: false,
      addProtect: null,
    };
  },
  computed: {
    disableAdd() {
      return !this.addBundle;
    },
  },
  methods: {
    ...mapActions("common", ["showLoadingDuringAction"]),
    doAdd() {
      if (this.addBundle) {
        let b = {
          bundle: this.addBundle,
          storeOutside: this.addOutside,
          protectionDesc: this.addProtect,
        };
        let c = bundleDefinition.createBundleDefinition(b);
        this.showLoadingDuringAction(c).then((res) => {
          if (res) {
            let r = res.data.payload;
            this.toastSuccess(`${r.bundle} added`);
            this.resetAdd();
          }
        });
      }
    },
    upper(t) {
      return textutils.toUpper(t);
    },
    resetAdd() {
      this.addBundle = null;
      this.addOutside = false;
      this.addProtect = null;
    },
    toastInfo(msg, pos = "top-right", dur = 5000) {
      this.$toasted.info(msg, {
        duration: dur,
        position: pos,
      });
    },
    toastSuccess(msg, pos = "top-right", dur = 5000) {
      this.$toasted.success(msg, {
        duration: dur,
        position: pos,
      });
    },
  },
};
</script>

<style scoped></style>
