// initial state
const state = {
  facility: null,
};

// getters
const getters = {
  facility: (state) => {
    return state.facility;
  },
};

const actions = {
  resetStore({ dispatch, commit }) {
    commit("setFacility", null);
    dispatch("common/setErrorMessage", null, { root: true });
    dispatch("common/setLoading", false, { root: true });
  },
  setFacility({ commit }, facility) {
    commit("setFacility", facility);
  },
};
// mutations
const mutations = {
  setFacility(state, f) {
    state.facility = f;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
