import Vue from "vue";
import Vuex from "vuex";
import common from "./modules/common";
import trailers from "./modules/trailers";
import trailerssimple from "./modules/trailerssimple";
import security from "./modules/security";
import forktruckaudit from "./modules/forktruckaudit";
import bundlestorage from "./modules/bundlestorage";
import bundlestoragemanagement from "./modules/bundlestoragemanagement";
import jdm from "./modules/jdm";
import yard from "./modules/yard";
import bays from "./modules/bays";
import routes from "./modules/driverroutes";
import spot from "./modules/spot";
import rating from "./modules/rating";
import parcels from "./modules/parcels";
import bol from "./modules/bol";
import openbols from "./modules/openbols";
import openmanifests from "./modules/openmanifests";
import shipment from "./modules/shipment";
import drivers from "./modules/drivers";
import edi from "./modules/edi";
import invBundles from "./modules/invBundles";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    common,
    trailers,
    trailerssimple,
    security,
    forktruckaudit,
    bundlestorage,
    bundlestoragemanagement,
    jdm,
    yard,
    bays,
    routes,
    spot,
    rating,
    parcels,
    bol,
    openbols,
    openmanifests,
    shipment,
    drivers,
    edi,
    invBundles,
  },
});
