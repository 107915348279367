<template>
  <modal
    name="addTrailerModal"
    height="auto"
    :scrollable="true"
    @before-close="beforeClose"
    @before-open="beforeOpen"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ title }}</h5>
        <div
          class="close"
          @click="$modal.hide('addTrailerModal')"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </div>
      </div>
      <div class="modal-body">
        <div class="card-body">
          <div class="form-group row">
            <label for="scac" class="col-sm-2 col-form-label">SCAC</label>
            <div class="col-sm-10">
              <input
                type="text"
                v-model.trim="scac"
                class="form-control text-uppercase"
                id="scac"
                placeholder="SCAC"
                required
                autofocus
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="id" class="col-sm-2 col-form-label">ID</label>
            <div class="col-sm-10">
              <input
                type="text"
                v-model.trim="id"
                class="form-control text-uppercase"
                id="id"
                placeholder="ID"
                required
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="type" class="col-sm-2 col-form-label">Type</label>
            <div class="col-sm-10">
              <select v-model="type" id="type" class="form-control" required>
                <option :value="null" disabled>Select Type</option>
                <option>VAN</option>
                <option>FLAT</option>
                <option>STEP</option>
                <option>CONTAINER</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label for="group" class="col-sm-2 col-form-label">Group</label>
            <div class="col-sm-10">
              <select v-model="group" id="group" class="form-control" required>
                <option :value="null" disabled>Select Group</option>
                <option>VAN</option>
                <option>FLATBED</option>
                <option>CONTAINER</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label for="status" class="col-sm-2 col-form-label">Status</label>
            <div class="col-sm-10">
              <select
                v-model="status"
                id="status"
                class="form-control"
                required
              >
                <option :value="null" disabled>Select Status</option>
                <option>EMPTY</option>
                <option>LOADED</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <button
              class="btn btn-primary"
              :disabled="!validInput"
              v-on:click="addTrailer"
            >
              Add Trailer
            </button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { trailers as trailerApi } from "../../api/movementapi";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      title: null,
      scac: null,
      id: null,
      status: null,
      type: null,
      group: null,
      callback: null,
    };
  },
  computed: {
    validInput() {
      return (
        this.scac != null &&
        this.id != null &&
        this.status != null &&
        this.type != null &&
        this.group != null
      );
    },
  },
  methods: {
    ...mapActions("common", ["showLoadingDuringAction"]),
    beforeOpen(event) {
      this.title = event.params.title;
      this.scac = event.params.scac;
      this.id = event.params.id;
      this.status = event.params.status;
      this.callback = event.params.callback;
    },
    beforeClose() {
      this.title = null;
      this.scac = null;
      this.id = null;
      this.type = null;
      this.group = null;
      this.callback = null;
      this.status = null;
    },
    addTrailer() {
      let t = {
        scac: this.scac,
        id: this.id,
        status: this.status,
        type: this.type,
        trailerGroup: this.group,
      };
      this.showLoadingDuringAction(trailerApi.addTrailer(t)).then((res) => {
        if (this.callback != null) {
          this.callback(res);
        }
        this.$modal.hide("addTrailerModal");
      });
    },
  },
};
</script>

<style scoped></style>
