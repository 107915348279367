<template>
  <div :class="displayClass">{{ display }}</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TrailerLocationDisplay",
  props: {
    trailer: Object,
  },
  computed: {
    ...mapGetters("trailers", ["activeRoute"]),
    display() {
      let loc = null;
      if (this.trailer.externalLocation) {
        loc = this.trailer.externalLocation;
      }
      if (this.trailer.facilityLocation) {
        loc =
          this.trailer.facilityLocation.facility +
          " " +
          this.trailer.facilityLocation.code;
      }
      if (
        this.trailer != null &&
        this.trailer.scac != null &&
        this.trailer.id != null
      ) {
        let r = this.activeRoute(this.trailer);
        if (r != null) {
          loc = `${r.activity} to ${r.endLoc} (${loc})`;
        }
      }
      return loc;
    },
    displayClass() {
      return this.trailer.externalLocation ? "externalLocation" : null;
    },
  },
};
</script>

<style scoped>
.externalLocation {
  font-style: italic;
  background-color: lightgrey;
  padding: 2px;
}
</style>
