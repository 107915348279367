<template>
  <b-dropdown size="sm" text="Docs" boundary="viewport">
    <b-dropdown-header
      >Docs for {{ this.shipment.shipmentName }}</b-dropdown-header
    >
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item @click="manifestClicked">Manifest</b-dropdown-item>
    <b-dropdown-item @click="pickListClicked">Pick List</b-dropdown-item>
    <b-dropdown-item @click="hazmatClicked">Hazmat</b-dropdown-item>
    <b-dropdown-item @click="compactInboundClicked"
      >Compact Inbound</b-dropdown-item
    >
    <b-dropdown-item @click="fullInboundClicked">Full Inbound</b-dropdown-item>
    <b-dropdown-item @click="parcelSheetClicked">Parcel Sheet</b-dropdown-item>
    <b-dropdown-item @click="fourBySixLabelsClicked"
      >4x6 Labels</b-dropdown-item
    >
    <b-dropdown-item @click="inbManifestClicked">Inb Manifest</b-dropdown-item>
    <b-dropdown-item @click="inbBolsClicked">Inb Bols</b-dropdown-item>
  </b-dropdown>
</template>

<script>
import reportsapi from "../../../api/reportsapi";
import { headers } from "../../../api/qcfssystem";

export default {
  props: {
    shipment: Object,
  },
  methods: {
    manifestClicked() {
      window.open(
        reportsapi.getReportLink("IncomingShipmentManifest", ".pdf", {
          ShipmentId: this.shipment.id,
        }),
        "_new",
      );
    },
    pickListClicked() {
      window.open(reportsapi.getPickListLink(this.shipment.id), "_new");
    },
    hazmatClicked() {
      window.open(
        reportsapi.getReportLink("IncomingShipmentHazmat", ".pdf", {
          ShipmentId: this.shipment.id,
        }),
        "_new",
      );
    },
    compactInboundClicked() {
      window.open(
        reportsapi.getReportLink("IncomingShipmentBarcodeSheet", ".pdf", {
          ShipmentId: this.shipment.id,
        }),
        "_new",
      );
    },
    fullInboundClicked() {
      let r =
        this.shipment.facility === "ADC"
          ? "BarcodeDetailSheetsForShipment"
          : "MtJoyBarcodeDetailSheetsForShipment";
      window.open(
        reportsapi.getReportLink(r, ".pdf", {
          incomingShipmentHeaderId: this.shipment.id,
        }),
        "_new",
      );
    },
    parcelSheetClicked() {
      window.open(
        reportsapi.getReportLink("Incoming_Parcels", ".pdf", {
          incomingShipmentHeaderId: this.shipment.id,
        }),
        "_new",
      );
    },
    fourBySixLabelsClicked() {
      window.open(
        reportsapi.getReportLink("4x6ShippingBC", ".pdf", {
          incomingShipmentHeaderId: this.shipment.id,
        }),
        "_new",
      );
    },
    inbManifestClicked() {
      window.open(
        reportsapi.getReportLink("Inbounds/InbBillingManifest", ".pdf", {
          incomingShipmentHeaderId: this.shipment.id,
        }),
        "_new",
      );
    },
    inbBolsClicked() {
      window.open(
        reportsapi.getReportLink("Inbounds/InbBillingManifestBol", ".pdf", {
          incomingShipmentHeaderId: this.shipment.id,
        }),
        "_new",
      );
    },
  },
};
</script>
