<template>
  <b-container fluid class="h-100">
    <div v-show="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <b-tabs @input="onTab" :no-fade="true" content-class="mt-3">
      <b-tab title="Assign" active>
        <b-row>
          <b-col md="1">
            <label for="assignSerialInput">Serial:</label>
          </b-col>
          <b-col>
            <b-form-input
              v-on:keyup.native.enter="focusAssignOrderInput"
              :formatter="formatter"
              type="text"
              ref="assignSerialInput"
              id="assignSerialInput"
              v-model="assignSerial"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="1">
            <label for="assignOrderInput">Order No:</label>
          </b-col>
          <b-col>
            <b-form-input
              v-on:keyup.native.enter="doAssign"
              :formatter="formatter"
              type="text"
              id="assignOrderInput"
              ref="assignOrderInput"
              v-model="assignOrder"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <button
              :disabled="!validAssign"
              ref="assignBtn"
              class="btn btn-icon"
              @click="doAssign"
            >
              <font-awesome-icon icon="plus-square" />
            </button>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Remove">
        <b-row class="justify-content-md-center">
          <b-col md="1">
            <label for="removeSerialInput">Serial:</label>
          </b-col>
          <b-col>
            <b-form-input
              v-on:keyup.native.enter="doRemove"
              :formatter="formatter"
              type="text"
              ref="removeSerialInput"
              id="removeSerialInput"
              v-model="removeSerial"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <button
              :disabled="!validRemove"
              ref="removeBtn"
              class="btn btn-icon"
              @click="doRemove"
            >
              <font-awesome-icon icon="minus-square" />
            </button>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Find">
        <b-row>
          <b-col md="1">
            <label for="findSerialInput">Serial:</label>
          </b-col>
          <b-col>
            <b-form-input
              v-on:keyup.native.enter="focusFindOrderInput"
              :formatter="formatter"
              type="text"
              ref="findSerialInput"
              id="findSerialInput"
              v-model="findSerial"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="1">
            <label for="findOrderInput">Order No:</label>
          </b-col>
          <b-col>
            <b-form-input
              v-on:keyup.native.enter="doFind"
              :formatter="formatter"
              type="text"
              ref="findOrderInput"
              id="findOrderInput"
              v-model="findOrder"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <button
              :disabled="!validFind"
              ref="findBtn"
              class="btn btn-icon"
              @click="doFind"
            >
              <font-awesome-icon icon="search" />
            </button>
          </b-col>
        </b-row>
        <template v-if="searchResults.length > 0">
          <b-row class="resultsHeader">
            <b-col>Order Number</b-col>
            <b-col>Serial Number</b-col>
          </b-row>
          <b-row v-for="item in searchResults" :key="item.id">
            <b-col>{{ item.orderNumber }}</b-col>
            <b-col>{{ item.serialNumber }}</b-col>
          </b-row>
        </template>
      </b-tab>
    </b-tabs>
    <LoadingSplash />
  </b-container>
</template>

<script>
import textutils from "../../textutils";
import { serial as serialApi } from "../../api/manifest";
import { mapActions, mapGetters } from "vuex";
import LoadingSplash from "../LoadingSplash.vue";

export default {
  components: {
    LoadingSplash,
  },
  data() {
    return {
      assignOrder: null,
      assignSerial: null,
      removeSerial: null,
      findSerial: null,
      findOrder: null,
      searchResults: [],
    };
  },
  computed: {
    ...mapGetters("common", ["errorMessage"]),
    validAssign() {
      return this.assignOrder != null && this.assignSerial != null;
    },
    validRemove() {
      return this.removeSerial != null;
    },
    validFind() {
      return this.findOrder != null || this.findSerial != null;
    },
  },
  methods: {
    ...mapActions("common", ["showLoadingDuringAction", "setErrorMessage"]),
    onTab(tabIndex) {
      if (tabIndex == 0) {
        window.setTimeout(() => this.$refs.assignSerialInput.focus(), 100);
      }
      if (tabIndex == 1) {
        window.setTimeout(() => this.$refs.removeSerialInput.focus(), 100);
      }
      if (tabIndex == 2) {
        window.setTimeout(() => this.$refs.findSerialInput.focus(), 100);
      }
    },
    focusAssignOrderInput() {
      this.$refs.assignOrderInput.focus();
    },
    focusFindOrderInput() {
      this.$refs.findOrderInput.focus();
    },
    formatter(val) {
      return textutils.toUpper(val);
    },
    doAssign() {
      this.searchResults = [];
      if (this.validAssign) {
        this.$refs.assignBtn.focus();
        let p = serialApi
          .create(this.assignSerial, this.assignOrder)
          .then(() =>
            this.showToast(
              `${this.assignSerial} assigned to ${this.assignOrder}`,
            ),
          );
        this.showLoadingDuringAction(p);
      }
    },
    doRemove() {
      this.searchResults = [];
      if (this.validRemove) {
        this.$refs.removeBtn.focus();
        let p = serialApi
          .delete(this.removeSerial)
          .then((res) =>
            this.showToast(
              `${res.data.payload.serialNumber} removed from ${res.data.payload.orderNumber}`,
            ),
          );
        this.showLoadingDuringAction(p);
      }
    },
    doFind() {
      this.searchResults = [];
      if (this.validFind) {
        this.$refs.findBtn.focus();
        let p = null;
        if (this.findSerial != null) {
          p = serialApi
            .findBySerial(this.findSerial)
            .then((res) => this.showResult(res));
        } else {
          p = serialApi
            .findByOrder(this.findOrder)
            .then((res) => this.showResults(res));
        }
        this.showLoadingDuringAction(p);
      }
    },
    showToast(msg) {
      this.clearInputs();
      this.$toasted.success(msg, { duration: 5000, position: "bottom-right" });
    },
    showResults(res) {
      let data = res.data;
      if (data.error) {
        this.setErrorMessage(data.errorMessage);
      } else {
        this.searchResults = data.payload;
      }
      this.clearInputs();
    },
    showResult(res) {
      let data = res.data;
      if (data.error) {
        this.setErrorMessage(data.errorMessage);
      } else {
        this.searchResults = [data.payload];
      }
      this.clearInputs();
    },
    clearInputs() {
      this.assignOrder = null;
      this.assignSerial = null;
      this.removeSerial = null;
      this.findSerial = null;
      this.findOrder = null;
    },
  },
  mounted() {
    this.onTab(0);
  },
};
</script>

<style scoped>
.btn-icon {
  background-color: lightgray;
}
.resultsHeader {
  background-color: lightgray;
}
</style>
