import axios from "axios";
import store from "../store";
import textutils from "../textutils";

var API_URL = null;
try {
  // eslint-disable-next-line no-undef
  API_URL = EXPORT_API_URL;
} catch (e) {
  API_URL = "http://exportapi.home";
}

export default {
  async create(item) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/exportinventory/items?user=${store.getters["security/userName"]}`;
    return axios.post(url, item, {
      headers: { "Content-Type": "application/json" },
    });
  },
  async update(item) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/exportinventory/items/${item.id}?user=${store.getters["security/userName"]}`;
    return axios.put(url, item, {
      headers: { "Content-Type": "application/json" },
    });
  },
  async returnShipment(id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/exportinventory/return/${id}?user=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
  async shipContainer(container) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/exportinventory/shipcontainer/${container}?user=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
  async ship(id, container) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/exportinventory/ship/${id}/${container}?user=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
  async delete(id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/exportinventory/items/${id}`;
    return axios.delete(url);
  },
  async search(
    caseCode,
    orderNo,
    location,
    unit,
    account,
    dest,
    shippedContainer,
    includeShipped,
  ) {
    await store.dispatch("security/refreshSecurity");
    let url = new URL(
      `${API_URL}/exportinventory/search?user=${store.getters["security/userName"]}`,
    );
    textutils.appendUrlQuery(url, "caseCode", caseCode);
    textutils.appendUrlQuery(url, "orderNo", orderNo);
    textutils.appendUrlQuery(url, "location", location);
    textutils.appendUrlQuery(url, "unit", unit);
    textutils.appendUrlQuery(url, "account", account);
    textutils.appendUrlQuery(url, "dest", dest);
    textutils.appendUrlQuery(url, "shippedContainer", shippedContainer);
    textutils.appendUrlQuery(url, "includeShipped", includeShipped);
    return axios.get(url);
  },
};
