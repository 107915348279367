<template>
  <div>
    <div class="text-center">
      <font-awesome-icon
        icon="window-close"
        @click="selectPallet(null)"
        v-show="selectedPallet != null"
      />
      <span v-show="selectedPallet != null">Selected:</span>
      {{ selectedPallet ? selectedPallet.name : "" }}
      <button @click="assignToOp" v-show="selectedPallet != null">
        Generate Overpack Sheet</button
      ><br />
      <h6>
        {{ overpack }} -
        <a v-if="overpackLink != null" :href="overpackLink" target="qcfsop">{{
          overpackLink
        }}</a>
      </h6>
    </div>
    <div
      v-bind:style="{ 'max-height': tabContentHeight - 70 + 'px' }"
      style="overflow-y: auto"
    >
      <template v-for="pallet in pallets">
        <div
          @click="selectPallet(pallet)"
          v-bind:class="{ selectedPallet: pallet === selectedPallet }"
          class="row"
          style="border-bottom: 1px black solid"
          :key="pallet.id"
        >
          <div class="col-3">{{ pallet.name }}:</div>
          <div class="col-3">{{ pallet.contents.length }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import reportsapi from "../../api/reportsapi";

export default {
  name: "PalletList",
  props: {
    tabContentHeight: Number,
  },
  data() {
    return {
      selectedPallet: null,
      overpackLink: null,
      overpack: null,
    };
  },
  computed: {
    ...mapGetters("jdm", ["pallets", "facility", "apiUrl"]),
  },
  methods: {
    ...mapActions("jdm", ["generateOverpack", "assignLocationToOverpack"]),
    selectPallet: function (pallet) {
      this.selectedPallet = pallet;
    },
    async assignToOp() {
      if (
        confirm(
          `Are you sure you want to generate an overpack for ${this.selectedPallet.name}?`,
        )
      ) {
        let res = await this.generateOverpack();
        let op = res.data.payload[0];
        if (op) {
          //            this.overpackLink = `${this.apiUrl}/report?QCFSReport=BarcodeDetailSheet&QCFSReportExt=.pdf&inline=true&ParcelCode=${op}&facility=${this.facility}`;
          this.overpackLink = reportsapi.getReportLink(
            "BarcodeDetailSheet",
            ".pdf",
            {
              ParcelCode: op,
              facility: this.facility,
            },
          );
          this.overpack = `${op}(${this.selectedPallet.name})`;
          await this.assignLocationToOverpack({
            location: this.selectedPallet.name,
            overpack: op,
            overpackLink: this.overpackLink,
          });
          this.selectPallet(null);
        }
      }
    },
  },
};
</script>

<style scoped>
.selectedPallet {
  background-color: green;
  color: white;
}
</style>
