import axios from "axios";
import store from "../store";
import textutils from "../textutils";

var API_URL = null;
try {
  // eslint-disable-next-line no-undef
  API_URL = INTEGRATION_API_URL;
} catch (e) {
  API_URL = "http://integrationapi.home";
  //API_URL = "http://localhost:9090";
}

const edi = {
  async loadTenders() {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/edi/204/dashboard`;
    return axios.get(url);
  },
  async acceptEdi204(id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/edi/204/${id}/accept?by=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
  async denyEdi204(id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/edi/204/${id}/deny?by=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
  async updateTrailer(id, trailer) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/edi/204/${id}/trailer/${trailer}?by=${store.getters["security/userName"]}`;
    return axios.put(url);
  },
  generateEdi204SheetLink(id) {
    return `${API_URL}/edi/204/${id}/html`;
  },
  generateEdi204PdfSheetLink(id) {
    return `${API_URL}/edi/204/${id}/pdf`;
  },
  async searchEdi204(shipmentId) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/edi/204/search?shipmentId=${shipmentId}`;
    return axios.get(url);
  },
};

export { edi };
