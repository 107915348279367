<template>
  <div class="container-fluid h-100">
    <div class="card">
      <div class="card-header">
        <div v-show="errorMessage" class="alert alert-danger">
          {{ errorMessage }}
        </div>
      </div>
      <div class="card-body">
        <span v-if="!rating"
          >Manifests: {{ unratedManifests.length }}
          <button
            v-if="unratedManifests.length > 0"
            type="button"
            @click="rate"
            class="btn btn-primary"
          >
            Rate Manifests
          </button></span
        >
        <span v-if="rating"
          >Manifest {{ ratedCount }} of
          {{ unratedManifests.length }} rated</span
        >
        <span v-if="rated">
          &nbsp;
          <button
            type="button"
            @click="ratedManifestReport"
            class="btn btn-primary"
          >
            Rated Manifest Report
          </button>
        </span>
        <hr />
        <div
          v-bind:style="{ 'max-height': tabContentHeight + 'px' }"
          style="overflow-y: auto"
        >
          <table class="table table-bordered table-sm">
            <thead>
              <tr>
                <td>Manifest</td>
                <td>Date</td>
                <td>Ship To</td>
                <td>Carrier</td>
                <td>PU</td>
                <td>S &amp; S</td>
                <td>Handling</td>
                <td>QCCD</td>
                <td>Fuel</td>
                <td>TOTAL</td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="manifest in unratedManifests"
                :key="manifest.manifestNo"
                :id="manifest.manifestNo"
                :class="{ rateError: manifest.errorMessage != null }"
              >
                <td>{{ manifest.manifestNo }}</td>
                <td>{{ formatLongDate(manifest.date) }}</td>
                <td>
                  <PartyDisplay
                    :code="manifest.shipToCode"
                    :isVertical="true"
                  />
                </td>
                <td>
                  <PartyDisplay
                    :code="manifest.carrierCode"
                    :isVertical="true"
                  />
                </td>
                <td colspan="6" v-if="manifest.errorMessage != null">
                  {{ manifest.errorMessage }}
                </td>
                <td v-if="manifest.errorMessage == null" class="acctCol">
                  {{
                    manifest.rating != null
                      ? `${
                          manifest.rating.pu
                        } $${manifest.rating.totalPu.toFixed(2)}`
                      : "..."
                  }}
                </td>
                <td v-if="manifest.errorMessage == null" class="acctCol">
                  {{
                    manifest.rating != null
                      ? `${
                          manifest.rating.split
                        } $${manifest.rating.totalSplit.toFixed(2)}`
                      : ""
                  }}
                </td>
                <td v-if="manifest.errorMessage == null" class="acctCol">
                  {{
                    manifest.rating != null
                      ? `${
                          manifest.rating.handling
                        } $${manifest.rating.totalHandling.toFixed(2)}`
                      : ""
                  }}
                </td>
                <td v-if="manifest.errorMessage == null" class="acctCol">
                  {{
                    manifest.rating != null
                      ? `${
                          manifest.rating.qccd
                        } $${manifest.rating.totalQccd.toFixed(2)}`
                      : ""
                  }}
                </td>
                <td v-if="manifest.errorMessage == null" class="acctCol">
                  {{
                    manifest.rating != null
                      ? `${
                          manifest.rating.miles
                        } $${manifest.rating.milesCharges.toFixed(2)}`
                      : ""
                  }}
                </td>
                <td v-if="manifest.errorMessage == null" class="acctCol">
                  {{
                    manifest.rating != null
                      ? `$${manifest.rating.totalCharges.toFixed(2)}`
                      : ""
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <LoadingSplash />
  </div>
</template>

<script>
import LoadingSplash from "../LoadingSplash.vue";
import { mapGetters, mapActions } from "vuex";
import dateutil from "../../dateutil";
import reportsapi from "../../api/reportsapi";
import moment from "moment";
import PartyDisplay from "../common/PartyDisplay.vue";

export default {
  components: {
    LoadingSplash,
    PartyDisplay,
  },
  data() {
    return {
      windowHeight: window.innerHeight || 0,
      rating: false,
      rated: false,
      ratedCount: 0,
      startDate: null,
      endDate: null,
    };
  },
  computed: {
    ...mapGetters("common", ["loading", "errorMessage"]),
    ...mapGetters("rating", ["unratedManifests"]),
    tabContentHeight: function () {
      return this.windowHeight - 220 || 0;
    },
  },
  methods: {
    ...mapActions("rating", [
      "loadUnratedManifests",
      "rateManifest",
      "resetModel",
      "setFuelRate",
    ]),
    ...mapActions("common", ["setLoading"]),
    formatLongDate(d) {
      return dateutil.getLocalDateTime(d, "YYYY-MM-DD hh:mma z");
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    updateStartEndDates(manifest) {
      let d = dateutil.getLocalDateTime(manifest.date, "YYYY-MM-DD");
      if (this.startDate == null || this.startDate > d) {
        this.startDate = d;
      }
      if (this.endDate == null || this.endDate < d) {
        this.endDate = d;
      }
    },
    async doSetFuelRate(dateStr, fuelRate) {
      return this.setFuelRate({ dateStr, fuelRate });
    },
    async rate() {
      this.setLoading(true);
      this.rating = true;
      for (let idx = 0; idx < this.unratedManifests.length; ) {
        let m = this.unratedManifests[idx];
        let ctrControl = await this.tryRateManifest(m, idx);
        idx = idx + ctrControl;
      }
      this.rated = true;
      this.setLoading(false);
    },
    async tryRateManifest(m, idx) {
      this.updateStartEndDates(m);
      let obj = {
        manifestNo: m.manifestNo,
        counter: idx + 1,
        count: this.unratedManifests.length,
      };
      let rateErr = null;
      let counterControl = 1;
      await this.rateManifest(obj)
        .then((res) => {
          m.rating = res.data.payload;
          this.ratedCount = this.ratedCount + 1;
        })
        .catch((err) => {
          rateErr = err;
        })
        .finally(() => {
          document
            .getElementById(m.manifestNo.toString())
            .scrollIntoView(false);
        });
      if (rateErr) {
        let msg = this.extractCustomErrorMessage(rateErr);
        if (msg) {
          if (msg.includes("fuel rate")) {
            await this.trySetFuelRate(m).then(() => (counterControl = 0));
          } else {
            m.errorMessage = msg;
          }
        } else {
          m.errorMessage = "Unknown error occured";
          console.log(rateErr);
          console.log(rateErr.response);
        }
      }
      return counterControl;
    },
    async trySetFuelRate(manifest) {
      let d = moment(manifest.date).format("YYYY-MM-DD");
      let rate = window.prompt(`Please enter a fuel rate for ${d}`);
      return this.doSetFuelRate(d, rate)
        .then((res) => {
          let rateObj = res.data.payload;
          alert(
            `Fuel Rate set to ${rateObj.fuelRate} for ${rateObj.effectiveDate}`,
          );
        })
        .catch((err) => {
          this.handleRatingError(err, manifest);
        });
    },
    extractCustomErrorMessage(err) {
      let msg = null;
      if (err.response) {
        if (err.response.data) {
          if (err.response.data.errorMessage) {
            msg = err.response.data.errorMessage;
          }
        }
      }
      return msg;
    },
    async handleRatingError(err, manifest, callback) {
      let msg = this.extractCustomErrorMessage(err);
      if (msg) {
        if (callback) {
          return callback(msg);
        } else {
          manifest.errorMessage = msg;
        }
      } else {
        manifest.errorMessage = "Unknown error occured";
        console.log(err);
        console.log(err.response);
      }
    },
    ratedManifestReport() {
      this.endDate = moment(this.endDate).add(1, "days").format("YYYY-MM-DD");
      window.open(
        reportsapi.getReportLink("RatedManifestReport", ".pdf", {
          StartDate: this.startDate,
          EndDate: this.endDate,
        }),
        "_new",
      );
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.loadUnratedManifests();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    this.resetModel();
  },
};
</script>

<style scoped>
.acctCol {
  text-align: right;
}
.rateError {
  color: white;
  background-color: red;
}
</style>
