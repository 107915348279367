<template>
  <modal
    name="showLogModal"
    height="auto"
    :scrollable="true"
    width="90%"
    @before-close="beforeClose"
    @before-open="beforeOpen"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ title }}</h5>
        <div
          class="close"
          @click="$modal.hide('showLogModal')"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </div>
      </div>
      <div class="modal-body">
        <template v-for="(l, idx) in logs">
          <b-row class="row-striped" :key="idx">
            <b-col md="auto">
              {{ local(l.date) }}
            </b-col>
            <b-col>
              {{ l.message }}
            </b-col>
          </b-row>
        </template>
      </div>
    </div>
  </modal>
</template>

<script>
import dateutils from "../../dateutil";

export default {
  data() {
    return {
      logs: [],
      title: null,
    };
  },
  methods: {
    beforeOpen(event) {
      this.logs = event.params.logs;
      this.title = event.params.title;
    },
    beforeClose() {
      this.logs = null;
      this.title = null;
    },
    local(val) {
      return dateutils.getLocalDateTime(val, "YYYY-MM-DD hh:mma z");
    },
  },
};
</script>

<style scoped></style>
