<template>
  <span>{{ display }}</span>
</template>

<script>
export default {
  name: "TrailerNoteDisplay",
  props: {
    trailer: Object,
  },
  computed: {
    display() {
      return this.trailer.note ? this.trailer.note : "";
    },
  },
};
</script>
