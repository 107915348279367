import forktruckauditApi from "../../api/misc";

// initial state
const state = {
  lastAdded: null,
};

// getters
const getters = {
  lastAdded: (state) => {
    return state.lastAdded;
  },
};

// actions
const actions = {
  resetStore({ dispatch, commit }) {
    commit("setLastAdded", null);
    dispatch("common/setErrorMessage", null, { root: true });
    dispatch("common/setLoading", false, { root: true });
  },
  saveForkTruckAudit({ dispatch, commit }, forktruckaudit) {
    dispatch("common/setErrorMessage", null, { root: true });
    dispatch("common/setLoading", true, { root: true });
    forktruckauditApi
      .saveForkTruckAudit(forktruckaudit)
      .then((res) => {
        commit("setLastAdded", res.data);
      })
      .catch((err) => {
        dispatch("common/handleHttpError", err, {
          root: true,
        });
      })
      .finally(() => {
        dispatch("common/setLoading", false, { root: true });
      });
  },
};

// mutations
const mutations = {
  setLastAdded(state, t) {
    state.lastAdded = t;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
