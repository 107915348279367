<template>
  <div :title="title">{{ display }}</div>
</template>

<script>
import dateutil from "../../dateutil";

export default {
  name: "TrailerLastUpdateDisplay",
  props: {
    trailer: Object,
  },
  computed: {
    display() {
      let d = dateutil.getLocalDateTime(this.trailer.lastUpdatedAt, "MM/DD");
      if (this.trailer.arrivedAtExternalLocationLoaded) {
        d = dateutil.getLocalDateTime(
          this.trailer.arrivedAtExternalLocationLoaded,
          "MM/DD",
        );
      }
      return d;
    },
    title() {
      let t = `Last updated ${dateutil.getLocalDateTime(
        this.trailer.lastUpdatedAt,
        "YYYY-MM-DD hh:mma z",
      )}\nby ${this.trailer.lastUpdatedBy}`;
      if (this.trailer.arrivedAtExternalLocationLoaded) {
        t =
          `Arrived loaded at ${dateutil.getLocalDateTime(
            this.trailer.arrivedAtExternalLocationLoaded,
            "YYYY-MM-DD hh:mma z",
          )}\n` + t;
      }
      return t;
    },
  },
};
</script>
