<template>
  <b-tabs :no-fade="true" id="jdmTabs">
    <b-tab title="Shipments">
      <ShipmentList :tabContentHeight="tabContentHeight"></ShipmentList>
    </b-tab>
    <b-tab title="Pallets">
      <PalletList :tabContentHeight="tabContentHeight"></PalletList>
    </b-tab>
    <b-tab title="Delete Barcode">
      <div class="text-center">
        Delete Barcode:
        <input
          type="text"
          id="barcodeToDelete"
          v-model.trim="barcodeToDelete"
        />
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ShipmentList from "./ShipmentList.vue";
import PalletList from "./PalletList.vue";

export default {
  components: {
    ShipmentList,
    PalletList,
  },
  data() {
    return {
      windowHeight: window.innerHeight || 0,
      barcodeToDelete: null,
    };
  },
  computed: {
    ...mapGetters("jdm", ["pallets", "incomingShipments"]),
    tabContentHeight: function () {
      return this.windowHeight - 220 || 0;
    },
  },
  watch: {
    barcodeToDelete(newVal) {
      if (newVal && newVal.length === 16) {
        this.deleteBarcode(newVal).then(() => (this.barcodeToDelete = null));
      }
    },
  },
  methods: {
    ...mapActions("jdm", ["deleteBarcode"]),
    onResize() {
      this.windowHeight = window.innerHeight;
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
