<template>
  <b-container fluid class="h-100">
    <div v-show="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <b-card no-body>
      <b-card-header>
        <b-row>
          <b-col md="4">
            {{ shipmentSummaries.length }} Open Shipments for Facility:
            <b-form-select
              id="facility-input"
              size="sm"
              style="width: auto"
              v-model="facility"
            >
              <b-form-select-option
                v-if="facility == null"
                :value="null"
                disabled
                >-- Select a Facility --</b-form-select-option
              >
              <b-form-select-option value="ADC">ADC</b-form-select-option>
              <b-form-select-option value="MTJOY">MTJOY</b-form-select-option>
              <b-form-select-option value="ATIRI">ATIRI</b-form-select-option>
              <b-form-select-option value="MTJOPEN"
                >MTJOPEN</b-form-select-option
              >
            </b-form-select>
          </b-col>
          <b-col class="text-right">
            <b-button variant="light" size="sm" @click="searchShipmentClicked">
              <font-awesome-icon title="Search for Shipment" icon="search" />
            </b-button>
            <b-button variant="light" size="sm" @click="addShipmentClicked">
              <font-awesome-icon title="Add new Shipment" icon="plus" />
            </b-button>
            <b-button variant="light" size="sm" @click="importManifestClicked">
              <font-awesome-icon
                title="Import Manifest as Shipment"
                icon="file-import"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body v-if="facility != null">
        <b-row class="table-responsive">
          <b-table
            :items="shipmentSummaries"
            :fields="headers"
            :filter="filter"
            responsive="md"
            striped
            small
            :sticky-header="contentHeight"
          >
            <template #head(shipmentName)="data">
              {{ data.label }}<span v-if="filter != null">*</span>
              <b-dropdown variant="link" size="sm" no-caret>
                <template #button-content>
                  <font-awesome-icon
                    size="sm"
                    variant="light"
                    title="Filter Shipments"
                    icon="filter"
                    style="color: black"
                  />
                </template>
                <b-dropdown-form>
                  <b-form-input
                    size="md"
                    :formatter="upper"
                    v-model="filter"
                    placeholder="Filter"
                    debounce="500"
                  />
                </b-dropdown-form>
              </b-dropdown>
              <b-button
                :disabled="filter == null"
                variant="light"
                size="sm"
                @click="clearFilter"
              >
                <font-awesome-icon title="Clear Filter" icon="window-close" />
              </b-button>
            </template>
            <template #cell(shipmentName)="data">
              <a
                :href="
                  manifestSystemLink(
                    `/IncomingShipmentView/Detail?incomingShipmentId=${data.item.id}`,
                  )
                "
                target="manifestFromMissy"
              >
                {{ data.item.shipmentName }}</a
              >
            </template>
            <template #cell(actions)="data">
              <b-button
                :disabled="data.item.dateArrived != null"
                variant="light"
                size="sm"
                @click="acceptShipment(data.item)"
              >
                <font-awesome-icon title="Mark shipment arrived" icon="check" />
              </b-button>
              <b-button
                :disabled="cannotDelete(data.item)"
                variant="light"
                size="sm"
                @click="deleteShipment(data.item)"
              >
                <font-awesome-icon
                  title="Delete this shipment"
                  icon="trash-alt"
                />
              </b-button>
              <b-button
                variant="light"
                size="sm"
                @click="emailShipmentDocuments(data.item)"
              >
                <font-awesome-icon
                  title="Email shipment documents"
                  icon="envelope"
                />
              </b-button>
              <docs-dropdown :shipment="data.item" />
            </template>
          </b-table>
        </b-row>
      </b-card-body>
    </b-card>
    <LoadingSplash />
    <ImportManifestModal
      :currentFacility="facility"
      :shipmentSummaries="shipmentSummaries"
    />
    <CreateNewShipmentModal
      :currentFacility="facility"
      :shipmentSummaries="shipmentSummaries"
    />
    <SearchShipmentModal />
  </b-container>
</template>

<script>
import LoadingSplash from "../../LoadingSplash.vue";
import DocsDropdown from "./DocsDropdown.vue";
import dateutils from "../../../dateutil";
import textutils from "../../../textutils";
import { shipmentApi } from "../../../api/manifest";
import { mapActions, mapGetters } from "vuex";
import { MANIFEST_URL } from "../../../config";
import ImportManifestModal from "./ImportManifestModal";
import CreateNewShipmentModal from "./CreateNewShipmentModal";
import SearchShipmentModal from "./SearchShipmentModal";
import reportsapi from "../../../api/reportsapi";

export default {
  components: {
    LoadingSplash,
    DocsDropdown,
    ImportManifestModal,
    CreateNewShipmentModal,
    SearchShipmentModal,
  },
  data() {
    return {
      shipmentSummaries: [],
      headers: [
        { key: "shipmentName", label: "Shipment" },
        { key: "dateShipped", label: "Shipped", formatter: "displayDate" },
        { key: "dateArrived", label: "Arrived", formatter: "displayDate" },
        { key: "trailerNo", label: "Trailer" },
        { key: "pieces", label: "PCS", class: "text-right" },
        { key: "weight", label: "WGT", class: "text-right" },
        { key: "piecesManifested", label: "MAN", class: "text-right" },
        { key: "parcels", label: "BAR", class: "text-right" },
        { key: "parcelsVerified", label: "SCN", class: "text-right" },
        { key: "actions", label: "", class: "text-right" },
      ],
      facility: null,
      filter: null,
      windowHeight: window.innerHeight || 0,
    };
  },
  watch: {
    facility(newVal) {
      if (newVal) {
        this.loadSummaries(newVal);
      }
    },
  },
  computed: {
    ...mapGetters("common", ["errorMessage", "defaultFacility"]),
    contentHeight() {
      return (this.windowHeight - 195 || 0) + "px";
    },
  },
  methods: {
    ...mapActions("common", ["showLoadingDuringAction"]),
    upper(val) {
      return textutils.toUpper(val);
    },
    loadSummaries() {
      let cutoff = dateutils.getLocalDateTime(
        dateutils.utcNow().subtract(15, "days"),
        "YYYY-MM-DD",
      );
      let call = shipmentApi
        .loadOpenShipmentSummaries(this.facility, cutoff)
        .then((res) => (this.shipmentSummaries = res.data.payload));
      this.showLoadingDuringAction(call);
    },
    async acceptShipment(shipment) {
      let confirmed = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to accept Shipment ${shipment.shipmentName}? Items will be eligible to ship.`,
      );
      if (confirmed) {
        let call = shipmentApi.acceptShipment(shipment.id).then((res) => {
          shipment.dateArrived = res.data.payload.dateArrived;
          this.$toasted.success(`Shipment ${shipment.shipmentName} accepted`);
        });
        this.showLoadingDuringAction(call);
      }
    },
    async deleteShipment(shipment) {
      let confirmed = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to delete Shipment ${shipment.shipmentName}?`,
      );
      if (confirmed) {
        let call = shipmentApi.deleteShipment(shipment.id).then((res) => {
          let idx = this.shipmentSummaries
            .map((s) => s.id)
            .indexOf(res.data.payload.id);
          if (idx > -1) {
            this.shipmentSummaries.splice(idx, 1);
            this.$toasted.success(`Shipment ${shipment.shipmentName} deleted`);
          }
        });
        this.showLoadingDuringAction(call);
      }
    },
    emailShipmentDocuments(shipment) {
      reportsapi.emailShippingDocs(shipment.id).then(() => {
        this.$toasted.success(
          `Shipment ${shipment.shipmentName} documents emailed`,
        );
      });
    },
    displayDate(date) {
      return date != null ? dateutils.getLocalDateTime(date, "MM-DD") : null;
    },
    cannotDelete(shipment) {
      let hasPiecesAssigned =
        shipment.piecesManifested > 0 ||
        shipment.parcels > 0 ||
        shipment.parcelsVerified > 0;
      return hasPiecesAssigned;
    },
    clearFilter() {
      this.filter = null;
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    manifestSystemLink(path) {
      return `${MANIFEST_URL}${path}`;
    },
    searchShipmentClicked() {
      this.$bvModal.show("search-shipment-modal");
    },
    addShipmentClicked() {
      this.$bvModal.show("create-new-shipment-modal");
    },
    importManifestClicked() {
      this.$bvModal.show("import-manifest-as-shipment-modal");
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.facility = this.defaultFacility;
  },
};
</script>

<style scoped>
a {
  color: blue;
}
</style>
