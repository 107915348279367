<template>
  <span
    :class="title"
    :title="title"
    :style="{
      paddingTop: pt + 'px',
      paddingRight: pr + 'px',
      paddingBottom: pb + 'px',
      paddingLeft: pl + 'px',
    }"
    >&nbsp;</span
  >
</template>

<script>
export default {
  name: "TrailerLoadedDisplay",
  props: {
    trailer: Object,
    loadedOverride: String,
    pt: { default: 0, type: Number },
    pr: { default: 6, type: Number },
    pb: { default: 0, type: Number },
    pl: { default: 6, type: Number },
  },
  computed: {
    title() {
      let t = this.loadedOverride != null ? this.loadedOverride : null;
      if (t == null) {
        t = this.trailer != null ? this.trailer.status : null;
      }
      return t;
    },
  },
};
</script>

<style scoped>
.LOADED {
  background-color: black;
}
.EMPTY {
  background-color: transparent;
}
span {
  border: 1px solid black;
}
</style>
