import miscApi from "../../api/misc";

// initial state
const state = {
  lastSearched: null,
  lastSaved: null,
};

// getters
const getters = {
  lastSearched: (state) => {
    return state.lastSearched;
  },
  lastSaved: (state) => {
    return state.lastSaved;
  },
};

// actions
const actions = {
  resetStore({ dispatch, commit }) {
    commit("setLastSearched", null);
    commit("setLastSaved", null);
    dispatch("common/setErrorMessage", null, { root: true });
    dispatch("common/setLoading", false, { root: true });
  },
  search({ dispatch, commit }, bundle) {
    dispatch("common/setErrorMessage", null, { root: true });
    dispatch("common/setLoading", true, { root: true });
    miscApi
      .findBundleStorage(bundle)
      .then((res) => {
        commit("setLastSearched", res.data);
      })
      .catch((err) => {
        if (err.response && err.response && err.response.status == 404) {
          dispatch("common/setErrorMessage", `${bundle} was not found.`, {
            root: true,
          });
        } else {
          dispatch("common/handleHttpError", err, {
            root: true,
          });
        }
      })
      .finally(() => {
        dispatch("common/setLoading", false, { root: true });
      });
  },
  save({ dispatch, commit }, bundleStorage) {
    dispatch("common/setErrorMessage", null, { root: true });
    dispatch("common/setLoading", true, { root: true });
    miscApi
      .createOrUpdateBundleStorage(bundleStorage)
      .then((res) => {
        commit("setLastSaved", res.data);
      })
      .catch((err) => {
        dispatch("common/handleHttpError", err, {
          root: true,
        });
      })
      .finally(() => {
        dispatch("common/setLoading", false, { root: true });
      });
  },
};

// mutations
const mutations = {
  setLastSearched(state, t) {
    state.lastSearched = t;
  },
  setLastSaved(state, t) {
    state.lastSaved = t;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
