<template>
  <b-modal
    id="search-shipment-modal"
    ref="searchShipmentModal"
    title="Search Shipment"
    size="lg"
    :ok-disabled="disableOk"
    @show="resetModal"
    @hide="hide"
    @ok="okClicked"
    @shown="shown"
  >
    <div v-show="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <b-form>
      <b-row>
        <b-col>
          <b-form-group label-cols-sm label="From Date:">
            <b-form-datepicker
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              :max="shipmentParams.toDate"
              v-model="shipmentParams.fromDate"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label-cols-sm label="To Date:">
            <b-form-datepicker
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              :min="shipmentParams.fromDate"
              v-model="shipmentParams.toDate"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label-cols-sm label="Tracking:">
            <b-form-input
              trim
              :formatter="upper"
              v-model="shipmentParams.trackingId"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label-cols-sm label="Carrier BOL:">
            <b-form-input
              trim
              :formatter="upper"
              v-model="shipmentParams.carrierBol"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label-cols-sm label="Trailer:">
            <b-form-input
              trim
              :formatter="upper"
              v-model="shipmentParams.trailerNo"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label-cols-sm label="Supplier:">
            <b-form-input
              ref="supplierId"
              trim
              :formatter="upper"
              v-model="shipmentParams.supplierId"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label-cols-sm label="Carrier:">
            <b-form-input
              trim
              :formatter="upper"
              v-model="shipmentParams.carrier"
            />
          </b-form-group>
        </b-col>
        <b-col>&nbsp;</b-col>
      </b-row>
      <b-row>
        <b-col><hr /></b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label-cols-sm label="Destination:">
            <b-form-input
              trim
              :formatter="upper"
              v-model="lineParams.destinationCode"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label-cols-sm label="Bill To:">
            <b-form-input
              trim
              :formatter="upper"
              v-model="lineParams.billToCode"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label-cols-sm label="Order:">
            <b-form-input
              trim
              :formatter="upper"
              v-model="lineParams.orderNo"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label-cols-sm label="Weight:">
            <b-form-input
              trim
              number
              :formatter="upper"
              v-model="lineParams.weight"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label-cols-sm label="Barcode:">
            <b-form-input
              trim
              :formatter="upper"
              v-model="lineParams.barcode"
            />
          </b-form-group>
        </b-col>
        <b-col></b-col>
      </b-row>
    </b-form>
    <ShipmentSearchResultModal :shipmentSummaries="shipmentSummaries" />
    <LineSearchResultModal :lines="lines" />
  </b-modal>
</template>

<script>
import { shipmentApi, parcels } from "../../../api/manifest";
import indexApi from "../../../api/indexapi";
import { mapActions, mapGetters } from "vuex";
import textutils from "../../../textutils";
import ShipmentSearchResultModal from "./ShipmentSearchResultModal";
import LineSearchResultModal from "./LineSearchResultModal";

export default {
  components: {
    ShipmentSearchResultModal,
    LineSearchResultModal,
  },
  data() {
    return {
      shipmentParams: {
        supplierId: null,
        fromDate: null,
        toDate: null,
        trackingId: null,
        trailerNo: null,
        carrier: null,
        carrierBol: null,
      },
      lineParams: {
        destinationCode: null,
        billToCode: null,
        orderNo: null,
        barcode: null,
        weight: null,
      },
      shipmentSummaries: [],
      lines: [],
      shipmentQuery: `{"query":{"bool":{"filter":{"bool":{"filter":@FILTER@}}}},"aggs":{"shipment":{"terms":{"field":"inc_id","size": 100}}},"size":0}`,
      lineQuery: `{"query":{"bool":{"filter":{"bool":{"filter":@FILTER@}}}},"size":100}`,
    };
  },
  computed: {
    ...mapGetters("common", ["errorMessage", "defaultFacility"]),
    disableOk() {
      return (
        this.shipmentParams.supplierId == null &&
        this.shipmentParams.trackingId == null &&
        this.shipmentParams.trailerNo == null &&
        this.shipmentParams.carrier == null &&
        this.shipmentParams.carrierBol == null &&
        this.shipmentParams.fromDate == null &&
        this.shipmentParams.toDate == null &&
        this.lineParams.destinationCode == null &&
        this.lineParams.billToCode == null &&
        this.lineParams.orderNo == null &&
        this.lineParams.barcode == null &&
        this.lineParams.weight == null
      );
    },
  },
  methods: {
    ...mapActions("common", ["showLoadingDuringAction", "setErrorMessage"]),
    resetModal() {
      this.shipmentParams.supplierId = null;
      this.shipmentParams.trackingId = null;
      this.shipmentParams.trailerNo = null;
      this.shipmentParams.carrier = null;
      this.shipmentParams.carrierBol = null;
      this.shipmentParams.fromDate = null;
      this.shipmentParams.toDate = null;
      this.lineParams.destinationCode = null;
      this.lineParams.billToCode = null;
      this.lineParams.orderNo = null;
      this.lineParams.barcode = null;
      this.lineParams.weight = null;
      this.setErrorMessage(null);
    },
    isShipmentOnly() {
      return (
        this.upper(this.lineParams.destinationCode) == null &&
        this.upper(this.lineParams.billToCode) == null &&
        this.upper(this.lineParams.orderNo) == null &&
        this.upper(this.lineParams.barcode) == null &&
        this.upper(this.lineParams.weight) == null
      );
    },
    isBarcodeSearch() {
      return this.upper(this.lineParams.barcode) != null;
    },
    upper(s) {
      return textutils.toUpper(s);
    },
    createQuery() {
      let q = null;
      if (this.isShipmentOnly()) {
        q = this.shipmentQuery.replace("@FILTER@", this.buildFilterString());
      } else {
        q = this.lineQuery.replace("@FILTER@", this.buildFilterString());
      }
      return JSON.parse(q);
    },
    buildLineFilterString(ids) {
      let filter = [];
      let shouldFilter = [];
      ids.forEach((id) => {
        shouldFilter.push(this.makeTerm("line_id", id));
      });
      let should = { bool: { should: shouldFilter } };
      filter.push(should);
      filter.push(
        JSON.parse(`{"bool":{"must":{"exists":{"field":"inc_id"}}}}`),
      );
      let str = JSON.stringify(filter);
      return str;
    },
    buildFilterString() {
      let filter = [];
      if (this.shipmentParams.supplierId) {
        filter.push(
          this.makeTerm("inc_supplier_id", this.shipmentParams.supplierId),
        );
      }
      if (this.shipmentParams.trackingId) {
        filter.push(
          this.makeTerm("inc_tracking_id", this.shipmentParams.trackingId),
        );
      }
      if (this.shipmentParams.trailerNo) {
        filter.push(
          this.makeTerm("inc_trailer_no", this.shipmentParams.trailerNo),
        );
      }
      if (this.shipmentParams.carrier) {
        filter.push(
          this.makeTerm("inc_carrier_bol", this.shipmentParams.carrier),
        );
      }
      if (this.shipmentParams.carrierBol) {
        filter.push(
          this.makeTerm("inc_carrier_bol", this.shipmentParams.carrierBol),
        );
      }
      if (this.shipmentParams.fromDate || this.shipmentParams.toDate) {
        let o = { range: { inc_date_shipped: {} } };
        if (this.shipmentParams.fromDate) {
          o.range.inc_date_shipped["gte"] = this.shipmentParams.fromDate;
        }
        if (this.shipmentParams.toDate) {
          o.range.inc_date_shipped["lte"] = this.shipmentParams.toDate;
        }
        filter.push(o);
      }
      if (this.lineParams.destinationCode) {
        filter.push(
          this.makeTerm("dest_code", this.lineParams.destinationCode),
        );
      }
      if (this.lineParams.billToCode) {
        filter.push(this.makeTerm("billto_code", this.lineParams.billToCode));
      }
      if (this.lineParams.orderNo) {
        filter.push(this.makeTerm("line_orders", this.lineParams.orderNo));
      }
      if (this.lineParams.weight) {
        filter.push(this.makeTerm("line_weight", this.lineParams.weight));
      }
      if (!this.isShipmentOnly()) {
        filter.push(
          JSON.parse(`{"bool":{"must":{"exists":{"field":"inc_id"}}}}`),
        );
      }
      let str = JSON.stringify(filter);
      return str;
    },
    makeTerm(key, val) {
      let o = { term: {} };
      o.term[key] = `${textutils.toLower(val)}`;
      return o;
    },
    async okClicked(bvModalEvt) {
      bvModalEvt.preventDefault();
      return this.performSearch();
    },
    async performSearch() {
      this.lines = [];
      this.shipmentSummaries = [];
      if (this.isBarcodeSearch()) {
        let lines = await this.showLoadingDuringAction(
          this.loadLineByBarcode(),
        );
        this.lines = lines;
        this.$bvModal.show("line-search-result-modal");
      } else if (this.isShipmentOnly()) {
        let summaries = await this.showLoadingDuringAction(
          this.loadShipmentResults(),
        );
        this.shipmentSummaries = summaries;
        this.$bvModal.show("shipment-search-result-modal");
      } else {
        let lines = await this.showLoadingDuringAction(this.loadLineResults());
        this.lines = lines;
        this.$bvModal.show("line-search-result-modal");
      }
    },
    async loadShipmentResults() {
      let q = this.createQuery();
      let ids = await indexApi.searchLine(q).then((res) => {
        return res.data.aggregations.shipment.buckets.map((r) => r.key).sort();
      });
      if (ids) {
        let loadProms = [];
        ids.forEach((id) => {
          let l = shipmentApi
            .loadShipmentSummary(id)
            .then((r) => r.data.payload);
          loadProms.push(l);
        });
        let summaries = await Promise.all(loadProms);
        if (summaries) {
          return summaries;
        }
      }
    },
    async loadLineResults(query) {
      let q = query;
      if (!q) {
        q = this.createQuery();
      }
      let lines = await indexApi.searchLine(q).then((res) => {
        return res.data.hits.hits.map((r) => r._source);
      });
      lines.sort((a, b) => a.line_id - b.line_id);
      lines.forEach((l) => {
        if (l.line_orders && Array.isArray(l.line_orders)) {
          l.line_orders = l.line_orders.join(", ");
        }
        l.line_orders =
          l.line_orders != null ? textutils.toUpper(l.line_orders) : null;
      });
      return lines;
    },
    async loadLineByBarcode() {
      let ids = await parcels
        .searchParcels(null, this.lineParams.barcode)
        .then((res) => {
          return res.data.payload.map((p) =>
            p.incomingShipmentId != null
              ? p.incomingShipmentId
              : p.overpackSeed,
          );
        });
      if (ids.length > 0) {
        let q = this.lineQuery.replace(
          "@FILTER@",
          this.buildLineFilterString(ids),
        );
        return await this.loadLineResults(q);
      } else {
        return [];
      }
    },
    keyboardShortcuts(e) {
      if (e.key == "Enter" && !this.disableOk) {
        e.preventDefault();
        this.performSearch();
      }
      //console.log(`${e.code} ${e.key} ${e.metaKey} ${e.ctrlKey}`);
    },
    shown() {
      document
        .getElementById("search-shipment-modal")
        .addEventListener("keydown", this.keyboardShortcuts);
      this.$refs.supplierId.focus();
    },
    hide() {
      document
        .getElementById("search-shipment-modal")
        .removeEventListener("keydown", this.keyboardShortcuts);
      this.resetModal();
    },
  },
};
</script>
