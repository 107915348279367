<template>
  <div class="container-fluid h-100" style="overflow: hidden; overflow-y: auto">
    <div class="card">
      <div class="card-header">
        <div v-show="errorMessage" class="alert alert-danger">
          {{ errorMessage }}
        </div>
        <div v-if="lastSaved" class="alert alert-success">
          Bundle {{ lastSaved.bundle }} saved successfully
        </div>
        <div v-if="lastSearched" class="alert alert-success">
          Bundle {{ lastSearched.bundle }} loaded successfully
        </div>
        Manage Bundle
      </div>
      <div class="card-body">
        <form>
          <div class="form-group row">
            <label for="bundle" class="col-sm-2 col-form-label">Bundle</label>
            <div class="col-sm-10">
              <input
                type="text"
                v-model.trim="bundle"
                id="bundle"
                class="form-control text-uppercase"
                required
                placeholder="Bundle"
                autofocus
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="storeOutside" class="col-sm-2 col-form-label"
              >Store Outside</label
            >
            <div class="col-sm-1">
              <input type="checkbox" v-model="storeOutside" />
            </div>
          </div>
          <div class="form-group row">
            <label for="protectionDesc" class="col-sm-2 col-form-label"
              >Protection Description</label
            >
            <div class="col-sm-10">
              <input
                type="text"
                v-model.trim="protectionDesc"
                id="protectionDesc"
                class="form-control text-uppercase"
                placeholder="Protection Description"
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-12">
              <button type="button" @click="doSearch" class="btn btn-primary">
                Search</button
              >&nbsp;
              <button type="button" @click="doSave" class="btn btn-primary">
                Save</button
              >&nbsp;
              <button
                v-if="lastSearched"
                type="button"
                @click="doCancel"
                class="btn btn-primary"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <LoadingSplash />
  </div>
</template>

<script>
import LoadingSplash from "../LoadingSplash.vue";
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    LoadingSplash,
  },
  data() {
    return {
      bundle: null,
      storeOutside: false,
      protectionDesc: null,
    };
  },
  validations() {
    let v = {
      bundle: {
        required,
      },
    };
    return v;
  },
  computed: {
    ...mapGetters("common", ["loading", "errorMessage"]),
    ...mapGetters("security", ["userName"]),
    ...mapGetters("bundlestoragemanagement", ["lastSearched", "lastSaved"]),
  },
  watch: {
    loading(newVal) {
      if (!newVal) {
        if (!this.errorMessage) {
          this.reset();
        }
      }
    },
    lastSearched(newVal) {
      if (newVal) {
        this.bundle = newVal.bundle;
        this.storeOutside = newVal.storeOutside;
        this.protectionDesc = newVal.protectionDesc;
      } else {
        this.reset();
      }
    },
  },
  destroyed() {
    this.resetStore();
  },
  methods: {
    ...mapActions("common", ["setErrorMessage"]),
    ...mapActions("bundlestoragemanagement", ["search", "save", "resetStore"]),
    reset() {
      this.bundle = null;
      this.storeOutside = false;
      this.protectionDesc = null;
    },
    doSearch() {
      this.resetStore();
      this.setErrorMessage(null);
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.setErrorMessage(
          "Check that all required fields are filled out with valid data",
        );
      } else {
        this.bundle = this.bundle.toUpperCase();
        this.search(this.bundle);
      }
    },
    doSave() {
      this.resetStore();
      this.setErrorMessage(null);
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.setErrorMessage(
          "Check that all required fields are filled out with valid data",
        );
      } else {
        this.bundle = this.bundle.toUpperCase();
        this.protectionDesc = this.protectionDesc
          ? this.protectionDesc.toUpperCase()
          : null;
        const t = {
          bundle: this.bundle,
          storeOutside: this.storeOutside,
          protectionDesc: this.protectionDesc,
          recordedBy: this.userName,
        };
        this.save(t);
      }
    },
    doCancel() {
      this.resetStore();
      this.reset();
    },
  },
};
</script>

<style scoped></style>
