<template>
  <b-modal
    id="shipment-search-result-modal"
    title="Incoming Shipment Search Results"
    size="xl"
    ok-only
  >
    <b-row class="table-responsive">
      <b-table
        :items="shipmentSummaries"
        :fields="headers"
        responsive="md"
        striped
        small
      >
        <template #cell(shipmentName)="data">
          <a
            :href="
              manifestSystemLink(
                `/IncomingShipmentView/Detail?incomingShipmentId=${data.item.id}`,
              )
            "
            target="manifestFromMissy"
          >
            {{ data.item.shipmentName }}</a
          >
        </template>
        <template #cell(dateShipped)="data">
          {{ displayDate(data.item.dateShipped) }}
        </template>
        <template #cell(dateArrived)="data">
          {{ displayDate(data.item.dateArrived) }}
        </template>
      </b-table>
    </b-row>
  </b-modal>
</template>

<script>
import { MANIFEST_URL } from "../../../config";
import dateutils from "../../../dateutil";

export default {
  props: {
    shipmentSummaries: Array,
  },
  data() {
    return {
      headers: [
        { key: "facility", label: "Facility" },
        { key: "shipmentName", label: "Shipment" },
        { key: "dateShipped", label: "Shipped" },
        { key: "dateArrived", label: "Arrived" },
        { key: "trailerNo", label: "Trailer" },
        { key: "pieces", label: "PCS", class: "text-right" },
        { key: "weight", label: "WGT", class: "text-right" },
        { key: "piecesManifested", label: "MAN", class: "text-right" },
        { key: "parcels", label: "BAR", class: "text-right" },
        { key: "parcelsVerified", label: "SCN", class: "text-right" },
      ],
    };
  },
  methods: {
    shipmentClicked() {
      // NOOP
    },
    displayDate(date) {
      return date != null
        ? dateutils.getLocalDateTime(date, "YYYY-MM-DD")
        : null;
    },
    manifestSystemLink(path) {
      return `${MANIFEST_URL}${path}`;
    },
  },
};
</script>

<style scoped>
a {
  color: blue;
}
</style>
