<template>
  <b-card no-body>
    <b-card-header>
      <b-row>
        <b-col md="4"
          >Line
          <span v-if="currentLine != null"
            >{{ currentLine.id }} [PCS:{{ shippingPieces }}]
            <font-awesome-icon
              title="View in QCFS Manifest"
              icon="exchange-alt"
              class="clickable"
              @click="viewShipmentLineInManifestClicked" /></span
        ></b-col>
        <b-col md="8" class="text-right">
          <b-button variant="light" size="md" @click="addLineClicked">
            <font-awesome-icon title="Add New Line" icon="plus" />
          </b-button>
          <b-button
            variant="light"
            size="md"
            @click="deleteLineClicked"
            :disabled="currentParcels.length > 0"
            v-if="currentLine != null && currentLine.id > 0"
          >
            <font-awesome-icon title="Delete Line" icon="trash-alt" />
          </b-button>
          <b-button
            variant="light"
            size="md"
            @click="generateBarcodesClicked"
            :disabled="shippingPieces == currentParcels.length"
            v-if="currentLine != null && currentLine.id > 0"
          >
            <font-awesome-icon title="Generate Barcodes" icon="sync" />
          </b-button>
          <b-button
            variant="light"
            size="md"
            @click="lineInboundSheetClicked"
            v-if="currentLine != null && currentLine.id > 0"
          >
            <font-awesome-icon
              title="Print Inbound Sheet"
              icon="check-square"
            />
          </b-button>
          <b-button
            variant="light"
            size="md"
            @click="lineShippingLabelClicked"
            v-if="currentLine != null && currentLine.id > 0"
          >
            <font-awesome-icon title="Print Shipping Labels" icon="tag" />
          </b-button>
          <b-button
            variant="light"
            size="md"
            @click="splitByDestinationClicked"
            v-if="currentLine != null && currentLine.id > 0"
          >
            <font-awesome-icon
              title="Split Shipment by this Destination"
              icon="expand-alt"
            />
          </b-button>
          <b-button
            variant="light"
            size="md"
            @click="splitByOrderClicked"
            v-if="currentLine != null && currentLine.id > 0"
          >
            <font-awesome-icon
              title="Split Shipment by this Order"
              icon="object-ungroup"
            />
          </b-button>
          <b-button
            variant="light"
            size="md"
            @click="mergeOrdersClicked"
            v-if="currentLine != null && currentLine.id > 0"
          >
            <font-awesome-icon
              title="Merge Order into this Order"
              icon="object-group"
            />
          </b-button>
          <b-button
            variant="light"
            size="md"
            @click="assignToManifestClicked"
            v-if="currentLine != null && currentLine.id > 0"
          >
            <font-awesome-icon title="Assign Line to Manifest" icon="share" />
          </b-button>
          <ShowLogIcon
            v-if="currentLine != null && currentLine.id > 0"
            :title="'Logs for Line ' + currentLine.id"
            :term="currentLine.id"
            type="line"
          />
          <FileUploadIcon
            v-if="currentLine != null && currentLine.id > 0"
            :title="'Attachments for Line ' + currentLine.id"
            :id="currentLine.id"
            type="line"
          />
          <b-button
            :disabled="currentLineIdx <= 0"
            variant="light"
            size="md"
            @click="previousLine"
          >
            <font-awesome-icon title="Previous Line" icon="chevron-left" />
          </b-button>
          {{ currentLineIdx + 1 }} of {{ lines.length }}
          <b-button
            :disabled="currentLineIdx + 1 == lines.length"
            variant="light"
            size="md"
            @click="nextLine"
          >
            <font-awesome-icon title="Next Line" icon="chevron-right" />
          </b-button>
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body>
      <b-form>
        <b-form-row>
          <b-col md="1" class="col-form-label">Dest:</b-col>
          <b-col md="2">
            <b-form-input
              v-model="currentLine.destinationCode"
              :formatter="upper"
              ref="destinationCodeInput"
              lazy
              trim
            />
          </b-col>
          <PartyDisplay
            :code="currentLine.destinationCode"
            class="col-md-5 col-form-label"
          />
          <b-col md="1">
            {{ parentLinkLabel }}
          </b-col>
          <b-col md="3">
            <a
              v-if="parentLink != null"
              target="manifestFromMissy"
              :href="parentLink"
              >{{ parentLinkText }}</a
            >
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col md="1" class="col-form-label">Bill To:</b-col>
          <b-col md="2">
            <b-form-input
              v-model="currentLine.billToCode"
              :formatter="upper"
              lazy
              trim
            />
          </b-col>
          <PartyDisplay
            :code="currentLine.billToCode"
            class="col-md-5 col-form-label"
          />
        </b-form-row>
        <b-form-row>
          <b-col md="1" class="col-form-label">Charge Code:</b-col>
          <b-col md="2">
            <b-form-input
              v-model="currentLine.outCarrierCode"
              :formatter="upper"
              lazy
              trim
            />
          </b-col>
          <PartyDisplay
            :code="currentLine.outCarrierCode"
            class="col-md-5 col-form-label"
          />
        </b-form-row>
        <b-form-row>
          <b-col md="1" class="col-form-label">Order:</b-col>
          <b-col md="2">
            <b-form-input
              v-model="currentLine.orderNo"
              :formatter="upper"
              lazy
              trim
            />
          </b-col>
          <b-col md="1" class="col-form-label">Sup PCS:</b-col>
          <b-col md="1">
            <b-form-input
              v-model="currentLine.quantityShipped"
              number
              lazy
              trim
            />
          </b-col>
          <b-col md="1" class="col-form-label">Weight:</b-col>
          <b-col md="1">
            <b-form-input
              v-model="currentLine.shippingWeight"
              number
              lazy
              trim
            />
          </b-col>
          <b-col v-if="facility == 'MTJOY'" md="1" class="col-form-label"
            >DLV #:</b-col
          >
          <b-col v-if="facility == 'MTJOY'" md="2">
            <b-form-input
              v-model="currentLine.deliveryNumber"
              number
              lazy
              trim
            />
          </b-col>
          <b-col md="1" class="col-form-label">Pool:</b-col>
          <b-col md="1">
            <b-form-input v-model="currentLine.poolCode" lazy trim />
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col md="1" class="col-form-label">Type:</b-col>
          <b-col md="2">
            <b-form-select
              v-model="currentLine.typeOfGoodsCode"
              :options="lineTypes"
            >
              <b-form-select-option
                v-if="currentLine.typeOfGoodsCode == null"
                :value="null"
                disabled
                >- Select Type -</b-form-select-option
              >
            </b-form-select>
          </b-col>
          <b-col md="1" class="col-form-label">O&amp;S:</b-col>
          <b-col md="1">
            <b-form-input v-model="currentLine.overShort" number lazy trim />
          </b-col>
          <b-col md="1" class="col-form-label">Damage:</b-col>
          <b-col md="1" class="col-form-label">
            <b-checkbox v-model="currentLine.damaged" />
          </b-col>
          <b-col md="1" class="col-form-label">Desc:</b-col>
          <b-col md="4">
            <b-form-input
              :disabled="!currentLine.damaged"
              v-model="currentLine.damageDesc"
              lazy
              trim
            />
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col md="1" class="col-form-label">Add'l Orders:</b-col>
          <b-col md="6">
            <b-form-textarea
              v-model="currentLine.additionalOrders"
              :formatter="upper"
              lazy
              trim
            />
          </b-col>
          <b-col md="1" class="col-form-label">Notes:</b-col>
          <b-col md="4">
            <b-form-textarea
              v-model="currentLine.comments"
              :formatter="upper"
              ref="comments"
              lazy
              trim
            />
          </b-col>
        </b-form-row>
      </b-form>
    </b-card-body>
    <b-card no-body>
      <b-card-body>
        <b-form>
          <b-form-row>
            <b-col md="1" class="col-form-label">Barcode:</b-col>
            <b-col md="2">
              <b-form-input
                v-model="barcodeToAdd"
                :formatter="upper"
                lazy
                trim
              />
            </b-col>
            <b-col md="1"><button>+</button></b-col>
            <b-col md="8">
              <b-list-group flush>
                <b-list-group-item
                  v-for="parcel in currentParcels"
                  :key="parcel.externalId"
                >
                  <ShowLogIcon
                    class="clickable"
                    icon="info-circle"
                    :title="'Logs for Parcel ' + parcel.externalId"
                    :term="parcel.externalId"
                    type="parcel"
                    iconOnly
                  />&nbsp;
                  <font-awesome-icon
                    title="Print parcel"
                    icon="print"
                    class="clickable"
                    @click="
                      printClicked('BarcodeDetailSheet', '.pdf', {
                        ParcelCode:
                          parcel.overpack != null
                            ? parcel.overpack
                            : parcel.externalId,
                        facility: currentLine.facility,
                      })
                    "
                  />&nbsp;
                  <font-awesome-icon
                    :title="barcodeVerifiedTitle(parcel)"
                    :icon="barcodeVerifiedIcon(parcel)"
                  />&nbsp;
                  {{ parcel.externalId }}
                  <span v-if="parcel.overpack != null">
                    (<ShowLogIcon
                      icon="info-circle"
                      class="clickable"
                      :title="'Logs for Overpack ' + parcel.overpack"
                      :term="parcel.overpack"
                      type="parcel"
                      iconOnly
                    />&nbsp;

                    <font-awesome-icon
                      title="Print overpack"
                      class="clickable"
                      icon="print"
                      @click="
                        printClicked('OverpackContents', '.pdf', {
                          overpack: parcel.overpack,
                          facility: currentLine.facility,
                        })
                      "
                    />
                    {{ parcel.overpack }})
                  </span>
                  <span v-if="parcel.location != null">
                    at
                    <span
                      class="clickable"
                      @click="
                        printClicked('LocationAuditSheet', '.pdf', {
                          Location: parcel.location,
                          facility: currentLine.facility,
                        })
                      "
                      >{{ parcel.location }}</span
                    >
                  </span>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-form-row>
        </b-form>
      </b-card-body>
    </b-card>
    <b-card v-if="!hideInbound" no-body>
      <b-card-header>
        Inbounds
        <font-awesome-icon
          title="Toggle Inbound Collapse"
          :icon="inboundCollapseIcon"
          @click="collapseInboundClicked"
        />
      </b-card-header>
      <b-card-body v-show="!collapseInbound">
        <b-form>
          <b-form-row>
            <b-col md="1" class="col-form-label">Bill To:</b-col>
            <b-col md="2">
              <b-form-input
                v-model="currentLine.inbBillingCode"
                :formatter="upper"
                lazy
                trim
              />
            </b-col>
            <PartyDisplay
              :code="currentLine.inbBillingCode"
              class="col-md-5 col-form-label"
            />
          </b-form-row>
          <b-form-row>
            <b-col md="1" class="col-form-label">Carrier:</b-col>
            <b-col md="2">
              <b-form-input
                v-model="currentLine.inbBillingCarrierCode"
                :formatter="upper"
                lazy
                trim
              />
            </b-col>
            <PartyDisplay
              :code="currentLine.inbBillingCarrierCode"
              class="col-md-5 col-form-label"
            />
          </b-form-row>
          <b-form-row>
            <b-col md="1" class="col-form-label">Billing:</b-col>
            <b-col md="2">
              <b-select v-model="currentLine.inbBillingType">
                <b-form-select-option
                  v-if="currentLine.inbBillingType == null"
                  :value="null"
                  disabled
                  >- Billing -</b-form-select-option
                >
                <b-form-select-option value="PREPAID"
                  >PREPAID</b-form-select-option
                >
                <b-form-select-option value="COLLECT"
                  >COLLECT</b-form-select-option
                >
                <b-form-select-option value="3RD PTY"
                  >3RD PTY</b-form-select-option
                >
              </b-select>
            </b-col>
            <b-col md="1" class="col-form-label">Charges:</b-col>
            <b-col md="2">
              <b-form-input
                v-model="currentLine.inbBillingCharges"
                :formatter="upper"
                number
                lazy
                trim
              />
            </b-col>
            <b-col md="1" class="col-form-label">Inb BOL:</b-col>
            <b-col md="2" class="col-form-label">
              {{ currentLine.inbBillingBol }}
            </b-col>
          </b-form-row>
        </b-form>
      </b-card-body>
      <b-card-footer>
        <b-col class="text-right" md="12">
          <b-button variant="light" title="Save Line" @click="saveLineClicked">
            <font-awesome-icon icon="save" />
          </b-button>
        </b-col>
      </b-card-footer>
    </b-card>
    <b-card no-body>
      <b-card-header style="background-color: pink">
        Hazmat
        <font-awesome-icon
          title="Toggle Hazmat Collapse"
          :icon="hazmatCollapseIcon"
          @click="collapseHazmatClicked"
        />
      </b-card-header>
      <b-card-body v-show="!collapseHazmat">
        <b-form>
          <b-form-row>
            <b-col md="1" class="col-form-label">Bill To:</b-col>
            <b-col md="2">
              <b-form-input
                v-model="currentLine.inbBillingCode"
                :formatter="upper"
                lazy
                trim
              />
            </b-col>
            <PartyDisplay
              :code="currentLine.inbBillingCode"
              class="col-md-5 col-form-label"
            />
          </b-form-row>
          <b-form-row>
            <b-col md="1" class="col-form-label">Carrier:</b-col>
            <b-col md="2">
              <b-form-input
                v-model="currentLine.inbBillingCarrierCode"
                :formatter="upper"
                lazy
                trim
              />
            </b-col>
            <PartyDisplay
              :code="currentLine.inbBillingCarrierCode"
              class="col-md-5 col-form-label"
            />
          </b-form-row>
        </b-form>
      </b-card-body>
    </b-card>
  </b-card>
</template>

<script>
import FileUploadIcon from "../../common/FileUploadIcon";
import ShowLogIcon from "../../common/ShowLogIcon";
import PartyDisplay from "../../common/PartyDisplay";
import textutils from "../../../textutils";
import { lookups, shipmentApi } from "../../../api/manifest";
import reportsapi from "../../../api/reportsapi";
import { MANIFEST_URL } from "../../../config";

export default {
  components: {
    FileUploadIcon,
    ShowLogIcon,
    PartyDisplay,
  },
  props: {
    currentLine: Object,
    lines: Array,
    currentParcels: Array,
    currentLineIdx: Number,
    nextLine: Function,
    previousLine: Function,
    newLine: Function,
    saveLine: Function,
    deleteLine: Function,
    facility: [String, Function],
    hideManifest: {
      type: Boolean,
      default: true,
    },
    hideShipment: {
      type: Boolean,
      default: true,
    },
    hideInbound: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lineTypes: [],
      barcodeToAdd: null,
      collapseHazmat: false,
      collapseInbound: false,
      shipment: null,
      manifestUrl: MANIFEST_URL,
    };
  },
  watch: {
    currentLine(newVal) {
      if (newVal != null) {
        if (
          !this.hideShipment &&
          this.newVal.incomingShipmentHeaderId != null
        ) {
          shipmentApi
            .loadShipment(newVal.incomingShipmentHeaderId)
            .then((res) => (this.shipment = res.data.payload));
        }
        this.collapseInbound = newVal.inbBillingCode == null;
      }
    },
  },
  computed: {
    shippingPieces() {
      let p = 0;
      p = this.currentLine.quantityShipped + this.currentLine.overShort;
      return p;
    },
    hazmatCollapseIcon() {
      return this.collapseHazmat ? "caret-down" : "caret-up";
    },
    inboundCollapseIcon() {
      return this.collapseInbound ? "caret-down" : "caret-up";
    },
    parentLinkLabel() {
      let l = null;
      if (!this.hideManifest && this.currentLine.manifestNo != null) {
        l = "Manifest:";
      } else if (
        !this.hideShipment &&
        this.currentLine.incomingShipmentHeaderId != null
      ) {
        l = "Shipment:";
      }
      return l;
    },
    parentLink() {
      let l = null;
      if (!this.hideManifest && this.currentLine.manifestNo != null) {
        l = `${this.manifestUrl}/ManifestView/Index?manifest=${this.currentLine.manifestNo}&initialLineId=${this.currentLine.id}`;
      } else if (
        !this.hideShipment &&
        this.currentLine.incomingShipmentHeaderId != null
      ) {
        l = `${this.manifestUrl}/IncomingShipmentView/Detail?incomingShipmentId=${this.currentLine.incomingShipmentHeaderId}&incomingShipmentLineId=${this.currentLine.id}`;
      }
      return l;
    },
    parentLinkText() {
      let l = null;
      if (!this.hideManifest && this.currentLine.manifestNo != null) {
        l = `${this.currentLine.manifestNo}`;
      } else if (
        !this.hideShipment &&
        this.currentLine.incomingShipmentHeaderId != null
      ) {
        l = this.shipment.shipmentName;
      }
      return l;
    },
  },
  methods: {
    upper(val) {
      return textutils.toUpper(val);
    },
    printClicked(report, ext, params) {
      let url = reportsapi.getReportLink(report, ext, params);
      window.open(url);
    },
    barcodeVerifiedIcon(parcel) {
      return parcel.manifestNo == null ? "ban" : "check-circle";
    },
    barcodeVerifiedTitle(parcel) {
      return parcel.manifestNo == null
        ? "Not verified on manifest"
        : "Verified on manifest";
    },
    collapseHazmatClicked() {
      this.collapseHazmat = !this.collapseHazmat;
    },
    collapseInboundClicked() {
      this.collapseInbound = !this.collapseInbound;
    },
    viewShipmentLineInManifestClicked() {
      let u = `${this.manifestUrl}/IncomingShipmentView/Detail?incomingShipmentId=${this.currentLine.incomingShipmentHeaderId}&incomingShipmentLineId=${this.currentLine.id}`;
      window.open(u, "manifestFromMissy");
    },
    async addLineClicked() {
      await this.newLine();
      this.$refs.destinationCodeInput.focus();
    },
    deleteLineClicked() {
      this.deleteLine();
    },
    generateBarcodesClicked() {},
    lineInboundSheetClicked() {},
    lineShippingLabelClicked() {},
    splitByDestinationClicked() {},
    splitByOrderClicked() {},
    mergeOrdersClicked() {},
    assignToManifestClicked() {},
    saveLineClicked() {
      this.saveLine();
    },
  },
  async created() {
    this.lineTypes = await lookups
      .loadLineTypes()
      .then((res) => res.data.payload.map((t) => t.id));
  },
};
</script>

<style scoped>
.ccText {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  writing-mode: lr-tb;
}
</style>
