<template>
  <div>
    <button @click="checkOut" type="button" class="btn btn-primary">
      Check Out
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters("yard", ["selectedStayForActions"]),
  },
  methods: {
    ...mapActions("yard", ["showStayActions", "stayEnded"]),
    checkOut() {
      this.stayEnded();
      this.close();
    },
    close() {
      this.showStayActions({ s: null, a: null });
    },
  },
};
</script>
