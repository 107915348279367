<template>
  <b-container fluid class="h-100" style="overflow: hidden; overflow-y: auto">
    <div v-show="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <b-card no-body>
      <b-card-header>
        <b-row>
          <b-col
            >Active Employees ({{ employees.length }})
            <font-awesome-icon @click="loadEmployees" icon="redo" />
          </b-col>
          <b-col class="float-right">
            <b-row>
              <b-col
                ><b-form-input v-model="newFirstName" placeholder="First Name"
              /></b-col>
              <b-col
                ><b-form-input v-model="newLastName" placeholder="Last Name"
              /></b-col>
              <!--
              <b-col>
                <b-form-select v-model="newSupervisor" :options="supervisors">
                </b-form-select>
              </b-col>
              -->
              <b-col md="auto"
                ><b-button :disabled="addNewDisabled" @click="addNewEmployee"
                  >Add</b-button
                ></b-col
              >
            </b-row>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col md="4">
            <b-form-input v-model="filter" placeholder="Filter names..." />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="2">Last Name</b-col>
          <b-col md="2">First Name</b-col>
          <b-col>Current ({{ currentWeek }})</b-col>
          <b-col>Next ({{ nextWeek }})</b-col>
          <!-- <b-col>Supervisor</b-col> -->
          <b-col v-if="isAdmin">Remove</b-col>
        </b-row>
        <b-row
          class="row-striped"
          v-for="emp in filteredEmployees"
          :key="emp.id"
        >
          <b-col md="2">{{ emp.lastName }}</b-col>
          <b-col md="2">{{ emp.firstName }}</b-col>
          <b-col
            style="margin-top: 1px; margin-bottom: 1px"
            :class="statusClass(currentDoc(emp))"
          >
            <b-row>
              <b-col>
                <span
                  v-if="currentDoc(emp) != null"
                  class="clickable"
                  @click="doDownloadFile(emp, currentDoc(emp))"
                  >View</span
                >
              </b-col>
              <b-col title="Mark Test Document Verified">
                <b-form-checkbox
                  v-if="currentDoc(emp) != null"
                  :disabled="disableVerify"
                  v-model="currentDoc(emp).verified"
                  value="true"
                  unchecked-value="false"
                  @change="
                    doToggleVerify(currentDoc(emp).id, currentDoc(emp).verified)
                  "
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col
            style="margin-top: 1px; margin-bottom: 1px"
            :class="statusClass(nextDoc(emp))"
          >
            <b-row>
              <b-col>
                <span
                  v-if="nextDoc(emp) != null"
                  class="clickable"
                  @click="doDownloadFile(emp, nextDoc(emp))"
                  >View</span
                >
              </b-col>
              <b-col>
                <font-awesome-icon
                  v-if="
                    isAdmin &&
                    nextDoc(emp) != null &&
                    canBackdate(nextDoc(emp).docCoverageDate)
                  "
                  @click="doBackDate(emp)"
                  icon="arrow-left"
                  title="Make document for current week"
                />
              </b-col>
              <b-col title="Mark Test Document Verified">
                <b-form-checkbox
                  v-if="nextDoc(emp) != null"
                  :disabled="disableVerify"
                  v-model="nextDoc(emp).verified"
                  title="Mark Test Document Verified"
                  value="true"
                  unchecked-value="false"
                  @change="
                    doToggleVerify(nextDoc(emp).id, nextDoc(emp).verified)
                  "
                />
              </b-col>
            </b-row>
          </b-col>
          <!-- <b-col>{{ superName(emp.supervisor) }}</b-col> -->
          <b-col v-if="isAdmin">
            <font-awesome-icon @click="inactivate(emp)" icon="window-close" />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <LoadingSplash />
  </b-container>
</template>

<script>
import textutils from "../../textutils";
import { mapActions, mapGetters } from "vuex";
import LoadingSplash from "../LoadingSplash.vue";
import miscApi from "../../api/misc";
import moment from "moment";
import attachApi from "../../api/attach";
import dateutil from "../../dateutil";

export default {
  components: {
    LoadingSplash,
  },
  data() {
    return {
      employees: [],
      newFirstName: null,
      newLastName: null,
      newSupervisor: null,
      currentWeek: null,
      nextWeek: null,
      filter: null,
      supervisors: [{ value: null, text: "-- Supervisor --", disabled: true }],
    };
  },
  computed: {
    ...mapGetters("common", ["errorMessage"]),
    ...mapGetters("security", ["inRole", "userName"]),
    addNewDisabled() {
      return (
        this.newFirstName == null || this.newLastName == null // ||
        //this.newSupervisor == null
      );
    },
    isAdmin() {
      return this.inRole("VaxAdmin");
    },
    isSupervisor() {
      return this.inRole("VaxSupervisor");
    },
    disableVerify() {
      return !this.isAdmin;
    },
    filteredEmployees() {
      if (this.filter != null && this.filter.length > 0) {
        let f = textutils.toLower(this.filter);
        return this.employees.filter(
          (e) =>
            textutils.toLower(e.firstName).startsWith(f) ||
            textutils.toLower(e.lastName).startsWith(f),
        );
      } else {
        return this.employees;
      }
    },
  },
  methods: {
    ...mapActions("common", ["showLoadingDuringAction"]),
    async loadEmployees() {
      let call = this.doLoadEmployees();
      return this.showLoadingDuringAction(call);
    },
    async doLoadEmployees() {
      return miscApi.loadVaxEmployeesWithDocs(this.currentWeek).then((r) => {
        let x = r.data;
        if (this.isSupervisor && !this.isAdmin) {
          x = x.filter((e) => e.supervisor == this.userName);
        }
        this.employees = x;
      });
    },
    async doBackDate(emp) {
      let d = moment(this.currentWeek).format();
      if (this.currentDoc(emp)) {
        let f_id = `${this.currentDoc(emp).id}${this.currentDoc(emp).docExt}`;
        await attachApi.deleteFile("vaxdoc", emp.id, f_id);
      }
      let c = miscApi.setVaxDocCoverage(this.nextDoc(emp).id, d).finally(() => {
        this.doLoadEmployees();
      });
      return this.showLoadingDuringAction(c);
    },
    async doToggleVerify(docId, verified) {
      let c = miscApi.setVaxDocVerified(docId, verified).finally(() => {
        this.doLoadEmployees();
      });
      return this.showLoadingDuringAction(c);
    },
    currentDoc(emp) {
      return emp.docs.find(
        (d) => this.docDateToLocalDate(d.docCoverageDate) == this.currentWeek,
      );
    },
    nextDoc(emp) {
      return emp.docs.find(
        (d) => this.docDateToLocalDate(d.docCoverageDate) == this.nextWeek,
      );
    },
    canBackdate(docDate) {
      return this.docDateToLocalDate(docDate) == this.nextWeek;
    },
    superName(supervisor) {
      let r = "";
      if (supervisor) {
        let e = this.supervisors.find((a) => a.value == supervisor);
        if (e) {
          r = e.text;
        }
      }
      return r;
    },
    statusClass(doc) {
      let cls = "trouble";
      if (doc) {
        cls = "caution";
        if (doc.verified) {
          cls = "allgood";
        }
      }
      return cls;
    },
    docDateToLocalDate(docDate) {
      let r = "";
      if (docDate) {
        r = dateutil.getUTCDateTime(docDate, "YYYY-MM-DD"); //Change to Local in prod
      }
      return r;
    },
    doDownloadFile(emp, doc) {
      let fn = `${doc.id}${doc.docExt}`;
      this.downloadFile(emp.id, fn);
    },
    downloadFile(id, fileName) {
      window.open(attachApi.downloadLink("vaxdoc", id, fileName), "_new", "");
    },
    addNewEmployee() {
      let fn = textutils.toUpper(this.newFirstName);
      let ln = textutils.toUpper(this.newLastName);
      let s = "ovandernat@qcfreightservice.com"; //textutils.toLower(this.newSupervisor);
      let emp = { firstName: fn, lastName: ln, supervisor: s };
      let c = miscApi.createVaxEmployee(emp).finally(() => {
        this.clearNew();
        miscApi.loadVaxEmployees().then((r) => {
          this.employees = r.data;
        });
      });
      return this.showLoadingDuringAction(c);
    },
    clearNew() {
      this.newFirstName = null;
      this.newLastName = null;
    },
    inactivate(emp) {
      let c = miscApi.inactivateVaxEmployee(emp.id).finally(() => {
        this.doLoadEmployees();
      });
      return this.showLoadingDuringAction(c);
    },
  },
  mounted() {
    let m = dateutil.getLocalDateTime(
      moment().day("Monday").startOf("day"),
      "YYYY-MM-DD",
    );
    this.currentWeek = m;
    this.nextWeek = dateutil.getLocalDateTime(
      moment(m).add(1, "week"),
      "YYYY-MM-DD",
    );
    this.loadEmployees();
  },
};
</script>

<style scoped>
.allgood {
  background-color: green;
  color: white;
}
.allgood .clickable {
  color: white;
}
.caution {
  background-color: yellow;
}
.trouble {
  background-color: red;
  color: white;
}
</style>
