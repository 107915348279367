import { trailers as trailersApi } from "../../api/movementapi";

// initial state
const state = {
  lastAdded: null,
};

// getters
const getters = {
  lastAdded(state) {
    return state.lastAdded;
  },
};

// actions
const actions = {
  resetStore({ dispatch, commit }) {
    commit("setLastAdded", null);
    dispatch("common/setErrorMessage", null, { root: true });
    dispatch("common/setLoading", false, { root: true });
  },
  addEntity({ dispatch, commit }, { func, target }) {
    commit("setLastAdded", null);
    dispatch("common/setErrorMessage", null, { root: true });
    dispatch("common/setLoading", true, { root: true });
    trailersApi[func](target)
      .then((res) => {
        commit("setLastAdded", res.data.payload);
      })
      .catch((err) => {
        dispatch("common/handleHttpError", err, {
          root: true,
        });
      })
      .finally(() => {
        dispatch("common/setLoading", false, { root: true });
      });
  },
};

// mutations
const mutations = {
  setLastAdded(state, obj) {
    state.lastAdded = obj;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
