<template>
  <b-modal
    id="add-stop-modal"
    title="Add Stop to Bill of Lading"
    size="md"
    @show="resetModal"
    @hidden="resetModal"
    @ok="okClicked"
    @shown="shown"
    :ok-disabled="!validInputs"
  >
    <div v-show="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <b-form ref="addStopModalForm" @submit.stop.prevent="handleSubmit">
      <b-row>
        <b-col>
          <b-form-group
            label="Stop Code:"
            label-for="stopcode-input"
            label-cols-md="4"
          >
            <b-form-input
              id="stopcode-input"
              ref="stopCodeInput"
              v-model="stopCode"
              :formatter="upper"
              trim
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-text> -- OR -- </b-form-text>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Manifest:"
            label-for="manifest-input"
            label-cols-md="4"
          >
            <b-form-input
              id="manifest-input"
              ref="manifestInput"
              v-model="manifestNo"
              :formatter="upper"
              trim
              number
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Comment:"
            label-for="comment-input"
            label-cols-md="4"
          >
            <b-form-textarea
              id="comment-input"
              ref="commentInput"
              v-model="comment"
              :formatter="upper"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import textutils from "../../../textutils";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      stopCode: null,
      manifestNo: null,
      comment: null,
    };
  },
  computed: {
    ...mapGetters("common", ["defaultFacility", "errorMessage"]),
    ...mapGetters("bol", ["bol"]),
    validInputs() {
      return this.stopCode || this.manifestNo;
    },
  },
  methods: {
    ...mapActions("common", ["setErrorMessage"]),
    ...mapActions("bol", ["createStop"]),
    upper(val) {
      return textutils.toUpper(val);
    },
    resetModal() {
      this.stopCode = null;
      this.manifestNo = null;
      this.comment = null;
      this.setErrorMessage(null);
    },
    okClicked(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      let s = {
        billOfLadingNo: this.bol.billOfLadingNo,
        stopCode: this.stopCode,
        comment: this.comment,
      };
      this.createStop({
        stop: s,
        manifestNo: this.manifestNo,
      }).then((stop) => {
        if (stop) {
          this.$bvModal.hide("add-stop-modal");
          this.$nextTick(() => {
            this.$toasted.success(
              `Stop ${stop.id} ${stop.stopNo} ${stop.stopCode} created`,
            );
          });
        }
      });
    },
    shown() {
      this.$refs.stopCodeInput.focus();
    },
  },
};
</script>
