<template>
  <b-modal
    id="loading-splash-modal"
    :no-fade="true"
    :hide-footer="true"
    :hide-header="true"
    modal-class="trans"
    content-class="trans"
    dialog-class="qcfs-spinner"
  >
    <div
      class="spinner-border"
      style="width: 10rem; height: 10rem"
      role="status"
    >
      <span class="sr-only">Loading...</span>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("common", ["loading"]),
  },
  watch: {
    loading(newVal) {
      if (newVal) {
        this.$bvModal.show("loading-splash-modal");
      } else {
        this.$bvModal.hide("loading-splash-modal");
      }
    },
  },
};
</script>

<style>
.trans {
  background-color: transparent;
  border: none;
}
.qcfs-spinner {
  display: table;
  position: relative;
  margin: 0 auto;
  top: calc(50% - 24px);
  background-color: transparent;
  border: none;
}
</style>
