<template>
  <div class="container-fluid h-100">
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col">
            <button class="btn" @click="toggleGlobalHideEnded">
              <font-awesome-icon
                title="Hide All Ended Routes"
                icon="eye"
                v-show="!globalHideEnded"
              />
              <font-awesome-icon
                title="Show All Ended Routes"
                icon="eye-slash"
                v-show="globalHideEnded"
              />
            </button>
            &nbsp; Routes for
            <select v-model="workDay" :options="availableWorkDays">
              <option v-for="d in availableWorkDays" :key="d">{{ d }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div v-show="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <div class="card">
      <div
        class="card-body"
        v-bind:style="{ 'max-height': contentHeight + 'px' }"
        style="overflow-y: auto; padding: 0px"
        v-if="filteredWorkDay != null"
      >
        <WorkDayPanelForDriver class="row-striped" :workDay="filteredWorkDay" />
      </div>
    </div>
    <LoadingSplash />
    <LadingShipmentModal />
  </div>
</template>

<script>
import LoadingSplash from "../LoadingSplash.vue";
import LadingShipmentModal from "./LadingShipmentModal.vue";
import { mapGetters, mapActions } from "vuex";
import messaging from "../../messaging";
import dateutil from "../../dateutil";
import WorkDayPanelForDriver from "./WorkDayPanelForDriver.vue";

export default {
  components: {
    LoadingSplash,
    WorkDayPanelForDriver,
    LadingShipmentModal,
  },
  data() {
    return {
      workDay: null,
      windowHeight: window.innerHeight || 0,
      driverToBeginRoutes: null,
      driverFilter: null,
      timerHandle: null,
    };
  },
  computed: {
    ...mapGetters("security", ["userName"]),
    ...mapGetters("common", ["errorMessage", "loading"]),
    ...mapGetters("routes", [
      "getDrivers",
      "driversWithWorkDay",
      "driversWithoutWorkDay",
      "workDays",
      "routes",
      "routesForDriver",
      "readOnly",
      "globalHideEnded",
    ]),
    contentHeight() {
      return this.windowHeight - 180 || 0;
    },
    availableWorkDays() {
      let d = [];
      for (let i = 0; i < 2; i++) {
        let tmp = dateutil.localNow().subtract(i, "days").format("YYYY-MM-DD");
        d.push(tmp);
      }
      return d;
    },
    filteredWorkDay() {
      return this.workDays.length > 0 ? this.workDays[0] : null;
    },
  },
  watch: {
    driverToBeginRoutes(newVal) {
      if (newVal != null) {
        this.beginWorkDayForDriver(newVal).then(
          () => (this.driverToBeginRoutes = null),
        );
      }
    },
    workDay(newVal) {
      if (newVal != null) {
        this.setWorkDay({ workDay: newVal, driver: null });
      }
    },
  },
  methods: {
    ...mapActions("routes", [
      "loadInitialData",
      "clearData",
      "handleDriverWorkDayRouteMutation",
      "handleDriverWorkDayMutation",
      "handleDriverMutation",
      "handleTrailerMutations",
      "beginWorkDayForDriver",
      "setWorkDay",
      "loadLookups",
      "toggleGlobalHideEnded",
      "setDriver",
      "updateRoutes",
    ]),
    clearDriverFilter() {
      this.driverFilter = null;
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    async doStart() {
      await this.clearData();
      await this.setDriver(this.userName);
      messaging.subscribe(
        "DriverDispatchPage.DriverMutations",
        "/topic/Topic.DriverMutations",
        this.handleDriverMutation,
      );
      messaging.subscribe(
        "DriverDispatchPage.DriverWorkDayMutations",
        "/topic/Topic.DriverWorkDayMutations",
        this.handleDriverWorkDayMutation,
      );
      messaging.subscribe(
        "DriverDispatchPage.DriverWorkDayRouteMutations",
        "/topic/Topic.DriverWorkDayRouteMutations",
        this.handleDriverWorkDayRouteMutation,
      );
      messaging.subscribe(
        "DriverDispatchPage.TrailerMutations",
        "/topic/Topic.TrailerMutations",
        this.handleTrailerMutations,
      );
      messaging.restart();
      this.workDay = dateutil.localNowFormatted("YYYY-MM-DD");
    },
    onTick() {
      this.updateRoutes();
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.loadLookups().then(() => {
      this.doStart();
      this.timerHandle = window.setInterval(this.onTick, 1000 * 60);
    });
  },
  beforeDestroy() {
    window.clearInterval(this.timerHandle);
    window.removeEventListener("resize", this.onResize);
  },
  destroyed() {
    messaging.unsubscribe("DriverDispatchPage.TrailerMutations");
    messaging.unsubscribe("DriverDispatchPage.DriverWorkDayRouteMutations");
    messaging.unsubscribe("DriverDispatchPage.DriverWorkDayMutations");
    messaging.unsubscribe("DriverDispatchPage.DriverMutations");
  },
};
</script>

<style scoped>
.btn {
  background-color: lightgrey;
}
.btn:hover {
  background-color: lightgreen;
}
.row-striped:nth-of-type(odd) {
  background-color: darkcyan;
}
.row-striped:nth-of-type(even) {
  background-color: lightsalmon;
}
.row-striped {
  border: 3px solid black;
}
</style>
