<template>
  <b-modal
    id="inbound-shipment-inv-bundles"
    :title="title"
    @show="resetModal"
    @hidden="resetModal"
    @shown="shown"
  >
    <b-row class="table-responsive">
      <b-table
        :items="bundles"
        :fields="headers"
        responsive="sm"
        primary-key="id"
        striped
        small
      >
      </b-table>
    </b-row>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import { inboundInvShipment } from "../../api/bundleinventory";

export default {
  props: {
    inboundShipment: Object,
  },
  data() {
    return {
      bundles: [],
      headers: [
        { key: "bundle", label: "Bundle" },
        { key: "quantity", label: "Quantity" },
        { key: "quantityStored", label: "Stored" },
      ],
    };
  },
  computed: {
    title() {
      if (this.inboundShipment) {
        return `${this.inboundShipment.name} Inventory Bundles`;
      } else {
        return "";
      }
    },
  },
  methods: {
    ...mapActions("common", ["showLoadingDuringAction"]),
    resetModal() {
      this.bundles = [];
    },
    shown() {
      let l = inboundInvShipment.getShipmentBundles(this.inboundShipment.id);
      this.showLoadingDuringAction(l).then((res) => {
        if (res) {
          this.bundles = res.data.payload;
        }
      });
    },
  },
};
</script>
