<template>
  <b-container fluid class="h-100" style="overflow: hidden; overflow-y: auto">
    <div v-show="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <b-row>
      <b-col md="9">
        <b-card no-body>
          <b-card-header>
            <b-row>
              <b-col md="4"
                >Shipment
                <span v-if="shipment != null"
                  >{{ shipment.facility }} {{ shipment.id }}
                  <font-awesome-icon
                    title="View in QCFS Manifest"
                    class="clickable"
                    icon="exchange-alt"
                    @click="viewShipmentInManifestClicked"
                  /> </span
              ></b-col>
              <b-col md="8" class="text-right">
                <b-button
                  variant="light"
                  size="md"
                  @click="searchShipmentClicked"
                >
                  <font-awesome-icon
                    title="Search for Shipment"
                    icon="search"
                  />
                </b-button>
                <b-button variant="light" size="md" @click="addShipmentClicked">
                  <font-awesome-icon title="Add new Shipment" icon="plus" />
                </b-button>
                <b-button
                  variant="light"
                  size="md"
                  @click="acceptShipmentClicked"
                  :disabled="shipment.dateArrived != null"
                  v-if="shipment != null"
                >
                  <font-awesome-icon title="Accept Shipment" icon="check" />
                </b-button>
                <b-button
                  variant="light"
                  size="md"
                  @click="assignLinesToManifestClicked"
                  :disabled="shipmentReceivedPieces > shipmentPiecesManifested"
                  v-if="shipment != null"
                >
                  <font-awesome-icon
                    title="Assign Lines to Manifest"
                    icon="file-upload"
                  />
                </b-button>
                <b-button
                  variant="light"
                  size="md"
                  @click="removeLinesFromManifestClicked"
                  :disabled="shipmentPiecesManifested == 0"
                  v-if="shipment != null"
                >
                  <font-awesome-icon
                    title="Remove Lines from Manifest"
                    icon="file-download"
                  />
                </b-button>
                <b-button
                  variant="light"
                  size="md"
                  @click="deleteShipmentClicked"
                  :disabled="shipmentParcelCount > 0"
                  v-if="shipment != null"
                >
                  <font-awesome-icon title="Delete Shipment" icon="trash-alt" />
                </b-button>
                <b-button
                  variant="light"
                  size="md"
                  @click="generateBarcodesClicked"
                  :disabled="shipmentReceivedPieces == shipmentParcelCount"
                  v-if="shipment != null"
                >
                  <font-awesome-icon title="Generate Barcodes" icon="sync" />
                </b-button>
                <b-button
                  variant="light"
                  size="md"
                  @click="assignBarcodesToOrdersClicked"
                  v-if="shipment != null"
                >
                  <font-awesome-icon
                    title="Assign Barcodes to Orders"
                    icon="barcode"
                  />
                </b-button>
                <b-button
                  variant="light"
                  size="md"
                  @click="splitByShipToClicked"
                  :disabled="shipment.dateArrived != null"
                  v-if="shipment != null"
                >
                  <font-awesome-icon
                    title="Split by Ship To Codes"
                    icon="expand-alt"
                  />
                </b-button>
                <ShowLogIcon
                  v-if="shipment != null"
                  :title="'Logs for Shipment ' + shipment.id"
                  :term="shipment.id"
                  type="shipment"
                />
                <b-button
                  variant="light"
                  size="md"
                  @click="emailShipmentDocuments"
                >
                  <font-awesome-icon
                    title="Email shipment documents"
                    icon="envelope"
                  />
                </b-button>
                <FileUploadIcon
                  v-if="shipment != null"
                  :title="'Attachments for Shipment ' + shipment.id"
                  :id="shipment.id"
                  @files-updated="attachmentsUpdated"
                  type="shipment"
                />
                <DocsDropdown v-if="shipment != null" :shipment="shipment" />
              </b-col>
            </b-row>
          </b-card-header>
          <b-card-body v-if="shipment != null">
            <form>
              <b-form-row>
                <b-col md="1" class="col-form-label">Supplier:</b-col>
                <b-form-input
                  v-model="shipment.supplierId"
                  class="col-md-2"
                  :formatter="upper"
                ></b-form-input>
                <b-col md="1" class="col-form-label">Shipped:</b-col>
                <b-col md="3" class="col-form-label">{{
                  local(shipment.dateShipped)
                }}</b-col>
                <b-col md="1" class="col-form-label">Arrived:</b-col>
                <b-col md="3" class="col-form-label"
                  ><span v-if="shipment.dateArrived != null">{{
                    local(shipment.dateArrived)
                  }}</span></b-col
                >
              </b-form-row>
              <b-form-row>
                <b-col md="1" class="col-form-label">Name:</b-col>
                <b-form-input
                  v-model="shipment.shipmentName"
                  class="col-md-5"
                  :formatter="upper"
                ></b-form-input>
                <b-col md="1" class="col-form-label">Type:</b-col>
                <b-form-select
                  class="col-md-5"
                  v-model="shipment.shipmentTypeCode"
                  :options="shipmentTypes"
                  text-field="shipmentTypeCodeDisplay"
                  value-field="shipmentTypeCode"
                  ref="shipmentTypeCode"
                >
                  <template #first>
                    <b-form-select-option
                      v-if="shipment.shipmentTypeCode == null"
                      :value="null"
                      disabled
                      >-- Select a Shipment Type --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-row>
              <b-form-row>
                <b-col md="1" class="col-form-label">Tracking:</b-col>
                <b-form-input
                  v-model="shipment.trackingId"
                  class="col-md-2"
                  :formatter="upper"
                ></b-form-input>
                <b-col md="1" class="col-form-label">Carrer:</b-col>
                <b-form-input
                  v-model="shipment.carrier"
                  class="col-md-2"
                  :formatter="upper"
                ></b-form-input>
                <b-col md="1" class="col-form-label">Trailer:</b-col>
                <b-form-input
                  v-model="shipment.trailerNo"
                  class="col-md-2"
                  :formatter="upper"
                ></b-form-input>
                <b-col md="1" class="col-form-label">BOL:</b-col>
                <b-form-input
                  v-model="shipment.carrierBol"
                  class="col-md-2"
                  :formatter="upper"
                ></b-form-input>
              </b-form-row>
            </form>
          </b-card-body>
          <b-card-footer>
            <b-row>
              <b-col md="10">
                Pieces(Supplier/Received/Manifested/Coded/Scanned):
                {{ shipmentSupplierPieces }}/{{ shipmentReceivedPieces }}/{{
                  shipmentPiecesManifested
                }}/{{ shipmentParcelCount }}/{{
                  shipmentParcelCountVerified
                }}
                Weight: {{ shipmentWeight }}
              </b-col>
            </b-row>
          </b-card-footer>
        </b-card>
        <LineDetail
          v-if="shipment != null"
          :currentLine="currentLine"
          :currentLineIdx="currentLineIdx"
          :lines="lines"
          :currentParcels="lineParcels(currentLine.id)"
          :previousLine="previousLine"
          :nextLine="nextLine"
          :newLine="newLine"
          :saveLine="saveLine"
          :deleteLine="deleteLine"
          :facility="shipment.facility"
          :hideManifest="false"
        />
      </b-col>
      <OrderSidebar
        showManifest
        :lines="lines"
        :isNotStaged="isNotStaged"
        :isCurrentOrder="isCurrentOrder"
        :setCurrentLine="setCurrentLine"
        :lineParcelCount="lineParcelCount"
        :lineParcelCountVerified="lineParcelCountVerified"
        :currentLine="currentLine"
      />
    </b-row>
    <LoadingSplash />
    <CreateNewShipmentModal
      :currentFacility="shipment != null ? shipment.facility : null"
    />
    <SearchShipmentModal />
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import reportsapi from "../../../api/reportsapi";
import { shipmentApi } from "../../../api/manifest";
import LoadingSplash from "../../LoadingSplash";
import OrderSidebar from "./OrderSidebar";
import DocsDropdown from "./DocsDropdown";
import CreateNewShipmentModal from "./CreateNewShipmentModal";
import SearchShipmentModal from "./SearchShipmentModal";
import LineDetail from "./LineDetail";
import ShowLogIcon from "../../common/ShowLogIcon";
import FileUploadIcon from "../../common/FileUploadIcon";
import textutils from "../../../textutils";
import dateutils from "../../../dateutil";
import { MANIFEST_URL } from "../../../config";

export default {
  components: {
    LoadingSplash,
    OrderSidebar,
    DocsDropdown,
    ShowLogIcon,
    FileUploadIcon,
    CreateNewShipmentModal,
    SearchShipmentModal,
    LineDetail,
  },
  data() {
    return {
      hasAttachments: false,
      manifestUrl: MANIFEST_URL,
    };
  },
  computed: {
    ...mapGetters("common", ["errorMessage"]),
    ...mapGetters("shipment", [
      "shipment",
      "shipmentSupplierPieces",
      "shipmentReceivedPieces",
      "shipmentPiecesManifested",
      "shipmentParcelCount",
      "shipmentParcelCountVerified",
      "shipmentWeight",
      "lines",
      "lineParcels",
      "lineParcelCount",
      "lineParcelCountVerified",
      "shipmentTypes",
      "currentLine",
      "currentLineIdx",
      "lineNotStaged",
    ]),
  },
  methods: {
    ...mapActions("common", ["showLoadingDuringAction"]),
    ...mapActions("shipment", [
      "loadShipment",
      "loadShipmentTypes",
      "setCurrentLine",
      "previousLine",
      "nextLine",
      "acceptShipment",
      "newLine",
      "saveCurrentLine",
      "deleteCurrentLine",
    ]),
    upper(val) {
      return textutils.toUpper(val);
    },
    local(val) {
      return dateutils.getLocalDateTime(val, "YYYY-MM-DD hh:mma z");
    },
    isNotStaged(line) {
      return line.manifestNo == null && this.lineNotStaged(line.id);
    },
    isCurrentOrder(line) {
      return this.currentLine != null && line.id == this.currentLine.id;
    },
    attachmentsUpdated(num) {
      this.hasAttachments = num > 0;
    },
    searchShipmentClicked() {
      this.$bvModal.show("search-shipment-modal");
    },
    addShipmentClicked() {
      this.$bvModal.show("create-new-shipment-modal");
    },
    acceptShipmentClicked() {
      this.acceptShipment();
    },
    assignLinesToManifestClicked() {},
    removeLinesFromManifestClicked() {},
    async deleteShipmentClicked() {
      let confirmed = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to delete Shipment ${this.shipment.shipmentName}?`,
      );
      if (confirmed) {
        let call = shipmentApi.deleteShipment(this.shipment.id).then((res) => {
          this.$toasted.success(
            `Shipment ${res.data.payload.shipmentName} deleted`,
          );
          this.$router.push({ name: "inbshipments" });
        });
        this.showLoadingDuringAction(call);
      }
    },
    generateBarcodesClicked() {
      let call = shipmentApi
        .createBarcodesForShipment(this.shipment.id)
        .then((res) => {
          this.$toasted.success(
            `${res.data.payload.length} barcodes created for ${this.shipment.shipmentName}`,
          );
        });
      this.showLoadingDuringAction(call);
    },
    assignBarcodesToOrdersClicked() {},
    async splitByShipToClicked() {
      let confirmed = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to split Shipment ${this.shipment.shipmentName} by Ship To codes?`,
      );
      if (confirmed) {
        let s = this.shipment;
        let call = shipmentApi
          .splitAllByShipTo(this.shipment.id)
          .then((res) => {
            this.$toasted.success(
              `Shipment ${s.shipmentName} split by Ship To codes into ${res.data.payload.length} new shipments`,
            );
            this.$router.push({ name: "inbshipments" });
          });
        this.showLoadingDuringAction(call);
      }
    },
    emailShipmentDocuments() {
      reportsapi.emailShippingDocs(this.shipment.id).then(() => {
        this.$toasted.success(
          `Shipment ${this.shipment.shipmentName} documents emailed`,
        );
      });
    },
    viewShipmentInManifestClicked() {
      let u = `${this.manifestUrl}/IncomingShipmentView/Detail?incomingShipmentId=${this.shipment.id}`;
      window.open(u, "manifestFromMissy");
    },
    async saveLine() {
      let l = await this.saveCurrentLine();
      if (l != null) {
        this.setCurrentLine(l);
      }
    },
    async deleteLine() {
      let idx = this.currentLineIdx;
      let l = await this.deleteCurrentLine();
      if (l != null) {
        if (idx > 0) {
          this.previousLine();
        }
      }
    },
  },
  created() {
    this.$watch(
      () => this.$route.params,
      (toParams) => {
        this.loadShipment({
          id: toParams.id,
          lineId: this.$route.query.lineId,
        });
      },
    );
  },
  async mounted() {
    await this.loadShipmentTypes();
    if (this.$route.params.id) {
      this.loadShipment({
        id: this.$route.params.id,
        lineId: this.$route.query.lineId,
      });
    }
  },
  beforeUnmount() {
    this.loadShipment({ id: null, lineId: null });
  },
};
</script>

<style scoped>
a {
  color: blue;
}
.selectedOrder {
  background-color: blue;
  color: white;
}
.notFullyStaged {
  background-color: yellow;
}
.orderEntry {
  border: solid lightgray 1px;
}
</style>
