import axios from "axios";

var API_URL = null;
try {
  // eslint-disable-next-line no-undef
  API_URL = MAILER_URL;
} catch (e) {
  API_URL = "http://mailer.home";
  //API_URL = "http://localhost:9090";
}

export default {
  render(template, json) {
    let url = `${API_URL}/render?template=${template}`;
    return axios.post(url, json, {
      headers: { "Content-Type": "application/json" },
    });
  },
};
