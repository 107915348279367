<template>
  <table class="panel">
    <thead>
      <tr>
        <th colspan="6" class="header">{{ selectedFacility }} {{ group }}</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="loc in locations">
        <BayPanel :bay="loc" v-bind:key="loc.id" />
      </template>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from "vuex";
import BayPanel from "./BayPanel.vue";

export default {
  components: {
    BayPanel,
  },
  props: {
    group: String,
  },
  computed: {
    ...mapGetters("security", ["inRole"]),
    ...mapGetters("common", ["errorMessage", "loading"]),
    ...mapGetters("bays", [
      "bays",
      "selectedFacility",
      "selectedItemForActions",
      "groups",
    ]),
    locations: function () {
      let l = null;
      if (this.bays != null) {
        l = this.bays
          .filter((b) => b.locGroup === this.group)
          .sort((b1, b2) => {
            return (
              new Number(b1.code.substring(1)) -
              new Number(b2.code.substring(1))
            );
          });
      }
      return l;
    },
  },
};
</script>

<style scoped>
.panel {
  border: 1px lightgrey solid;
  width: 100%;
}
.header {
  border: 1px lightgrey solid;
  background-color: lightgrey;
  font-style: italic;
  text-align: center;
}
</style>
