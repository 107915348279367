import { bolApi, stopApi } from "../../api/manifest";

const state = {
  openBols: [],
  stops: [],
  loaded: false,
};

const getters = {
  openBols(state) {
    return state.openBols;
  },
  stops: (state) => (billOfLadingNo) => {
    let s = state.stops.filter((s) => s.billOfLadingNo === billOfLadingNo);
    s.sort((a, b) => a.stopNo - b.stopNo);
    return s;
  },
  loaded: (state) => {
    return state.loaded;
  },
  locked: (state) => (billOfLadingNo) => {
    let r = false;
    let s = state.stops.filter((s) => s.billOfLadingNo === billOfLadingNo);
    if (s) {
      s.forEach((t) => {
        if (t.pieces > 0) {
          r = true;
        }
      });
    }
    return r;
  },
};

const actions = {
  async loadOpenBols({ dispatch, commit }, facility) {
    commit("reset");
    dispatch("common/setErrorMessage", null, { root: true });
    dispatch("common/setLoading", true, { root: true });
    try {
      let bols = await bolApi
        .loadOpenBols(facility)
        .then((res) => res.data.payload);
      commit("setOpenBols", bols);
      let stopProms = [];
      bols.forEach((bol) => {
        let loadStop = bolApi
          .loadStopsForBol(bol.billOfLadingNo)
          .then((res) => res.data.payload);
        stopProms.push(loadStop);
      });
      let stops = await Promise.all(stopProms).then((res) => {
        return res.reduce((res, stop) => res.concat(stop));
      });
      let summariesProms = [];
      stops.forEach((stop) => {
        stop.pieces = "-";
        stop.weight = "-";
        commit("addStop", stop);
        summariesProms.push(dispatch("loadStopSummaryData", stop));
      });
      await Promise.all(summariesProms);
      commit("setLoaded", true);
    } catch (err) {
      dispatch("common/handleHttpError", err, {
        root: true,
      });
    } finally {
      dispatch("common/setLoading", false, { root: true });
    }
  },
  async loadStopSummaryData({}, stop) {
    stop.pieces = await stopApi.getPieceCount(stop.id).then((res) => res.data);
    stop.weight = await stopApi.getWeight(stop.id).then((res) => res.data);
  },
  async deleteBol({ dispatch, commit }, bol) {
    dispatch("common/setErrorMessage", null, { root: true });
    dispatch("common/setLoading", true, { root: true });
    try {
      await bolApi.deleteBol(bol).then((res) => {
        commit("removeBol", bol.billOfLadingNo);
      });
    } catch (err) {
      dispatch("common/handleHttpError", err, {
        root: true,
      });
    } finally {
      dispatch("common/setLoading", false, { root: true });
    }
  },
};

const mutations = {
  reset(state) {
    state.openBols = [];
    state.stops = [];
  },
  setOpenBols(state, openBols) {
    state.openBols = openBols;
  },
  removeBol(state, bolNo) {
    let idx = state.openBols.map((b) => b.billOfLadingNo).indexOf(bolNo);
    if (idx > -1) {
      state.openBols.splice(idx, 1);
    }
  },
  addStop(state, s) {
    let idx = state.stops.map((r) => r.id).indexOf(s.id);
    if (idx < 0) {
      state.stops.push(s);
    }
  },
  setLoaded(state, l) {
    state.loaded = l;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
