<template>
  <b-modal
    id="yard-exec-trailer-list-modal"
    :title="execModalTitle"
    size="lg"
    @hidden="resetFilters"
  >
    <b-row>
      <b-col md="4">{{ dateTitle }}</b-col>
      <b-col>Trailer</b-col>
      <b-col>Note</b-col>
      <b-col v-if="execIsInbound">Origin</b-col>
      <b-col v-if="showDestination">Destination</b-col>
      <b-col v-if="showContent">Content</b-col>
      <b-col v-if="execIsOutbound">BOL</b-col>
    </b-row>
    <b-row v-for="stay in execFilteredStays" :key="stay.id">
      <b-col md="4">{{ formatDate(stay) }}</b-col>
      <b-col>{{ stay.trailer.scac }} {{ stay.trailer.id }}</b-col>
      <b-col>{{ stay.trailer.note }}</b-col>
      <b-col v-if="execIsInbound">{{ getOrigin(stay.inboundOrigin) }}</b-col>
      <b-col v-if="showDestination">{{
        getDestination(stay.inboundDestination)
      }}</b-col>
      <b-col v-if="showContent">{{ getContent(stay.inboundContent) }}</b-col>
      <b-col v-if="execIsOutbound">{{ stay.outboundBol }}</b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import dateutil from "../../dateutil";

export default {
  computed: {
    ...mapGetters("yard", [
      "execFilteredStays",
      "inboundDests",
      "inboundOrigins",
      "inboundContents",
      "execStayDateProp",
      "execModalTitle",
      "execIsInbound",
      "execIsOutbound",
      "execInbDestFilter",
      "execInbContentsFilter",
    ]),
    dateTitle() {
      return this.execStayDateProp == "beginStay" ? "Arrived" : "Loaded At";
    },
    showDestination() {
      return this.execIsInbound && !this.execInbDestFilter;
    },
    showContent() {
      return this.execIsInbound && !this.execInbContentsFilter;
    },
  },
  methods: {
    ...mapActions("yard", [
      "setExecInbDestFilter",
      "setExecInbContentsFilter",
      "setExecEmptyFilter",
      "setExecOutFilter",
      "setExecUnknownLoadedFilter",
      "setExecStayDateProp",
    ]),
    resetFilters() {
      this.setExecInbDestFilter(null);
      this.setExecInbContentsFilter(null);
      this.setExecEmptyFilter(null);
      this.setExecOutFilter(null);
      this.setExecUnknownLoadedFilter(null);
      this.setExecStayDateProp(null);
    },
    getOrigin(id) {
      return this.getItem(this.inboundOrigins, id);
    },
    getDestination(id) {
      return this.getItem(this.inboundDests, id);
    },
    getContent(id) {
      return this.getItem(this.inboundContents, id);
    },
    getItem(arr, id) {
      return arr != null ? arr.find((o) => o.id == id)?.item : null;
    },
    formatDate(stay) {
      return dateutil.getLocalDateTime(
        stay[this.execStayDateProp],
        "YYYY-MM-DD hh:mm a z",
      );
    },
  },
};
</script>
