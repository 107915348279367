<template>
  <b-modal
    id="addLadingRouteModal"
    title="Add Route from Lading Request"
    size="xl"
    @show="resetModal"
    @hide="onHide"
    @shown="onShown"
    @ok="okClicked"
  >
    <template v-if="edi204 == null || localMessage != null">
      <table width="100%">
        <thead class="tenderRow">
          <th>Shipment</th>
          <th>From</th>
          <th>Trailer</th>
          <th>To</th>
        </thead>
        <tbody>
          <tr
            class="tenderRow"
            v-for="tender in availableTenders"
            :key="tender.shipmentId"
          >
            <td>
              <span class="clickable" @click="load204(tender)">{{
                tender.shipmentId
              }}</span>
            </td>
            <td>{{ name(getShipFrom(tender)) }}</td>
            <td>{{ getTrailer(tender) }}</td>
            <td>{{ name(getShipTo(tender)) }}</td>
          </tr>
        </tbody>
      </table>
    </template>
    <template v-if="edi204 != null && localMessage == null">
      <b-row>
        <b-col>
          <h6>{{ edi204.partner }} {{ edi204.shipmentId }}</h6>
        </b-col>
      </b-row>
      <b-row>
        <b-col :class="trailerClass"
          ><strong>Trailer:</strong> {{ trailerDesc }}</b-col
        >
      </b-row>
      <b-row v-if="edi204 != null">
        <b-col><strong>From:</strong></b-col>
        <b-col><strong>To:</strong></b-col>
      </b-row>
      <b-row>
        <b-col>
          <span :class="codeClass(shipFromDestination)"
            >CODE: {{ code(shipFromDestination, shipFrom) }}</span
          ><br />
          {{ name(shipFrom) }}<br />
          {{ address1(shipFrom) }}<br />
          <span v-if="hasAddress2(shipFrom)">
            {{ address2(shipFrom) }}<br />
          </span>
          {{ city(shipFrom) }},
          {{ state(shipFrom) }}
          {{ zip(shipFrom) }}
        </b-col>
        <b-col>
          <span :class="codeClass(shipToDestination)"
            >CODE: {{ code(shipToDestination, shipTo) }}</span
          ><br />
          {{ name(shipTo) }}<br />
          {{ address1(shipTo) }}<br />
          <span v-if="hasAddress2(shipTo)"> {{ address2(shipTo) }}<br /> </span>
          {{ city(shipTo) }},
          {{ state(shipTo) }}
          {{ zip(shipTo) }}
        </b-col>
      </b-row>
    </template>
    <b-row v-if="localMessage != null">
      <b-col>{{ localMessage }}</b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { edi } from "../../api/integrationapi";
import { driverroutes, trailers } from "../../api/movementapi";
import { mapActions, mapGetters } from "vuex";
import textutils from "../../textutils";

export default {
  data() {
    return {
      availableTenders: null,
      driverWorkDay: null,
      shipmentId: null,
      edi204: null,
      shipFrom: null,
      shipTo: null,
      shipFromDestination: null,
      shipToDestination: null,
      localMessage: null,
      trailer: null,
    };
  },
  computed: {
    ...mapGetters("routes", [
      "selectedDriverWorkDay",
      "selectedDriverRoute",
      "routesForDriver",
      "destinations",
    ]),
    trailerDesc() {
      return this.trailer != null
        ? `${this.trailer.scac} ${this.trailer.id}`
        : `UNKNOWN - ${this.getTrailer(this.edi204)}`;
    },
    trailerClass() {
      return this.trailerDesc.startsWith("UNKNOWN") ? "noCodeFound" : null;
    },
  },
  methods: {
    ...mapActions("common", ["showLoadingDuringAction"]),
    ...mapActions("routes", [
      "addRoute",
      "updateRouteEdi",
      "setSelectedDriverWorkDay",
      "setSelectedDriverRoute",
    ]),
    codeClass(destination) {
      return destination ? "" : "noCodeFound";
    },
    upper(s) {
      return textutils.toUpper(s);
    },
    resetModal() {
      this.shipmentId = null;
      this.setSelectedDriverWorkDay = null;
      this.setSelectedDriverRoute = null;
      this.resetResults();
    },
    resetResults() {
      this.edi204 = null;
      this.shipFrom = null;
      this.shipTo = null;
      this.shipFromDestination = null;
      this.shipToDestination = null;
      this.localMessage = null;
      this.trailer = null;
    },
    async findShipment() {
      this.showLoadingDuringAction(this.load204());
    },
    async load204(tender) {
      this.resetResults();
      this.edi204 = tender;
      this.shipFrom = this.edi204.shipmentJson?.stops[0]?.shipFrom;
      this.shipTo = this.edi204.shipmentJson?.stops[1]?.shipTo;
      if (this.edi204 && this.shipFrom && this.shipTo) {
        let a = driverroutes
          .findDestinationByName(this.shipFrom.name)
          .then((r) => {
            if (r.data.payload && r.data.payload[0]) {
              this.shipFromDestination = r.data.payload[0];
            }
          });
        let b = driverroutes
          .findDestinationByName(this.shipTo.name)
          .then((r) => {
            if (r.data.payload && r.data.payload[0]) {
              this.shipToDestination = r.data.payload[0];
            }
          });
        await Promise.all([a, b]);
        if (
          textutils.toUpper(this.edi204.shipmentJson?.equipment?.id) !=
            "ZZZZ" &&
          textutils.toUpper(this.edi204.shipmentJson?.equipment?.id) !=
            "LIVE" &&
          textutils.toUpper(this.edi204.shipmentJson?.equipment?.id) != "LTL"
        ) {
          try {
            await trailers
              .findTrailer("QUCF", this.edi204.shipmentJson?.equipment?.id)
              .then((r) => (this.trailer = r.data.payload));
          } catch (err) {}
          if (this.trailer == null) {
            try {
              await trailers
                .findTrailer("DERK", this.edi204.shipmentJson?.equipment?.id)
                .then((r) => (this.trailer = r.data.payload));
            } catch (err) {}
          }
        }
        if (this.selectedDriverRoute != null) {
          this.validateAddLadingToExistingRoute();
        }
      } else {
        this.localMessage = `No lading found for shipment ${this.shipmentId}`;
      }
    },
    code(destination, party) {
      return destination
        ? destination.code
        : `NO ENTRY FOUND FOR ${party.name}`;
    },
    getShipFrom(tender) {
      return tender?.shipmentJson?.stops[0]?.shipFrom;
    },
    getShipTo(tender) {
      return tender?.shipmentJson?.stops[1]?.shipTo;
    },
    getTrailer(tender) {
      return tender.shipmentJson?.equipment?.id;
    },
    name(party) {
      return party?.name;
    },
    address1(party) {
      return party?.address1;
    },
    address2(party) {
      return party?.address2;
    },
    hasAddress2(party) {
      return party.address2 ? true : false;
    },
    city(party) {
      return party?.city;
    },
    state(party) {
      return party?.state;
    },
    zip(party) {
      return party?.zip;
    },
    async onShown() {
      let c = edi.loadTenders().then((r) => {
        let t = r.data.payload.filter(
          (f) => f.accepted && f.driverWorkDayRouteId == null,
        );
        t.forEach((x) => (x.shipmentJson = JSON.parse(x.shipmentJson)));
        return t;
      });
      let activeTenders = await this.showLoadingDuringAction(c);
      if (this.selectedDriverRoute != null) {
        // by route
        if (this.selectedDriverRoute.startLoc) {
          let loc = this.destinations.find(
            (d) => d.code == this.selectedDriverRoute.startLoc,
          );
          activeTenders = activeTenders.filter(
            (t) =>
              loc.name == t.shipmentJson?.stops[0]?.shipFrom?.name ||
              (loc.address == t.shipmentJson?.stops[0]?.shipFrom?.address1 &&
                loc.zip == t.shipmentJson?.stops[0]?.shipFrom?.zip),
          );
        }
        if (this.selectedDriverRoute.endLoc) {
          let loc = this.destinations.find(
            (d) => d.code == this.selectedDriverRoute.endLoc,
          );
          activeTenders = activeTenders.filter(
            (t) =>
              loc.name == t.shipmentJson?.stops[1]?.shipTo?.name ||
              (loc.address == t.shipmentJson?.stops[1]?.shipTo?.address1 &&
                loc.zip == t.shipmentJson?.stops[1]?.shipTo?.zip),
          );
        }
        if (this.selectedDriverRoute.trailer) {
          activeTenders = activeTenders.filter((t) => {
            let tmpTrailer = this.selectedDriverRoute.trailer.id;
            if (
              t.shipmentJson?.equipment?.id.length < 11 &&
              this.selectedDriverRoute.trailer.id.length > 10
            ) {
              tmpTrailer = this.selectedDriverRoute.trailer.id.substring(0, 10);
            }
            return (
              textutils.toUpper(tmpTrailer) ==
                textutils.toUpper(t.shipmentJson?.equipment?.id) ||
              textutils.toUpper(t.shipmentJson?.equipment?.id) == "ZZZZ" ||
              textutils.toUpper(t.shipmentJson?.equipment?.id) == "LIVE" ||
              textutils.toUpper(t.shipmentJson?.equipment?.id) == "LTL"
            );
          });
        }
      } else {
        // by day
        let routes = this.routesForDriver(this.selectedDriverWorkDay.driver);
        let lastRoute = routes.length > 0 ? routes[routes.length - 1] : null;
        if (lastRoute && lastRoute.endLoc) {
          let loc = this.destinations.find((d) => d.code == lastRoute.endLoc);
          activeTenders = activeTenders.filter(
            (t) => loc.name == t.shipmentJson?.stops[0]?.shipFrom?.name,
          );
        }
      }
      this.availableTenders = activeTenders;
      if (this.availableTenders.length == 1) {
        this.load204(this.availableTenders[0]);
      }
      if (this.availableTenders.length == 0) {
        this.localMessage = "No matching tenders found";
      }
    },
    onHide() {
      this.resetModal();
    },
    okClicked() {
      if (this.shipFromDestination && this.shipToDestination) {
        if (this.edi204.accepted) {
          if (
            this.selectedDriverWorkDay != null &&
            this.selectedDriverRoute == null
          ) {
            this.addNewRouteFromLading();
          } else if (
            this.selectedDriverWorkDay == null &&
            this.selectedDriverRoute != null
          ) {
            this.addLadingToExistingRoute();
          }
        }
      }
    },
    addLadingToExistingRoute() {
      if (this.localMessage == null) {
        this.selectedDriverRoute.edi204Id = this.edi204.id;
        this.selectedDriverRoute.edi204ShipmentId = this.edi204.shipmentId;
        this.billable = true;
        this.updateRouteEdi(this.selectedDriverRoute);
      }
    },
    validateAddLadingToExistingRoute() {
      let r = this.selectedDriverRoute;
      try {
        /*
        if (r.startLoc != this.shipFromDestination.code) {
          throw `Route starts at ${r.startLoc} but tender starts at ${this.shipFromDestination.code}`;
        }
        if (r.endLoc != this.shipToDestination.code) {
          throw `Route ends at ${r.endLoc} but tender ends at ${this.shipToDestination.code}`;
        }
        */
        let tmpTrailer =
          r.trailer.id.length > 10
            ? r.trailer.id.substring(0, 10)
            : r.trailer.id;
        if (r.trailer && this.trailer && tmpTrailer != this.trailer.id) {
          throw `Route trailer is ${r.trailer.id} but tender trailer is ${this.trailer.id}`;
        }
      } catch (err) {
        this.localMessage = err;
      }
    },
    addNewRouteFromLading() {
      let r = { driverWorkDay: this.selectedDriverWorkDay };
      r.startLoc = this.shipFromDestination.code;
      r.endLoc = this.shipToDestination.code;
      r.activity = "TRANSITING";
      r.billable = true;
      r.edi204Id = this.edi204.id;
      r.edi204ShipmentId = this.edi204.shipmentId;
      if (this.trailer != null) {
        r.trailer = this.trailer;
      }
      this.addRoute(r);
    },
    keyboardShortcuts(e) {
      if (e.key == "Enter") {
        e.preventDefault();
        this.findShipment();
      }
    },
  },
};
</script>

<style scoped>
.noCodeFound {
  color: white;
  background-color: red;
}

.tenderRow {
  border-bottom: 1px solid black;
}
</style>
