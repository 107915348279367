<template>
  <div class="container-fluid h-100" style="overflow: hidden; overflow-y: auto">
    <div class="card">
      <div class="card-header">
        <div v-show="errorMessage" class="alert alert-danger">
          {{ errorMessage }}
        </div>
        <div v-if="lastAdded" class="alert alert-success">
          Facility Location {{ lastAdded.facilty }} {{ lastAdded.type }}
          {{ lastAdded.code }} added successfully
        </div>
        Add Facility Location
      </div>
      <div class="card-body">
        <form>
          <div class="form-group row">
            <label for="facility" class="col-sm-2 col-form-label"
              >Facility</label
            >
            <div class="col-sm-10">
              <select
                v-model="facility"
                id="facility"
                class="form-control"
                required
                autofocus
              >
                <option :value="null" disabled>Select Facility</option>
                <option>ADC</option>
                <option>MTJOY</option>
                <option>MTJDRAY</option>
                <option>MTJOPEN</option>
                <option>ATIRI</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label for="type" class="col-sm-2 col-form-label">Type</label>
            <div class="col-sm-10">
              <select v-model="type" id="type" class="form-control" required>
                <option :value="null" disabled>Select Type</option>
                <option>BAY</option>
                <option>YARD</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label for="locGroup" class="col-sm-2 col-form-label">Group</label>
            <div class="col-sm-10">
              <input
                type="text"
                v-model.trim="locGroup"
                id="locGroup"
                class="form-control text-uppercase"
                required
                placeholder="Group"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="code" class="col-sm-2 col-form-label">Code</label>
            <div class="col-sm-10">
              <input
                type="text"
                v-model.trim="code"
                class="form-control text-uppercase"
                id="code"
                required
                placeholder="Code"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="name" class="col-sm-2 col-form-label">Name</label>
            <div class="col-sm-10">
              <input
                type="text"
                v-model.trim="name"
                class="form-control text-uppercase"
                id="name"
                required
                placeholder="Name"
              />
            </div>
          </div>
          <div class="form-group row">
            <button type="button" @click="add" class="btn btn-primary">
              Add Facility Location
            </button>
          </div>
        </form>
      </div>
    </div>
    <LoadingSplash />
  </div>
</template>

<script>
import LoadingSplash from "../LoadingSplash.vue";
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    LoadingSplash,
  },
  data() {
    return {
      facility: null,
      type: null,
      locGroup: null,
      name: null,
      code: null,
    };
  },
  validations() {
    let v = {
      facility: {
        required,
      },
      type: {
        required,
      },
      locGroup: {
        required,
      },
      name: {
        required,
      },
      code: {
        required,
      },
    };
    return v;
  },
  computed: {
    ...mapGetters("common", ["loading", "errorMessage"]),
    ...mapGetters("trailerssimple", ["lastAdded"]),
  },
  watch: {
    loading(newVal) {
      if (!newVal) {
        if (!this.errorMessage) {
          this.reset();
          this.success = true;
        }
      }
    },
  },
  destroyed() {
    this.resetStore();
  },
  methods: {
    ...mapActions("common", ["setErrorMessage"]),
    ...mapActions("trailerssimple", ["addEntity", "resetStore"]),
    reset() {
      this.facility = null;
      this.type = null;
      this.locGroup = null;
      this.name = null;
      this.code = null;
    },
    add() {
      this.setErrorMessage(null);
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.setErrorMessage(
          "Check that all required fields are filled out with valid data",
        );
      } else {
        const t = {
          facility: this.facility.toUpperCase(),
          type: this.type.toUpperCase(),
          locGroup: this.locGroup.toUpperCase(),
          name: this.name.toUpperCase(),
          code: this.code.toUpperCase(),
        };
        this.addEntity({ func: "addFacilityLocation", target: t });
      }
    },
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
