<template>
  <b-container
    fluid
    :class="containerClass"
    style="overflow: hidden; overflow-y: auto"
    id="bolPage"
  >
    <div v-show="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <b-card no-body>
      <b-card-header>
        <b-row>
          <b-col>
            Bill Of Lading <span v-if="bol != null">({{ bol.facility }})</span>
          </b-col>
          <b-col>
            <span class="alert-danger" v-show="bol != null && bolIsHazmat">
              HAZARDOUS MATERIAL ON BOARD
            </span>
          </b-col>
          <b-col class="text-right">
            <b-button variant="light" size="md" @click="helpBolClicked">
              <font-awesome-icon title="BOL Shortcuts" icon="question" />
            </b-button>
            <b-button variant="light" size="md" @click="searchBolClicked">
              <font-awesome-icon title="Search for BOL" icon="search" />
            </b-button>
            <b-button variant="light" size="md" @click="addBolClicked">
              <font-awesome-icon title="Add new BOL" icon="plus" />
            </b-button>
            <b-button
              v-if="bol != null"
              :disabled="hasAttachments || inputsDisabled"
              variant="light"
              size="md"
              @click="deleteBolClicked"
            >
              <font-awesome-icon title="Delete BOL" icon="trash-alt" />
            </b-button>
            <b-button
              v-if="bol != null"
              variant="light"
              size="md"
              @click="printBolClicked"
            >
              <font-awesome-icon title="Print BOL" icon="print" />
            </b-button>
            <b-button
              v-if="bol != null"
              variant="light"
              size="md"
              @click="printBolBarcodeClicked"
            >
              <font-awesome-icon title="Print BOL barcode" icon="barcode" />
            </b-button>
            <b-button
              v-if="bol != null"
              variant="light"
              size="md"
              @click="printBolShippingListClicked"
            >
              <font-awesome-icon title="Print shipping list" icon="list" />
            </b-button>
            <ShowLogIcon
              v-if="bol != null"
              :title="'Logs for BOL ' + bol.billOfLadingNo"
              :term="bol.billOfLadingNo"
              type="bol"
            />
            <FileUploadIcon
              v-if="bol != null"
              :title="'Attachments for BOL ' + bol.billOfLadingNo"
              :id="bol.billOfLadingNo"
              @files-updated="attachmentsUpdated"
              type="bol"
            />
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body v-if="bol != null">
        <form>
          <b-form-row>
            <b-col md="1" class="col-form-label">BOL:</b-col>
            <b-form-input
              id="billOfLadingNo"
              readonly
              v-model="bol.billOfLadingNo"
              class="col-md-1"
              :formatter="upper"
              number
            ></b-form-input>
            <b-col md="1" class="col-form-label">Date:</b-col>
            <b-col md="4" class="col-form-label">
              {{ local(bol.dateCreated) }}
            </b-col>
            <b-col v-show="bol.finalized" md="1">EDI:</b-col>
            <b-col v-show="bol.finalized" md="1">
              <b-checkbox disabled :checked="bol.chrFileSent">Logi</b-checkbox>
            </b-col>
            <b-col v-show="bol.finalized" md="1">
              <b-checkbox disabled :checked="bol.manifestFileSent"
                >Carrier</b-checkbox
              >
            </b-col>
            <b-col :class="ediCountDownClass" md="2">
              {{ ediCountdown }}
            </b-col>
          </b-form-row>
          <b-form-row>
            <label for="thirdPartyCode" class="col-md-1 col-form-label"
              >3rd Party:</label
            >
            <b-form-input
              id="thirdPartyCode"
              v-model="bol.thirdPartyCode"
              class="col-md-1"
              :disabled="inputsDisabled"
              :formatter="upper"
              ref="thirdParty"
              lazy
              trim
            ></b-form-input>
            <b-col md="1">&nbsp;</b-col>
            <party-display
              :code="bol.thirdPartyCode"
              class="col-md-4 col-form-label"
            />
            <b-col v-show="bol.finalized" md="1" class="col-form-label"
              >Finalized At:</b-col
            >
            <b-col v-show="bol.finalized" md="3" class="col-form-label">{{
              local(bol.finalizedDate)
            }}</b-col>
          </b-form-row>
          <TrailerDisplay :bol="bol" :overrideFinalized="overrideFinalized" />
          <b-form-row>
            <label for="comment" class="col-md-1 col-form-label"
              >Comments:</label
            >
            <b-form-textarea
              id="comment"
              v-model="bol.comment"
              class="col-md-11"
              :disabled="inputsDisabled"
              :formatter="upper"
            ></b-form-textarea>
          </b-form-row>
        </form>
      </b-card-body>
      <b-card-footer v-if="bol != null">
        <b-row>
          <b-col cols="4">
            Pieces: {{ bolPieces }} Weight: {{ bolWeight }}
          </b-col>
          <b-col class="text-right">
            <b-button
              :disabled="inputsDisabled"
              variant="light"
              size="md"
              @click="saveBolClicked"
            >
              <font-awesome-icon title="Save BOL" icon="save" />
            </b-button>
            <b-button
              v-show="!bol.finalized"
              variant="light"
              size="md"
              @click="finalizeBolClicked"
            >
              <font-awesome-icon title="Finalize BOL" icon="check-double" />
            </b-button>
            <b-button
              v-show="bol.finalized"
              variant="light"
              size="md"
              @click="toggleOverrideFinalized"
            >
              <font-awesome-icon
                :title="toggleLockTitle"
                :icon="toggleLockIcon"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>
    <b-card no-body v-if="bol != null">
      <b-card-header>
        <b-row>
          <b-col> Stops </b-col>
          <b-col class="text-right">
            <b-button
              :disabled="inputsDisabled"
              variant="light"
              size="md"
              @click="addStopClicked"
            >
              <font-awesome-icon title="Add Stop" icon="plus" />
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row style="border-bottom: 1px solid lightgray">
          <b-col md="1">Stop</b-col>
          <b-col md="3">Ship To</b-col>
          <b-col md="1" class="text-right">Pieces</b-col>
          <b-col md="1" class="text-right">Weight</b-col>
          <b-col>Comments</b-col>
          <b-col md="1">Manifests</b-col>
          <b-col md="1">Hazmat</b-col>
          <b-col md="2">&nbsp;</b-col>
        </b-row>
        <b-row
          v-for="(stop, stopIdx) in stops"
          class="row-striped"
          :key="stop.id"
        >
          <b-col md="1">
            <b-row no-gutters>
              <b-col v-if="stops.length > 1">
                <b-button
                  v-show="stopIdx > 0"
                  variant="light"
                  size="md"
                  :disabled="inputsDisabled"
                  @click="moveStopUpClicked(stop)"
                >
                  <font-awesome-icon icon="arrow-up" /> </b-button
                ><br />
                <b-button
                  v-show="stopIdx < stops.length - 1"
                  variant="light"
                  size="md"
                  :disabled="inputsDisabled"
                  @click="moveStopDownClicked(stop)"
                >
                  <font-awesome-icon icon="arrow-down" />
                </b-button>
              </b-col>
              <b-col>{{ stopText(stop) }}</b-col>
            </b-row>
          </b-col>
          <b-col md="3"
            ><party-display :code="stop.stopCode" isVertical
          /></b-col>
          <b-col md="1" class="text-right">{{ stopPieces(stop.id) }}</b-col>
          <b-col md="1" class="text-right">{{ stopWeight(stop.id) }}</b-col>
          <b-col>{{ stop.comment }}</b-col>
          <b-col md="1">
            <b-row
              no-gutters
              v-for="manifest in manifests(stop.id)"
              :key="manifest.manifestNo"
            >
              <b-col class="nowrap">
                <b-button
                  variant="light"
                  size="md"
                  :disabled="inputsDisabled"
                  @click="removeManifestClicked(manifest)"
                >
                  <font-awesome-icon
                    title="Remove Manifest from BOL"
                    icon="trash-alt"
                  />
                </b-button>
                <a
                  :href="manifestLink(manifest.manifestNo)"
                  target="manifestFromMissy"
                >
                  {{ manifest.manifestNo }}</a
                ></b-col
              ></b-row
            >
          </b-col>
          <b-col md="1"
            ><b-checkbox disabled :checked="stopIsHazmat(stop.id)"
          /></b-col>
          <b-col md="2" class="nowrap">
            <ShowLogIcon
              v-if="bol != null"
              :title="'Logs for Stop ' + stop.id"
              :term="stop.id"
              type="stop"
            />
            <b-button
              variant="light"
              size="md"
              :disabled="inputsDisabled"
              @click="addManifestToStopClicked(stop)"
            >
              <font-awesome-icon
                title="Add Manifest to Stop"
                icon="search-plus"
              />
            </b-button>
            <b-button
              variant="light"
              size="md"
              :disabled="inputsDisabled"
              @click="editStopClicked(stop)"
            >
              <font-awesome-icon title="Edit Stop" icon="edit" />
            </b-button>
            <b-button
              variant="light"
              size="md"
              :disabled="inputsDisabled"
              @click="deleteStopClicked(stop)"
            >
              <font-awesome-icon title="Delete Stop" icon="trash-alt" />
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col>
            Last modified by {{ bol.modifiedBy }} @
            {{ local(bol.dateModified) }}
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>
    <LoadingSplash />
    <SearchBolModal />
    <AddBolModal />
    <HelpBolModal />
    <AddStopModal />
    <AddManifestModal :bol="bol" :stop="selectedStop" />
    <EditStopModal :stop="selectedStop" />
  </b-container>
</template>

<script>
import textutils from "../../../textutils";
import dateutils from "../../../dateutil";
import { mapActions, mapGetters } from "vuex";
import LoadingSplash from "../../LoadingSplash.vue";
import PartyDisplay from "../../common/PartyDisplay.vue";
import FileUploadIcon from "../../common/FileUploadIcon.vue";
import ShowLogIcon from "../../common/ShowLogIcon.vue";
import { MANIFEST_URL } from "../../../config";
import SearchBolModal from "./SearchBolModal.vue";
import AddBolModal from "./AddBolModal.vue";
import HelpBolModal from "./HelpBolModal.vue";
import AddStopModal from "./AddStopModal.vue";
import AddManifestModal from "./AddManifestModal.vue";
import EditStopModal from "./EditStopModal.vue";
import TrailerDisplay from "./TrailerDisplay.vue";
import reportsapi from "../../../api/reportsapi";
import messaging from "../../../messaging";
import moment from "moment";

export default {
  components: {
    LoadingSplash,
    PartyDisplay,
    FileUploadIcon,
    ShowLogIcon,
    SearchBolModal,
    AddBolModal,
    HelpBolModal,
    AddStopModal,
    AddManifestModal,
    EditStopModal,
    TrailerDisplay,
  },
  data() {
    return {
      facility: null,
      bolNo: null,
      hasAttachments: false,
      manifestUrl: MANIFEST_URL,
      facilityOptions: [
        { value: null, text: "-- Select a Facility --" },
        { value: "ADC", text: "ADC" },
        { value: "MTJOY", text: "MTJOY" },
        { value: "ATIRI", text: "ATIRI" },
      ],
      selectedStop: null,
      timerHandle: null,
      ediCountdown: null,
      overrideFinalized: false,
    };
  },
  computed: {
    ...mapGetters("common", ["errorMessage"]),
    ...mapGetters("bol", [
      "bol",
      "stops",
      "manifests",
      "manifestSummaries",
      "stopPieces",
      "stopWeight",
      "stopIsHazmat",
      "bolPieces",
      "bolWeight",
      "bolIsHazmat",
      "bolIsCanadian",
    ]),
    toggleLockTitle() {
      return this.overrideFinalized ? "Lock BOL" : "Unlock BOL";
    },
    toggleLockIcon() {
      return this.overrideFinalized ? "lock" : "unlock";
    },
    containerClass() {
      let c = "h-100";
      if (this.overrideFinalized) {
        c = `${c} overrideFinalized`;
      }
      return c;
    },
    inputsDisabled() {
      let d = true;
      if (this.bol != null) {
        d = false;
        if (this.bol.finalized && !this.overrideFinalized) {
          d = true;
        }
      }
      return d;
    },
    keymap() {
      return {
        "ctrl+f1": this.helpBolClicked,
        "ctrl+b": this.searchBolClicked,
        "ctrl+s": this.saveBolClicked,
        f6: this.printBolClicked,
        f9: this.commentF9,
        f10: this.commentF10,
        f11: this.commentF11,
      };
    },
    bolEdiWindowIsOpen() {
      return (
        this.bol &&
        this.bol.finalized &&
        !(this.bol.chrFileSent || this.bol.manifestFileSent)
      );
    },
    ediCountDownClass() {
      let c = null;
      if (this.bolEdiWindowIsOpen && this.ediCountdown) {
        let cutoff = moment(this.bol.finalizedDate).add(15, "minutes");
        let redZone = moment().isSameOrAfter(cutoff);
        if (redZone) {
          c = "ediCountDownOutOfWindow";
        } else {
          c = "ediCountDownInWindow";
        }
      }
      return c;
    },
  },
  watch: {
    bol(newVal) {
      if (newVal) {
        this.overrideFinalized = false;
        window.setTimeout(() => this.$refs.thirdParty.focus(), 500);
      }
    },
  },
  methods: {
    ...mapActions("common", ["showLoadingDuringAction", "setErrorMessage"]),
    ...mapActions("bol", [
      "loadBol",
      "updateBol",
      "deleteBol",
      "deleteStop",
      "removeManifestFromStop",
      "handleBolMutation",
      "handleStopMutation",
      "handleManifestMutation",
      "moveStopDown",
      "moveStopUp",
      "finalizeBol",
    ]),
    keyboardShortcuts(e) {
      if (e.key == "F1" && e.ctrlKey) {
        e.preventDefault();
        this.helpBolClicked();
      }
      if (e.key == "b" && e.ctrlKey) {
        e.preventDefault();
        this.searchBolClicked();
      }
      if (e.key == "s" && e.ctrlKey) {
        e.preventDefault();
        this.saveBolClicked();
      }
      if (e.key == "F6") {
        e.preventDefault();
        this.printBolClicked();
      }
      if (e.key == "F9") {
        e.preventDefault();
        this.commentF9();
      }
      if (e.key == "F10") {
        e.preventDefault();
        this.commentF10();
      }
      if (e.key == "F11") {
        e.preventDefault();
        this.commentF11();
      }
    },
    async toggleOverrideFinalized() {
      if (!this.overrideFinalized) {
        let confirmed = await this.$bvModal.msgBoxConfirm(
          `Are you sure you want to unlock BOL ${this.bol.billOfLadingNo}?  If the EDIs have been sent you will need to retrigger them if you make any changes.`,
        );
        if (confirmed) {
          this.overrideFinalized = true;
        }
      } else {
        this.overrideFinalized = false;
      }
    },
    upper(val) {
      return textutils.toUpper(val);
    },
    local(val) {
      return dateutils.getLocalDateTime(val, "YYYY-MM-DD hh:mma z");
    },
    attachmentsUpdated(num) {
      this.hasAttachments = num > 0;
    },
    doFocus(focusMe) {
      setTimeout(() => {
        focusMe.focus();
      }, 100);
    },
    stopText(stop) {
      let txt = "";
      if (this.stops.length > 0) {
        txt =
          this.stops[this.stops.length - 1].id === stop.id
            ? "Dest"
            : stop.stopNo;
      }
      return txt;
    },
    manifestLink(manifestNo) {
      let u = `${this.manifestUrl}/ManifestView/Index?manifest=${manifestNo}`;
      return u;
    },
    helpBolClicked() {
      this.$bvModal.show("help-bol-modal");
    },
    searchBolClicked() {
      this.$bvModal.show("search-bol-modal");
    },
    addBolClicked() {
      this.$bvModal.show("add-bol-modal");
    },
    async deleteBolClicked() {
      let confirmed = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to delete BOL ${this.bol.billOfLadingNo}?`,
      );
      if (confirmed) {
        this.deleteBol().then((bol) =>
          this.$toasted.success(`BOL ${bol.billOfLadingNo} deleted`),
        );
      }
    },
    printBolClicked() {
      this.bolGuard(() => {
        window.open(
          reportsapi.getReportLink("BOL", ".pdf", {
            BillOfLadingNo: this.bol.billOfLadingNo,
          }),
          "bolPrint",
        );
        if (this.bolIsCanadian) {
          window.open(
            reportsapi.getReportLink("BOL_Pars", ".pdf", {
              BillOfLadingNo: this.bol.billOfLadingNo,
            }),
            "bolParsPrint",
          );
        }
        this.manifestSummaries.forEach((m) => {
          window.open(
            reportsapi.getReportLink("Manifest", ".pdf", {
              ManifestNo: m.manifestNo,
            }),
            "_blank",
          );
        });
      });
    },
    printBolBarcodeClicked() {
      this.bolGuard(() =>
        window.open(
          reportsapi.getReportLink("BillOfLadingBarcode", ".pdf", {
            BillOfLadingNo: this.bol.billOfLadingNo,
          }),
          "bolBarcodePrint",
        ),
      );
    },
    printBolShippingListClicked() {
      this.bolGuard(() => {
        let rep = "Waiting_To_Ship_BOL";
        if (this.bol.facility === "MTJOY") {
          rep = `${rep}_MtJoy`;
        }
        window.open(
          reportsapi.getReportLink(rep, ".pdf", {
            billOfLadingNo: this.bol.billOfLadingNo,
          }),
          "bolShippingListPrint",
        );
      });
    },
    saveBolClicked() {
      if (!this.bol.finalized || this.overrideFinalized) {
        this.updateBol().then((bol) => {
          if (bol) {
            this.$toasted.success(`BOL ${bol.billOfLadingNo} saved`);
          }
        });
      }
    },
    async finalizeBolClicked() {
      let confirmed = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to finalize BOL ${this.bol.billOfLadingNo}?`,
      );
      if (confirmed) {
        this.finalizeBol().then((b) => {
          if (b) {
            this.$toasted.success(`BOL ${b.billOfLadingNo} finalized`);
          }
        });
      }
    },
    addStopClicked() {
      this.$bvModal.show("add-stop-modal");
    },
    moveStopUpClicked(stop) {
      this.moveStopUp(stop).then((s) => {
        if (s) {
          this.$toasted.success(
            `Stop ${stop.id} ${stop.stopNo} ${stop.stopCode} moved up`,
          );
        }
      });
    },
    moveStopDownClicked(stop) {
      this.moveStopDown(stop).then((s) => {
        if (s) {
          this.$toasted.success(
            `Stop ${stop.id} ${stop.stopNo} ${stop.stopCode} moved down`,
          );
        }
      });
    },
    removeManifestClicked(manifest) {
      this.removeManifestFromStop(manifest).then((m) => {
        if (m) {
          this.$toasted.success(`Manifest ${m.manifestNo} removed`);
        }
      });
    },
    addManifestToStopClicked(stop) {
      this.selectedStop = stop;
      this.$bvModal.show("add-manifest-to-stop-modal");
    },
    editStopClicked(stop) {
      this.selectedStop = stop;
      this.$bvModal.show("edit-stop-modal");
    },
    async deleteStopClicked(stop) {
      let confirmed = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to delete Stop ${stop.id} ${stop.stopNo} ${stop.stopCode} from BOL ${this.bol.billOfLadingNo}?`,
      );
      if (confirmed) {
        this.deleteStop(stop).then((stop) => {
          if (stop) {
            this.$toasted.success(
              `Stop ${stop.id} ${stop.stopNo} ${stop.stopCode} deleted`,
            );
          }
        });
      }
    },
    commentF9() {
      this.appendBolComment("** DAILY DEDICATED FLASH **");
    },
    commentF10() {
      this.appendBolComment("** STOP AT PDC **");
    },
    commentF11() {
      this.appendBolComment("**DRIVER NOTE: 2 LOAD LOCKS REQUIRED**");
    },
    appendBolComment(str) {
      if (!this.bol.finalized || this.overrideFinalized) {
        this.bol.comment = textutils.appendIfNotPresent(this.bol.comment, str);
      }
    },
    bolGuard(func) {
      if (this.bol) {
        func();
      }
    },
    onTimer() {
      if (this.bolEdiWindowIsOpen) {
        let now = moment();
        let cutoff = moment(this.bol.finalizedDate).add(15, "minutes");
        let timeleft = moment.duration(cutoff.diff(now));
        this.ediCountdown = `${timeleft.format("mm:ss", {
          trim: false,
        })} until EDI is sent`;
      }
    },
    subscribe() {
      messaging.subscribe(
        "BolPage.BolMutations",
        "/topic/Topic.BolMutations",
        this.handleBolMutation,
      );
      messaging.subscribe(
        "BolPage.StopMutations",
        "/topic/Topic.StopMutations",
        this.handleStopMutation,
      );
      messaging.subscribe(
        "BolPage.ManifestMutations",
        "/topic/Topic.ManifestMutations",
        this.handleManifestMutation,
      );
    },
    unsubscribe() {
      messaging.unsubscribe("BolPage.BolMutations");
      messaging.unsubscribe("BolPage.StopMutations");
      messaging.unsubscribe("BolPage.ManifestMutations");
    },
  },
  created() {
    this.$watch(
      () => this.$route.params,
      (toParams) => {
        this.loadBol(toParams.billOfLadingNo);
      },
    );
  },
  mounted() {
    this.timerHandle = window.setInterval(this.onTimer, 1000);
    if (this.$route.params.billOfLadingNo) {
      this.loadBol(this.$route.params.billOfLadingNo);
    }
    this.unsubscribe();
    this.subscribe();
    messaging.restart();
    document
      .getElementById("bolPage")
      .addEventListener("keydown", this.keyboardShortcuts);
  },
  beforeUnmount() {
    document
      .getElementById("bolPage")
      .removeEventListener("keydown", this.keyboardShortcuts);
    window.clearInterval(this.timerHandle);
    this.unsubscribe();
    this.loadBol(null);
  },
};
</script>

<style scoped>
a {
  color: blue;
}

.ediCountDownInWindow {
  background-color: yellow;
}

.ediCountDownOutOfWindow {
  background-color: red;
  color: white;
}

.overrideFinalized {
  background-color: lightyellow;
}
</style>
