<template>
  <b-modal @hide="close" id="trailer-modal" :title="title" :hide-footer="true">
    <TrailerNoteFrag v-if="selectedActionPanel === 'note'" />
    <TrailerRedTagDeleteFrag v-if="selectedActionPanel === 'redTag'" />
    <TrailerStatusFrag v-if="selectedActionPanel === 'status'" />
    <TrailerLocationFrag v-if="selectedActionPanel === 'location'" />
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TrailerNoteFrag from "./TrailerNoteFrag.vue";
import TrailerRedTagDeleteFrag from "./TrailerRedTagDeleteFrag.vue";
import TrailerStatusFrag from "./TrailerStatusFrag.vue";
import TrailerLocationFrag from "./TrailerLocationFrag.vue";

export default {
  components: {
    TrailerNoteFrag,
    TrailerRedTagDeleteFrag,
    TrailerStatusFrag,
    TrailerLocationFrag,
  },
  computed: {
    ...mapGetters("trailers", [
      "selectedTrailerForActions",
      "selectedActionPanel",
    ]),
    title() {
      if (this.selectedTrailerForActions != null) {
        return `Trailer ${this.selectedTrailerForActions.scac} ${this.selectedTrailerForActions.id}`;
      } else {
        return "";
      }
    },
  },
  watch: {
    selectedTrailerForActions(newVal) {
      if (newVal && this.selectedActionPanel != null) {
        this.$bvModal.show("trailer-modal");
      } else {
        this.$bvModal.hide("trailer-modal");
      }
    },
    selectedActionPanel(newVal) {
      if (newVal && this.selectedTrailerForActions != null) {
        this.$bvModal.show("trailer-modal");
      } else {
        this.$bvModal.hide("trailer-modal");
      }
    },
  },
  methods: {
    ...mapActions("trailers", ["showTrailerActions"]),
    close() {
      this.showTrailerActions({ t: null, a: null });
    },
  },
};
</script>
