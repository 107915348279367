<template>
  <b-container fluid class="h-100" style="overflow: hidden; overflow-y: auto">
    <div v-show="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <b-card no-body>
      <b-card-header>
        <b-row>
          <b-col md="3">
            {{ openManifests.length }} Open Manifests for Facility:
            <b-form-select
              id="facility-input"
              size="sm"
              style="width: auto"
              v-model="facility"
            >
              <b-form-select-option
                v-if="facility == null"
                :value="null"
                disabled
                >-- Select a Facility --</b-form-select-option
              >
              <b-form-select-option value="ADC">ADC</b-form-select-option>
              <b-form-select-option value="MTJOY">MTJOY</b-form-select-option>
              <b-form-select-option value="ATIRI">ATIRI</b-form-select-option>
              <b-form-select-option value="MTJOPEN"
                >MTJOPEN</b-form-select-option
              >
            </b-form-select>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body v-if="facility != null">
        <b-row class="table-responsive">
          <table class="table table-sm">
            <thead>
              <tr>
                <th>Manifest</th>
                <th>Date</th>
                <th>Ship To</th>
                <th>Carrier</th>
                <th>Trailer</th>
                <th>Shipments</th>
                <th class="text-right">Lines</th>
                <th class="text-right">Pieces</th>
                <th class="text-right">Weight</th>
                <th>Modified</th>
                <th>By</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="m in openManifests"
                class="row-striped"
                :key="m.manifestNo"
              >
                <td>
                  <a
                    :href="
                      manifestSystemLink(
                        `/ManifestView/Index?manifest=${m.manifestNo}`,
                      )
                    "
                    target="manifestFromMissy"
                  >
                    {{ m.manifestNo }}</a
                  >
                </td>
                <td>{{ displayDate(m.date) }} {{ displayTime(m.date) }}</td>
                <td>{{ m.shipToCode }}</td>
                <td>{{ m.carrierCode }}</td>
                <td>{{ m.trailerNo }}</td>
                <td>
                  <span v-for="shipment in m.shipments" :key="shipment.id">
                    <a
                      v-if="shipment.id > 0"
                      target="manifestFromMissy"
                      :href="
                        manifestSystemLink(
                          `/IncomingShipmentView/Detail?incomingShipmentId=${shipment.id}`,
                        )
                      "
                    >
                      {{ shipment.shipmentName }}</a
                    >
                    <span v-if="shipment.id == 0">{{
                      shipment.shipmentName
                    }}</span>
                    <br />
                  </span>
                </td>
                <td class="text-right">{{ m.lines }}</td>
                <td class="text-right">{{ m.pieces }}</td>
                <td class="text-right">{{ m.weight }}</td>
                <td>
                  {{ displayDate(m.dateModified) }}
                  {{ displayTime(m.dateModified) }}
                </td>
                <td>{{ shortName(m.modifiedBy) }}</td>
              </tr>
            </tbody>
          </table>
        </b-row>
      </b-card-body>
    </b-card>
    <LoadingSplash />
  </b-container>
</template>

<script>
import dateutils from "../../../dateutil";
import textutils from "../../../textutils";
import { mapActions, mapGetters } from "vuex";
import LoadingSplash from "../../LoadingSplash.vue";
import { MANIFEST_URL } from "../../../config";

export default {
  components: {
    LoadingSplash,
  },
  data() {
    return {
      facility: null,
    };
  },
  computed: {
    ...mapGetters("common", ["errorMessage", "defaultFacility"]),
    ...mapGetters("openmanifests", ["openManifests"]),
  },
  watch: {
    facility(newVal) {
      if (newVal) {
        this.loadOpenManifests(newVal);
      }
    },
  },
  methods: {
    ...mapActions("openmanifests", ["loadOpenManifests"]),
    displayDate(date) {
      return dateutils.getLocalDateTime(date, "MM-DD");
    },
    displayTime(date) {
      return dateutils.getLocalDateTime(date, "hh:mm A");
    },
    manifestSystemLink(path) {
      return `${MANIFEST_URL}${path}`;
    },
    shortName(name) {
      return textutils.shortUserName(name);
    },
  },
  mounted() {
    this.facility = this.defaultFacility;
  },
};
</script>

<style scoped>
a {
  color: blue;
}
</style>
