<template>
  <div>
    <div class="card">
      <div class="card-header">Add Location</div>
      <div class="card-body">
        <form>
          <div class="form-group row">
            <label for="location" class="col-sm-2 col-form-label"
              >Location</label
            >
            <div class="col-sm-2">
              <b-form-input
                type="text"
                :formatter="upper"
                v-model.trim="addLocation"
                id="addBundle"
                placeholder="Location"
              />
            </div>
            <div class="col-sm-1">
              <button
                type="button"
                @click="doAdd"
                class="btn btn-primary"
                :disabled="disableAdd"
              >
                Add
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { inventoryLocation } from "../../api/bundleinventory";
import { mapGetters, mapActions } from "vuex";
import textutils from "../../textutils";

export default {
  data() {
    return {
      addLocation: null,
    };
  },
  computed: {
    ...mapGetters("invBundles", ["facility"]),
    disableAdd() {
      return !this.addLocation;
    },
  },
  methods: {
    ...mapActions("common", ["showLoadingDuringAction"]),
    doAdd() {
      if (this.addLocation) {
        let b = { facility: this.facility, location: this.addLocation };
        let c = inventoryLocation.createLocation(b);
        this.showLoadingDuringAction(c).then((res) => {
          if (res) {
            let r = res.data.payload;
            this.toastSuccess(`${r.facility} ${r.location} added`);
            this.resetAdd();
          }
        });
      }
    },
    upper(t) {
      return textutils.toUpper(t);
    },
    resetAdd() {
      this.addLocation = null;
    },
    toastInfo(msg, pos = "top-right", dur = 5000) {
      this.$toasted.info(msg, {
        duration: dur,
        position: pos,
      });
    },
    toastSuccess(msg, pos = "top-right", dur = 5000) {
      this.$toasted.success(msg, {
        duration: dur,
        position: pos,
      });
    },
  },
};
</script>

<style scoped></style>
