<template>
  <b-container
    fluid
    class="h-100"
    v-bind:style="{ 'max-height': tabContentHeight + 'px' }"
    style="overflow-y: auto"
  >
    <b-card no-body>
      <b-card-header>
        <div v-show="errorMessage" class="alert alert-danger">
          {{ errorMessage }}
        </div>
        <b-row>
          <b-col md="2">Spot - Facility:</b-col>
          <b-col md="2">
            <b-form-select v-model="selectedFacility">
              <b-form-select-option :value="null" disabled
                >Select Facility</b-form-select-option
              >
              <b-form-select-option value="ADC">ADC</b-form-select-option>
              <b-form-select-option value="MTJOY">MTJOY</b-form-select-option>
              <b-form-select-option value="ATIRI">ATIRI</b-form-select-option>
              <b-form-select-option value="MTJOPEN"
                >MTJOPEN</b-form-select-option
              >
            </b-form-select>
          </b-col>
        </b-row>
      </b-card-header>
    </b-card>
    <b-card no-body v-if="inRole('Dispatch')">
      <b-card-body>
        <b-row>
          <b-col cols="12" md="auto">Trailer</b-col>
          <b-col md>
            <Autocomplete
              ref="autocomplete"
              :source="availableTrailers"
              resultsProperty="id"
              :resultsDisplay="formatStayTrailer"
              @selected="setStay"
              @clear="setStay"
            />
            <!-- <input id="reqScacId" type="text" v-model="reqScacId" /> -->
          </b-col>
          <b-col cols="12" md="auto">
            <span v-if="reqStay != null">Loc</span>
          </b-col>
          <b-col md>
            <span v-if="reqStay != null">
              <b-input type="text" trim v-model="reqLocCode" />
            </span>
          </b-col>
          <b-col cols="12" md="auto">
            <span v-if="reqStay != null">
              <button @click="doFindLocation" class="btn btn-wrap">
                <font-awesome-icon icon="search" />
              </button>
            </span>
          </b-col>
          <b-col md="6">
            <span v-if="reqStay != null">
              {{ reqStay.trailer.scac }} {{ reqStay.trailer.id }}
              <font-awesome-icon
                icon="tag"
                v-show="reqStay.trailer.redTag"
                style="color: red"
              />
              {{ reqStay.trailer.status }}
              {{ reqStay.trailer.facilityLocation.code }} To
            </span>
            <span v-if="reqStay != null && reqToLoc == null">???</span>
            <span v-if="reqToLoc != null"
              >{{ reqToLoc.type != "YARD" ? reqToLoc.type : "" }}
              {{ reqToLoc.code }}</span
            >
            <span v-if="reqStay != null && reqToLoc != null">
              Note
              <input type="text" trim v-model="reqNote" />
              Priority
              <input type="checkbox" v-model="reqPriority" />&nbsp;
              <button @click="doRequest" class="btn btn-primary">
                Request Move
              </button>
            </span>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col md>ID</b-col>
          <b-col md>Requested By</b-col>
          <b-col md="2">Trailer</b-col>
          <b-col md>From</b-col>
          <b-col md>To</b-col>
          <b-col md="2">Note</b-col>
          <b-col md>Claimed By</b-col>
          <b-col md>&nbsp;</b-col>
        </b-row>
        <transition-group name="fade-left">
          <template v-for="spot in openRequests">
            <b-row
              class="row-striped"
              :class="{ priority: spot.priority }"
              style="padding: 1em 0em"
              :key="spot.id"
            >
              <b-col md>{{ spot.requestNumber }}</b-col>
              <b-col md
                >{{
                  spot.requestedBy
                    .substring(0, spot.requestedBy.indexOf("@"))
                    .toUpperCase()
                }}
                - {{ elapsedTime(spot) }}</b-col
              >
              <b-col md="2">
                <button
                  @click="showUpdateTrailerStatus(spot.trailerStay.trailer)"
                >
                  <TrailerLoadedDisplay
                    :pr="8"
                    :pl="8"
                    :trailer="spot.trailerStay.trailer"
                  />
                </button>
                {{ spot.trailerStay.trailer.scac }}
                {{ spot.trailerStay.trailer.id }}
                <font-awesome-icon
                  icon="tag"
                  v-show="isTagged(spot.trailerStay.trailer)"
                  :class="{
                    redTag: spot.trailerStay.trailer.redTag,
                    yellowTag: spot.trailerStay.trailer.yellowTag,
                  }"
                />
              </b-col>
              <b-col md>{{ spot.from.code }}</b-col>
              <b-col md>{{ spot.to.code }}</b-col>
              <b-col md="2">{{ spot.note }}</b-col>
              <b-col md>{{ shortName(spot.claimedBy) }}</b-col>
              <b-col md class="text-right">
                <span v-if="spot.claimedBy == null">
                  <button
                    title="Claim Move"
                    class="btn btn-wrap"
                    @click="claim(spot.id)"
                  >
                    <font-awesome-icon icon="plus-square" />
                  </button>
                </span>
                <span v-if="spot.claimedBy === userName">
                  <button
                    title="Remove Claim"
                    class="btn btn-wrap"
                    @click="release(spot.id)"
                  >
                    <font-awesome-icon icon="minus-square" />
                  </button>
                </span>
                <span v-if="spot.claimedBy === userName">
                  <button
                    title="Complete Move"
                    class="btn btn-wrap"
                    @click="complete(spot.id)"
                  >
                    <font-awesome-icon icon="check-square" />
                  </button>
                </span>
                <span v-if="spot.requestedBy === userName && spot.priority">
                  <button
                    title="Set Normal Priority"
                    class="btn btn-wrap"
                    @click="togglePriority(spot)"
                  >
                    <font-awesome-icon icon="bell-slash" />
                  </button>
                </span>
                <span v-if="spot.requestedBy === userName && !spot.priority">
                  <button
                    title="Set Priority"
                    class="btn btn-wrap"
                    @click="togglePriority(spot)"
                  >
                    <font-awesome-icon icon="bell" />
                  </button>
                </span>
                <span v-if="spot.requestedBy === userName">
                  <button
                    title="Remove"
                    class="btn btn-wrap"
                    @click="remove(spot.id)"
                  >
                    <font-awesome-icon icon="window-close" />
                  </button>
                </span>
              </b-col>
            </b-row>
          </template>
        </transition-group>
      </b-card-body>
    </b-card>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import messaging from "../../messaging";
import TrailerLoadedDisplay from "../trailer/TrailerLoadedDisplay.vue";
import Autocomplete from "vuejs-auto-complete";
import dateutil from "../../dateutil";
import moment from "moment";

export default {
  components: {
    TrailerLoadedDisplay,
    Autocomplete,
  },
  data() {
    return {
      windowHeight: window.innerHeight || 0,
      selectedFacility: null,
      reqStay: null,
      reqLocCode: null,
      reqNote: null,
      reqPriority: false,
      reqToLoc: null,
      timerHandle: null,
      now: dateutil.utcNow(),
    };
  },
  computed: {
    ...mapGetters("security", ["inRole", "userName"]),
    ...mapGetters("common", ["errorMessage", "loading", "defaultFacility"]),
    ...mapGetters("spot", ["openRequests", "facility", "availableTrailers"]),
    tabContentHeight: function () {
      //return this.windowHeight - (this.inYardSecurity ? 430 : 330) || 0;
      return this.windowHeight - 100;
    },
  },
  watch: {
    selectedFacility(newVal) {
      this.setFacility(newVal);
      this.$cookies.set("yard_facility", newVal);
    },
    reqLocCode(newVal) {
      if (newVal != null && newVal.length > 0) {
        this.reqLocCode = newVal.toUpperCase();
      } else {
        this.reqToLoc = null;
      }
    },
    reqNote(newVal) {
      if (newVal != null && newVal.length > 0) {
        this.reqNote = newVal.toUpperCase();
      } else {
        this.reqNote = null;
      }
    },
  },
  methods: {
    ...mapActions("common", ["setErrorMessage"]),
    ...mapActions("spot", [
      "setFacility",
      "handleTrailerMutations",
      "handleSpotMovementMutation",
      "handleTrailerStayMutation",
      "claim",
      "release",
      "complete",
      "findLocation",
      "requestMove",
      "remove",
      "priority",
    ]),
    elapsedTime: function (spot) {
      let s = moment(spot.requestedAt);
      return moment.duration(this.now.diff(s)).format("h:mm:ss");
    },
    isTagged(trailer) {
      return trailer.redTag || trailer.yellowTag;
    },
    setStay(obj) {
      if (obj) {
        this.reqStay = obj.selectedObject;
      } else {
        this.reqStay = null;
        this.reqToLoc = null;
        this.reqLocCode = null;
        this.reqNote = null;
        this.reqPriority = false;
      }
    },
    formatStayTrailer(stay) {
      return `${stay.trailer.scac} ${stay.trailer.id}`;
    },
    shortName(n) {
      return n != null ? n.substring(0, n.indexOf("@")).toUpperCase() : null;
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    showUpdateTrailerStatus(trailer) {
      let obj = {
        title: `Update status for ${trailer.scac} ${trailer.id}`,
        trailer: trailer,
      };
      this.$modal.show("updateTrailerStatusModal", obj);
    },
    doFindLocation() {
      this.findLocation(this.reqLocCode).then((res) => {
        let l = res.data.payload;
        this.reqToLoc = l;
      });
    },
    doRequest() {
      let r = {
        facility: this.reqStay.facility,
        trailerStay: this.reqStay,
        from: this.reqStay.trailer.facilityLocation,
        to: this.reqToLoc,
        note: this.reqNote,
        priority: this.reqPriority,
      };
      this.requestMove(r).then(() => this.clearInputs());
    },
    clearInputs() {
      this.reqPriority = false;
      this.reqNote = null;
      this.reqToLoc = null;
      this.reqStay = null;
      this.reqLocCode = null;
      this.reqScacId = null;
      this.$refs.autocomplete.clear();
    },
    togglePriority(spot) {
      this.priority({ id: spot.id, priority: !spot.priority });
    },
    subscribe() {
      messaging.subscribe(
        "SpotPage.TrailerMutations",
        "/topic/Topic.TrailerMutations",
        this.handleTrailerMutations,
      );
      messaging.subscribe(
        "SpotPage.SpotMovementMutations",
        "/topic/Topic.SpotMovementMutations",
        this.handleSpotMovementMutation,
      );
      messaging.subscribe(
        "SpotPage.TrailerStayMutations",
        "/topic/Topic.TrailerStayMutations",
        this.handleTrailerStayMutation,
      );
    },
    unsubscribe() {
      messaging.unsubscribe("SpotPage.TrailerMutations");
      messaging.unsubscribe("SpotPage.SpotMovementMutations");
      messaging.unsubscribe("SpotPage.TrailerStayMutations");
    },
    updateTime() {
      this.now = dateutil.utcNow();
    },
  },
  mounted() {
    this.selectedFacility = this.defaultFacility;
    window.addEventListener("resize", this.onResize);
    this.unsubscribe();
    this.subscribe();
    messaging.restart();
    this.timerHandle = window.setInterval(this.updateTime, 1000);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    window.clearInterval(this.timerHandle);
  },
  destroyed() {
    this.unsubscribe();
  },
};
</script>

<style scoped>
.priority {
  border: 3px solid red;
}
</style>
