<template>
  <b-button @click="doClick" :title="title" :variant="variant">
    <font-awesome-icon icon="upload" />
    <font-awesome-layers>
      <font-awesome-layers-text
        counter
        class="fa-3x"
        :style="{ backgroundColor: countColor }"
        :value="files.length"
        position="top-right"
      />
    </font-awesome-layers>
  </b-button>
</template>

<script>
import attachApi from "../../api/attach";

export default {
  props: {
    type: String,
    id: [String, Number],
    title: String,
    variant: {
      type: String,
      default: "light",
    },
  },
  data() {
    return {
      files: [],
    };
  },
  watch: {
    files() {
      this.$emit("files-updated", this.files.length);
    },
  },
  computed: {
    countColor() {
      return this.files == null || this.files.length == 0 ? "grey" : "green";
    },
  },
  methods: {
    doClick() {
      let obj = {
        type: this.type,
        id: this.id,
        title: this.title,
        files: this.files,
      };
      this.$modal.show("fileUploadModal", obj);
    },
    async getAttachments() {
      attachApi.getFileList(this.type, this.id).then((aRes) => {
        if (aRes.data != null && aRes.data.payload != null) {
          aRes.data.payload.forEach((f) => this.files.push(f));
        }
      });
    },
  },
  mounted() {
    this.getAttachments();
  },
};
</script>
