import { driverroutes } from "../../api/movementapi";

const state = {
  drivers: [],
};

const getters = {
  drivers: (state) => {
    return state.drivers.sort((a, b) => {
      let r = a.lastName.localeCompare(b.lastName);
      if (r == 0) {
        r = a.firstName.localeCompare(b.firstName);
      }
      return r;
    });
  },
};

const actions = {
  async loadDrivers({ commit, dispatch }) {
    let call = driverroutes.getAllDrivers().then((res) => {
      commit("setDrivers", res.data.payload);
    });
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  async updateDriver({ commit, dispatch }, driver) {
    let call = driverroutes.updateDriver(driver).then((res) => {
      commit("updateDriver", res.data.payload);
    });
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  async addDriver({ commit, dispatch }, driver) {
    let call = driverroutes.addDriver(driver).then((res) => {
      commit("updateDriver", res.data.payload);
    });
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
};

const mutations = {
  setDrivers(state, drivers) {
    state.drivers = drivers;
  },
  updateDriver(state, driver) {
    let d = state.drivers.find((x) => x.id === driver.id);
    if (d) {
      d.firstName = driver.firstName;
      d.lastName = driver.lastName;
      d.active = driver.active;
    } else {
      state.drivers.push(driver);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
