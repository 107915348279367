<template>
  <div>
    <div class="row">
      <div class="col-3">Supplier</div>
      <div class="col">Date Shipped</div>
      <div class="col">Trailer</div>
      <div class="col">Tracking ID</div>
      <div class="col">Barcoded/Total</div>
    </div>
    <div
      v-bind:style="{ 'max-height': tabContentHeight - 60 + 'px' }"
      style="overflow-y: auto"
    >
      <template v-for="shipment in incomingShipments">
        <div
          @click="selectShipment(shipment)"
          style="border-bottom: 1px black solid"
          class="row"
          :class="{
            completedShipment: shipment.parcels == shipment.pieces,
          }"
          :key="shipment.id"
        >
          <div class="col-3">
            {{ shipment.supplierId }}
          </div>
          <div class="col">
            {{ shipment.dateShipped }}
          </div>
          <div class="col">
            {{ shipment.trailerNo }}
          </div>
          <div class="col">
            {{ shipment.trackingId }}
          </div>
          <div class="col">{{ shipment.parcels }}/{{ shipment.pieces }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ShipmentList",
  props: {
    tabContentHeight: Number,
  },
  computed: {
    ...mapGetters("jdm", ["incomingShipments"]),
  },
  methods: {
    ...mapActions("jdm", ["selectShipment"]),
  },
};
</script>

<style scoped>
.completedShipment {
  background-color: green;
}
</style>
