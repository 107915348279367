<template>
  <b-modal
    id="edit-stop-modal"
    :title="title"
    size="md"
    @show="resetModal"
    @hidden="resetModal"
    @ok="okClicked"
    @shown="shown"
    :ok-disabled="!validInputs"
  >
    <div v-show="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <b-form ref="addStopModalForm" @submit.stop.prevent="handleSubmit">
      <b-row>
        <b-col>
          <b-form-group
            label="Stop Code:"
            label-for="stopcode-input"
            label-cols-md="4"
          >
            <b-form-input
              id="stopcode-input"
              ref="stopCodeInput"
              v-model="stopCode"
              :formatter="upper"
              trim
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Comment:"
            label-for="comment-input"
            label-cols-md="4"
          >
            <b-form-textarea
              id="comment-input"
              ref="commentInput"
              v-model="comment"
              :formatter="upper"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import textutils from "../../../textutils";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    stop: Object,
  },
  data() {
    return {
      stopCode: null,
      comment: null,
    };
  },
  computed: {
    ...mapGetters("common", ["defaultFacility", "errorMessage"]),
    ...mapGetters("bol", ["bol"]),
    validInputs() {
      return this.stopCode != null;
    },
    title() {
      if (this.stop) {
        return `Edit Stop ${this.stop.id} ${this.stop.stopNo}`;
      } else {
        return "";
      }
    },
  },
  methods: {
    ...mapActions("common", ["setErrorMessage"]),
    ...mapActions("bol", ["updateStop"]),
    upper(val) {
      return textutils.toUpper(val);
    },
    resetModal() {
      this.stopCode = null;
      this.comment = null;
      this.setErrorMessage(null);
    },
    okClicked(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.stop.stopCode = this.stopCode;
      this.stop.comment = this.comment;
      this.updateStop(this.stop).then((stop) => {
        if (stop) {
          this.$bvModal.hide("edit-stop-modal");
          this.$nextTick(() => {
            this.$toasted.success(
              `Stop ${stop.id} ${stop.stopNo} ${stop.stopCode} updated`,
            );
          });
        }
      });
    },
    shown() {
      this.stopCode = this.stop.stopCode;
      this.comment = this.stop.comment;
      this.$refs.stopCodeInput.focus();
    },
  },
};
</script>
