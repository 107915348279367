<template>
  <div>
    <b-row>
      <b-col>
        <button type="button" class="btn btn-primary" @click="toggleRedTag">
          {{ redTagLabel }}
        </button>
      </b-col>
      <b-col>
        <button type="button" class="btn btn-primary" @click="toggleYellowTag">
          {{ yellowTagLabel }}
        </button>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="auto">Tag Note:</b-col>
      <b-col>
        <b-input
          v-model="tagNote"
          class="form-control form-control-sm text-uppercase"
        />
      </b-col>
    </b-row>
    <hr />
    Facility Audit Report
    <button
      class="btn"
      title="Download Excel Facility Audit"
      @click="trailerReport('.xlsx')"
    >
      <font-awesome-icon icon="file-excel" />
    </button>
    <button
      class="btn"
      title="Download PDF Facility Audit"
      @click="trailerReport('.pdf')"
    >
      <font-awesome-icon icon="file-pdf" />
    </button>
    <hr />
    Route Audit Report
    <button
      class="btn"
      title="Download Excel Route Audit"
      @click="routeReport('.xlsx')"
    >
      <font-awesome-icon icon="file-excel" />
    </button>
    <button
      class="btn"
      title="Download PDF Route Audit"
      @click="routeReport('.pdf')"
    >
      <font-awesome-icon icon="file-pdf" />
    </button>

    <hr />
    <button type="button" class="btn btn-secondary" @click="doDelete">
      Delete
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import reportsapi from "../../api/reportsapi";
import textutils from "../../textutils";

export default {
  data() {
    return {
      tagNote: null,
    };
  },
  computed: {
    redTagLabel() {
      let l = null;
      if (this.selectedTrailerForActions) {
        l = this.selectedTrailerForActions.redTag
          ? "Remove Red Tag"
          : "Set Red Tag";
      }
      return l;
    },
    yellowTagLabel() {
      let l = null;
      if (this.selectedTrailerForActions) {
        l = this.selectedTrailerForActions.yellowTag
          ? "Remove Yellow Tag"
          : "Set Yellow Tag";
      }
      return l;
    },
    ...mapGetters("trailers", ["selectedTrailerForActions"]),
  },
  methods: {
    ...mapActions("trailers", [
      "showTrailerActions",
      "updateRedTag",
      "updateYellowTag",
      "deleteTrailer",
    ]),
    close() {
      this.showTrailerActions({ t: null, a: null });
    },
    toggleRedTag() {
      this.updateRedTag({
        redTag: !this.selectedTrailerForActions.redTag,
        note: textutils.toUpper(this.tagNote),
      });
      this.close();
    },
    toggleYellowTag() {
      this.updateYellowTag({
        yellowTag: !this.selectedTrailerForActions.yellowTag,
        note: textutils.toUpper(this.tagNote),
      });
      this.close();
    },
    doDelete() {
      if (
        confirm(
          `Are you sure you want to ***DELETE*** trailer ${this.selectedTrailerForActions.scac} ${this.selectedTrailerForActions.id}?`,
        )
      ) {
        this.deleteTrailer();
        this.close();
      }
    },
    trailerReport(extension) {
      window.open(
        reportsapi.getReportLink(
          "TrailerAuditLog",
          extension,
          {
            TrailerCarrierCode: this.selectedTrailerForActions.scac,
            TrailerNo: this.selectedTrailerForActions.id,
          },
          true,
        ),
        "_new",
        "",
      );
    },
    routeReport(extension) {
      window.open(
        reportsapi.getReportLink(
          "TrailerRoutes",
          extension,
          {
            scac: this.selectedTrailerForActions.scac,
            trailer: this.selectedTrailerForActions.id,
          },
          true,
        ),
        "_new",
        "",
      );
    },
  },
  mounted() {
    if (this.selectedTrailerForActions) {
      this.tagNote = this.selectedTrailerForActions.tagNote;
    }
  },
};
</script>
