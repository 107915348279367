<template>
  <b-container fluid style="overflow: hidden; overflow-y: auto">
    <div v-show="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <RequestedLoadsPanel :tenders="requestedTenders" />
    <ActiveLoadsPanel :tenders="activeTenders" />
    <LoadingSplash />
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LoadingSplash from "../LoadingSplash.vue";
import RequestedLoadsPanel from "./RequestedLoadsPanel.vue";
import ActiveLoadsPanel from "./ActiveLoadsPanel.vue";
import messaging from "../../messaging";

export default {
  components: {
    LoadingSplash,
    RequestedLoadsPanel,
    ActiveLoadsPanel,
  },
  data() {
    return {};
  },
  watch: {
    $route() {
      this.handleUrlAcceptDeny();
    },
  },
  computed: {
    ...mapGetters("common", ["errorMessage", "messagingConnected"]),
    ...mapGetters("edi", ["requestedTenders", "activeTenders"]),
  },
  methods: {
    ...mapActions("edi", [
      "loadTenders",
      "handleEdi204Mutation",
      "acceptTenderId",
      "denyTenderId",
    ]),
    async sleep(msec) {
      return new Promise((resolve) => setTimeout(resolve, msec));
    },
    async handleUrlAcceptDeny() {
      if (this.$route.query.id && this.$route.query.action) {
        console.log(`${this.$route.query.id} ${this.$route.query.action}`);
        while (!this.messagingConnected) {
          await this.sleep(500);
        }
        if (this.$route.query.action == "accept") {
          await this.acceptTenderId(this.$route.query.id);
        }
        if (this.$route.query.action == "deny") {
          await this.denyTenderId(this.$route.query.id);
        }
      }
    },
  },
  async mounted() {
    await this.loadTenders();
    messaging.subscribe(
      "EdiPage.Edi204Mutations",
      "/topic/Topic.Edi204Mutations",
      this.handleEdi204Mutation,
    );
    await messaging.restart();
    this.handleUrlAcceptDeny();
  },
  beforeDestroy() {
    messaging.unsubscribe("EdiPage.Edi204Mutations");
  },
};
</script>

<style>
.table-striped > tbody > tr:nth-child(odd).table-denied,
.table-denied {
  font-style: italic;
  text-decoration: line-through;
}
.table-striped > tbody > tr:nth-child(odd).table-request,
.table-request {
  border: 3px solid yellow;
}
.table-striped > tbody > tr:nth-child(odd).table-started,
.table-started {
  border: 3px solid green;
}
.table-striped > tbody > tr:nth-child(odd).table-completed,
.table-completed {
  background-color: green;
  color: white;
}
.table-striped > tbody > tr:nth-child(odd).table-cancelled,
.table-cancelled {
  background-color: red;
  text-decoration: line-through;
}
</style>
