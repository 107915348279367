<template>
  <b-card>
    <b-card-header>
      Add Inbound - Upload Format = SUPPLIER,TRACKING,BUNDLE,QTY
    </b-card-header>
    <b-card-body>
      <b-form>
        <b-form-row>
          <label for="data" class="col-sm-1 col-form-label">Data</label>
          <b-col sm="10">
            <b-form-textarea
              :formatter="upper"
              rows="10"
              v-model.trim="data"
              id="data"
              :placeholder="example"
              ref="data"
            />
          </b-col>
          <b-col sm="1">
            <button
              type="button"
              @click="doAdd"
              class="btn btn-primary"
              :disabled="disableAdd"
            >
              Add
            </button>
          </b-col>
        </b-form-row>
        <b-form-row>
          <label for="input-file1" class="col-sm-1 col-form-label">File</label>
          <b-col sm="10">
            <b-form-file
              id="input-file1"
              ref="file1"
              v-model="file1"
              :state="Boolean(file1)"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              accept=".csv"
              @input="onFile"
            ></b-form-file>
          </b-col>
        </b-form-row>
      </b-form>
    </b-card-body>
  </b-card>
</template>

<script>
import { inboundInvShipment } from "../../api/bundleinventory";
import { mapActions, mapGetters } from "vuex";
import textutils from "../../textutils";

export default {
  data() {
    return {
      data: null,
      file1: null,
    };
  },
  computed: {
    ...mapGetters("invBundles", ["facility"]),
    disableAdd() {
      return !this.data;
    },
    example() {
      return "SUPPLIER,TRACKING,BUNDLE,QTY\nSUPPLIER,TRACKING,BUNDLE,QTY\n\SUPPLIER,TRACKING,BUNDLE,QTY";
    },
  },
  methods: {
    ...mapActions("common", ["showLoadingDuringAction"]),
    doAdd() {
      if (this.data) {
        let c = inboundInvShipment.createInboundFromRawData(
          this.facility,
          this.data,
        );
        this.showLoadingDuringAction(c).then((res) => {
          if (res) {
            let r = res.data.payload;
            let t = "";
            for (let i = 0; i < r.length; i++) {
              t = t + `${r[i].name}`;
              if (i + 1 != r.length) {
                t = t + ", ";
              }
            }
            this.toastSuccess(`${t} added`);
            this.resetAdd();
          }
        });
      }
    },
    onFile(fileObj) {
      if (fileObj) {
        this.readFile(fileObj);
        this.$refs.file1.reset();
      }
    },
    readFile(source) {
      console.log(source);
      const reader = new FileReader();
      reader.addEventListener("load", (event) => {
        this.data = event.target.result;
      });
      reader.readAsText(source);
    },
    upper(t) {
      return textutils.toUpper(t);
    },
    resetAdd() {
      this.data = null;
    },
    toastInfo(msg, pos = "top-right", dur = 5000) {
      this.$toasted.info(msg, {
        duration: dur,
        position: pos,
      });
    },
    toastSuccess(msg, pos = "top-right", dur = 5000) {
      this.$toasted.success(msg, {
        duration: dur,
        position: pos,
      });
    },
  },
};
</script>

<style scoped></style>
