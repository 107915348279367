<template>
  <tr class="bayRow">
    <td
      @click="emitItemSelected(bay, 'bay')"
      class="cell bayempty"
      :class="{ bayfull: trailer != null }"
    >
      &nbsp;{{ bay.code }}&nbsp;
    </td>
    <td
      @click="emitItemSelected(bay, 'bay')"
      class="cell trailer"
      :title="hoverTrailer"
    >
      &nbsp;{{ trailerScac }} {{ trailerCode }}&nbsp;
      <font-awesome-icon
        icon="tag"
        v-show="trailer != null && (trailer.redTag || trailer.yellowTag)"
        :class="{
          redTag: trailer != null && trailer.redTag,
          yellowTag: trailer != null && trailer.yellowTag,
        }"
      />
      <div v-if="showInbound" class="smaller">
        &nbsp;O:
        {{ inbOrigin }}&nbsp;<br />
        &nbsp;D:
        {{ inbDest }}&nbsp;<br />
        &nbsp;C:
        {{ inbContent }}&nbsp;
      </div>
      <div v-if="showOutbound" class="smaller">
        &nbsp;<router-link
          class="clickable"
          :to="{
            name: 'bol',
            params: { billOfLadingNo: stay.outboundBol },
          }"
        >
          {{ outInfo }} </router-link
        >&nbsp;
      </div>
    </td>
    <td @click="emitTrailerSelected('status')" class="cell statusCol">
      <TrailerLoadedDisplay
        v-show="trailer"
        :pr="8"
        :pl="8"
        :trailer="trailer"
      />
      <span v-show="trailer == null">&nbsp;</span>
    </td>
    <td @click="emitItemSelected(bay, 'note')" class="cell note">
      &nbsp;
      <em v-show="trailerNote">{{ trailerNote }}</em>
      <span v-show="trailerNote && bay.note"> - </span>
      {{ bay.note }}
      &nbsp;
    </td>
    <td @click="emitItemSelected(bay, 'workers')" class="cell">
      {{ bay.assignedWorkers }}
      <span v-show="bay.assignedWorkers == null"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
      >
    </td>
    <td :title="fullTimeInBay" class="cell timeCol">
      &nbsp;{{ timeInBay }}&nbsp;
    </td>
  </tr>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import dateutil from "../../dateutil";
import TrailerLoadedDisplay from "../trailer/TrailerLoadedDisplay.vue";

export default {
  components: {
    TrailerLoadedDisplay,
  },
  props: {
    bay: Object,
  },
  data() {
    return {
      timeInBay: null,
      timerId: null,
    };
  },
  computed: {
    ...mapGetters("security", ["inRole"]),
    ...mapGetters("common", ["errorMessage", "loading"]),
    ...mapGetters("bays", [
      "stayForBay",
      "inboundContents",
      "inboundDests",
      "inboundOrigins",
    ]),
    stay: function () {
      return this.stayForBay(this.bay);
    },
    trailer: function () {
      return this.stay != null ? this.stay.trailer : null;
    },
    trailerScac: function () {
      return this.trailer != null ? this.trailer.scac : null;
    },
    trailerCode: function () {
      return this.trailer != null ? this.trailer.id : null;
    },
    trailerStatus: function () {
      return this.trailer != null ? this.trailer.status : null;
    },
    showInbound: function () {
      return this.stay?.inboundLoaded && this.stay?.inboundUnloadedAt == null;
    },
    inbOrigin() {
      return this.getLookupValue(this.inboundOrigins, this.stay.inboundOrigin);
    },
    inbDest() {
      return this.getLookupValue(
        this.inboundDests,
        this.stay.inboundDestination,
      );
    },
    inbContent() {
      return this.getLookupValue(
        this.inboundContents,
        this.stay.inboundContent,
      );
    },
    showOutbound: function () {
      return (
        !this.showInbound &&
        this.stay?.outboundBol &&
        this.stay?.outboundDestination
      );
    },
    outInfo() {
      return `${this.stay.outboundBol} ${this.stay.outboundDestination}`;
    },
    hoverTrailer: function () {
      let n = "";
      if (this.trailer) {
        if (this.trailer.redTag) {
          n += "REDTAGGED";
        }
        if (this.trailer.yellowTag) {
          n += "YELLOWTAGGED";
        }
        if (this.trailer.tagNote) {
          n += `: ${this.trailer.tagNote}`;
        }
        if (this.trailer.redTag || this.trailer.yellowTag) {
          n += "\n";
        }
        if (this.trailer.note) {
          n += this.trailer.note;
        }
      }
      return n;
    },
    trailerNote: function () {
      return this.trailer != null ? this.trailer.note : null;
    },
    fullTimeInBay: function () {
      let s = null;
      if (this.trailer != null) {
        s = `Arrived ${dateutil.getLocalDateTime(
          this.trailer.arrivedAtFacilityLocation,
          "YYYY-MM-DD hh:mma z",
        )}\nUpdated ${dateutil.getLocalDateTime(
          this.trailer.lastUpdatedAt,
          "YYYY-MM-DD hh:mma z",
        )}\nby ${this.trailer.lastUpdatedBy}`;
      }
      return s;
    },
  },
  methods: {
    ...mapActions("bays", ["showItemActions"]),
    getLookupValue(arr, id) {
      let v = null;
      let s = arr.find((a) => a.id == id);
      if (s) {
        v = s.item;
      }
      return v;
    },
    emitItemSelected(item, action) {
      this.showItemActions({ i: item, a: action });
    },
    emitTrailerSelected(action) {
      if (this.trailer != null) {
        this.showItemActions({ i: this.bay, a: action });
      }
    },
    formatDate(d) {
      return dateutil.getLocalDateTime(d, "hh:mm:ss");
    },
    calcTimeInBay() {
      let r = null;
      if (this.trailer != null) {
        let s = moment(this.trailer.arrivedAtFacilityLocation);
        let now = moment();
        r = moment.duration(now.diff(s));
        //console.log(`${this.bay.code} n: ${now} t: ${this.trailer.arrivedAtFacilityLocation} r: ${r}`);
        r = r.format("h:mm:ss");
        //r = r.get("hours") + ":" + r.get("minutes") + ":" + r.get("seconds");
      }
      this.timeInBay = r;
    },
  },
  mounted() {
    this.timerId = window.setInterval(this.calcTimeInBay, 1000);
  },
  beforeDestroy() {
    window.clearInterval(this.timerId);
  },
};
</script>

<style scoped>
.cell {
  border: 1px lightgray solid;
  padding: 0px;
  margin: 0px;
}
.bayempty {
  background-color: green;
  color: white;
}
.bayfull {
  background-color: red;
  color: white;
}
.timeCol {
  text-align: right;
  white-space: nowrap;
}
.statusCol {
  white-space: nowrap;
}
.note {
  width: 100%;
}
.trailer {
  white-space: nowrap;
}
.smaller {
  font-size: smaller;
}
</style>
