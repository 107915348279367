<template>
  <div class="container-fluid h-100" style="overflow: hidden; overflow-y: auto">
    <div class="card">
      <div class="card-header">
        <div v-show="errorMessage" class="alert alert-danger">
          {{ errorMessage }}
        </div>
        <div v-if="lastAdded" class="alert alert-success">
          Trailer {{ lastAdded.scac }} {{ lastAdded.id }} added successfully
        </div>
        Add Trailer
      </div>
      <div class="card-body">
        <form>
          <div class="form-group row">
            <label for="scac" class="col-sm-2 col-form-label">SCAC</label>
            <div class="col-sm-10">
              <input
                type="text"
                v-model.trim="scac"
                class="form-control text-uppercase"
                id="scac"
                placeholder="SCAC"
                required
                autofocus
              />
              <div class="text-danger" v-if="!$v.scac.minLength">
                SCAC must have {{ $v.scac.$params.minLength.min }} letters.
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="id" class="col-sm-2 col-form-label">ID</label>
            <div class="col-sm-10">
              <input
                type="text"
                v-model.trim="id"
                class="form-control text-uppercase"
                id="id"
                placeholder="ID"
                required
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="group" class="col-sm-2 col-form-label">Group</label>
            <div class="col-sm-10">
              <select v-model="group" id="group" class="form-control" required>
                <option :value="null" disabled>Select Group</option>
                <option>VAN</option>
                <option>FLATBED</option>
                <option>CONTAINER</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label for="type" class="col-sm-2 col-form-label">Type</label>
            <div class="col-sm-10">
              <b-select
                v-model="type"
                id="type"
                :options="types"
                class="form-control"
                required
              >
                <template #first>
                  <b-select-option option :value="null" disabled
                    >Select Type</b-select-option
                  >
                </template>
              </b-select>
            </div>
          </div>
          <div class="form-group row">
            <label for="status" class="col-sm-2 col-form-label">Status</label>
            <div class="col-sm-10">
              <select
                v-model="status"
                id="status"
                class="form-control"
                required
              >
                <option :value="null" disabled>Select Status</option>
                <option>EMPTY</option>
                <option>LOADED</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label for="externalLocation" class="col-sm-2 col-form-label"
              >External Location</label
            >
            <div class="col-sm-10">
              <input
                type="text"
                v-model.trim="externalLocation"
                class="form-control text-uppercase"
                id="externalLocation"
                placeholder="External Location"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="locationDate" class="col-sm-2 col-form-label"
              >Location Date</label
            >
            <div class="col-sm-10">
              <input
                type="date"
                v-model.trim="locationDate"
                class="form-control text-uppercase"
                id="locationDate"
                :disabled="disableLocation"
              />
            </div>
          </div>
          <div class="form-group row">
            <button type="button" @click="add" class="btn btn-primary">
              Add Trailer
            </button>
          </div>
        </form>
      </div>
    </div>
    <LoadingSplash />
  </div>
</template>

<script>
import LoadingSplash from "../LoadingSplash.vue";
import { mapGetters, mapActions } from "vuex";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  components: {
    LoadingSplash,
  },
  data() {
    return {
      scac: null,
      id: null,
      type: null,
      group: null,
      status: null,
      externalLocation: null,
      locationDate: null,
      success: false,
    };
  },
  validations() {
    let v = {
      scac: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
      id: {
        required,
      },
      type: {
        required,
      },
      group: {
        required,
      },
      status: {
        required,
      },
    };
    if (this.externalLocation && this.status === "LOADED") {
      v.locationDate = { required };
    }
    return v;
  },
  computed: {
    ...mapGetters("common", ["loading", "errorMessage"]),
    ...mapGetters("trailerssimple", ["lastAdded"]),
    disableLocation() {
      let loaded = this.status == "LOADED";
      let xtern =
        this.externalLocation != null && this.externalLocation.length > 0;
      return !(loaded && xtern);
    },
    types() {
      let t = [];
      if (this.group == "VAN") {
        t = ["VAN", "VAN48"];
      }
      if (this.group == "FLATBED") {
        t = ["FLAT", "STEP"];
      }
      if (this.group == "CONTAINER") {
        t = ["CONTAINER"];
      }
      return t;
    },
  },
  watch: {
    loading(newVal) {
      if (!newVal) {
        if (!this.errorMessage) {
          this.reset();
          this.success = true;
        }
      }
    },
  },
  destroyed() {
    this.resetStore();
  },
  methods: {
    ...mapActions("common", ["setErrorMessage"]),
    ...mapActions("trailerssimple", ["addEntity", "resetStore"]),
    reset() {
      this.scac = null;
      this.id = null;
      this.type = null;
      this.group = null;
      this.status = null;
      this.externalLocation = null;
      this.locationDate = null;
    },
    add() {
      this.success = false;
      this.setErrorMessage(null);
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.setErrorMessage(
          "Check that all required fields are filled out with valid data",
        );
      } else {
        this.externalLocation = this.externalLocation
          ? this.externalLocation.toUpperCase()
          : null;
        const t = {
          scac: this.scac.toUpperCase(),
          id: this.id.toUpperCase(),
          type: this.type,
          trailerGroup: this.group,
          status: this.status,
          externalLocation: this.externalLocation,
          arrivedAtExternalLocationLoaded: this.locationDate
            ? this.locationDate + "T00:00:00Z"
            : null,
        };
        //this.addTrailer(t);
        this.addEntity({ func: "addTrailer", target: t });
      }
    },
  },
};
</script>

<style scoped></style>
