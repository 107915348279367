<template>
  <BaseModal
    :selectedItemForActions="selectedItemForActions"
    :selectedActionPanel="selectedActionPanel"
    :showItemActions="showItemActions"
    :title="title"
    id="bays-bay-modal"
    actionPanel="bay"
    @shown="doShown"
  >
    <div>
      <div class="container" v-if="trailerInbay == null">
        <div class="row">
          <div class="col">Trailer:</div>
          <div class="col col-9">
            <Autocomplete
              ref="autocomplete"
              :source="availableTrailers"
              :results-display="getTrailerLabel"
              @selected="setStay"
              @clear="clearStay"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">Note:</div>
          <div class="col col-9">
            <input
              class="form-control text-uppercase"
              type="text"
              v-model.trim="noteInput"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">Workers:</div>
          <div class="col col-9">
            <input
              class="form-control text-uppercase"
              type="text"
              v-model.trim="workersInput"
            />
          </div>
        </div>
        <hr />
        <button
          @click="trailerToBay"
          :disabled="selected == null"
          type="button"
          class="btn btn-primary"
        >
          Assign Trailer
        </button>
      </div>
      <div v-else>
        <button @click="clearBay" type="button" class="btn btn-primary">
          Clear Bay
        </button>
        <hr />
        <b-row>
          <b-col>{{ trailerInbay.scac }} {{ trailerInbay.id }} Note:</b-col>
          <b-col>
            <b-input
              type="text"
              :formatter="upper"
              trim
              v-model="trailerNote"
            />
          </b-col>
          <b-col
            ><button
              type="button"
              class="btn btn-primary"
              @click="doSetTrailerNote"
            >
              Set Note
            </button></b-col
          >
        </b-row>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BaseModal from "../common/BaseModal.vue";
import Autocomplete from "vuejs-auto-complete";
import textutils from "../../textutils";

export default {
  components: {
    BaseModal,
    Autocomplete,
  },
  data() {
    return {
      selected: null,
      trailerInput: null,
      noteInput: null,
      workersInput: null,
      trailerNote: null,
    };
  },
  computed: {
    ...mapGetters("bays", [
      "availableTrailers",
      "selectedItemForActions",
      "selectedActionPanel",
      "stayForBay",
    ]),
    title() {
      return `${this.facility} ${this.type} ${this.code}`;
    },
    trailerInbay() {
      let r = null;
      if (this.selectedItemForActions != null) {
        let s = this.stayForBay(this.selectedItemForActions);
        if (s) {
          r = s.trailer;
        }
      }
      return r;
    },
    facility() {
      return this.selectedItemForActions != null
        ? this.selectedItemForActions.facility
        : null;
    },
    type() {
      return this.selectedItemForActions != null
        ? this.selectedItemForActions.type
        : null;
    },
    code() {
      return this.selectedItemForActions != null
        ? this.selectedItemForActions.code
        : null;
    },
  },
  watch: {
    selectedItemForActions(newVal) {
      if (newVal != null) {
        this.noteInput = newVal.note;
        this.workersInput = newVal.assignedWorkers;
      }
    },
    trailerInput(newVal) {
      if (newVal != null) {
        this.trailerInput = newVal.toUpperCase();
      }
    },
    noteInput(newVal) {
      if (newVal != null) {
        this.noteInput = newVal.toUpperCase();
      }
    },
    workersInput(newVal) {
      if (newVal != null) {
        this.workersInput = newVal.toUpperCase();
      }
    },
  },
  methods: {
    ...mapActions("bays", [
      "showItemActions",
      "clearBay",
      "assignTrailerToBay",
      "setTrailerNote",
    ]),
    doSetTrailerNote() {
      this.setTrailerNote({
        scac: this.trailerInbay.scac,
        id: this.trailerInbay.id,
        note: this.trailerNote,
      });
    },
    upper(val) {
      return textutils.toUpper(val);
    },
    setStay(s) {
      this.selected = s.selectedObject;
    },
    clearStay() {
      this.selected = null;
      this.trailerInput = null;
    },
    trailerToBay() {
      let parm = {
        t: this.selected.trailer,
        n: this.noteInput,
        w: this.workersInput,
      };
      this.$refs.autocomplete.clear();
      this.assignTrailerToBay(parm);
    },
    getTrailerLabel(s) {
      return s != null ? `${s.trailer.scac} ${s.trailer.id}` : null;
    },
    doShown() {
      if (this.$refs.autocomplete) {
        window.setTimeout(
          () =>
            this.$refs.autocomplete.$el
              .getElementsByTagName("input")[0]
              .focus(),
          250,
        );
      }
      if (this.trailerInbay) {
        this.trailerNote = this.trailerInbay.note;
      }
    },
  },
};
</script>

<style scoped></style>
