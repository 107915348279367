import {
  trailers as trailersApi,
  driverroutes as routesApi,
  yard as yardApi,
} from "../../api/movementapi";
import Vue from "vue";

function getLookupValue(arr, id) {
  let v = null;
  let s = arr.find((a) => a.id == id);
  if (s) {
    v = s.item;
  }
  return v;
}

// initial state
const state = {
  trailers: [],
  facilityLocations: [],
  selectedTrailerForActions: null,
  selectedActionPanel: null,
  activeRoutes: [],
  stays: [],
  inboundOrigins: [],
  inboundDestinations: [],
  inboundContents: [],
};

// getters
const getters = {
  getOriginValue: (state) => (id) => {
    return getLookupValue(state.inboundOrigins, id);
  },
  getDestinationValue: (state) => (id) => {
    return getLookupValue(state.inboundDestinations, id);
  },
  getContentValue: (state) => (id) => {
    return getLookupValue(state.inboundContents, id);
  },
  stays(state) {
    return state.stays;
  },
  activeRoutes(state) {
    return state.activeRoutes;
  },
  activeRoute: (state) => (trailer) => {
    return trailer != null
      ? state.activeRoutes
          .filter((r) => r.trailer != null)
          .find(
            (r) =>
              r.trailer.scac === trailer.scac && r.trailer.id === trailer.id,
          )
      : null;
  },
  facilityLocations(state) {
    return state.facilityLocations;
  },
  facilities(state) {
    return [...new Set(state.facilityLocations.map((f) => f.facility))].sort();
  },
  facilityGroups: (state) => (facility) => {
    return [
      ...new Set(
        state.facilityLocations
          .filter((f) => f.facility === facility)
          .map((f) => f.locGroup),
      ),
    ].sort();
  },
  facilityGroupLocations: (state) => (facility, group) => {
    return state.facilityLocations
      .filter((f) => f.facility === facility && f.locGroup === group)
      .sort();
  },
  trailers(state) {
    if (state.trailers) {
      state.trailers.sort(function (a, b) {
        return a.scac + a.id < b.scac + b.id ? -1 : 1;
      });
    }
    return state.trailers;
  },
  allVans(state) {
    return state.trailers.filter((t) => t.type === "VAN");
  },
  van48s(state) {
    return state.trailers.filter((t) => t.type === "VAN48");
  },
  flatbeds(state) {
    return state.trailers.filter((t) => t.type === "FLAT");
  },
  col1Vans(state, getters) {
    let start = 0;
    let end =
      start +
      getters.allVans.length / 3 +
      (getters.allVans.length % 3 > 0 ? 1 : 0);
    return getters.allVans.slice(start, end);
  },
  col2Vans(state, getters) {
    let start = getters.col1Vans.length;
    let end =
      start +
      getters.allVans.length / 3 +
      (getters.allVans.length % 3 > 1 ? 1 : 0);
    return getters.allVans.slice(start, end);
  },
  col3Vans(state, getters) {
    let start = getters.col1Vans.length + getters.col2Vans.length;
    let end = getters.allVans.length;
    return getters.allVans.slice(start, end);
  },
  selectedTrailerForActions(state) {
    return state.selectedTrailerForActions;
  },
  selectedActionPanel(state) {
    return state.selectedActionPanel;
  },
};

// actions
const actions = {
  async trailerAdded({ commit }, trailer) {
    commit("trailerAdded", trailer);
  },
  async trailerDeleted({ commit }, trailer) {
    commit("trailerDeleted", trailer);
  },
  async updateTrailer({ commit }, trailer) {
    commit("updateTrailer", trailer);
  },
  async _trailerMutation({ dispatch, commit }, promise) {
    if (getters.selectedTrailerForActions) {
      dispatch("common/setErrorMessage", null, { root: true });
      dispatch("common/setLoading", true, { root: true });
      promise
        .then((res) => {
          commit("updateTrailer", res.data.payload);
        })
        .catch((err) => {
          dispatch("common/handleHttpError", err, {
            root: true,
          });
        })
        .finally(() => {
          dispatch("common/setLoading", false, { root: true });
        });
    }
  },
  async loadInitialData({ dispatch, commit }) {
    dispatch("common/setErrorMessage", null, { root: true });
    dispatch("common/setLoading", true, { root: true });
    let proms = [];
    var t = trailersApi.loadTrailers().then((res) => {
      commit("setTrailers", res.data.payload);
    });
    proms.push(t);

    var l = trailersApi.loadFacilityLocations().then((res) => {
      commit("setFacilityLocations", res.data.payload);
    });
    proms.push(l);
    let fac = ["ADC", "MTJOY", "MTJDRAY", "ATIRI"];
    fac.forEach((f) => {
      let tmps = yardApi.loadStays(f).then((res) => {
        res.data.payload
          .filter((s) => s.trailer.scac == "QUCF")
          .forEach((s) => commit("addStay", s));
      });
      proms.push(tmps);
      let tmpinbo = yardApi.loadLookupInboundOrigins(f).then((res) => {
        res.data.payload.forEach((lv) => commit("addInboundOrigin", lv));
      });
      proms.push(tmpinbo);
      let tmpinbd = yardApi.loadLookupInboundDests(f).then((res) => {
        res.data.payload.forEach((lv) => commit("addInboundDestination", lv));
      });
      proms.push(tmpinbd);
      let tmpinbc = yardApi.loadLookupInboundContents(f).then((res) => {
        res.data.payload.forEach((lv) => commit("addInboundContent", lv));
      });
      proms.push(tmpinbc);
    });

    let r = routesApi.getActiveRoutesWithTrailers().then((res) => {
      commit("setActiveRoutes", res.data.payload);
    });
    proms.push(r);
    return Promise.all(proms)
      .catch((err) => {
        dispatch("common/handleHttpError", err, {
          root: true,
        });
      })
      .finally(() => {
        dispatch("common/setLoading", false, { root: true });
      });
  },
  async deleteTrailer({ dispatch, getters }) {
    dispatch(
      "_trailerMutation",
      trailersApi.deleteTrailer(
        getters.selectedTrailerForActions.scac,
        getters.selectedTrailerForActions.id,
      ),
    );
  },
  async showTrailerActions({ commit }, { t, a }) {
    commit("setSelectedTrailerForActions", t);
    commit("setSelectedActionPanel", a);
  },
  async updateRedTag({ getters, dispatch }, { redTag, note }) {
    dispatch(
      "_trailerMutation",
      trailersApi.setRedTag(
        getters.selectedTrailerForActions.scac,
        getters.selectedTrailerForActions.id,
        redTag,
        note,
      ),
    );
  },
  async updateYellowTag({ getters, dispatch }, { yellowTag, note }) {
    dispatch(
      "_trailerMutation",
      trailersApi.setYellowTag(
        getters.selectedTrailerForActions.scac,
        getters.selectedTrailerForActions.id,
        yellowTag,
        note,
      ),
    );
  },
  async updateStatus({ getters, dispatch }, { status, eligibleForDetention }) {
    dispatch(
      "_trailerMutation",
      trailersApi.setStatus(
        getters.selectedTrailerForActions.scac,
        getters.selectedTrailerForActions.id,
        status,
        eligibleForDetention,
      ),
    );
  },
  async updateExternalLocation({ getters, dispatch }, loc) {
    dispatch(
      "_trailerMutation",
      trailersApi.setExternalLocation(
        getters.selectedTrailerForActions.scac,
        getters.selectedTrailerForActions.id,
        loc,
      ),
    );
  },
  async updateNote({ getters, dispatch }, note) {
    dispatch(
      "_trailerMutation",
      trailersApi.setNote(
        getters.selectedTrailerForActions.scac,
        getters.selectedTrailerForActions.id,
        note,
      ),
    );
  },
  async updateFacilityLocation({ getters, dispatch }, loc) {
    dispatch(
      "_trailerMutation",
      trailersApi.setFacilityLocation(
        getters.selectedTrailerForActions.scac,
        getters.selectedTrailerForActions.id,
        loc,
      ),
    );
  },
  handleDriverWorkDayRouteMutation({ commit }, msg) {
    //driverWorkDayRouteCreated, driverWorkDayRouteUpdated, driverWorkDayRouteDeleted, driverWorkDayRouteStarted, driverWorkDayRouteEnded;
    if (msg && msg.body) {
      let mutation = JSON.parse(msg.body);
      switch (mutation.action) {
        case "driverWorkDayRouteStarted":
          commit("addActiveRoute", mutation.current);
          break;
        case "driverWorkDayRouteEnded":
          commit("removeActiveRoute", mutation.current);
          break;
      }
      //console.log(mutation);
    }
  },
  handleTrailerStayMutation({ commit }, msg) {
    if (msg && msg.body) {
      let mutation = JSON.parse(msg.body);
      if (mutation?.current?.trailer?.scac == "QUCF") {
        if (mutation.previous) {
          commit("updateStay", mutation.current);
        } else {
          commit("addStay", mutation.current);
        }
      }
    }
  },
};

// mutations
const mutations = {
  addInboundOrigin(state, lv) {
    state.inboundOrigins.push(lv);
  },
  addInboundDestination(state, lv) {
    state.inboundDestinations.push(lv);
  },
  addInboundContent(state, lv) {
    state.inboundContents.push(lv);
  },
  addStay(state, stay) {
    state.stays.push(stay);
  },
  updateStay(state, stay) {
    let idx = state.stays.map((s) => s.id).indexOf(stay.id);
    if (idx > -1) {
      if (stay.endStay) {
        state.stays.splice(idx, 1);
      } else {
        Vue.set(state.stays, idx, stay);
      }
    } else {
      state.stays.push(stay);
    }
  },
  trailerAdded(state, t) {
    state.trailers.push(t);
  },
  trailerDeleted(state, t) {
    let idx = state.trailers.map((i) => i.scac + i.id).indexOf(t.scac + t.id);
    if (idx > -1) {
      state.trailers.splice(idx, 1);
    }
  },
  setSelectedTrailerForActions(state, t) {
    state.selectedTrailerForActions = t;
  },
  setSelectedActionPanel(state, a) {
    state.selectedActionPanel = a;
  },
  setTrailers(state, data) {
    state.trailers = data;
  },
  setFacilityLocations(state, data) {
    state.facilityLocations = data;
  },
  setActiveRoutes(state, data) {
    state.activeRoutes = data;
  },
  addActiveRoute(state, route) {
    let idx = state.activeRoutes.map((r) => r.id).indexOf(route.id);
    if (idx < 0) {
      state.activeRoutes.push(route);
    }
  },
  removeActiveRoute(state, route) {
    let idx = state.activeRoutes.map((r) => r.id).indexOf(route.id);
    if (idx > -1) {
      state.activeRoutes.splice(idx, 1);
    }
  },
  updateTrailer(state, data) {
    state.trailers.forEach(function (item, i) {
      if (item.scac === data.scac && item.id === data.id) {
        Vue.set(state.trailers, i, data);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
