<template>
  <b-modal
    @hide="close"
    id="yard-trailer-modal"
    :title="title"
    :hide-footer="true"
  >
    <TrailerStatusFrag v-if="selectedActionPanel === 'status'" />
    <TrailerLocationFrag v-if="selectedActionPanel === 'location'" />
    <CheckOutFrag v-if="selectedActionPanel === 'checkOut'" />
    <TrailerRedTagFrag v-if="selectedActionPanel === 'redTag'" />
    <TrailerCheckInFrag v-if="selectedActionPanel === 'checkin'" />
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TrailerStatusFrag from "./TrailerStatusFrag.vue";
import TrailerLocationFrag from "./TrailerLocationFrag.vue";
import CheckOutFrag from "./CheckOutFrag.vue";
import TrailerRedTagFrag from "./TrailerRedTagFrag.vue";
import TrailerCheckInFrag from "./TrailerCheckInFrag.vue";

export default {
  components: {
    TrailerStatusFrag,
    TrailerLocationFrag,
    CheckOutFrag,
    TrailerRedTagFrag,
    TrailerCheckInFrag,
  },
  computed: {
    ...mapGetters("yard", ["selectedStayForActions", "selectedActionPanel"]),
    title() {
      if (this.selectedStayForActions != null) {
        return `Trailer ${this.selectedStayForActions.trailer.scac} ${this.selectedStayForActions.trailer.id}`;
      } else {
        return "";
      }
    },
  },
  watch: {
    selectedTrailerForActions(newVal) {
      if (newVal && this.selectedActionPanel != null) {
        this.$bvModal.show("yard-trailer-modal");
      } else {
        this.$bvModal.hide("yard-trailer-modal");
      }
    },
    selectedActionPanel(newVal) {
      if (newVal && this.selectedStayForActions != null) {
        this.$bvModal.show("yard-trailer-modal");
      } else {
        this.$bvModal.hide("yard-trailer-modal");
      }
    },
  },
  methods: {
    ...mapActions("yard", ["showStayActions"]),
    close() {
      this.showStayActions({ t: null, a: null });
    },
  },
};
</script>
