<template>
  <b-card no-body>
    <b-card-header>
      <b-row>
        <b-col>
          Requested Load Tenders
          <span v-if="tenders != null">({{ tenders.length }})</span>
          <span v-if="sortBy != null && sortBy.length > 0"
            >, Sorting By: <b>{{ sortText }}</b>
            {{ sortDesc ? "Descending" : "Ascending" }}
          </span>
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body v-if="tenders != null">
      <b-row class="table-responsive">
        <b-table
          :items="tenders"
          :fields="headers"
          :tbody-tr-class="rowClass"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          responsive="md"
          primary-key="shipmentId"
          :tbody-transition-props="transProps"
          striped
          small
          sort-icon-left
        >
          <template #cell(shipmentId)="data">
            <font-awesome-icon
              icon="print"
              @click="printShipmentClicked(data.item.id)"
            />&nbsp;
            <span class="clickable" @click="shipmentIdClicked(data.item.id)">{{
              formatShipmentId(data.item)
            }}</span>
          </template>
          <template #cell(from)="data">
            <span :title="locDetails(data.item.shipmentJson.stops[0].shipFrom)">
              {{ data.item.shipmentJson.stops[0].shipFrom.name }}
            </span>
          </template>
          <template #cell(to)="data">
            <span :title="locDetails(data.item.shipmentJson.stops[1].shipTo)">
              {{ data.item.shipmentJson.stops[1].shipTo.name }}
            </span>
          </template>
          <template #cell(status)="data">
            {{ debugStatus(data.item) }}
          </template>
          <template #cell(actions)="data">
            <button
              v-if="!data.item.accepted && !data.item.denied"
              title="Accept Shipment"
              class="btn"
              @click="acceptButtonClicked(data.item.id)"
            >
              <font-awesome-icon icon="check" />
            </button>
            <button
              v-if="!data.item.accepted && !data.item.denied"
              title="Deny Shipment"
              class="btn"
              @click="denyButtonClicked(data.item.id)"
            >
              <font-awesome-icon icon="minus" />
            </button>
            {{ data.item.waitTime }}
          </template>
        </b-table>
      </b-row>
    </b-card-body>
  </b-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import dateutil from "../../dateutil";
import moment from "moment";
import { edi } from "../../api/integrationapi";

export default {
  props: {
    tenders: Array,
  },
  data() {
    return {
      transProps: {
        name: "fade-left",
      },
      timerHandle: null,
      sortBy: "shipBy",
      sortDesc: false,
      headers: [
        { key: "shipmentId", label: "Shipment", sortable: true },
        {
          key: "shipBy",
          label: "Ship By",
          formatter: "formatShipBy",
          sortable: true,
          sortByFormatted: true,
        },
        {
          key: "deliverBy",
          label: "Deliver By",
          formatter: "formatDeliverBy",
          sortable: true,
          sortByFormatted: true,
        },
        {
          key: "trailer",
          label: "Trailer",
          formatter: "formatTrailer",
          sortable: true,
          sortByFormatted: true,
        },
        { key: "from", label: "From" },
        { key: "to", label: "To" },
        {
          key: "dateReceived",
          label: "Received",
          formatter: "formatDateReceived",
          sortable: true,
          sortByFormatted: true,
        },
        { key: "actions", label: "", class: "text-right" },
      ],
    };
  },
  watch: {
    tenders(newVal) {
      this.sortBy = this.sortBy;
    },
  },
  computed: {
    ...mapGetters("common", ["errorMessage", "messagingConnected"]),
    sortText() {
      let st = null;
      if (this.sortBy == "shipBy") {
        st = "Ship By";
      }
      if (this.sortBy == "shipmentId") {
        st = "Shipment";
      }
      if (this.sortBy == "deliverBy") {
        st = "Deliver By";
      }
      if (this.sortBy == "dateReceived") {
        st = "Date Received";
      }
      return st;
    },
  },
  methods: {
    ...mapActions("edi", ["acceptTenderId", "denyTenderId"]),
    acceptButtonClicked(id) {
      this.acceptTenderId(id);
    },
    denyButtonClicked(id) {
      this.denyTenderId(id);
    },
    formatShipBy(value, key, item) {
      return item.shipmentJson?.stops?.[0]?.shipBy
        ? this.formatDate(item.shipmentJson?.stops?.[0]?.shipBy, false)
        : "N/A";
    },
    formatTrailer(value, key, item) {
      return item?.shipmentJson?.equipment?.id;
    },
    formatDeliverBy(value, key, item) {
      return item.shipmentJson?.stops?.[1]?.deliverBy
        ? this.formatDate(item.shipmentJson?.stops?.[1]?.deliverBy, false)
        : "N/A";
    },
    formatDateReceived(value, key, item) {
      return this.formatDate(item.dateReceived, true);
    },
    formatShipmentId(tender) {
      return tender.shipmentId;
    },
    formatDate(d, local = true) {
      if (d) {
        return local
          ? dateutil.getLocalDateTime(d, "MM-DD h:mma")
          : dateutil.format(d, "MM-DD h:mma");
      } else {
        return "";
      }
    },
    locDetails(loc) {
      let d = null;
      if (loc) {
        d = `${loc?.name}\n${loc?.address1}\n${loc?.city}, ${loc?.state} ${loc?.zip}`;
      }
      return d;
    },
    rowClass(tender, type) {
      if (!tender || type !== "row") {
        return;
      }
      let cls = "table-request";
      if (tender.denied) {
        cls = "table-denied";
      }
      if (tender.accepted) {
        cls = "table-accepted";
      }
      if (tender.cancelled) {
        cls = "table-cancelled";
      }
      return cls;
    },
    shipmentIdClicked(id) {
      window.open(edi.generateEdi204SheetLink(id), "_new");
    },
    printShipmentClicked(id) {
      window.open(`${edi.generateEdi204PdfSheetLink(id)}?inline=true`, "_new");
    },
    calcRequestWaitTime(tender) {
      let d = null;
      if (!tender.accepted && !tender.denied) {
        let s = moment(tender.dateReceived);
        let e = moment();
        d = moment.duration(e.diff(s)).format("h:mm:ss");
      }
      return d;
    },
    onTick() {
      this.tenders.forEach((t) => {
        t.waitTime = this.calcRequestWaitTime(t);
      });
    },
  },
  async mounted() {
    this.onTick();
    this.timerHandle = window.setInterval(this.onTick, 1000);
  },
  beforeDestroy() {
    window.clearInterval(this.timerHandle);
  },
};
</script>

<style scoped>
.btn {
  background-color: lightgrey;
}
</style>
