<template>
  <div>
    <b-row>
      <b-col>
        <button type="button" class="btn btn-primary" @click="toggleRedTag">
          {{ redTagLabel }}
        </button>
      </b-col>
      <b-col>
        <button type="button" class="btn btn-primary" @click="toggleYellowTag">
          {{ yellowTagLabel }}
        </button>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="auto">Tag Note: </b-col>
      <b-col>
        <b-input
          v-model="tagNote"
          class="form-control form-control-sm text-uppercase"
        />
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col>Note:</b-col>
      <b-col>
        <b-input type="text" :formatter="upper" trim v-model="note" />
      </b-col>
      <b-col
        ><button type="button" class="btn btn-primary" @click="setNote">
          Set Note
        </button></b-col
      >
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import textutils from "../../textutils";

export default {
  data() {
    return {
      note: null,
      tagNote: null,
    };
  },
  computed: {
    redTagLabel() {
      let l = null;
      if (this.selectedStayForActions) {
        l = this.selectedStayForActions.trailer.redTag
          ? "Remove Red Tag"
          : "Set Red Tag";
      }
      return l;
    },
    yellowTagLabel() {
      let l = null;
      if (this.selectedStayForActions) {
        l = this.selectedStayForActions.trailer.yellowTag
          ? "Remove Yellow Tag"
          : "Set Yellow Tag";
      }
      return l;
    },
    ...mapGetters("yard", ["selectedStayForActions"]),
  },
  methods: {
    ...mapActions("yard", [
      "showStayActions",
      "updateRedTag",
      "updateYellowTag",
      "updateTrailerNote",
    ]),
    upper(val) {
      return textutils.toUpper(val);
    },
    close() {
      this.showStayActions({ s: null, a: null });
    },
    toggleRedTag() {
      this.updateRedTag({
        redTag: !this.selectedStayForActions.trailer.redTag,
        note: textutils.toUpper(this.tagNote),
      });
      this.close();
    },
    toggleYellowTag() {
      this.updateYellowTag({
        yellowTag: !this.selectedStayForActions.trailer.yellowTag,
        note: textutils.toUpper(this.tagNote),
      });
      this.close();
    },
    setNote() {
      this.updateTrailerNote({
        scac: this.selectedStayForActions.trailer.scac,
        id: this.selectedStayForActions.trailer.id,
        note: this.note,
      }).then((r) => this.close());
    },
  },
  mounted() {
    if (this.selectedStayForActions) {
      this.note = this.selectedStayForActions.trailer.note;
      this.tagNote = this.selectedStayForActions.trailer.tagNote;
    }
  },
};
</script>
