export default {
  toUpper: function (t) {
    let r = null;
    if (t != null && t.trim && t.toUpperCase) {
      if (t.trim && t.toUpperCase) {
        if (t.trim().length > 0) {
          r = t.toUpperCase();
        } else {
          r = t.toString().toUpperCase();
        }
      }
    } else {
      r = t;
    }
    return r;
  },
  toLower: function (t) {
    let r = null;
    if (t != null && t.trim && t.toUpperCase) {
      if (t.trim().length > 0) {
        r = t.toLowerCase();
      }
    } else {
      r = t;
    }
    return r;
  },
  appendIfNotPresent(dest, strToAppend) {
    if (dest) {
      if (dest.indexOf(strToAppend) > -1) {
        return dest;
      } else {
        return `${dest} ${strToAppend}`;
      }
    } else {
      return strToAppend;
    }
  },
  shortUserName(name) {
    let n = name;
    if (name) {
      let idx = name.indexOf("@");
      n = idx > -1 ? name.substring(0, idx) : name;
      n = this.toUpper(n);
    }
    return n;
  },
  blankIfNull(s) {
    return s != null ? s.toString() : "";
  },
  appendUrlQuery(url, key, value, includeIfBlank) {
    let v = this.blankIfNull(value);
    if (v.length > 0) {
      url.searchParams.append(key, v);
    } else if (includeIfBlank) {
      url.searchParams.append(key, "");
    }
  },
};
