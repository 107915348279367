<template>
  <div v-bind:class="{ hasError: hasError }">
    <loading-placeholder v-if="loading" />
    <div v-if="!loading">
      <div v-if="!isVertical">
        {{ horizontal }}
      </div>
      <div v-else>
        {{ name }}<br />
        {{ address }}<br />
        {{ cityStateZip }}
      </div>
    </div>
    <div v-if="hasError">{{ errorMessage }}</div>
  </div>
</template>

<script>
import { partyApi } from "../../api/manifest";
import LoadingPlaceholder from "../../components/common/LoadingPlaceholder.vue";

export default {
  components: {
    LoadingPlaceholder,
  },
  props: {
    code: String,
    isVertical: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      party: null,
      loading: false,
      hasError: false,
      errorMessage: null,
    };
  },
  computed: {
    horizontal() {
      let h = "";
      if (this.party != null) {
        h = this.append(h, this.name);
        h = this.append(h, this.city);
        h = this.append(h, this.state);
      }
      return h;
    },
    name() {
      return this.party != null && this.party.name != null
        ? this.party.name
        : "";
    },
    address() {
      return this.party != null && this.party.address != null
        ? this.party.address
        : "";
    },
    city() {
      return this.party != null && this.party.city != null
        ? this.party.city
        : "";
    },
    state() {
      return this.party != null && this.party.state != null
        ? this.party.state
        : "";
    },
    zip() {
      return this.party != null && this.party.zip != null ? this.party.zip : "";
    },
    cityStateZip() {
      return this.party != null
        ? `${this.city}, ${this.state} ${this.zip}`
        : "";
    },
  },
  watch: {
    code(val, oldVal) {
      this.hasError = false;
      this.errorMessage = null;
      if (val !== oldVal) {
        this.party = null;
        this.load();
      }
    },
  },
  methods: {
    load() {
      if (this.code) {
        this.loading = true;
        partyApi
          .loadParty(this.code)
          .then((res) => (this.party = res.data))
          .catch((err) => {
            this.hasError = true;
            this.errorMessage = err.response.data.message;
          })
          .finally(() => (this.loading = false));
      }
    },
    append(main, str) {
      if (main != null && main.length > 0 && str != null && str.length > 0) {
        main = `${main}, `;
      }
      if (str != null && str.length > 0) {
        main = `${main}${str}`;
      }
      return main;
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style scoped>
.hasError {
  border: solid 1px red;
  background-color: lightcoral;
  font-style: italic;
  border-radius: 0.25rem;
}
</style>
