<template>
  <div class="container-fluid h-100">
    <div class="card">
      <div class="card-header">
        <div v-show="errorMessage" class="alert alert-danger">
          {{ errorMessage }}
        </div>
        JDM ({{ percentComplete }})
        <font-awesome-icon
          style="float: right"
          icon="file-download"
          title="Download JDM Summary"
          @click="downloadSummary()"
        />
      </div>
      <div id="jdmMainContent">
        <ShipmentListView v-show="currentShipment == null"></ShipmentListView>
        <ShipmentView
          v-if="currentShipment != null && currentOrder == null"
        ></ShipmentView>
        <OrderPanel v-if="currentOrder != null"></OrderPanel>
      </div>
    </div>
    <LoadingSplash />
  </div>
</template>

<script>
import LoadingSplash from "../LoadingSplash.vue";
import { mapGetters, mapActions } from "vuex";
import $ from "jquery/dist/jquery.js";
import messaging from "../../messaging";
import ShipmentListView from "./ShipmentListView.vue";
import ShipmentView from "./ShipmentView.vue";
import OrderPanel from "./OrderPanel.vue";
import reportsapi from "../../api/reportsapi";
import dateutil from "../../dateutil";

export default {
  components: {
    LoadingSplash,
    ShipmentListView,
    ShipmentView,
    OrderPanel,
  },
  computed: {
    ...mapGetters("common", ["errorMessage"]),
    ...mapGetters("jdm", [
      "currentShipment",
      "currentOrder",
      "percentComplete",
    ]),
  },
  methods: {
    ...mapActions("jdm", [
      "initialize",
      "resetStore",
      "handleParcelCodeUpdate",
      "handleParcelCodeMutation",
    ]),
    receiveParcelCodeUpdate: function (msg) {
      this.handleParcelCodeUpdate(msg.body);
    },
    receiveParcelCodeMutation: function (msg) {
      this.handleParcelCodeMutation(msg.body);
    },
    autoFocus: function (e) {
      var target = e.target.attributes.href.value;
      $(target + " input").focus();
    },
    downloadSummary() {
      let cutoff = dateutil.getLocalDateTime(
        dateutil.utcNow().subtract(60, "days"),
        "YYYY-MM-DD",
      );
      let params = { cutoff: `${cutoff}` };
      window.open(
        reportsapi.getReportLink("JDM_Summary", ".xlsx", params),
        "_new",
      );
    },
  },
  mounted() {
    let self = this;
    this.initialize().then(function () {
      messaging.subscribe(
        "JdmPage.ParcelCodeUpdates",
        "/topic/Topic.ParcelCodeUpdates",
        self.receiveParcelCodeUpdate,
      );
      messaging.subscribe(
        "JdmPage.ParcelCodeMutations",
        "/topic/Topic.ParcelCodeMutations",
        self.receiveParcelCodeMutation,
      );
      messaging.restart();
    });
    $('a[data-toggle="tab"]').on("shown.bs.tab", this.autoFocus);
  },
  destroyed() {
    $('a[data-toggle="tab"]').off("shown.bs.tab");
    messaging.unsubscribe("JdmPage.ParcelCodeUpdates");
    messaging.unsubscribe("JdmPage.ParcelCodeMutations");
    this.resetStore();
  },
};
</script>

<style scoped>
#jdmMainContent {
  font-size: 200%;
}
</style>
