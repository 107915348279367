import {
  spotter as spotapi,
  trailers as trailerapi,
  yard as yardApi,
} from "../../api/movementapi";
import Vue from "vue";

const requestSort = function (r1, r2) {
  let result = 0;
  if ((r1.priority && r2.priority) || (!r1.priority && !r2.priority)) {
    result = r1.requestNumber - r2.requestNumber;
  }
  if (r1.priority && !r2.priority) {
    result = -1;
  }
  if (!r1.priority && r2.priority) {
    result = 1;
  }
  return result;
};

const state = {
  facility: null,
  openRequests: [],
  stays: [],
};

const getters = {
  facility(state) {
    return state.facility;
  },
  openRequests(state) {
    return state.openRequests.sort(requestSort);
  },
  availableTrailers(state) {
    let res = [];
    if (state != null && state.stays != null) {
      res = state.stays;
      res.sort((a, b) =>
        `${a.trailer.scac}-${a.trailer.id}`.localeCompare(
          `${b.trailer.scac}-${b.trailer.id}`,
        ),
      );
    }
    return res;
  },
  stays(state) {
    return state.stays;
  },
};

const actions = {
  async setFacility({ commit, dispatch }, facility) {
    commit("clearRequests");
    commit("setFacility", facility);
    let spots = spotapi.loadOpen(facility).then((res) => {
      if (res.data.payload) {
        res.data.payload.forEach((request) => commit("addRequest", request));
      }
    });
    let stays = yardApi.loadStays(facility).then((res) => {
      commit("setStays", res.data.payload);
    });
    let all = Promise.all([spots, stays]);
    return dispatch("common/showLoadingDuringAction", all, { root: true });
  },
  async claim({ dispatch }, id) {
    let call = spotapi.claim(id);
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  async release({ dispatch }, id) {
    let call = spotapi.release(id);
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  async complete({ dispatch }, id) {
    let call = spotapi.complete(id);
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  async findLocation({ dispatch, getters }, code) {
    let call = trailerapi.findLocation(getters.facility, code);
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  async requestMove({ dispatch }, req) {
    let call = spotapi.requestMove(req);
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  async remove({ dispatch }, id) {
    let call = spotapi.remove(id);
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  async priority({ dispatch }, { id, priority }) {
    let call = spotapi.priority(id, priority);
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  handleTrailerMutations({ commit, getters }, msg) {
    let mutation = JSON.parse(msg.body);
    if (mutation != null && mutation.current != null) {
      let trailer = mutation.current;
      let reqIdx = getters.openRequests
        .map((r) => `${r.trailerStay.trailer.scac}${r.trailerStay.trailer.id}`)
        .indexOf(`${trailer.scac}${trailer.id}`);
      if (reqIdx > -1) {
        commit("updateTrailerInOpenRequest", { idx: reqIdx, t: trailer });
        this.$app.$toasted.info(
          `Trailer ${trailer.scac} ${trailer.id} updated`,
          {
            duration: 5000,
            position: "bottom-right",
          },
        );
      }
      reqIdx = getters.stays
        .map((r) => `${r.trailer.scac}${r.trailer.id}`)
        .indexOf(`${trailer.scac}${trailer.id}`);
      if (reqIdx > -1) {
        commit("updateTrailerInStays", { idx: reqIdx, t: trailer });
      }
    }
  },
  handleTrailerStayMutation({ commit, getters }, msg) {
    let mutation = JSON.parse(msg.body);
    let mutationFacility =
      mutation.current != null
        ? mutation.current.facility
        : mutation.previous.facility;
    if (mutationFacility === getters.facility) {
      switch (mutation.action) {
        case "arrivedAtFacility":
          commit("addStay", mutation.current);
          break;
        case "departedFacility":
          commit("removeStay", mutation.previous);
          break;
      }
    }
  },
  handleSpotMovementMutation({ commit, getters }, msg) {
    let mutation = JSON.parse(msg.body);
    // requested, claimed, claimReleased, completed, priorityUpdated, removed
    let notify = null;
    let mutationFacility =
      mutation.current != null
        ? mutation.current.facility
        : mutation.previous.facility;
    if (mutationFacility === getters.facility) {
      switch (mutation.action) {
        case "requested":
          commit("addRequest", mutation.current);
          notify = `Move ${mutation.current.requestNumber} added`;
          break;
        case "claimed":
          commit("updateRequest", mutation.current);
          notify = `Move ${
            mutation.current.requestNumber
          } claimed by ${mutation.current.claimedBy
            .substring(0, mutation.current.claimedBy.indexOf("@"))
            .toUpperCase()}`;
          break;
        case "claimReleased":
          commit("updateRequest", mutation.current);
          notify = `Move ${mutation.current.requestNumber} claim released`;
          break;
        case "completed":
          commit("removeRequest", mutation.current);
          notify = `Move ${
            mutation.current.requestNumber
          } completed by ${mutation.current.claimedBy
            .substring(0, mutation.current.claimedBy.indexOf("@"))
            .toUpperCase()}`;
          break;
        case "priorityUpdated":
          commit("updateRequest", mutation.current);
          notify = `Move ${mutation.current.requestNumber} priority updated to ${mutation.current.priority}`;
          break;
        case "removed":
          commit("removeRequest", mutation.previous);
          notify = `Move ${mutation.previous.requestNumber} removed`;
          break;
      }
      if (notify != null) {
        this.$app.$toasted.info(notify, {
          duration: 5000,
          position: "bottom-right",
        });
      }
    }
  },
};

const mutations = {
  setFacility(state, facility) {
    state.facility = facility;
  },
  setStays(state, stays) {
    state.stays = stays;
  },
  addStay(state, stay) {
    state.stays.push(stay);
  },
  removeStay(state, stay) {
    let idx = state.stays.map((s) => s.id).indexOf(stay.id);
    if (idx > -1) {
      state.stays.splice(idx, 1);
    }
  },
  clearRequests(state) {
    state.openRequests.splice(0, state.openRequests.length);
  },
  addRequest(state, request) {
    state.openRequests.push(request);
  },
  updateRequest(state, request) {
    let idx = state.openRequests.map((r) => r.id).indexOf(request.id);
    if (idx > -1) {
      Vue.set(state.openRequests, idx, request);
    } else {
      state.openRequests.push(request);
    }
  },
  removeRequest(state, request) {
    let idx = state.openRequests.map((r) => r.id).indexOf(request.id);
    if (idx > -1) {
      state.openRequests.splice(idx, 1);
    }
  },
  updateTrailerInOpenRequest(state, { idx, t }) {
    state.openRequests[idx].trailerStay.trailer = t;
  },
  updateTrailerInStays(state, { idx, t }) {
    state.stays[idx].trailer = t;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
