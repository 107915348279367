<template>
  <div class="container">
    <div class="row">
      <div class="col">Note:</div>
      <div class="col-10">
        <div class="input-group mb-3">
          <input
            class="form-control text-uppercase"
            ref="focusThis"
            type="text"
            v-model.trim="note"
          />
          <div class="input-group-append">
            <button
              @click="doClearNote"
              :disabled="note == null || note.trim().length == 0"
              class="btn btn-outline-secondary"
              type="button"
            >
              &times;
            </button>
          </div>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col11">
          <button @click="saveNote" type="button" class="btn btn-primary">
            Update Note
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters("trailers", [
      "selectedTrailerForActions",
      "selectedActionPanel",
    ]),
  },
  data() {
    return {
      note: null,
    };
  },
  methods: {
    ...mapActions("trailers", ["showTrailerActions", "updateNote"]),
    close() {
      this.showTrailerActions({ t: null, a: null });
    },
    doClearNote() {
      this.note = null;
    },
    saveNote() {
      this.updateNote(this.note ? this.note.toUpperCase() : null);
      this.note = null;
      this.close();
    },
  },
  mounted() {
    window.setTimeout(() => this.$refs.focusThis.focus(), 250);
  },
};
</script>
