export default {
  indexOf(key, arr, arrFunc) {
    let idx = -1;
    for (let i = 0; i < arr.length; i++) {
      let c = arr[i];
      if (arrFunc) {
        c = arrFunc(c);
      }
      if (key === c) {
        idx = i;
        break;
      }
    }
    return idx;
  },
  prop(p) {
    return p === undefined ? null : p;
  },
  downloadBlob(blob, fileName) {
    const aEle = document.createElement("a");
    const href = window.URL.createObjectURL(blob);
    try {
      aEle.href = href;
      aEle.download = fileName;
      document.body.appendChild(aEle);
      aEle.click();
      document.body.removeChild(aEle);
    } finally {
      window.URL.revokeObjectURL(href);
    }
  },
  downloadText(txt, fileName) {
    const encodedUri = encodeURI(txt);
    const aEle = document.createElement("a");
    aEle.href = encodedUri;
    aEle.download = fileName;
    document.body.appendChild(aEle);
    aEle.click();
    document.body.removeChild(aEle);
  },
};
