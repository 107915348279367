<template>
  <b-modal
    id="create-new-shipment-modal"
    title="Create Shipment"
    size="md"
    :ok-disabled="disableOk"
    @show="resetModal"
    @hidden="resetModal"
    @ok="okClicked"
    @shown="shown"
  >
    <div v-show="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <b-form>
      <b-form-group label-cols-md label="Target Facility:">
        <b-form-select ref="facility" v-model="facility">
          <b-form-select-option v-if="facility == null" :value="null" disabled
            >-- Select a Facility --</b-form-select-option
          >
          <b-form-select-option value="ADC">ADC</b-form-select-option>
          <b-form-select-option value="MTJOY">MTJOY</b-form-select-option>
          <b-form-select-option value="ATIRI">ATIRI</b-form-select-option>
          <b-form-select-option value="MTJOPEN">MTJOPEN</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group label-cols-md label="Shipment Type:">
        <b-form-select
          v-model="shipmentTypeCode"
          :options="shipmentTypes"
          text-field="shipmentTypeCodeDisplay"
          value-field="shipmentTypeCode"
          ref="shipmentTypeCode"
        >
          <template #first>
            <b-form-select-option
              v-if="shipmentTypeCode == null"
              :value="null"
              disabled
              >-- Select a Shipment Type --</b-form-select-option
            >
          </template>
        </b-form-select>
      </b-form-group>
      <b-form-group label-cols-md label="Supplier:">
        <b-form-input trim :formatter="upper" v-model="supplierId" />
      </b-form-group>
      <b-form-group label-cols-md label="Tracking:">
        <b-form-input trim :formatter="upper" v-model="trackingId" />
      </b-form-group>
      <b-form-group label-cols-md label="Trailer:">
        <b-form-input trim :formatter="upper" v-model="trailerNo" />
      </b-form-group>
      <b-form-group label-cols-md label="Carrier:">
        <b-form-input trim :formatter="upper" v-model="carrier" />
      </b-form-group>
      <b-form-group label-cols-md label="Carrier BOL:">
        <b-form-input trim :formatter="upper" v-model="carrierBol" />
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import { lookups, shipmentApi } from "../../../api/manifest";
import { mapActions, mapGetters } from "vuex";
import { MANIFEST_URL } from "../../../config";
import textutils from "../../../textutils";

export default {
  props: {
    shipmentSummaries: Array,
    currentFacility: String,
  },
  data() {
    return {
      supplierId: null,
      shipmentTypeCode: null,
      facility: null,
      trackingId: null,
      trailerNo: null,
      carrier: null,
      carrierBol: null,
      shipmentTypes: [],
    };
  },
  computed: {
    ...mapGetters("common", ["errorMessage", "defaultFacility"]),
    disableOk() {
      return (
        this.supplierId == null ||
        this.shipmentTypeCode == null ||
        this.facility == null ||
        this.trackingId == null ||
        this.trailerNo == null ||
        this.carrier == null
      );
    },
  },
  methods: {
    ...mapActions("common", ["showLoadingDuringAction", "setErrorMessage"]),
    resetModal() {
      this.supplierId = null;
      this.shipmentTypeCode = null;
      this.facility = null;
      this.trackingId = null;
      this.trailerNo = null;
      this.carrier = null;
      this.carrierBol = null;
      this.setErrorMessage(null);
    },
    upper(s) {
      return textutils.toUpper(s);
    },
    async okClicked(bvModalEvt) {
      bvModalEvt.preventDefault();
      let shipment = {
        facility: this.facility,
        supplierId: this.supplierId,
        shipmentTypeCode: this.shipmentTypeCode,
        trackingId: this.trackingId,
        trailerNo: this.trailerNo,
        carrier: this.carrier,
        carrierBol: this.carrierBol,
      };
      let call = shipmentApi.createShipment(shipment);
      let res = await this.showLoadingDuringAction(call);
      if (res) {
        shipment = res.data.payload;
        if (
          shipment.facility === this.currentFacility &&
          this.shipmentSummaries != null
        ) {
          await shipmentApi
            .loadShipmentSummary(shipment.id)
            .then((r) => this.shipmentSummaries.push(r.data.payload));
        }
        this.$toasted.success(`Shipment ${shipment.shipmentName} created`);
        this.$bvModal.hide("create-new-shipment-modal");
        let url = `${MANIFEST_URL}/IncomingShipmentView/Detail?incomingShipmentId=${shipment.id}`;
        window.open(url, "manifestFromMissy");
      }
    },
    shown() {
      this.facility = this.currentFacility;
      if (this.facility != null) {
        this.$refs.shipmentTypeCode.focus();
      } else {
        this.$refs.facility.focus();
      }
    },
  },
  mounted() {
    lookups
      .loadShipmentTypes()
      .then((res) => (this.shipmentTypes = res.data.payload));
  },
};
</script>
