import { parcels as manifestApiParcels } from "../../api/manifest";

const state = {};

const getters = {};

const actions = {
  async setAtLocation({ dispatch }, { facility, barcode, target, damaged }) {
    let call = manifestApiParcels.assignParcelToLocation(
      facility,
      barcode,
      target,
      damaged,
    );
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  async addToBOL({ dispatch }, { facility, barcode, target }) {
    let call = manifestApiParcels.assignParcelToBol(facility, barcode, target);
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  async addToOP({ dispatch }, { facility, barcode, target }) {
    let call = manifestApiParcels.assignParcelToOverpack(
      facility,
      barcode,
      target,
    );
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  async addLocToOP({ dispatch }, { facility, barcode, target }) {
    let call = manifestApiParcels.assignLocationToOverpack(
      facility,
      barcode,
      target,
    );
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  async removeFromLocation({ dispatch }, { facility, barcode }) {
    let call = manifestApiParcels.removeParcelFromLocation(facility, barcode);
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  async removeFromBOL({ dispatch }, { facility, barcode }) {
    let call = manifestApiParcels.removeParcelFromManifest(facility, barcode);
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  async removeFromOP({ dispatch }, { facility, barcode }) {
    let call = manifestApiParcels.removeParcelFromOverpack(facility, barcode);
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
