<template>
  <b-container fluid class="h-100" style="overflow: hidden; overflow-y: auto">
    <div v-show="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <div v-if="lastAdded" class="alert alert-success">
      File uploaded successfully
    </div>
    <b-card no-body>
      <b-card-header>
        <b-row>
          <b-col
            >Upload Test Document for Week of
            {{ formatMomentDate(coverageDate) }}</b-col
          >
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-form>
          <b-row>
            <b-col>Employee:</b-col>
            <b-form-select v-model="employeeId">
              <b-form-select-option disabled :value="null"
                >-- Select Employee --</b-form-select-option
              >
              <b-form-select-option
                v-for="emp in employees"
                :key="emp.id"
                :value="emp.id"
              >
                {{ empName(emp) }}
              </b-form-select-option>
            </b-form-select>
            <b-col>File</b-col>
            <b-form-file v-model="file" ref="file" />
            <b-button :disabled="cantSubmit" @click="onSubmit">Upload</b-button>
            <b-button @click="doClear">Clear</b-button>
          </b-row>
        </b-form>
      </b-card-body>
    </b-card>
    <LoadingSplash />
  </b-container>
</template>

<script>
//import dateutils from "../../../dateutil";
//import textutils from "../../../textutils";
import { mapActions, mapGetters } from "vuex";
import LoadingSplash from "../LoadingSplash.vue";
import miscApi from "../../api/misc";
import dateutil from "../../dateutil";
import moment from "moment";
import attachApi from "../../api/attach";
import textutils from "../../textutils";

export default {
  components: {
    LoadingSplash,
  },
  data() {
    return {
      employeeId: null,
      employees: [],
      file: null,
      vaxDoc: null,
      lastAdded: null,
      coverageDate: null,
    };
  },
  computed: {
    ...mapGetters("common", ["errorMessage"]),
    cantSubmit() {
      return this.employeeId == null || this.file == null;
    },
  },
  methods: {
    ...mapActions("common", ["showLoadingDuringAction"]),
    async onSubmit() {
      this.lastAdded = null;
      let p = new Promise((resolve, reject) => {
        this.doUpload(resolve);
      });
      this.showLoadingDuringAction(p);
    },
    empName(emp) {
      return `${emp.lastName}, ${emp.firstName}`;
    },
    fileExt() {
      let idx = this.file.name.lastIndexOf(".");
      return textutils.toLower(this.file.name.substring(idx));
    },
    doClear() {
      this.employeeId = null;
      this.file = null;
      this.vaxDoc = null;
    },
    async doUpload(resolve) {
      let goTime = this.coverageDate.format();
      let vd = await miscApi
        .createVaxDoc(this.employeeId, this.fileExt(), goTime)
        .then((r) => r.data);
      if (!vd) {
        return false;
      }
      this.vaxDoc = vd;
      let f_id = `${this.employeeId}/${this.vaxDoc.id}${this.fileExt()}`;
      let doc = await attachApi.uploadFile(
        "vaxdoc",
        this.employeeId,
        this.file,
        f_id,
      );
      if (doc) {
        this.lastAdded = doc;
        this.doClear();
      }
      resolve();
    },
    formatMomentDate(m) {
      return dateutil.getLocalDateTime(m, "YYYY-MM-DD");
    },
    async loadEmployees() {
      let call = miscApi.loadVaxEmployees().then((r) => {
        this.employees = r.data;
      });
      return this.showLoadingDuringAction(call);
    },
  },
  mounted() {
    let now = dateutil.getLocalDateTime(moment(), "YYYY-MM-DD");
    let m = dateutil.getLocalDateTime(
      moment().day("Monday").startOf("day"),
      "YYYY-MM-DD",
    );
    if (now > m) {
      m = dateutil.getLocalDateTime(
        moment().add(1, "week").day("Monday").startOf("day"),
        "YYYY-MM-DD",
      );
    }
    this.coverageDate = moment(m);
    this.loadEmployees();
  },
};
</script>
