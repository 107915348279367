<template>
  <div
    :title="titleDisplay"
    class="trailerNameLabel"
    :class="{
      eligibleForDetention: eligibleForDetention,
      inDetention: inDetention,
    }"
  >
    <span v-if="showInbound">[ I ]</span>
    <span v-if="showOutbound">[O]</span>
    {{ trailer.id }}
    <font-awesome-icon
      icon="tag"
      v-show="isTagged"
      :class="{ redTag: trailer.redTag, yellowTag: trailer.yellowTag }"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TrailerNameLabel",
  props: {
    trailer: Object,
  },
  computed: {
    ...mapGetters("trailers", [
      "stays",
      "getOriginValue",
      "getDestinationValue",
      "getContentValue",
    ]),
    stay() {
      let s = this.stays.find((s) => s?.trailer?.id == this.trailer.id);
      return s;
    },
    showInbound() {
      let r = false;
      if (this.stay) {
        r =
          this.stay.inboundLoaded &&
          this.stay.inboundUnloadedAt == null &&
          (this.stay.facility == "ADC" || this.stay.facility == "MTJOY");
      }
      return r;
    },
    showOutbound() {
      let r = false;
      if (this.stay) {
        r =
          !this.showInbound &&
          this.stay.outboundBol &&
          (this.stay.facility == "ADC" || this.stay.facility == "MTJOY");
      }
      return r;
    },
    titleDisplay() {
      let title = this.trailer.scac + " " + this.trailer.id;
      if (this.trailer.redTag) {
        title += "\nREDTAGGED";
      }
      if (this.trailer.yellowTag) {
        title += "\nYELLOWTAGGED";
      }
      if (this.trailer.tagNote) {
        title += `: ${this.trailer.tagNote}`;
      }
      if (this.eligibleForDetention) {
        title += "\nELIGIBLE FOR DETENTION";
      }
      if (this.inDetention) {
        title += "\n" + this.trailer.daysInDetention + " DAYS IN DETENTION";
      }
      if (this.showInbound) {
        title += `\nO: ${this.getOriginValue(this.stay.inboundOrigin)}`;
        title += `\nD: ${this.getDestinationValue(
          this.stay.inboundDestination,
        )}`;
        title += `\nC: ${this.getContentValue(this.stay.inboundContent)}`;
      }
      if (this.showOutbound) {
        title += `\nBOL ${this.stay.outboundBol} ${this.stay.outboundDestination}`;
      }
      return title;
    },
    inDetention() {
      return this.trailer.daysInDetention > 0;
    },
    eligibleForDetention() {
      return (
        this.trailer.arrivedAtExternalLocationLoaded != null &&
        !this.inDetention
      );
    },
    isTagged() {
      return this.trailer.redTag || this.trailer.yellowTag;
    },
  },
};
</script>

<style scoped>
.trailerNameLabel {
  margin: 0px;
  padding: 0px;
  white-space: nowrap;
  border: 2px transparent solid;
}
.eligibleForDetention {
  border: 2px yellow solid;
}
.inDetention {
  border: 2px red solid;
}
</style>
