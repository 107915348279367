import axios from "axios";
import dateutil from "../dateutil";
import textutils from "../textutils";
import jsutils from "../jsutils";

var API_URL = null;
try {
  // eslint-disable-next-line no-undef
  API_URL = REPORTS_URL;
} catch (e) {
  API_URL = "http://manifestapi.home/report";
  //API_URL = "http://localhost:9090";
}

export default {
  getReportLink(report, extension, params, inline = true) {
    let url = new URL(`${API_URL}`);
    url.searchParams.append("report", `${report}${extension}`);
    url.searchParams.append("inline", inline);
    let tz = dateutil.tzname;
    if (tz) {
      url.searchParams.append("userTimezone", tz);
    }
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key]),
    );
    return url;
  },
  getPickListLink(shipmentId, inline = true) {
    let url = new URL(`${API_URL}/picklist`);
    url.searchParams.append("shipmentId", `${shipmentId}`);
    url.searchParams.append("inline", inline);
    let tz = dateutil.tzname;
    if (tz) {
      url.searchParams.append("userTimezone", tz);
    }
    return url;
  },
  emailShippingDocs(shipmentId) {
    let url = new URL(`${API_URL}/emailshippingdocs`);
    url.searchParams.append("shipmentId", shipmentId);
    let tz = dateutil.tzname;
    if (tz) {
      url.searchParams.append("userTimezone", tz);
    }
    return axios.post(url);
  },
  xmlToPdf(params, xml) {
    let url = new URL(`${API_URL}/xmltopdf`);
    Object.keys(params).forEach((k) => {
      textutils.appendUrlQuery(url, k, params[k]);
    });
    axios({
      url: url,
      method: "post",
      data: xml,
      headers: { "Content-Type": "plain/text" },
      responseType: "blob",
    })
      .then((response) => {
        let disposition = response.headers["content-disposition"];
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        let name = disposition?.filename
          ? disposition.filename
          : params.fileName
            ? params.fileName
            : "download.pdf";
        jsutils.downloadBlob(blob, name);
      })
      .catch((err) => {
        if (err.response) {
          err.response.data.text().then((msg) => {
            console.log(msg);
          });
        } else if (err.request) {
          console.log(err.message);
        }
      });
  },
};
