<template>
  <b-container
    fluid
    class="manifestPage h-100"
    style="overflow: hidden; overflow-y: auto; font-size: smaller"
    id="exportPage"
  >
    <div v-show="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <b-row>
      <b-col md="12">
        <b-card no-body v-if="inRole('Export Inventory')">
          <b-card-header>
            <b-row>
              <b-col>Add Export Inventory</b-col>
            </b-row>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col>Case ID:</b-col>
              <b-col>
                <b-form-input
                  size="sm"
                  type="text"
                  v-model="caseCode"
                  :formatter="upper"
                  maxLength="6"
                  lazy
                />
              </b-col>
              <b-col>Order:</b-col>
              <b-col>
                <b-form-input
                  size="sm"
                  type="text"
                  v-model="orderNo"
                  :formatter="upper"
                  maxLength="10"
                  lazy
                />
              </b-col>
              <b-col>Location:</b-col>
              <b-col>
                <b-form-input
                  size="sm"
                  type="text"
                  v-model="location"
                  :formatter="upper"
                  lazy
                />
              </b-col>
              <b-col>Weight:</b-col>
              <b-col>
                <b-form-input size="sm" type="number" v-model="weight" />
              </b-col>
            </b-row>
            <b-row>
              <b-col>Length:</b-col>
              <b-col>
                <b-form-input size="sm" type="number" v-model="invLength" placeholder="inches" />
              </b-col>
              <b-col>Width:</b-col>
              <b-col>
                <b-form-input size="sm" type="number" v-model="invWidth" placeholder="inches" />
              </b-col>
              <b-col>Height:</b-col>
              <b-col>
                <b-form-input size="sm" type="number" v-model="invHeight" placeholder="inches" />
              </b-col>
              <b-col>Cubes:</b-col>
              <b-col>
                {{ cubes }}
              </b-col>
            </b-row>
            <b-row>
              <b-col>Unit:</b-col>
              <b-col>
                <b-form-input
                  size="sm"
                  type="text"
                  v-model="unit"
                  :formatter="upper"
                  maxLength="4"
                  lazy
                />
              </b-col>
              <b-col>Account:</b-col>
              <b-col>
                <b-form-input
                  size="sm"
                  type="text"
                  v-model="account"
                  :formatter="upper"
                  maxLength="6"
                  lazy
                />
              </b-col>
              <b-col>Dest:</b-col>
              <b-col>
                <b-form-input
                  size="sm"
                  type="text"
                  v-model="dest"
                  :formatter="upper"
                  maxLength="4"
                  lazy
                />
              </b-col>
              <b-col>Hazardous:</b-col>
              <b-col>
                <b-form-checkbox size="sm" v-model="hazardous" />
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-footer>
            <b-row>
              <b-col>
                <b-button
                  :disabled="!validInput"
                  @click="addInventory"
                  variant="primary"
                  >Add Export Inventory</b-button
                >
              </b-col>
            </b-row>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card no-body>
          <b-card-header>
            <b-row>
              <b-col
                >Search Export Inventory (TOTAL: {{ searchResults.length }},
                weight {{ resultWeight }}, cubes: {{ resultCubes }}; SELECTED:
                {{ selectedItems.length }}, weight: {{ selectedWeight }}, cubes:
                {{ selectedCubes }})</b-col
              >
            </b-row>
          </b-card-header>
          <b-card-body>
            <b-table
              :items="searchResults"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              striped
              small
              primary-key="id"
            >
              <template #thead-top>
                <b-tr>
                  <b-th>Filter:</b-th>
                  <b-th><b-input type="text" size="sm" v-model="searchCaseCode" :formatter="upper" @keydown="checkKey" placeholder="Case ID" /></b-th>
                  <b-th><b-input type="text" size="sm" v-model="searchOrderNo" :formatter="upper" @keydown="checkKey" placeholder="Order #" /></b-th>
                  <b-th><b-input type="text" size="sm" v-model="searchLocation" :formatter="upper" @keydown="checkKey" placeholder="Location" /></b-th>
                  <b-th>&nbsp;</b-th>
                  <b-th>&nbsp;</b-th>
                  <b-th>&nbsp;</b-th>
                  <b-th>&nbsp;</b-th>
                  <b-th>&nbsp;</b-th>
                  <b-th><b-input type="text" size="sm" v-model="searchUnit" :formatter="upper" @keydown="checkKey" placeholder="Unit" /></b-th>
                  <b-th><b-input type="text" size="sm" v-model="searchAccount" :formatter="upper" @keydown="checkKey" placeholder="Account" /></b-th>
                  <b-th><b-input type="text" size="sm" v-model="searchDest" :formatter="upper" @keydown="checkKey" placeholder="Dest" /></b-th>
                  <b-th>&nbsp;</b-th>
                  <b-th>&nbsp;</b-th>
                  <b-th><b-input type="text" size="sm" v-model="searchShippedContainer" :formatter="upper" @keydown="checkKey" placeholder="Container" /></b-th>
                  <b-th><b-form-checkbox size="sm" v-model="includeShipped"
                      inline @change="searchInventory"/></b-th>
                  <b-th>
                    <b-button
                      variant="light"
                      size="sm"
                      @click="searchInventory"
                    >
                      <font-awesome-icon
                        size="sm"
                        title="Search"
                        icon="filter"
                      />
                    </b-button>
                    <b-button
                      variant="light"
                      size="sm"
                      @click="print"
                      v-if="searchResults.length > 0"
                    >
                      <font-awesome-icon size="sm" title="Print" icon="print" />
                    </b-button>
                    <b-button
                      variant="light"
                      size="sm"
                      @click="exportCSV"
                      v-if="searchResults.length > 0"
                    >
                      <font-awesome-icon
                        size="sm"
                        title="Export CSV"
                        icon="file-download"
                      />
                    </b-button>
                    <b-button
                      variant="light"
                      size="sm"
                      @click="exportOSLCSV"
                      v-if="searchResults.length > 0"
                    >
                      <font-awesome-icon
                        size="sm"
                        title="Export OSL CSV"
                        icon="download"
                      />
                    </b-button>
                  </b-th>
                </b-tr>
              </template>
              <template #head(cubes)> Ft<sup>3</sup> </template>
              <template #cell(selected)="data">
                <b-form-checkbox
                  inline
                  v-model="data.item.selected"
                  v-if="showItemActions && !editedItem"
                />
              </template>
              <template #cell(caseCode)="data">
                <b-form-input
                  v-model="editedItem.caseCode"
                  v-if="editedItem?.id == data.item.id"
                  type="text"
                  :formatter="upper"
                  size="sm"
                  maxLength="6"
                />
                <span v-else>{{ data.item.caseCode }}</span>
              </template>
              <template #cell(orderNo)="data">
                <b-form-input v-model="editedItem.location" v-if="editedItem?.id == data.item.id" type="text"
                  :formatter="upper" size="sm" maxLength="10" />
                <span v-else>{{ data.item.orderNo }}</span>
              </template>
              <template #cell(location)="data">
                <b-form-input v-model="editedItem.orderNo" v-if="editedItem?.id == data.item.id" type="text"
                  :formatter="upper" size="sm" />
                <span v-else>{{ data.item.location }}</span>
              </template>
              <template #cell(weight)="data">
                <b-form-input
                  v-model="editedItem.weight"
                  v-if="editedItem?.id == data.item.id"
                  type="number"
                  size="sm"
                />
                <span v-else>{{ data.item.weight }}</span>
              </template>
              <template #cell(invLength)="data">
                <b-form-input
                  v-model="editedItem.invLength"
                  v-if="editedItem?.id == data.item.id"
                  type="number"
                  size="sm"
                />
                <span v-else>{{ data.item.invLength }}</span>
              </template>
              <template #cell(invWidth)="data">
                <b-form-input
                  v-model="editedItem.invWidth"
                  v-if="editedItem?.id == data.item.id"
                  type="number"
                  size="sm"
                />
                <span v-else>{{ data.item.invWidth }}</span>
              </template>
              <template #cell(invHeight)="data">
                <b-form-input
                  v-model="editedItem.invHeight"
                  v-if="editedItem?.id == data.item.id"
                  type="number"
                  size="sm"
                />
                <span v-else>{{ data.item.invHeight }}</span>
              </template>
              <template #cell(cubes)="data">
                <span v-if="editedItem?.id == data.item.id">{{
                  editedCubes
                }}</span>
                <span v-else>{{ data.item.cubes }}</span>
              </template>
              <template #cell(unit)="data">
                <b-form-input
                  v-model="editedItem.unit"
                  v-if="editedItem?.id == data.item.id"
                  type="text"
                  :formatter="upper"
                  size="sm"
                  maxLength="4"
                />
                <span v-else>{{ data.item.unit }}</span>
              </template>
              <template #cell(account)="data">
                <b-form-input
                  v-model="editedItem.account"
                  v-if="editedItem?.id == data.item.id"
                  type="text"
                  :formatter="upper"
                  size="sm"
                  maxLength="6"
                />
                <span v-else>{{ data.item.account }}</span>
              </template>
              <template #cell(dest)="data">
                <b-form-input
                  v-model="editedItem.dest"
                  v-if="editedItem?.id == data.item.id"
                  type="text"
                  :formatter="upper"
                  size="sm"
                  maxLength="4"
                />
                <span v-else>{{ data.item.dest }}</span>
              </template>
              <template #cell(hazardous)="data">
                <b-form-checkbox
                  v-model="editedItem.hazardous"
                  v-if="editedItem?.id == data.item.id"
                />
                <b-form-checkbox
                  v-else
                  v-model="data.item.hazardous"
                  disabled
                />
              </template>
              <template #cell(casedDate)="data">
                <span v-if="editedItem?.id == data.item.id">
                  <b-form-datepicker
                    :title="df(editedItem.casedDate)"
                    v-model="dateForEdit"
                    size="sm"
                    today-button
                    button-only
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  >
                    <template v-slot:button-content>{{
                      df(editedItem.casedDate)
                    }}</template>
                  </b-form-datepicker>
                </span>
                <span v-else>{{ df(data.item.casedDate) }}</span>
              </template>
              <template #cell(shippedDate)="data">
                {{ df(data.item.shippedDate) }}
              </template>
              <template #cell(action)="data">
                <span v-if="showItemActions">
                  <b-button
                    v-if="canEdit(data.item)"
                    variant="light"
                    size="sm"
                    @click="edit(data.item)"
                  >
                    <font-awesome-icon size="sm" title="Edit" icon="edit" />
                  </b-button>
                  <b-button
                    v-if="canSave(data.item)"
                    :disabled="!validEdit"
                    variant="light"
                    size="sm"
                    @click="save"
                  >
                    <font-awesome-icon size="sm" title="Save" icon="save" />
                  </b-button>
                  <b-button
                    v-if="canCancel(data.item)"
                    variant="light"
                    size="sm"
                    @click="cancel"
                  >
                    <font-awesome-icon
                      size="sm"
                      title="Cancel"
                      icon="window-close"
                    />
                  </b-button>
                  <b-button
                    v-if="canAddToContainer(data.item)"
                    variant="light"
                    size="sm"
                    @click="addToContainer(data.item)"
                  >
                    <font-awesome-icon
                      size="sm"
                      title="Add to container"
                      icon="plus"
                    />
                  </b-button>
                  <b-button
                    v-if="canRemoveFromContainer(data.item)"
                    variant="light"
                    size="sm"
                    @click="removeFromContainer(data.item)"
                  >
                    <font-awesome-icon
                      size="sm"
                      title="Remove from container"
                      icon="minus"
                    />
                  </b-button>
                  <b-button
                    v-if="canShipContainer(data.item)"
                    variant="light"
                    size="sm"
                    @click="shipContainer(data.item)"
                  >
                    <font-awesome-icon
                      size="sm"
                      title="Ship container"
                      icon="plane-up"
                    />
                  </b-button>
                  <b-button
                    v-if="canReturnShipment(data.item)"
                    variant="light"
                    size="sm"
                    @click="returnShipment(data.item)"
                  >
                    <font-awesome-icon
                      size="sm"
                      title="Return shipment"
                      icon="rotate-left"
                    />
                  </b-button>
                </span>
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import textutils from "../../textutils";
import { mapActions, mapGetters } from "vuex";
import exportApi from "../../api/exportapi";
import dateutil from "../../dateutil";
import mailerApi from "../../api/mailer";
import reportsApi from "../../api/reportsapi";
import jsutils from "../../jsutils";

export default {
  data() {
    return {
      sortBy: 'casedDate',
      sortDesc: false,
      caseCode: null,
      orderNo: null,
      location: null,
      weight: null,
      invLength: null,
      invWidth: null,
      invHeight: null,
      unit: null,
      account: null,
      dest: null,
      hazardous: false,
      fields: [
        { key: "selected", label: "", sortable: false },
        { key: "caseCode", label: "Case ID", sortable: true },
        { key: "orderNo", label: "Order #", sortable: true },
        { key: "location", label: "Location", sortable: true },
        { key: "weight", label: "LBS", sortable: false },
        { key: "invLength", label: "L", sortable: false },
        { key: "invWidth", label: "W", sortable: false },
        { key: "invHeight", label: "H", sortable: false },
        { key: "cubes", label: "Ft", sortable: false },
        { key: "unit", label: "Unit", sortable: true },
        { key: "account", label: "Account", sortable: true },
        { key: "dest", label: "Dest", sortable: true },
        { key: "hazardous", label: "Haz", sortable: false },
        { key: "casedDate", label: "Cased", sortable: true },
        { key: "shippedContainer", label: "Container", sortable: true },
        { key: "shippedDate", label: "Shipped", sortable: true },
        { key: "action", label: "", sortable: false },
      ],
      searchCaseCode: null,
      searchOrderNo: null,
      searchLocation: null,
      searchUnit: null,
      searchAccount: null,
      searchDest: null,
      searchShippedContainer: null,
      includeShipped: false,
      searchResults: [],
      editedItem: null,
      dateForEdit: null,
      timeForEdit: null,
    };
  },
  watch: {
    dateForEdit(newVal, oldVal) {
      if (newVal != oldVal) {
        let test = dateutil.getUTCDateTime(
          `${this.dateForEdit} ${this.timeForEdit}`,
        );
        this.editedItem.casedDate = test;
      }
    },
    timeForEdit(newVal, oldVal) {
      if (newVal != oldVal) {
        let test = dateutil.getUTCDateTime(
          `${this.dateForEdit} ${this.timeForEdit}`,
        );
        this.editedItem.casedDate = test;
      }
    },
  },
  computed: {
    ...mapGetters("common", ["errorMessage"]),
    ...mapGetters("security", ["inRole"]),
    validInput() {
      return (
        this.caseCode &&
        this.orderNo &&
        this.location &&
        this.weight &&
        this.invLength &&
        this.invWidth &&
        this.invHeight &&
        this.cubes &&
        this.unit &&
        this.account &&
        this.dest
      );
    },
    editedCubes() {
      let inches = this.editedItem.invHeight *
        this.editedItem.invLength *
        this.editedItem.invWidth;
      return Math.ceil(inches / 1728);
    },
    cubes() {
      let inches = this.invHeight * this.invLength * this.invWidth;
      return Math.ceil(inches / 1728);
    },
    showItemActions() {
      return this.inRole("Export Inventory");
    },
    selectedItems() {
      return this.searchResults.filter((s) => s.selected);
    },
    resultCount() {
      return this.searchResults.length;
    },
    resultWeight() {
      let weights = this.searchResults.map((s) => s.weight);
      return weights.length > 0 ? weights.reduce((a, b) => (a += b)) : 0;
    },
    resultCubes() {
      let cubes = this.searchResults.map((s) => s.cubes);
      return cubes.length > 0 ? cubes.reduce((a, b) => (a += b)) : 0;
    },
    selectedWeight() {
      let weights = this.selectedItems.map((s) => s.weight);
      return weights.length > 0 ? weights.reduce((a, b) => (a += b)) : 0;
    },
    selectedCubes() {
      let cubes = this.selectedItems.map((s) => s.cubes);
      return cubes.length > 0 ? cubes.reduce((a, b) => (a += b)) : 0;
    },
    validEdit() {
      return (
        this.editedItem.caseCode &&
        this.editedItem.orderNo &&
        this.editedItem.location &&
        this.editedItem.weight &&
        this.editedItem.invLength &&
        this.editedItem.invWidth &&
        this.editedItem.invHeight &&
        this.editedItem.cubes &&
        this.editedItem.unit &&
        this.editedItem.account &&
        this.editedItem.dest &&
        this.editedItem.casedDate
      );
    },
  },
  methods: {
    ...mapActions("common", ["showLoadingDuringAction", "confirm"]),
    upper(text) {
      return textutils.toUpper(text);
    },
    df(ts) {
      return ts ? dateutil.getLocalDateTime(ts, "YYYY-MM-DD") : "";
    },
    checkKey(e) {
      if(e.key == "Enter") {
        this.searchInventory();
      }
    },
    reset() {
      this.caseCode = null;
      this.orderNo = null;
      this.location = null;
      this.weight = null;
      this.invLength = null;
      this.invWidth = null;
      this.invHeight = null;
      this.unit = null;
      this.account = null;
      this.dest = null;
      this.hazardous = false;
    },
    addInventory() {
      let i = {
        caseCode: this.caseCode,
        orderNo: this.orderNo,
        location: this.location,
        weight: this.weight,
        invLength: this.invLength,
        invWidth: this.invWidth,
        invHeight: this.invHeight,
        cubes: this.cubes,
        unit: this.unit,
        account: this.account,
        dest: this.dest,
        hazardous: this.hazardous,
      };
      let c = exportApi.create(i).then((res) => {
        let item = res.data.exportInventoryItem;
        this.$toasted.success(`Export ${item.caseCode} [${item.id}] added`);
        this.reset();
        this.searchInventory();
      });
      this.showLoadingDuringAction(c);
    },
    searchInventory() {
      let c = exportApi
        .search(
          this.searchCaseCode,
          this.searchOrderNo,
          this.searchLocation,
          this.searchUnit,
          this.searchAccount,
          this.searchDest,
          this.searchShippedContainer,
          this.includeShipped,
        )
        .then((res) => {
          let r = res.data.exportInventoryItems;
          r.forEach((s) => {
            s.selected = false;
          });
          this.searchResults = r;
        });
      this.showLoadingDuringAction(c);
    },
    async print() {
      let prints = [];
      let clone = [...this.searchResults];
      clone.sort((a,b) => {
        let r = a[this.sortBy].localeCompare(b[this.sortBy]);
        return this.sortDesc ? -r : r;
      });
      clone.forEach((i) => {
        let copy = JSON.parse(JSON.stringify(i));
        delete copy.selected;
        copy.casedDate = this.df(copy.casedDate);
        copy.shippedDate = this.df(copy.shippedDate);
        prints.push(copy);
      });
      let obj = {
        total: this.searchResults.length,
        weight: this.resultWeight,
        cubes: this.resultCubes,
        items: prints,
      };
      let xml = await mailerApi
        .render("exportInventoryReport.mustache", obj)
        .then((res) => res.data);
      let params = {
        fileName: "exportInventory.pdf",
        inline: true,
      };
      reportsApi.xmlToPdf(params, xml);
    },
    exportCSV() {
      let header = [
        "Case Code",
        "Location",
        "Weight",
        "Length",
        "Width",
        "Height",
        "Cubes",
        "OrderNo",
        "Unit",
        "Dest",
        "Account",
        "Hazardous",
        "Cased Date",
      ];
      let lines = [];
      lines.push("data:text/csv;charset=utf-8," + header.join(","));
      let clone = [...this.searchResults];
      clone.sort((a,b) => {
        let r = a[this.sortBy].localeCompare(b[this.sortBy]);
        return this.sortDesc ? -r : r;
      });
      clone.forEach((i) => {
        let tmp = [];
        tmp.push(`"${i.caseCode}"`);
        tmp.push(`"${i.location}"`);
        tmp.push(`${i.weight}`);
        tmp.push(`${i.invLength}`);
        tmp.push(`${i.invWidth}`);
        tmp.push(`${i.invHeight}`);
        tmp.push(`${i.cubes}`);
        tmp.push(`"${i.orderNo}"`);
        tmp.push(`"${i.unit}"`);
        tmp.push(`"${i.dest}"`);
        tmp.push(`"${i.account}"`);
        tmp.push(`"${i.hazardous ? "Y" : "N"}"`);
        tmp.push(`"${this.df(i.casedDate)}"`);
        lines.push(tmp.join(","));
      });
      let csv = lines.join("\n");
      jsutils.downloadText(csv, "export.csv");
    },
    exportOSLCSV() {
      let header = [
        "",
        "Pool Code",
        "Destination Code",
        "Carrier",
        "Order Number",
        "PSC",
        "Order Type",
        "Marketing Account",
        "OCU",
        "Interpack Indicator",
        "Hazardous Material Indicator",
        "Partial Ship Code",
        "Oldest Order Submit Date",
        "Lines",
        "Load Count",
        "Weight(Lb)",
        "Volume(Cu. Feet)",
      ];
      let lines = [];
      lines.push("data:text/csv;charset=utf-8," + header.join(","));
      let clone = [...this.searchResults];
      clone.sort((a,b) => {
        let r = a[this.sortBy].localeCompare(b[this.sortBy]);
        return this.sortDesc ? -r : r;
      });
      clone.forEach((i) => {
        let tmp = [];
        tmp.push(`"${i.caseCode}"`);
        tmp.push(`"OSL"`);
        tmp.push(`"${i.account}${i.dest}"`);
        tmp.push(``);
        tmp.push(`"${i.orderNo}"`);
        tmp.push(``);
        tmp.push(``);
        tmp.push(`"${i.account}"`);
        tmp.push(`"DY01"`);
        tmp.push(``);
        tmp.push(`"${i.hazardous ? "Y" : "N"}"`);
        tmp.push(``);
        tmp.push(`"${this.df(i.casedDate)}"`);
        tmp.push(``);
        tmp.push(``);
        tmp.push(`${i.weight}`);
        tmp.push(`${i.cubes}`);
        lines.push(tmp.join(","));
      });
      let csv = lines.join("\n");
      jsutils.downloadText(csv, "oslExport.csv");
    },
    edit(item) {
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.dateForEdit = dateutil.getLocalDateTime(
        this.editedItem.casedDate,
        "YYYY-MM-DD",
      );
      this.timeForEdit = dateutil.getLocalDateTime(
        this.editedItem.casedDate,
        "HH:mm:ss",
      );
    },
    save() {
      this.editedItem.cubes = this.editedCubes;
      let c = exportApi.update(this.editedItem);
      this.showLoadingDuringAction(c).then(() => {
        this.$toasted.success(`${this.editedItem.caseCode} updated`);
        this.editedItem = null;
        this.searchInventory();
      });
    },
    cancel() {
      this.editedItem = null;
    },
    ensureSelected(item) {
      if (!item.selected) {
        item.selected = true;
      }
    },
    addToContainer(item) {
      this.ensureSelected(item);
      let items = this.selectedItems.filter((i) => this.canAddToContainer(i));
      let containerName = window.prompt("Please enter the Container");
      if (containerName) {
        containerName = textutils.toUpper(containerName);
        this.doAction(
          `Are you sure you want to add ${items.length} items to container ${containerName}`,
          items,
          (i) => {
            i.shippedContainer = containerName;
            return exportApi.update(i);
          },
          `${items.length} items added to ${containerName}`,
        );
      }
    },
    removeFromContainer(item) {
      this.ensureSelected(item);
      let items = this.selectedItems.filter((i) =>
        this.canRemoveFromContainer(i),
      );
      this.doAction(
        `Are you sure you want to remove ${items.length} items from their container?`,
        items,
        (i) => {
          i.shippedContainer = null;
          return exportApi.update(i);
        },
        `${items.length} items removed from containers`,
      );
    },
    shipContainer(item) {
      this.ensureSelected(item);
      this.doAction(
        null,
        [item],
        (i) => {
          return exportApi.shipContainer(i.shippedContainer);
        },
        `${item.shippedContainer} shipped`,
      );
    },
    returnShipment(item) {
      this.ensureSelected(item);
      let items = this.selectedItems.filter((i) => this.canReturnShipment(i));
      this.doAction(
        `Are you sure you want to return ${items.length} shipments?`,
        items,
        (i) => {
          return exportApi.returnShipment(i.id);
        },
        `${items.length} items returned`,
      );
    },
    canEdit(item) {
      return !this.editedItem && !item.shippedDate;
    },
    canSave(item) {
      return this.editedItem?.id == item.id;
    },
    canCancel(item) {
      return this.editedItem?.id == item.id;
    },
    canAddToContainer(item) {
      return !item.shippedDate && !item.shippedContainer && !this.editedItem;
    },
    canRemoveFromContainer(item) {
      return !item.shippedDate && item.shippedContainer && !this.editedItem;
    },
    canShipContainer(item) {
      return !item.shippedDate && item.shippedContainer && !this.editedItem;
    },
    canReturnShipment(item) {
      return item.shippedDate && !this.editedItem;
    },
    async doAction(multipleQuestion, items, fn, successMsg) {
      let doIt = true;
      if (items.length > 1) {
        await this.$bvModal
          .msgBoxConfirm(multipleQuestion, {
            title: "Please Confirm",
          })
          .then((value) => {
            doIt = value;
          });
      }
      if (doIt) {
        let calls = [];
        items.forEach((i) => calls.push(fn(i)));
        this.showLoadingDuringAction(Promise.all(calls)).then(() => {
          this.selectedItems.forEach((i) => (i.selected = false));
          this.searchInventory();
          this.$toasted.success(successMsg);
        });
      }
    },
  },
};
</script>
