import {
  bays as baysApi,
  trailers as trailerApi,
  yard as yardApi,
} from "../../api/movementapi";
import Vue from "vue";

// initial state
const state = {
  selectedFacility: null,
  bays: null,
  stays: null,
  selectedItemForActions: null,
  selectedActionPanel: null,
  inboundDests: [],
  inboundContents: [],
  inboundOrigins: [],
};

const getters = {
  inboundDests(state) {
    return state.inboundDests;
  },
  inboundContents(state) {
    return state.inboundContents;
  },
  inboundOrigins(state) {
    return state.inboundOrigins;
  },
  selectedFacility(state) {
    return state.selectedFacility;
  },
  bays(state) {
    return state.bays;
  },
  stayForBay: (state) => (bay) => {
    return state.stays.find(
      (s) => s.trailer?.facilityLocation?.code == bay.code,
    );
  },
  yardForFacility(state) {
    let y = null;
    if (state != null && state.bays != null && state.selectedFacility != null) {
      y = state.bays.find(
        (b) => b.facility === state.selectedFacility && b.code === "YARD",
      );
    }
    return y;
  },
  groups(state) {
    let s = new Set();
    if (state != null && state.bays != null) {
      state.bays.forEach((b) => s.add(b.locGroup));
    }
    return s;
  },
  selectedItemForActions(state) {
    return state.selectedItemForActions;
  },
  selectedActionPanel(state) {
    return state.selectedActionPanel;
  },
  availableTrailers(state) {
    let r = [];
    if (state != null && state.stays != null) {
      r = state.stays.filter(
        (s) => !(s.trailer?.facilityLocation?.type === "BAY"),
      );
    }
    return r;
  },
};

const actions = {
  async assignTrailerToBay({ commit, getters, dispatch }, { t, n, w }) {
    let bay = getters.selectedItemForActions;
    if (bay != null) {
      dispatch("common/setErrorMessage", null, { root: true });
      dispatch("common/setLoading", true, { root: true });
      try {
        await baysApi.updateBayNote(bay, n);
        await baysApi.updateBayWorkers(bay, w);
        await trailerApi.setFacilityLocation(t.scac, t.id, bay);
        commit("setSelectedItemAndAction", { i: null, a: null });
      } catch (err) {
        dispatch("common/handleHttpError", err, {
          root: true,
        });
      } finally {
        dispatch("common/setLoading", false, { root: true });
      }
    }
  },
  async clearBay({ commit, getters, dispatch }) {
    let bay = getters.selectedItemForActions;
    if (bay != null) {
      let stay = getters.stayForBay(bay);
      let trailer = stay != null ? stay.trailer : null;
      let yard = getters.yardForFacility;

      if (trailer != null && yard != null) {
        let tApi = trailerApi.setFacilityLocation(
          trailer.scac,
          trailer.id,
          yard,
        );
        let bApi = trailerApi.setFacilityLocationNote(
          bay.facility,
          bay.code,
          null,
        );
        let all = Promise.all([tApi, bApi]).then(() =>
          commit("setSelectedItemAndAction", { i: null, a: null }),
        );
        return dispatch("common/showLoadingDuringAction", all, { root: true });
      }
    }
  },
  async updateBayWorkers({ dispatch, getters, commit }, workers) {
    return dispatch(
      "common/showLoadingDuringAction",
      baysApi.updateBayWorkers(getters.selectedItemForActions, workers),
      { root: true },
    ).then(() => {
      commit("setSelectedItemAndAction", { i: null, a: null });
    });
  },
  async setTrailerNote({ commit, dispatch }, { scac, id, note }) {
    return dispatch(
      "common/showLoadingDuringAction",
      trailerApi.setNote(scac, id, note),
      { root: true },
    ).then(() => {
      commit("setSelectedItemAndAction", { i: null, a: null });
    });
  },
  async updateBayNote({ commit, dispatch, getters }, note) {
    let bay = getters.selectedItemForActions;
    if (bay != null) {
      dispatch("common/setErrorMessage", null, { root: true });
      dispatch("common/setLoading", true, { root: true });
      return trailerApi
        .setFacilityLocationNote(bay.facility, bay.code, note)
        .then(() => {
          commit("setSelectedItemAndAction", { i: null, a: null });
        })
        .catch((err) => {
          dispatch("common/handleHttpError", err, {
            root: true,
          });
        })
        .finally(() => {
          dispatch("common/setLoading", false, { root: true });
        });
    }
  },
  async selectFacility({ commit }, facility) {
    commit("setSelectedFacility", facility);
  },
  async loadInitialData({ dispatch, getters, commit }) {
    dispatch("common/setErrorMessage", null, { root: true });
    dispatch("common/setLoading", true, { root: true });
    let inbDestsProm = yardApi
      .loadLookupInboundDests(getters.selectedFacility)
      .then((res) => commit("setInboundDests", res.data.payload));
    let inbContentsProm = yardApi
      .loadLookupInboundContents(getters.selectedFacility)
      .then((res) => commit("setInboundContents", res.data.payload));
    let inbOriginsProm = yardApi
      .loadLookupInboundOrigins(getters.selectedFacility)
      .then((res) => commit("setInboundOrigins", res.data.payload));
    let baysProm = baysApi.loadBays(getters.selectedFacility).then((res) => {
      commit("setBays", res.data.payload);
    });
    let staysProm = yardApi.loadStays(getters.selectedFacility).then((res) => {
      commit("setStays", res.data.payload);
    });
    return Promise.all([
      inbDestsProm,
      inbContentsProm,
      inbOriginsProm,
      baysProm,
      staysProm,
    ])
      .catch((err) => {
        dispatch("common/handleHttpError", err, {
          root: true,
        });
      })
      .finally(() => {
        dispatch("common/setLoading", false, { root: true });
      });
  },
  async showItemActions({ commit }, { i, a }) {
    commit("setSelectedItemAndAction", { i, a });
  },
  async updateTrailerStay({ commit }, mutation) {
    commit("updateTrailerStay", mutation);
  },
  async updateTrailer({ commit }, trailer) {
    commit("updateTrailer", trailer);
  },
  async updateBay({ commit }, mutation) {
    //facilityLocationCreated, facilityLocationUpdated, facilityLocationDeleted, facilityLocationNoteUpdated, facilityLocationWorkersUpdated
    switch (mutation.action) {
      case "facilityLocationUpdated":
        commit("updateBayNote", mutation.current);
        commit("updateBayWorkers", mutation.current);
        break;
      case "facilityLocationNoteUpdated":
        commit("updateBayNote", mutation.current);
        break;
      case "facilityLocationWorkersUpdated":
        commit("updateBayWorkers", mutation.current);
        break;
    }
  },
};

const mutations = {
  setInboundDests(state, data) {
    state.inboundDests = data;
  },
  setInboundContents(state, data) {
    state.inboundContents = data;
  },
  setInboundOrigins(state, data) {
    state.inboundOrigins = data;
  },
  setStays(state, stays) {
    state.stays = stays;
  },
  setSelectedFacility(state, facility) {
    state.selectedFacility = facility;
  },
  setBays(state, data) {
    state.bays = data;
  },
  setSelectedItemForActions(state, i) {
    state.selectedItemForActions = i;
  },
  setSelectedActionPanel(state, a) {
    state.selectedActionPanel = a;
  },
  setSelectedItemAndAction(state, { i, a }) {
    state.selectedItemForActions = i;
    state.selectedActionPanel = a;
  },
  updateTrailerStay(state, mutation) {
    if (mutation.current) {
      let stay = mutation.current;
      if (stay.facility == state.selectedFacility) {
        let idx = state.stays.map((s) => s.id).indexOf(stay.id);
        if (stay.endStay) {
          // remove
          if (idx > -1) {
            state.stays.splice(idx, 1);
          }
        } else {
          // add or update
          if (idx > -1) {
            Vue.set(state.stays, idx, stay);
          } else {
            state.stays.push(stay);
          }
        }
      }
    }
  },
  updateTrailer(state, data) {
    let stay = state.stays.find(
      (s) => s.trailer.scac == data.scac && s.trailer.id == data.id,
    );
    if (stay) {
      stay.trailer = data;
    }
  },
  updateBayNote(state, bay) {
    let idx = state.bays
      .map((b) => b.facility + b.code)
      .indexOf(bay.facility + bay.code);
    if (idx > -1) {
      let obs = state.bays[idx];
      obs.note = bay.note;
      obs.lastUpdatedAt = bay.lastUpdatedAt;
      obs.lastUpdatedBy = bay.lastUpdatedBy;
    }
  },
  updateBayWorkers(state, bay) {
    let idx = state.bays
      .map((b) => b.facility + b.code)
      .indexOf(bay.facility + bay.code);
    if (idx > -1) {
      let obs = state.bays[idx];
      obs.assignedWorkers = bay.assignedWorkers;
      obs.lastUpdatedAt = bay.lastUpdatedAt;
      obs.lastUpdatedBy = bay.lastUpdatedBy;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
