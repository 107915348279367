<template>
  <b-card class="myCard" style="padding: 5px">
    <b-card>
      <b-card-header>
        <b-row>
          <b-col md="1">Origin</b-col>
          <b-col md="2">Activity</b-col>
          <b-col md="2">Note</b-col>
          <b-col md="2">Trailer</b-col>
          <b-col md="1">Dest</b-col>
          <b-col md="2">Wait / Start / End / Duration</b-col>
          <b-col md="2">
            <span v-show="workDayStarted">
              T {{ displayTotalTime }} | A {{ displayActivityTime }} | W
              {{ displayWaitTime }}
            </span>
          </b-col>
        </b-row>
      </b-card-header>
    </b-card>
    <b-card v-show="showRoutes">
      <b-card-body
        body-class="myCardBody"
        style="padding-top: 0px; padding-bottom: 0px"
      >
        <transition-group name="fade-left">
          <template v-for="route in filteredRoutes">
            <RoutePanelForDriver
              :route="route"
              :driver="driver"
              :key="route.id"
            />
          </template>
        </transition-group>
      </b-card-body>
    </b-card>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import RoutePanelForDriver from "./RoutePanelForDriver.vue";
import {
  faCaretSquareUp,
  faCaretSquareDown,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export default {
  components: {
    RoutePanelForDriver,
  },
  data() {
    return {
      showRoutes: true,
      timerHandle: null,
      totalTime: moment.duration(0),
      activityTime: moment.duration(0),
      waitTime: moment.duration(0),
      hideEnded: false,
    };
  },
  props: {
    workDay: Object,
  },
  computed: {
    ...mapGetters("common", ["errorMessage", "loading"]),
    ...mapGetters("routes", [
      "getDriver",
      "routesForDriver",
      "readOnly",
      "globalHideEnded",
    ]),
    driver() {
      return this.workDay != null
        ? this.getDriver(this.workDay.driver.id)
        : null;
    },
    routes() {
      return this.routesForDriver(this.driver);
    },
    filteredRoutes() {
      let f = this.routes;
      if (this.hideEnded) {
        f = f.filter((x) => x.endTime == null);
      }
      return f;
    },
    showRoutesIcon() {
      return this.showRoutes ? faCaretSquareUp : faCaretSquareDown;
    },
    showRoutesTitle() {
      return this.showRoutes ? "Hide Routes" : "Show Routes";
    },
    workDayStarted() {
      return this.routes.find((r) => r.startTime != null) != null;
    },
    displayTotalTime() {
      return this.totalTime.format("h:mm");
    },
    displayActivityTime() {
      return this.activityTime.format("h:mm");
    },
    displayWaitTime() {
      return this.waitTime.format("h:mm");
    },
  },
  watch: {
    routes(newVal) {
      if (newVal != null) {
        this.updateDurations();
      }
    },
    globalHideEnded(newVal) {
      this.hideEnded = newVal;
    },
  },
  methods: {
    ...mapActions("routes", [
      "setSelectedDriverWorkDay",
      "setSelectedDriverRoute",
    ]),
    toggleHideEnded() {
      this.hideEnded = !this.hideEnded;
    },
    toggleShowRoutes() {
      this.showRoutes = !this.showRoutes;
    },
    updateDurations() {
      let tmpTotal = moment.duration(0);
      let tmpActivity = moment.duration(0);
      let tmpWait = moment.duration(0);
      let now = moment();
      if (this.routes != null) {
        this.routes.forEach((r, idx) => {
          if (r.startTime != null) {
            let start = moment(r.startTime);
            if (idx > 0) {
              let pr = this.routes[idx - 1];
              let end = moment(pr.endTime);
              let wait = moment.duration(start.diff(end));
              tmpTotal.add(wait);
              tmpWait.add(wait);
            }
            let end = r.endTime != null ? moment(r.endTime) : now;
            let act = moment.duration(end.diff(start));
            tmpTotal.add(act);
            tmpActivity.add(act);
          }
        });
      }
      this.totalTime = tmpTotal;
      this.activityTime = tmpActivity;
      this.waitTime = tmpWait;
    },
  },
  mounted() {
    this.timerHandle = window.setInterval(this.updateDurations, 1000 * 60);
  },
  beforeDestroy() {
    window.clearInterval(this.timerHandle);
  },
};
</script>

<style scoped>
.card-header {
  padding-top: 0px;
  padding-bottom: 0px;
}
.card-body {
  padding: 0em;
}
.btn {
  background-color: lightgrey;
}
.btn:hover {
  background-color: lightgreen;
}
</style>
