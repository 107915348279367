<template>
  <b-modal
    id="ediAssignTender"
    title="Assign Shipment to Driver"
    size="lg"
    @show="resetModal"
    @hide="onHide"
    @shown="onShown"
    ok-only
  >
    <template v-if="selectedTender != null">
      <b-row>
        <b-col
          ><h6>
            {{ selectedTender.partner }} {{ selectedTender.shipmentId }}
          </h6></b-col
        >
      </b-row>
      <b-row>
        <b-col><strong>Trailer:</strong> {{ trailerDesc }}</b-col>
      </b-row>
      <b-row>
        <b-col><strong>From:</strong></b-col>
        <b-col><strong>To:</strong></b-col>
      </b-row>
      <b-row>
        <b-col>
          <span :class="codeClass(shipFromDestination)"
            >CODE: {{ code(shipFromDestination, shipFrom) }}</span
          ><br />
          {{ name(shipFrom) }}<br />
          {{ address1(shipFrom) }}<br />
          <span v-if="hasAddress2(shipFrom)">
            {{ address2(shipFrom) }}<br />
          </span>
          {{ city(shipFrom) }},
          {{ state(shipFrom) }}
          {{ zip(shipFrom) }}
        </b-col>
        <b-col>
          <span :class="codeClass(shipToDestination)"
            >CODE: {{ code(shipToDestination, shipTo) }}</span
          ><br />
          {{ name(shipTo) }}<br />
          {{ address1(shipTo) }}<br />
          <span v-if="hasAddress2(shipTo)"> {{ address2(shipTo) }}<br /> </span>
          {{ city(shipTo) }},
          {{ state(shipTo) }}
          {{ zip(shipTo) }}
        </b-col>
      </b-row>
      <b-row
        ><b-col><hr /></b-col
      ></b-row>
      <b-row>
        <b-col> Assign to: </b-col>
        <b-col>
          <select
            ref="workDaySelect"
            v-model="workDay"
            :options="availableWorkDays"
          >
            <option v-for="d in availableWorkDays" :key="d">
              {{ d }}
            </option></select
          ><br />
          <div v-for="dwd in driverWorkDays" :key="dwd.id">
            <span class="clickable" @click="driverClicked(dwd)"
              >{{ dwd.driver.firstName }} {{ dwd.driver.lastName }}</span
            >
          </div>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import dateutil from "../../dateutil";
import textutils from "../../textutils";
import { driverroutes, trailers } from "../../api/movementapi";

export default {
  data() {
    return {
      shipFromDestination: null,
      shipToDestination: null,
      trailer: null,
      workDay: null,
      driverWorkDays: [],
    };
  },
  computed: {
    ...mapGetters("edi", ["selectedTender"]),
    availableWorkDays() {
      let d = [];
      for (let i = 0; i < 2; i++) {
        let tmp = dateutil.localNow().subtract(i, "days").format("YYYY-MM-DD");
        d.push(tmp);
      }
      return d;
    },
    shipFrom() {
      return this?.selectedTender?.shipmentJson?.stops[0]?.shipFrom;
    },
    shipTo() {
      return this?.selectedTender?.shipmentJson?.stops[1]?.shipTo;
    },
    trailerDesc() {
      return this.trailer != null
        ? `${this.trailer.scac} ${this.trailer.id}`
        : "UNKNOWN";
    },
  },
  watch: {
    workDay(newVal) {
      if (newVal) {
        let c = this.getDriverWorkDaysForWorkDay(this.workDay).then((r) => {
          this.driverWorkDays = r;
        });
        this.showLoadingDuringAction(c);
      }
    },
  },
  methods: {
    ...mapActions("common", ["showLoadingDuringAction"]),
    ...mapActions("edi", ["getDriverWorkDaysForWorkDay"]),
    ...mapActions("routes", ["addRoute"]),
    resetModal() {
      this.shipFromDestination = null;
      this.shipToDestination = null;
      this.trailer = null;
      this.workDay = null;
      this.driverWorkDays = [];
    },
    onHide() {
      this.resetModal();
    },
    async driverClicked(dwd) {
      let r = { driverWorkDay: dwd };
      r.startLoc = this.shipFromDestination.code;
      r.endLoc = this.shipToDestination.code;
      r.activity = "TRANSITING";
      r.billable = true;
      r.edi204Id = this.selectedTender.id;
      r.edi204ShipmentId = this.selectedTender.shipmentId;
      if (this.trailer != null) {
        r.trailer = this.trailer;
      }
      await this.addRoute(r);
      this.$bvModal.hide("ediAssignTender");
    },
    async onShown() {
      this.resetModal();
      this.workDay = this.availableWorkDays[0];
      this.$refs.workDaySelect.focus();
      if (this.selectedTender && this.shipFrom && this.shipTo) {
        let a = driverroutes
          .findDestinationByName(this.shipFrom.name)
          .then((r) => {
            if (r.data.payload && r.data.payload[0]) {
              this.shipFromDestination = r.data.payload[0];
            }
          });
        let b = driverroutes
          .findDestinationByName(this.shipTo.name)
          .then((r) => {
            if (r.data.payload && r.data.payload[0]) {
              this.shipToDestination = r.data.payload[0];
            }
          });
        await Promise.all([a, b]);
        if (
          textutils.toUpper(this.selectedTender.shipmentJson?.equipment?.id) !=
            "ZZZZ" &&
          textutils.toUpper(this.selectedTender.shipmentJson?.equipment?.id) !=
            "LIVE" &&
          textutils.toUpper(this.selectedTender.shipmentJson?.equipment?.id) !=
            "LTL"
        ) {
          try {
            await trailers
              .findTrailer(
                "QUCF",
                this.selectedTender.shipmentJson?.equipment?.id,
              )
              .then((r) => (this.trailer = r.data.payload));
          } catch (err) {}
          if (this.trailer == null) {
            try {
              await trailers
                .findTrailer(
                  "DERK",
                  this.selectedTender.shipmentJson?.equipment?.id,
                )
                .then((r) => (this.trailer = r.data.payload));
            } catch (err) {}
          }
        }
      }
    },
    codeClass(destination) {
      return destination ? "" : "noCodeFound";
    },
    code(destination, party) {
      return destination
        ? destination.code
        : `NO ENTRY FOUND FOR ${party.name}`;
    },
    name(party) {
      return party?.name;
    },
    address1(party) {
      return party?.address1;
    },
    address2(party) {
      return party?.address2;
    },
    hasAddress2(party) {
      return party.address2 ? true : false;
    },
    city(party) {
      return party?.city;
    },
    state(party) {
      return party?.state;
    },
    zip(party) {
      return party?.zip;
    },
  },
};
</script>
