import { shipmentApi, lookups, lineApi } from "../../api/manifest";

function blankLine() {
  return {
    id: null,
    destinationCode: null,
    billToCode: null,
    outCarrierCode: null,
    comments: null,
    orderNo: null,
    typeOfGoodsCode: null,
    quantityShipped: null,
    shippingWeight: null,
    poolCode: null,
    additionalOrders: null,
    deliveryNumber: null,
    overShort: null,
    damaged: null,
    damageDesc: null,
    inbBillingType: null,
    inbBillingCode: null,
    inbBillingCharges: null,
    inbBillingBol: null,
    inbBillingCarrierCode: null,
  };
}

const state = {
  shipment: null,
  lines: [],
  parcels: [],
  shipmentTypes: [],
  currentLine: blankLine(),
  currentLineIdx: -1,
};

const getters = {
  shipment: (state) => {
    return state.shipment;
  },
  shipmentSupplierPieces: (state) => {
    return state.lines.map((l) => l.quantityShipped).reduce((t, q) => t + q, 0);
  },
  shipmentReceivedPieces: (state) => {
    return state.lines.map((l) => l.pieces).reduce((t, q) => t + q, 0);
  },
  shipmentPiecesManifested: (state) => {
    return state.lines
      .filter((l) => l.manifestNo != null)
      .map((l) => l.pieces)
      .reduce((t, q) => t + q, 0);
  },
  shipmentParcelCount: (state) => {
    return state.parcels.map((o) => o.parcels).reduce((a, p) => a.concat(p), [])
      .length;
  },
  shipmentParcelCountVerified: (state) => {
    return state.parcels
      .map((o) => o.parcels)
      .reduce((a, p) => a.concat(p), [])
      .filter((x) => x.manifestNo != null).length;
  },
  shipmentWeight: (state) => {
    return state.lines.map((l) => l.shippingWeight).reduce((a, l) => a + l, 0);
  },
  lines: (state) => {
    return state.lines;
  },
  shipmentTypes: (state) => {
    return state.shipmentTypes;
  },
  parcels: (state) => {
    return state.parcels;
  },
  lineParcels: (state) => (lineId) => {
    let o = state.parcels.find((t) => t.id == lineId);
    return o != null ? o.parcels : [];
  },
  lineParcelCount: (state) => (lineId) => {
    let o = state.parcels.find((t) => t.id == lineId);
    return o != null ? o.parcels.length : 0;
  },
  lineParcelCountVerified: (state) => (lineId) => {
    let o = state.parcels.find((t) => t.id == lineId);
    return o != null ? o.parcels.filter((p) => p.manifestNo != null).length : 0;
  },
  lineNotStaged: (state) => (lineId) => {
    let o = state.parcels.find((t) => t.id == lineId);
    return o != null
      ? o.parcels.find((p) => p.location == null) != null
      : false;
  },
  currentLine: (state) => {
    return state.currentLine;
  },
  currentLineIdx: (state) => {
    return state.currentLineIdx;
  },
};

const actions = {
  async loadShipmentTypes({ commit }) {
    lookups.loadShipmentTypes().then((res) => {
      commit("setShipmentTypes", res.data.payload);
    });
  },
  async loadShipment({ dispatch, commit, getters }, { id, lineId }) {
    if (id != null) {
      commit("reset", null);
      let shipmentPrm = shipmentApi.loadShipment(id).then((res) => {
        commit("setShipment", res.data);
      });
      let linesPrm = shipmentApi.loadShipmentLines(id).then((res) => {
        res.data.payload.forEach((s) => commit("addLine", s));
      });
      let parcelsPrm = shipmentApi.loadShipmentParcels(id).then((res) => {
        res.data.payload.forEach((s) => commit("addParcel", s));
      });
      let call = Promise.all([parcelsPrm, linesPrm, shipmentPrm]).then(() => {
        if (getters.lines.length > 0) {
          if (lineId) {
            let line = getters.lines.find((l) => l.id == lineId);
            if (line) {
              dispatch("setCurrentLine", line);
            }
          } else {
            dispatch("setCurrentIdx", 0);
          }
        }
      });
      return dispatch("common/showLoadingDuringAction", call, { root: true });
    }
  },
  async setCurrentLine({ commit, getters }, line) {
    commit("setCurrentLine", line != null ? line : blankLine());
    if (line != null) {
      let idx = getters.lines.map((l) => l.id).indexOf(line.id);
      commit("setCurrentLineIdx", idx);
    } else {
      commit("setCurrentLineIdx", -1);
    }
  },
  async setCurrentIdx({ commit, getters }, idx) {
    commit("setCurrentLineIdx", idx);
    if (idx > -1 && idx < getters.lines.length) {
      commit("setCurrentLine", getters.lines[idx]);
    } else {
      commit("setCurrentLine", blankLine());
    }
  },
  async previousLine({ dispatch, getters }) {
    if (getters.currentLineIdx > 0) {
      dispatch("setCurrentIdx", getters.currentLineIdx - 1);
    }
  },
  async nextLine({ dispatch, getters }) {
    if (getters.currentLineIdx + 1 < getters.lines.length) {
      dispatch("setCurrentIdx", getters.currentLineIdx + 1);
    }
  },
  async acceptShipment({ commit, dispatch, getters }) {
    let call = shipmentApi.acceptShipment(getters.shipment.id).then((res) => {
      commit("setShipment", res.data.payload);
      this.$app.$toasted.success(`${getters.shipment.shipmentName} accepted`);
    });
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  newLine({ commit, getters }) {
    if (getters.shipment != null) {
      let l = blankLine();
      l.incomingShipmentHeaderId = getters.shipment.id;
      l.facility = getters.shipment.facility;
      commit("setCurrentLineIdx", -1);
      commit("setCurrentLine", l);
    }
  },
  async saveCurrentLine({ commit, dispatch, getters }) {
    let call = null;
    if (getters.currentLine) {
      if (getters.currentLine.id != null) {
        call = lineApi.saveLine(getters.currentLine).then(() => {
          this.$app.$toasted.success(
            `${getters.currentLine.id} ${getters.currentLine.orderNo} saved`,
          );
          return getters.currentLine;
        });
      } else {
        call = lineApi.createLine(getters.currentLine).then((res) => {
          let l = res.data.payload;
          commit("addLine", l);
          this.$app.$toasted.success(`${l.id} ${l.orderNo} created`);
          return l;
        });
      }
      return dispatch("common/showLoadingDuringAction", call, { root: true });
    }
  },
  async deleteCurrentLine({ commit, dispatch, getters }) {
    if (getters.currentLine != null && getters.currentLine.id != null) {
      let call = lineApi.deleteLine(getters.currentLine.id).then((res) => {
        let l = res.data.payload;
        commit("removeLine", l.id);
        this.$app.$toasted.success(`${l.id} ${l.orderNo} deleted`);
        return l;
      });
      return dispatch("common/showLoadingDuringAction", call, { root: true });
    }
  },
};

const mutations = {
  reset(state, t) {
    state.shipment = t;
    state.lines = [];
    state.parcels = [];
    state.currentLine = blankLine();
    state.currentLineIdx = -1;
  },
  setShipment(state, o) {
    state.shipment = o;
  },
  setShipmentTypes(state, o) {
    state.shipmentTypes = o;
  },
  addLine(state, line) {
    if (state.lines.map((l) => l.id).indexOf(line.id) < 0) {
      state.lines.push(line);
      let o = state.parcels.find((t) => t.id == line.id);
      if (o == null) {
        state.parcels.push({ id: line.id, parcels: [] });
      }
    }
  },
  removeLine(state, lineId) {
    let idx = state.lines.map((l) => l.id).indexOf(lineId);
    if (idx > -1) {
      state.lines.splice(idx, 1);
      idx = state.parcels.map((t) => t.id).indexOf(lineId);
      if (idx > -1) {
        state.parcels.splice(idx, 1);
      }
    }
  },
  addParcel(state, parcel) {
    let o = state.parcels.find((t) => t.id == parcel.incomingShipmentId);
    if (o == null) {
      o = { id: parcel.incomingShipmentId, parcels: [] };
      state.parcels.push(o);
    }
    if (
      o.parcels
        .map((p) => `${p.facility}_${p.externalId}`)
        .indexOf(`${parcel.facility}_${parcel.externalId}`) < 0
    ) {
      o.parcels.push(parcel);
    }
  },
  setCurrentLine(state, line) {
    state.currentLine = line;
  },
  setCurrentLineIdx(state, idx) {
    state.currentLineIdx = idx;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
