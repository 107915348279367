<template>
  <BaseModal
    :selectedItemForActions="selectedItemForActions"
    :selectedActionPanel="selectedActionPanel"
    :showItemActions="showItemActions"
    :title="title"
    actionPanel="note"
    id="bays-bay-note-modal"
    @shown="doShown"
  >
    <div>
      <div class="container">
        <div class="row">
          <div class="col">Note:</div>
          <div class="col col-10">
            <div class="input-group mb-3">
              <textarea
                class="form-control text-uppercase"
                ref="focusMe"
                type="text"
                v-model.trim="noteInput"
                cols="3"
              />
              <div class="input-group-append">
                <button
                  @click="doClearNote"
                  :disabled="noteInput == null || noteInput.trim().length == 0"
                  class="btn btn-outline-secondary"
                  type="button"
                >
                  &times;
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col">
            <button @click="doUpdateNote" type="button" class="btn btn-primary">
              Update Note
            </button>
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BaseModal from "../common/BaseModal.vue";

export default {
  components: {
    BaseModal,
  },
  data() {
    return {
      noteInput: null,
    };
  },
  computed: {
    ...mapGetters("bays", ["selectedItemForActions", "selectedActionPanel"]),
    title() {
      return `${this.facility} ${this.type} ${this.code}`;
    },
    facility() {
      return this.selectedItemForActions != null
        ? this.selectedItemForActions.facility
        : null;
    },
    type() {
      return this.selectedItemForActions != null
        ? this.selectedItemForActions.type
        : null;
    },
    code() {
      return this.selectedItemForActions != null
        ? this.selectedItemForActions.code
        : null;
    },
  },
  watch: {
    selectedItemForActions(newVal) {
      if (newVal != null) {
        this.noteInput = newVal.note;
      }
    },
    noteInput(newVal) {
      if (newVal != null) {
        this.noteInput = newVal.toUpperCase();
      }
    },
  },
  methods: {
    ...mapActions("bays", ["showItemActions", "updateBayNote"]),
    doUpdateNote() {
      this.updateBayNote(this.noteInput);
    },
    doClearNote() {
      this.noteInput = null;
    },
    doShown() {
      window.setTimeout(() => this.$refs.focusMe.focus(), 100);
    },
  },
};
</script>

<style scoped></style>
