<template>
  <table class="table table-bordered table-sm">
    <tbody>
      <tr
        v-for="trailer in trailers"
        :key="trailer.scac + trailer.id"
        style="text-align: center"
      >
        <td
          @click="emitTrailerSelected(trailer, 'redTag')"
          style="font-size: smaller; min-width: 15px; padding: 0px"
          class="trailerName"
        >
          <TrailerNameLabel :trailer="trailer" />
        </td>
        <td
          @click="emitTrailerSelected(trailer, 'location')"
          style="font-size: smaller; min-width: 15px; padding: 0px"
        >
          <TrailerLocationDisplay :trailer="trailer" />
        </td>
        <td
          @click="emitTrailerSelected(trailer, 'status')"
          style="font-size: smaller; min-width: 15px; padding: 2px"
        >
          <TrailerLoadedDisplay :trailer="trailer" />
        </td>
        <td
          @click="emitTrailerSelected(trailer, 'note')"
          style="font-size: smaller; min-width: 15px; padding: 0px"
        >
          <TrailerNoteDisplay :trailer="trailer" />
        </td>
        <td style="font-size: smaller; min-width: 15px; padding: 0px">
          <TrailerLastUpdateDisplay :trailer="trailer" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import TrailerNameLabel from "./TrailerNameLabel.vue";
import TrailerLastUpdateDisplay from "./TrailerLastUpdateDisplay.vue";
import TrailerLocationDisplay from "./TrailerLocationDisplay.vue";
import TrailerLoadedDisplay from "./TrailerLoadedDisplay.vue";
import TrailerNoteDisplay from "./TrailerNoteDisplay.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TrailerColumn",
  components: {
    TrailerNameLabel,
    TrailerLastUpdateDisplay,
    TrailerLocationDisplay,
    TrailerLoadedDisplay,
    TrailerNoteDisplay,
  },
  props: {
    trailers: Array,
  },
  computed: {
    ...mapGetters("trailers", ["activeRoute"]),
  },
  methods: {
    ...mapActions({
      showTrailerActions: "trailers/showTrailerActions",
    }),
    emitTrailerSelected(trailer, action) {
      if (action == "location") {
        let r = this.activeRoute(trailer);
        if (r) {
          alert(
            `${r.driverWorkDay.driver.firstName} ${r.driverWorkDay.driver.lastName} is using ${trailer.scac} ${trailer.id} for Route ${r.driverWorkDay.workDate} #${r.routeNo}, you cannot change the location here until the route is complete.`,
          );
          return;
        }
      }
      this.showTrailerActions({ t: trailer, a: action });
    },
  },
};
</script>
