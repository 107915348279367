<template>
  <span>
    [Empty: ADC {{ adcCount }}, MTJOY {{ mtJoyCount }}, MTJDRAY
    {{ mtJoyDrayCount }}, PDC {{ pdcCount }}, XPAC {{ xpacCount }}]
  </span>
</template>

<script>
export default {
  name: "EmptyTrailerDisplay",
  props: {
    trailers: Array,
  },
  data() {
    return {
      xpacLocations: ["525", "808", "824", "803", "801"],
    };
  },
  computed: {
    adcCount() {
      return this.trailers.filter(
        (t) =>
          t.status === "EMPTY" &&
          t.facilityLocation &&
          t.facilityLocation.facility === "ADC",
      ).length;
    },
    mtJoyCount() {
      return this.trailers.filter(
        (t) =>
          t.status === "EMPTY" &&
          t.facilityLocation &&
          t.facilityLocation.facility === "MTJOY",
      ).length;
    },
    mtJoyDrayCount() {
      return this.trailers.filter(
        (t) =>
          t.status === "EMPTY" &&
          t.facilityLocation &&
          t.facilityLocation.facility === "MTJDRAY",
      ).length;
    },
    pdcCount() {
      return this.trailers.filter(
        (t) => t.status === "EMPTY" && t.externalLocation === "PDC",
      ).length;
    },
    xpacCount() {
      return this.trailers.filter(
        (t) =>
          t.status === "EMPTY" &&
          t.externalLocation &&
          this.xpacLocations.indexOf(t.externalLocation) > -1,
      ).length;
    },
  },
};
</script>
