import { trailers as trailersApi } from "../../api/movementapi";
import { globalApi } from "../../api/manifest";

// initial state
const state = {
  loading: false,
  errorMessage: null,
  defaultFacility: null,
  globalNotificationMessage: null,
  messagingConnected: false,
};

// getters
const getters = {
  loading(state) {
    return state.loading;
  },
  errorMessage(state) {
    return state.errorMessage;
  },
  defaultFacility(state) {
    return state.defaultFacility;
  },
  globalNotificationMessage(state) {
    return state.globalNotificationMessage;
  },
  messagingConnected(state) {
    return state.messagingConnected;
  },
};

// actions
const actions = {
  setMessagingConnected({ commit }, connected) {
    commit("setMessagingConnected", connected);
  },
  setErrorMessage({ commit }, msg) {
    commit("setErrorMessage", msg);
  },
  setLoading({ commit }, msg) {
    commit("setLoading", msg);
  },
  setDefaultFacility({ commit }, facility) {
    commit("setDefaultFacility", facility);
  },
  handleHttpError({ commit }, err) {
    if (err.response) {
      if (err.response.data) {
        if (err.response.data.message) {
          console.log("response.data.message: " + err.response.data.message);
          commit("setErrorMessage", err.response.data.message);
        } else if (err.response.data.errorMessage) {
          console.log("response.data: " + err.response.data.errorMessage);
          commit("setErrorMessage", err.response.data.errorMessage);
        } else {
          console.log("response.data: " + err.response.data);
          commit("setErrorMessage", err.response.data);
        }
      } else {
        console.log("response: " + err.response);
      }
    } else {
      console.log("err: " + err);
      commit("setErrorMessage", err.message);
    }
  },
  async _blockDuringSync({ dispatch }, promise) {
    if (getters.selectedStayForActions) {
      dispatch("common/setErrorMessage", null, { root: true });
      dispatch("common/setLoading", true, { root: true });
      promise
        .catch((err) => {
          dispatch("common/handleHttpError", err, {
            root: true,
          });
        })
        .finally(() => {
          dispatch("common/setLoading", false, { root: true });
        });
    }
  },
  async updateTrailerStatus({ dispatch }, { trailer, status }) {
    dispatch(
      "_blockDuringSync",
      trailersApi.setStatus(trailer.scac, trailer.id, status),
    );
  },
  async showLoadingDuringAction({ dispatch }, actionPromise) {
    await dispatch("common/setErrorMessage", null, { root: true });
    await dispatch("common/setLoading", true, { root: true });
    return actionPromise
      .catch((err) => {
        dispatch("common/handleHttpError", err, {
          root: true,
        });
      })
      .finally(() => {
        dispatch("common/setLoading", false, { root: true });
      });
  },
  async initGlobals({ commit }) {
    globalApi.getNotificationMessage().then((res) => {
      commit("setGlobalNotificationMessage", res.data.payload);
    });
  },
  handleGlobalMessage({ commit }, msg) {
    let notifMsg = JSON.parse(JSON.stringify(JSON.parse(msg.body)));
    if (notifMsg != null) {
      commit("setGlobalNotificationMessage", notifMsg);
    }
  },
};

// mutations
const mutations = {
  setMessagingConnected(state, connected) {
    state.messagingConnected = connected;
  },
  setLoading(state, l) {
    state.loading = l;
  },
  setErrorMessage(state, msg) {
    state.errorMessage = msg;
  },
  setDefaultFacility(state, facility) {
    state.defaultFacility = facility;
  },
  setGlobalNotificationMessage(state, msg) {
    state.globalNotificationMessage = msg;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
