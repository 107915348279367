import store from "../store";

var API_URL = null;
try {
  // eslint-disable-next-line no-undef
  API_URL = EDI_API_URL;
} catch (e) {
  API_URL = "http://ediapi.home";
}

export default {
  async generateRunWeeklyEdiLink(ediType, production, clearToday) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/EDI/weekly?ediType=${ediType}&production=${production}&clearToday=${clearToday}&by=${store.getters["security/userName"]}`;
    return url;
  },
};
