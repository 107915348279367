<template>
  <b-modal
    id="add-manifest-to-stop-modal"
    ref="form"
    title="Add Manifest to Stop"
    size="lg"
    @show="resetModal"
    @hidden="resetModal"
    @shown="shown"
  >
    <div v-show="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <b-row>
      <b-col md="11">
        <b-form-group
          label="Manifest #:"
          label-cols-md="2"
          label-for="manifest-input"
        >
          <b-form-input
            id="manifest-input"
            ref="manifestNoInput"
            v-model="manifestNo"
            trim
            number
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="1">
        <b-button variant="light" size="md" @click="addManifestNo">
          <font-awesome-icon title="Add Manifest to Stop" icon="plus" />
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <table class="table">
        <thead>
          <tr>
            <th>Manifest</th>
            <th colspan="3">Date</th>
            <th colspan="2">Ship To</th>
            <th>Carrier</th>
            <th>Trailer</th>
            <th class="text-right">Lines</th>
            <th class="text-right">Pieces</th>
            <th class="text-right">Weight</th>
            <th class="text-right">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="m in openManifests"
            class="row-striped"
            :key="m.manifestNo"
          >
            <td>{{ m.manifestNo }}</td>
            <td colspan="3">{{ formatDate(m.manifestDate) }}</td>
            <td colspan="2">{{ upper(m.shipToCode) }}</td>
            <td>{{ upper(m.carrierCode) }}</td>
            <td>{{ upper(m.trailerNo) }}</td>
            <td class="text-right">{{ m.lines }}</td>
            <td class="text-right">{{ m.pieces }}</td>
            <td class="text-right">{{ m.weight }}</td>
            <td class="text-right">
              <b-button variant="light" size="md" @click="addOpenManifest(m)">
                <font-awesome-icon title="Add Manifest to Stop" icon="plus" />
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </b-row>
  </b-modal>
</template>

<script>
import dateutil from "../../../dateutil";
import textutil from "../../../textutils";
import indexApi from "../../../api/indexapi";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    stop: Object,
    bol: Object,
  },
  data() {
    return {
      manifestNo: null,
      openManifests: [],
      search_query: `{"query":{"bool":{"filter":{"bool":{"filter":[{"term":{"facility":"@FACILITY@"}},{"term":{"shipto_code":"@SHIPTOCODE@"}},{"range":{"manifest_date":{"gte":"@DATE@"}}},{"bool":{"should":[{"bool":{"must_not":{"exists":{"field":"bol_no"}}}}]}}]}}}},"_source":false,"aggs":{"manifest":{"terms":{"field":"manifest_no","size":100},"aggs":{"pieces":{"sum":{"field":"line_pieces","missing":0}},"weight":{"sum":{"field":"line_weight","missing":0}},"manifest_date":{"terms":{"field":"manifest_date"}},"shipto_code":{"terms":{"field":"shipto_code"}},"modifiedby":{"terms":{"field":"manifest_modified_by"}},"trailer":{"terms":{"field":"manifest_trailer"}},"carrier_code":{"terms":{"field":"manifest_carrier_code"}},"manifest_date_modified":{"terms":{"field":"manifest_date_modified"}},"line_date_modified":{"max":{"field":"line_date_modified","missing":0}},"line_count":{"value_count":{"field":"line_id"}}}}},"size":0}`,
    };
  },
  computed: {
    ...mapGetters("common", ["errorMessage"]),
  },
  methods: {
    ...mapActions("common", ["showLoadingDuringAction", "setErrorMessage"]),
    ...mapActions("bol", ["assignManifestToStop"]),
    resetModal() {
      this.manifestNo = null;
      this.openManifests = [];
      this.setErrorMessage(null);
    },
    addOpenManifest(manifest) {
      this.assignManifestToStop({ manifest: manifest, stop: this.stop })
        .then((m) => {
          if (m) {
            let idx = this.openManifests
              .map((a) => a.manifestNo)
              .indexOf(m.manifestNo);
            if (idx > -1) {
              this.openManifests.splice(idx, 1);
            }
            this.$nextTick(() => {
              this.$bvModal.hide("add-manifest-to-stop-modal");
              this.$toasted.success(
                `Manifest ${m.manifestNo} added to Stop ${this.stop.stopNo} ${this.stop.stopCode}`,
              );
            });
          }
        })
        .finally(() => (this.manifestNo = null));
    },
    addManifestNo() {
      let manifest = { manifestNo: this.manifestNo };
      this.addOpenManifest(manifest);
    },
    shown() {
      this.$refs.manifestNoInput.focus();
      this.doSearch();
    },
    upper(s) {
      return textutil.toUpper(s);
    },
    formatDate(d) {
      return dateutil.getLocalDateTime(d, "MM-DD hh:mm a z");
    },
    async doSearch() {
      this.openManifests = [];
      let call = indexApi.search(this.getQuery()).then((res) => {
        let list = [];
        res.data.aggregations.manifest.buckets.forEach((hit) => {
          let r = {
            manifestNo: hit.key,
            manifestDate: hit.manifest_date.buckets[0].key_as_string,
            shipToCode: hit.shipto_code.buckets.length
              ? hit.shipto_code.buckets[0].key
              : null,
            carrier: hit.carrier_code.buckets.length
              ? hit.carrier_code.buckets[0].key
              : null,
            trailerNo: hit.trailer.buckets.length
              ? hit.trailer.buckets[0].key
              : null,
            pieces: hit.pieces.value,
            weight: hit.weight.value,
            lines: hit.line_count.value,
          };
          this.openManifests.push(r);
        });
        return list;
      });
      await this.showLoadingDuringAction(call);
    },
    getQuery() {
      let q = this.search_query.replace("@FACILITY@", this.bol.facility);
      if (this.stop.stopCode) {
        q = q.replace("@SHIPTOCODE@", this.stop.stopCode);
      }
      let cutoff = dateutil
        .localNow()
        .subtract(1, "months")
        .format("YYYY-MM-DD");
      q = q.replace("@DATE@", cutoff);
      return q;
    },
  },
};
</script>
