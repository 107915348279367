/* eslint-disable no-empty-pattern */
import * as msal from "@azure/msal-browser";
import axios from "axios";

const msalConfig = {
  auth: {
    clientId: "d44f0273-7876-4088-87b3-3d4730057dd4",
    authority:
      "https://login.microsoftonline.com/8f190d67-a391-46d9-b399-e9c3aecb1145",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  graphMeMemberOfEndpoint: "https://graph.microsoft.com/v1.0/me/memberOf",
};

let accountId = "";

// this can be used for login or token request, however in more complex situations
// this can have diverging options
const requestObj = {
  scopes: ["user.read", "group.read.all", "directory.read.all"],
};

const myMSALObj =
  await msal.PublicClientApplication.createPublicClientApplication(msalConfig);

myMSALObj.initialize();

// initial state
const state = {
  userInfo: null,
  groupInfo: null,
  loggedIn: false,
  expires: null,
  roles: [],
};

// getters
const getters = {
  firstName: (state) => {
    return state.userInfo != null ? state.userInfo.givenName : null;
  },
  lastName: (state) => {
    return state.userInfo != null ? state.userInfo.surname : null;
  },
  userName() {
    let account = myMSALObj.getActiveAccount();
    //console.log(account);
    return account ? account.username : null;
  },
  inRole: (state) => (role) => {
    let r = null;
    if (state != null) {
      if (state.roles != null) {
        r = state.roles.indexOf(role) > -1;
      }
    }
    return r;
  },
  roles: (state) => {
    let r = null;
    if (state != null) {
      if (state.roles != null) {
        r = state.roles;
      }
    }
    return r;
  },
  inYard: (state, getters) => {
    return getters.inRole("Yard Security") || getters.inRole("Yard Spot");
  },
  loggedIn: (state) => {
    return state ? state.loggedIn : false;
  },
  userInfo: (state) => {
    return state ? state.userInfo : null;
  },
  expires: (state) => {
    return state ? state.expires : null;
  },
};

// actions
const actions = {
  async inRole({ getters }, role) {
    return getters.roles ? getters.roles.indexOf(role) > -1 : false;
  },
  async checkLoggedIn({ getters }) {
    //console.log("checkLoggedIn");
    return getters.loggedIn;
  },
  async signIn({ dispatch }) {
    //console.log("signIn");
    await myMSALObj
      .loginPopup(requestObj)
      .then(async (resp) => {
        if (resp) {
          accountId = resp.account.homeAccountId;
          myMSALObj.setActiveAccount(resp.account);
        } else {
          const currentAccounts = myMSALObj.getAllAccounts();
          if (!currentAccounts || currentAccounts.length < 1) {
            return;
          } else if (currentAccounts.length > 1) {
            // Add choose account code here
          } else if (currentAccounts.length === 1) {
            const activeAccount = currentAccounts[0];
            myMSALObj.setActiveAccount(activeAccount);
            accountId = activeAccount.homeAccountId;
          }
        }
        return await dispatch("refreshSecurity");
      })
      .catch((error) => {
        dispatch("common/setErrorMessage", error, { root: true });
        console.log("security.signIn: " + error);
      });
  },
  async refreshSecurity({ dispatch, getters, commit }) {
    //console.log("refreshSecurity");
    if (myMSALObj.getActiveAccount()) {
      return myMSALObj
        .acquireTokenSilent(requestObj)
        .then(async (tokenResponse) => {
          //console.log("state expires: " + getters.expires);
          //console.log("token expires: " + tokenResponse.expiresOn);
          if (!getters.expires || getters.expires < tokenResponse.expiresOn) {
            let uiarg = {
              ep: graphConfig.graphMeEndpoint,
              at: tokenResponse.accessToken,
              cb: (d) => {
                commit("setUserInfo", d);
              },
            };
            let urarg = {
              ep: graphConfig.graphMeMemberOfEndpoint,
              at: tokenResponse.accessToken,
              cb: async (d) => {
                commit("setGroupInfo", d.value);
                dispatch("setUserRoles");
              },
            };
            let ui = dispatch("callMSGraph", uiarg);
            let ur = dispatch("callMSGraph", urarg);
            await Promise.all([ui, ur]);
          }
          commit("setExpires", tokenResponse.expiresOn);
          commit("setLoggedIn", true);
        })
        .catch(async (error) => {
          //console.log("silent token aquistion fails.")
          if (error instanceof msal.InteractionRequiredAuthError) {
            if (getters.loggedIn) {
              try {
                await myMSALObj.acquireTokenPopup(requestObj);
                await dispatch("refreshSecurity");
              } catch (error_1) {
                dispatch("common/setErrorMessage", error_1, { root: true });
                console.log("security.refreshSecurity.error_1: " + error_1);
              }
            } else {
              commit("setLoggedIn", false);
              // Do nothing, let the user initiate a login
            }
          } else {
            dispatch("common/setErrorMessage", error, { root: true });
            console.log("security.refreshSecurity.error: " + error);
          }
        });
    } else {
      return null;
    }
  },
  async requiresInteraction(errorCode) {
    //console.log("requiresInteraction");
    if (!errorCode || !errorCode.length) {
      return false;
    }
    return (
      errorCode === "consent_required" ||
      errorCode === "interaction_required" ||
      errorCode === "login_required"
    );
  },
  async callMSGraph({}, { ep, at, cb }) {
    //console.log("callMSGraph");
    return axios
      .get(ep, { headers: { Authorization: "Bearer " + at } })
      .then(async (res) => {
        await cb(res.data);
      })
      .catch(function (err) {
        dispatch("common/setErrorMessage", err, { root: true });
        console.log("security.callMSGraph: " + err);
      });
  },
  async signOut({ commit }) {
    commit("setUserInfo", null);
    commit("setGroupInfo", null);
    const logoutRequest = {
      account: myMSALObj.getAccountByHomeId(accountId),
    };
    await myMSALObj.logoutRedirect(logoutRequest);
  },
  async setUserRoles({ commit }) {
    if (state.groupInfo) {
      state.groupInfo.forEach(function (g) {
        if (g.displayName) {
          commit("addUserRole", g.displayName);
        }
      });
    }
  },
};

// mutations
const mutations = {
  setUserInfo(state, l) {
    state.userInfo = l;
  },
  setGroupInfo(state, l) {
    state.groupInfo = l;
  },
  setLoggedIn(state, l) {
    state.loggedIn = l;
  },
  addUserRole(state, l) {
    if (state.roles.indexOf(l) < 0) {
      state.roles.push(l);
    }
  },
  setExpires(state, l) {
    state.expires = l;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
