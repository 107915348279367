import HomePage from "./components/HomePage.vue";
import TrailersPage from "./components/trailer/TrailersPage.vue";
import AddTrailerPage from "./components/trailer/AddTrailerPage.vue";
import AddFacilityLocationPage from "./components/trailer/AddFacilityLocationPage";
import AddForkTruckAuditPage from "./components/misc/AddForkTruckAuditPage";
import BundleStorageLookupPage from "./components/misc/BundleStorageLookupPage";
import BundleStorageManagementPage from "./components/misc/BundleStorageManagementPage";
import JdmPage from "./components/jdm/JdmPage";
import YardPage from "./components/yard/YardPage";
import YardExecSummaryPage from "./components/yard/YardExecSummaryPage";
import BayDoorPage from "./components/bays/BayDoorPage";
import DriverDispatchPage from "./components/driverdispatch/DriverDispatchPage";
import DispatchPageForDriver from "./components/driverdispatch/DispatchPageForDriver";
import SpotPage from "./components/spot/SpotPage";
import SerialPage from "./components/serial/SerialPage";
import RatingPage from "./components/billing/RatingPage";
import WeeklyEDIPage from "./components/billing/WeeklyEDIPage";
import ParcelPage from "./components/manifest/parcel/ParcelPage";
import BolPage from "./components/manifest/bol/BolPage";
import OpenBolPage from "./components/manifest/bol/OpenBolPage";
import OpenManifestPage from "./components/manifest/manifest/OpenManifestPage";
import ShipmentListPage from "./components/manifest/inbound/ShipmentListPage";
import ShipmentPage from "./components/manifest/inbound/ShipmentPage";
import DriverPage from "./components/driverdispatch/DriverPage";
import EdiPage from "./components/driverdispatch/EdiPage";
import VaxEmployeePage from "./components/vax/VaxEmployeePage";
import UploadVaxTestDocumentPage from "./components/vax/UploadVaxTestDocumentPage";
import BundleManagementPage from "./components/inventory/BundleManagementPage";
import LocationManagementPage from "./components/inventory/LocationManagementPage";
import InboundManagementPage from "./components/inventory/InboundManagementPage";
import ExportPage from "./components/export/ExportPage.vue";

export const routes = [
  {
    path: "/",
    name: "homepage",
    component: HomePage,
  },
  {
    path: "/misc/forktruckaudit",
    name: "forktruckaudit",
    component: AddForkTruckAuditPage,
  },
  {
    path: "/misc/bundlestorage",
    name: "bundlestorage",
    component: BundleStorageLookupPage,
  },
  {
    path: "/misc/bundlestoragemanagement",
    name: "bundlestoragemanagement",
    component: BundleStorageManagementPage,
    meta: { validRoles: [] },
  },
  {
    path: "/trailers",
    name: "trailers",
    component: TrailersPage,
    meta: { validRoles: ["Trailer Board"] },
  },
  {
    path: "/addtrailer",
    name: "addtrailer",
    component: AddTrailerPage,
    meta: { validRoles: ["Trailer Board"] },
  },
  {
    path: "/addfacilitylocation",
    name: "addfacilitylocation",
    component: AddFacilityLocationPage,
    meta: { validRoles: ["Trailer Board"] },
  },
  {
    path: "/driverdispatch",
    name: "driverdispatch",
    component: DriverDispatchPage,
    meta: { validRoles: ["Dispatch"] },
  },
  {
    path: "/routesfordriver",
    name: "routesfordriver",
    component: DispatchPageForDriver,
    meta: { validRoles: ["Drivers"] },
  },
  {
    path: "/driverdispatch/drivers",
    name: "drivers",
    component: DriverPage,
    meta: { validRoles: ["Dispatch"] },
  },
  {
    path: "/driverdispatch/edi",
    name: "edi",
    component: EdiPage,
    meta: { validRoles: ["Dispatch"] },
  },
  {
    path: "/jdm",
    name: "jdm",
    component: JdmPage,
    meta: { validRoles: ["JDM Barcoding"] },
  },
  {
    path: "/yard",
    name: "yard",
    component: YardPage,
    meta: { validRoles: ["Yard Security", "Yard Spot", "Dispatch"] },
  },
  {
    path: "/yardexec",
    name: "yardexec",
    component: YardExecSummaryPage,
    meta: { validRoles: ["Dispatch", "Yard Exec Summary"] },
  },
  {
    path: "/spot",
    name: "spot",
    component: SpotPage,
    meta: { validRoles: ["Dispatch", "Yard Spot"] },
  },
  {
    path: "/bays",
    name: "bays",
    component: BayDoorPage,
    meta: { validRoles: ["Dispatch", "Yard Spot"] },
  },
  {
    path: "/bays/:initialFacility",
    name: "baysWithFacility",
    props: true,
    component: BayDoorPage,
    meta: { validRoles: ["Dispatch", "Yard Spot"] },
  },
  {
    path: "/serial",
    name: "serial",
    component: SerialPage,
  },
  {
    path: "/billing/rate",
    name: "rate",
    component: RatingPage,
    meta: { validRoles: ["Manifest Billing"] },
  },
  {
    path: "/billing/weeklyedi",
    name: "weeklyedi",
    component: WeeklyEDIPage,
    meta: { validRoles: ["Manifest Billing"] },
  },
  {
    path: "/manifest/parcels",
    name: "parcels",
    component: ParcelPage,
    meta: { validRoles: ["Manifest Clerk"] },
  },
  {
    path: "/manifest/bol/:billOfLadingNo",
    name: "bol",
    component: BolPage,
    meta: { validRoles: ["Manifest Clerk"] },
  },
  {
    path: "/manifest/bol",
    name: "bolempty",
    component: BolPage,
    meta: { validRoles: ["Manifest Clerk"] },
  },
  {
    path: "/manifest/openbols",
    name: "openbols",
    component: OpenBolPage,
    meta: { validRoles: ["Manifest Clerk"] },
  },
  {
    path: "/manifest/openmanifests",
    name: "openmanifests",
    component: OpenManifestPage,
    meta: { validRoles: ["Manifest Clerk"] },
  },
  {
    path: "/manifest/inbshipments",
    name: "inbshipments",
    component: ShipmentListPage,
    meta: { validRoles: ["Manifest Clerk"] },
  },
  {
    path: "/manifest/inbshipment/:id",
    name: "shipment",
    component: ShipmentPage,
    meta: { validRoles: ["Manifest Clerk"] },
  },
  {
    path: "/vax/uploaddoc",
    name: "vaxuploaddoc",
    component: UploadVaxTestDocumentPage,
  },
  {
    path: "/vax/employees",
    name: "vaxemployees",
    component: VaxEmployeePage,
    meta: { validRoles: ["VaxAdmin", "VaxSupervisor"] },
  },
  {
    path: "/inventory/bundlemanagement",
    name: "invbundlemanagement",
    component: BundleManagementPage,
  },
  {
    path: "/inventory/locationmanagement",
    name: "invlocationmanagement",
    component: LocationManagementPage,
  },
  {
    path: "/inventory/inboundmanagement",
    name: "invinboundmanagement",
    component: InboundManagementPage,
  },
  {
    path: "/export/export",
    name: "export",
    component: ExportPage,
    meta: { validRoles: ["Export Inventory", "Export Inventory ReadOnly"] },
  },
];
