<template>
  <div>
    <button type="button" class="btn btn-primary" @click="toggleLoad">
      {{ loadUnloadLabel }}
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    loadUnloadLabel() {
      let l = null;
      if (this.selectedStayForActions) {
        if (this.isCorrection) {
          l =
            this.selectedStayForActions.trailer.status === "LOADED"
              ? "Set Inbound as Empty"
              : "Set Inbound as Loaded";
        } else {
          l =
            this.selectedStayForActions.trailer.status === "LOADED"
              ? "Set Empty"
              : "Set Loaded";
        }
      }
      return l;
    },
    ...mapGetters("yard", ["selectedStayForActions", "isCorrection"]),
  },
  methods: {
    ...mapActions("yard", [
      "showStayActions",
      "updateStatus",
      "setStayEmpty",
      "setStayLoaded",
    ]),
    close() {
      this.showStayActions({ s: null, a: null });
    },
    toggleLoad() {
      if (this.isCorrection) {
        if (this.selectedStayForActions.trailer.status === "LOADED") {
          this.setStayEmpty();
        } else {
          this.setStayLoaded();
        }
      } else {
        this.updateStatus(
          this.selectedStayForActions.trailer.status === "LOADED"
            ? "EMPTY"
            : "LOADED",
        );
      }
      this.close();
    },
    doDelete() {},
  },
};
</script>
