<template>
  <b-modal
    :no-fade="true"
    @hide="close"
    :id="id"
    :title="title"
    :hide-footer="true"
    v-on="$listeners"
    v-bind="$attrs"
  >
    <slot></slot>
  </b-modal>
</template>

<script>
export default {
  props: {
    id: String,
    actionPanel: String,
    selectedItemForActions: Object,
    selectedActionPanel: String,
    showItemActions: Function,
    title: [String, Function],
  },
  watch: {
    selectedItemForActions() {
      this.doUpdate();
    },
    selectedActionPanel() {
      this.doUpdate();
    },
  },
  mounted() {
    this.doUpdate();
  },
  methods: {
    doUpdate() {
      if (
        this.selectedActionPanel === this.actionPanel &&
        this.selectedItemForActions != null
      ) {
        this.$bvModal.show(this.id);
      } else {
        this.$bvModal.hide(this.id);
      }
    },
    close() {
      this.showItemActions({ i: null, a: null });
    },
  },
};
</script>
