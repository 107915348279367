import moment from "moment";
import jstz from "jstz";
import "moment-timezone";

var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

require("fast-text-encoding");

export default {
  tzname: jstz.determine().name(),
  getLocalDateTime: function (date, format) {
    return moment.utc(date).tz(this.tzname).format(format);
  },
  getUTCDateTime: function (localdate, format) {
    return moment(localdate).utc().format(format);
  },
  getLocalDateTimeNow: function (format) {
    return moment.tz(this.tzname).format(format);
  },
  localNow: function () {
    return moment().tz(this.tzname);
  },
  localNowFormatted: function (format) {
    return moment().tz(this.tzname).format(format);
  },
  nowFormatted: function (format) {
    return moment().format(format);
  },
  format: function (date, format) {
    return moment(date).format(format);
  },
  utcNow: function () {
    return moment().utc();
  },
};
