<template>
  <div class="container-fluid h-100" style="overflow: hidden; overflow-y: auto">
    <b-card no-body>
      <b-card-header>
        <div v-show="errorMessage" class="alert alert-danger">
          {{ errorMessage }}
        </div>
        <b-nav-form>
          <b-nav-text>Manage Inventory Locations for&nbsp;</b-nav-text>
          <b-form-select v-model="selectFacility" size="sm">
            <b-form-select-option :value="null" disabled
              >Select Facility</b-form-select-option
            >
            <template v-for="f in facilities">
              <b-form-select-option :value="f" :key="f">{{
                f
              }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-nav-form>
      </b-card-header>
      <b-card-body>
        <AddLocationPanel />
        <FindLocationPanel />
      </b-card-body>
    </b-card>
    <LoadingSplash />
  </div>
</template>

<script>
import LoadingSplash from "../LoadingSplash.vue";
import FindLocationPanel from "./FindLocationPanel.vue";
import AddLocationPanel from "./AddLocationPanel.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    LoadingSplash,
    FindLocationPanel,
    AddLocationPanel,
  },
  data() {
    return {
      selectFacility: null,
      facilities: ["ADC", "MTJOY", "ATI"],
    };
  },
  computed: {
    ...mapGetters("common", ["loading", "errorMessage", "defaultFacility"]),
    ...mapGetters("invBundles", ["facility"]),
  },
  watch: {
    selectFacility(newVal) {
      if (newVal) {
        if (this.facility != newVal) {
          this.setFacility(newVal);
        }
      }
    },
    facility(newVal) {
      if (newVal) {
        if (this.selectFacility != newVal) {
          this.selectFacility = newVal;
        }
      }
    },
  },
  methods: {
    ...mapActions("common", ["setErrorMessage"]),
    ...mapActions("invBundles", ["setFacility"]),
  },
  mounted() {
    this.selectFacility = this.defaultFacility;
    this.setFacility(this.selectFacility);
    this.setErrorMessage(null);
  },
};
</script>

<style scoped></style>
