<template>
  <b-modal
    id="add-bol-modal"
    ref="form"
    title="Add Bill of Lading"
    size="lg"
    @show="resetModal"
    @hidden="resetModal"
    @ok="okClicked"
    @shown="shown"
  >
    <b-form ref="addBolModalForm" @submit.stop.prevent="handleSubmit">
      <b-row>
        <b-col md="4">
          <b-form-group
            label="Facility:"
            label-for="facility-input"
            label-cols-md="4"
            invalid-feedback="Facility is required"
          >
            <b-form-select
              size="sm"
              id="facility-input"
              ref="facilityInput"
              v-model="facility"
              :state="facilityState"
              required
            >
              <b-form-select-option
                v-if="facility == null"
                :value="null"
                disabled
                >-- None --</b-form-select-option
              >
              <b-form-select-option value="ADC">ADC</b-form-select-option>
              <b-form-select-option value="MTJOY">MTJOY</b-form-select-option>
              <b-form-select-option value="ATIRI">ATIRI</b-form-select-option>
              <b-form-select-option value="MTJOPEN"
                >MTJOPEN</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group
            label="Trailer:"
            label-for="trailer-input"
            label-cols-md="4"
            invalid-feedback="Trailer is required"
          >
            <b-form-select
              id="trailer-input"
              ref="trailerStayInput"
              v-model="trailerStay"
              :disabled="facility == null"
              :state="trailerStayState"
              required
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Select Trailer --</b-form-select-option
                >
              </template>
              <b-form-select-option
                v-for="stay in trailerStays"
                :key="stay.id"
                :value="stay"
              >
                {{ stay.trailer.scac }} {{ stay.trailer.id }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <party-display
            v-if="trailerIsValid"
            :code="trailerStay.trailer.scac"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group
            label="3rd Party:"
            label-for="thirdparty-input"
            label-cols-md="4"
          >
            <b-form-input
              id="thirdparty-input"
              ref="thirdpartyInput"
              v-model="thirdPartyCode"
              :formatter="upper"
              lazy
              trim
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <party-display :code="thirdPartyCode" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group
            label="Ship To:"
            label-for="shipTo-input"
            label-cols-md="4"
            invalid-feedback="Ship To is required"
          >
            <b-form-input
              id="shipTo-input"
              ref="shipToInput"
              v-model="shipToCode"
              :formatter="upper"
              :state="shipToCodeState"
              lazy
              required
              trim
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <party-display :code="shipToCode" />
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import textutils from "../../../textutils";
import { mapActions, mapGetters } from "vuex";
import PartyDisplay from "../../common/PartyDisplay.vue";
import { yard } from "../../../api/movementapi";

export default {
  components: {
    PartyDisplay,
  },
  data() {
    return {
      facility: null,
      facilityState: null,
      trailerStay: null,
      thirdPartyCode: null,
      shipToCode: null,
      shipToCodeState: null,
      trailerStays: [],
    };
  },
  watch: {
    facility(newVal) {
      if (newVal) {
        yard
          .loadStays(newVal)
          .then((res) => (this.trailerStays = res.data.payload));
      }
    },
    trailerStayId(newVal) {
      console.log(newVal);
    },
  },
  computed: {
    ...mapGetters("common", ["defaultFacility"]),
    trailerIsValid() {
      return this.trailerStay != null && this.trailerStay.trailer != null;
    },
    trailerStayState() {
      let s = null;
      if (this.facility) {
        s = this.trailerStay != null;
      }
      return s;
    },
  },
  methods: {
    ...mapActions("bol", ["createBol"]),
    upper(val) {
      return textutils.toUpper(val);
    },
    checkFormValidity() {
      this.facilityState = this.facility != null;
      this.shipToCodeState = this.$refs.shipToInput.checkValidity();
      return (
        this.facilityState && this.trailerStay != null && this.shipToCodeState
      );
    },
    resetModal() {
      this.facility = this.defaultFacility;
      this.facilityState = null;
      this.trailerStay = null;
      this.shipToCode = null;
      this.shipToCodeState = null;
      this.thirdPartyCode = null;
    },
    okClicked(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        this.shown();
        return;
      }
      let b = {
        facility: this.facility,
        carrierCode: this.trailerStay.trailer.scac,
        trailerNo: this.trailerStay.trailer.id,
        trailerStayId: this.trailerStay.id,
        thirdPartyCode: this.thirdPartyCode,
      };
      this.createBol({ bol: b, shipToCode: this.shipToCode }).then((bol) => {
        this.$nextTick(() => {
          this.$bvModal.hide("add-bol-modal");
          this.$toasted.success(`BOL ${bol.billOfLadingNo} created`);
        });
      });
    },
    shown() {
      this.$refs.facilityInput.focus();
    },
  },
};
</script>
