/* eslint-disable no-empty-pattern */
import { rating } from "../../api/manifest";

// initial state
const state = {
  unratedManifests: [],
};

// getters
const getters = {
  unratedManifests: (state) => {
    return state.unratedManifests;
  },
};

// actions
const actions = {
  loadUnratedManifests({ dispatch, commit }) {
    dispatch("common/setErrorMessage", null, { root: true });
    dispatch("common/setLoading", true, { root: true });
    rating
      .getUnratedManifests()
      .then((res) => {
        commit("setsUnratedManifests", res.data.payload);
      })
      .catch((err) => {
        dispatch("common/handleHttpError", err, {
          root: true,
        });
      })
      .finally(() => {
        dispatch("common/setLoading", false, { root: true });
      });
  },
  rateManifest({}, { manifestNo, counter, count }) {
    return rating.rateManifest(manifestNo, counter, count);
  },
  setFuelRate({}, { dateStr, fuelRate }) {
    return rating.setFuelRate(dateStr, fuelRate);
  },
  resetModel({ commit }) {
    commit("setsUnratedManifests", []);
  },
};

// mutations
const mutations = {
  setsUnratedManifests(state, t) {
    t.forEach((m) => {
      m.rating = null;
      m.errorMessage = null;
    });
    state.unratedManifests = t;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
