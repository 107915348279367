<template>
  <div>
    <div class="text-center">
      Last 4 of Order #:
      <input
        type="text"
        ref="focusMe"
        v-model.trim="suffixToSearch"
        id="suffixToSearch"
      />
    </div>
    <div v-if="possibleOrders">
      <template v-for="order in possibleOrders">
        <div
          @click="doSelectOrder(order)"
          style="border-bottom: 1px black solid"
          :key="order.id"
        >
          {{ order.orderNo }}, {{ order.destName }}, {{ order.destCity }},
          {{ order.destState }}, {{ order.pieces }} pc(s),
          {{ order.shippingWeight }} lbs
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SearchPanel",
  props: {
    active: Boolean,
  },
  data() {
    return {
      suffixToSearch: null,
      possibleOrders: null,
    };
  },
  computed: {
    ...mapGetters("jdm", []),
  },
  watch: {
    suffixToSearch(newVal) {
      if (newVal && newVal.length == 4) {
        this.doSearch();
      }
    },
    active(newVal) {
      if (newVal) {
        this.autoFocus();
      }
    },
  },
  methods: {
    ...mapActions("jdm", ["searchOrderSuffix", "selectOrder", "playError"]),
    ...mapActions("common", ["setErrorMessage"]),
    autoFocus: function () {
      if (this.$refs.focusMe != null) {
        window.setTimeout(() => this.$refs.focusMe.focus(), 100);
      }
    },
    doSelectOrder: function (order) {
      this.possibleOrders = null;
      this.selectOrder(order);
    },
    doSearch: function () {
      this.setErrorMessage(null);
      this.searchOrderSuffix(this.suffixToSearch)
        .then((res) => {
          if (!res || res.length == 0) {
            this.playError();
            this.setErrorMessage(
              `No order ending in ${this.suffixToSearch} found.`,
            );
          } else if (res.length > 1) {
            this.possibleOrders = res;
          } else {
            this.doSelectOrder(res[0]);
          }
        })
        .finally(() => (this.suffixToSearch = null));
    },
  },
  mounted() {
    this.autoFocus();
  },
};
</script>

<style scoped></style>
