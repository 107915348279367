<template>
  <div>
    <div class="card">
      <div class="card-header">Find Inbound</div>
      <div class="card-body">
        <form>
          <div class="form-group row">
            <label for="findDate" class="col-sm-1 col-form-label">Date</label>
            <div class="col-sm-2">
              <b-form-input
                type="text"
                :formatter="upper"
                v-model.trim="findDate"
                id="find"
                placeholder="YYYY-MM-DD"
              />
            </div>
            <label for="findSupplier" class="col-sm-2 col-form-label"
              >Supplier</label
            >
            <div class="col-sm-2">
              <b-form-input
                type="text"
                :formatter="upper"
                v-model.trim="findSupplier"
                id="findSupplier"
                placeholder="Supplier"
              />
            </div>
            <label for="findTracking" class="col-sm-2 col-form-label"
              >Tracking</label
            >
            <div class="col-sm-2">
              <b-form-input
                type="text"
                :formatter="upper"
                v-model.trim="findTracking"
                id="findTracking"
                placeholder="Tracking"
              />
            </div>
            <div class="col-sm-1">
              <button type="button" @click="doSearch" class="btn btn-primary">
                Search
              </button>
            </div>
          </div>
        </form>
        <b-row class="table-responsive" v-if="shipments.length > 0">
          <b-table
            :items="shipments"
            :fields="headers"
            responsive="sm"
            primary-key="id"
            striped
            small
          >
            <template #cell(completed)="data">
              <span :title="completedDetails(data.item)">
                <b-checkbox v-model="data.item.completed" disabled />
              </span>
            </template>
            <template #cell(id)="data">
              <input type="hidden" v-model="data.item.id" />
              <b-button size="sm" @click="showInboundBundles(data.item)"
                >Bundles</b-button
              >&nbsp;
              <b-button
                size="sm"
                @click="completeShipment(data.item)"
                :disabled="disableComplete(data.item)"
                >Complete</b-button
              >
            </template>
          </b-table>
        </b-row>
      </div>
    </div>
    <InboundBundlesModal :inboundShipment="selectedInbound" />
  </div>
</template>

<script>
import { inboundInvShipment } from "../../api/bundleinventory";
import { mapGetters, mapActions } from "vuex";
import textutils from "../../textutils";
import dateutil from "../../dateutil";
import InboundBundlesModal from "./InboundBundlesModal.vue";

export default {
  components: {
    InboundBundlesModal,
  },
  data() {
    return {
      findDate: null,
      findSupplier: null,
      findTracking: null,
      selectedInbound: null,
      shipments: [],
      headers: [
        { key: "facility", label: "Facility" },
        { key: "dateCreated", label: "Date" },
        { key: "supplier", label: "Supplier" },
        { key: "trackingId", label: "Tracking" },
        { key: "completed", label: "Complete" },
        { key: "id", label: "Action" },
      ],
    };
  },
  computed: {
    ...mapGetters("invBundles", ["facility"]),
  },
  methods: {
    ...mapActions("common", ["showLoadingDuringAction"]),
    doSearch() {
      let i = inboundInvShipment.searchShipments(
        this.facility,
        this.findDate,
        this.findSupplier,
        this.findTracking,
      );
      this.showLoadingDuringAction(i).then((res) => {
        if (res) {
          this.shipments = res.data.payload;
          this.resetData();
        }
      });
    },
    disableComplete(item) {
      return item.completed;
    },
    resetData() {
      this.findDate = null;
      this.findSupplier = null;
      this.findTracking = null;
      this.selectedInbound = null;
    },
    upper(t) {
      return textutils.toUpper(t);
    },
    completedDetails(item) {
      return item.completed
        ? `${item.completedBy} @ ${dateutil.getLocalDateTime(
            item.completedAt,
            "YYYY-MM-DD hh:mma z",
          )}`
        : "";
    },
    async completeShipment(item) {
      let confirmed = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to complete ${item.name}?`,
      );
      if (confirmed) {
        return this.doCompleteShipment(item);
      }
    },
    doCompleteShipment(item) {
      let i = inboundInvShipment.completeShipment(item.id);
      return this.showLoadingDuringAction(i).then((res) => {
        if (res) {
          this.toastSuccess(`${item.name} marked completed`);
          this.doSearch();
        }
      });
    },
    showInboundBundles(item) {
      this.selectedInbound = item;
      this.$bvModal.show("inbound-shipment-inv-bundles");
    },
    toastInfo(msg, pos = "top-right", dur = 5000) {
      this.$toasted.info(msg, {
        duration: dur,
        position: pos,
      });
    },
    toastSuccess(msg, pos = "top-right", dur = 5000) {
      this.$toasted.success(msg, {
        duration: dur,
        position: pos,
      });
    },
  },
};
</script>

<style scoped></style>
