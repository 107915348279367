<template>
  <modal
    name="fileUploadModal"
    height="auto"
    :scrollable="true"
    @before-close="beforeClose"
    @before-open="beforeOpen"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ title }}</h5>
        <div
          class="close"
          @click="$modal.hide('fileUploadModal')"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </div>
      </div>
      <div class="modal-body">
        <template v-for="f in files">
          <div class="row row-striped" style="min-height: 40px" :key="f.name">
            <div class="col-8 mycol">
              <a href="#" @click="downloadFile(f.name)">{{ f.name }}</a>
            </div>
            <div class="col mycol text-right">
              {{ f.size.toLocaleString() }} bytes
            </div>
            <div class="col-1 mycol">
              <button @click="doDelete(f.name)" class="btn">X</button>
            </div>
          </div>
        </template>
        <hr />
        <h6>Add Document</h6>
        <input
          type="file"
          id="file"
          ref="file"
          v-on:change="handleFileUpload()"
        />
        <br />
        <br />
        <button
          class="btn btn-primary"
          :disabled="file == null"
          @click="doSubmit"
        >
          Upload
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import attachApi from "../../api/attach";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      type: null,
      id: null,
      title: null,
      files: [],
      file: null,
    };
  },
  methods: {
    ...mapActions("common", ["showLoadingDuringAction"]),
    beforeOpen(event) {
      this.type = event.params.type;
      this.id = event.params.id;
      this.title = event.params.title;
      this.files = event.params.files;
    },
    beforeClose() {
      this.type = null;
      this.id = null;
      this.title = null;
      this.files = null;
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    doSubmit() {
      let call = attachApi
        .uploadFile(this.type, this.id, this.file)
        .then(() => {
          this.files.push(this.file);
          this.file = null;
          this.$refs.file.value = null;
        });
      this.showLoadingDuringAction(call);
    },
    doDelete(filename) {
      let call = attachApi.deleteFile(this.type, this.id, filename).then(() => {
        let idx = this.files.map((f) => f.name).indexOf(filename);
        if (idx > -1) {
          this.files.splice(idx, 1);
        }
      });
      this.showLoadingDuringAction(call);
    },
    downloadFile(fileName) {
      window.open(
        attachApi.downloadLink(this.type, this.id, fileName),
        "_new",
        "",
      );
    },
  },
};
</script>

<style scoped>
.mycol {
  border-left: 1px lightgrey solid;
  border-right: 1px lightgrey solid;
}
.row-striped {
  border: 1px lightgrey solid;
}
</style>
