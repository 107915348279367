import { manifestApi } from "../../api/manifest";

const state = {
  openManifests: [],
};

const getters = {
  openManifests(state) {
    return state.openManifests;
  },
};

const actions = {
  async loadOpenManifests({ dispatch, commit }, facility) {
    commit("reset");
    dispatch("common/setErrorMessage", null, { root: true });
    dispatch("common/setLoading", true, { root: true });
    try {
      let manifests = await manifestApi
        .loadOpenManifests(facility)
        .then((res) => res.data.payload);
      manifests.forEach((m) => {
        m.lines = "-";
        m.pieces = "-";
        m.weight = "-";
        m.shipments = [{ id: 0, shipmentName: "-" }];
        dispatch("loadManifestSummaryData", m);
      });
      commit("setOpenManifests", manifests);
    } catch (err) {
      dispatch("common/handleHttpError", err, {
        root: true,
      });
    } finally {
      dispatch("common/setLoading", false, { root: true });
    }
  },
  async loadManifestSummaryData({}, manifest) {
    manifest.pieces = await manifestApi
      .loadPieces(manifest.manifestNo)
      .then((res) => res.data);
    manifest.weight = await manifestApi
      .loadWeight(manifest.manifestNo)
      .then((res) => res.data);
    manifest.lines = await manifestApi
      .loadLineCount(manifest.manifestNo)
      .then((res) => res.data);
    let shipments = await manifestApi
      .loadShipments(manifest.manifestNo)
      .then((res) => res.data.payload);
    if (shipments.length > 0) {
      shipments.sort((a, b) =>
        a.shipmentName > b.shipmentName
          ? 1
          : b.shipmentName > a.shipmentName
            ? -1
            : 0,
      );
      manifest.shipments = shipments;
    } else {
      manifest.shipments = [{ id: 0, shipmentName: "-- NONE --" }];
    }
  },
};

const mutations = {
  reset(state) {
    state.openManifets = [];
  },
  setOpenManifests(state, openManifests) {
    state.openManifests = openManifests;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
