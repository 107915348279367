<template>
  <div>
    <b-row>
      <b-col>Origin:</b-col>
      <b-col>
        <b-form-select
          v-model="origin"
          :options="inboundOrigins"
          value-field="id"
          text-field="item"
        >
          <template #first>
            <b-form-select-option :value="null" disabled
              >-- Select Origin --</b-form-select-option
            >
          </template>
        </b-form-select>
      </b-col>
    </b-row>
    <b-row>
      <b-col>Dest:</b-col>
      <b-col>
        <b-form-select
          v-model="dest"
          :options="inboundDests"
          value-field="id"
          text-field="item"
        >
          <template #first>
            <b-form-select-option :value="null" disabled
              >-- Select Dest --</b-form-select-option
            >
          </template>
        </b-form-select>
      </b-col>
    </b-row>
    <b-row>
      <b-col>Contents:</b-col>
      <b-col>
        <b-form-select
          v-model="contents"
          :options="inboundContents"
          value-field="id"
          text-field="item"
        >
          <template #first>
            <b-form-select-option :value="null" disabled
              >-- Select Contents --</b-form-select-option
            >
          </template>
        </b-form-select>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button variant="primary" :disabled="disableSubmit" @click="checkIn"
          >Check In</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      origin: null,
      dest: null,
      contents: null,
    };
  },
  computed: {
    ...mapGetters("yard", [
      "selectedStayForActions",
      "inboundDests",
      "inboundContents",
      "inboundOrigins",
    ]),
    disableSubmit() {
      return this.origin == null || this.dest == null || this.contents == null;
    },
  },
  methods: {
    ...mapActions("yard", ["showStayActions", "updateStay"]),
    reset() {
      this.origin = null;
      this.dest = null;
      this.contents = null;
    },
    async checkIn() {
      this.selectedStayForActions.inboundOrigin = this.origin;
      this.selectedStayForActions.inboundDestination = this.dest;
      this.selectedStayForActions.inboundContent = this.contents;
      await this.updateStay();
      this.reset();
      this.close();
    },
    close() {
      this.showStayActions({ s: null, a: null });
    },
  },
  mounted() {
    if (this.selectedStayForActions) {
    }
  },
};
</script>
