import { Client } from "@stomp/stompjs";

var amqUrl = null;
var amqUser = null;
var amqPassword = null;

try {
  // eslint-disable-next-line no-undef
  amqUrl = AMQ_WS_URL;
  // eslint-disable-next-line no-undef
  amqUser = AMQ_USER;
  // eslint-disable-next-line no-undef
  amqPassword = AMQ_PASSWORD;
} catch (e) {
  amqUrl = "ws://msg.home:62614";
  amqUser = "artemis";
  amqPassword = "simetraehcapa";
}

const msgClient = new Client({
  brokerURL: amqUrl,
  connectHeaders: {
    login: amqUser,
    passcode: amqPassword,
  },
  //  debug: function(str) {
  //    console.log(str);
  //  },
  reconnectDelay: 5000,
  heartbeatIncoming: 4000,
  heartbeatOutgoing: 4000,
});

msgClient.onWebSocketError = () => {
  connected = false;
};
msgClient.onConnect = () => {
  connected = true;
};

msgClient.onStompError = function (frame) {
  console.log("Broker reported error: " + frame.headers["message"]);
  console.log("Additional details: " + frame.body);
};

export default {
  msgClient: msgClient,
  subscriptionAndCallbacks: [],
  subscriptions: [],
  onConnect: null,
  start: function () {
    let self = this;
    this.msgClient.onConnect = function () {
      Object.keys(self.subscriptionAndCallbacks).forEach((k) => {
        let s = self.subscriptionAndCallbacks[k];
        if (s) {
          let sub = self.msgClient.subscribe(s.endpoint, s.callback);
          self.subscriptions[k] = sub;
        }
      });
      if (self.onConnect) {
        self.onConnect();
      }
    };
    this.msgClient.activate();
  },
  stop: function () {
    let self = this;
    Object.keys(this.subscriptions).forEach((k) =>
      self.subscriptions[k] != null ? self.subscriptions[k].unsubscribe() : 0,
    );
    this.subscriptions = [];
    return this.msgClient.deactivate();
  },
  restart: function () {
    let self = this;
    return this.stop().then(() => self.start());
  },
  subscribe: function (appkey, ep, cb) {
    this.subscriptionAndCallbacks[appkey] = { endpoint: ep, callback: cb };
  },
  unsubscribe: function (appkey) {
    let sub = this.subscriptions[appkey];
    if (sub) {
      this.subscriptionAndCallbacks[appkey] = null;
      this.subscriptions[appkey] = null;
      sub.unsubscribe();
    }
  },
};
