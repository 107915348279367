<template>
  <b-modal
    id="import-manifest-as-shipment-modal"
    ref="form"
    title="Import Manifest as Shipment"
    size="md"
    :ok-disabled="disableOk"
    @show="resetModal"
    @hidden="resetModal"
    @ok="okClicked"
    @shown="shown"
  >
    <div v-show="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <b-form>
      <b-form-group label-cols-md label="Manifest No:">
        <b-form-input trim number v-model="manifestNo" ref="manifestNo" />
      </b-form-group>
      <b-form-group label-cols-md label="Shipment Type:">
        <b-form-select
          v-model="shipmentTypeId"
          :options="shipmentTypes"
          text-field="shipmentTypeCodeDisplay"
          value-field="shipmentTypeCode"
        >
          <template #first>
            <b-form-select-option
              v-if="shipmentTypeId == null"
              :value="null"
              disabled
              >-- Select a Shipment Type --</b-form-select-option
            >
          </template>
        </b-form-select>
      </b-form-group>
      <b-form-group label-cols-md label="Target Facility:">
        <b-form-select v-model="targetFacility">
          <b-form-select-option
            v-if="targetFacility == null"
            :value="null"
            disabled
            >-- Select a Facility --</b-form-select-option
          >
          <b-form-select-option value="ADC">ADC</b-form-select-option>
          <b-form-select-option value="MTJOY">MTJOY</b-form-select-option>
          <b-form-select-option value="ATIRI">ATIRI</b-form-select-option>
          <b-form-select-option value="MTJOPEN">MTJOPEN</b-form-select-option>
        </b-form-select>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import { lookups, shipmentApi } from "../../../api/manifest";
import { mapActions, mapGetters } from "vuex";
import { MANIFEST_URL } from "../../../config";

export default {
  props: {
    shipmentSummaries: Array,
    currentFacility: String,
  },
  data() {
    return {
      manifestNo: null,
      shipmentTypeId: null,
      targetFacility: null,
      shipmentTypes: [],
    };
  },
  computed: {
    ...mapGetters("common", ["errorMessage", "defaultFacility"]),
    disableOk() {
      return (
        this.manifestNo == null ||
        this.shipmentTypeId == null ||
        this.targetFacility == null
      );
    },
  },
  methods: {
    ...mapActions("common", ["showLoadingDuringAction"]),
    resetModal() {
      this.manifestNo = null;
      this.shipmentTypeId = null;
      this.targetFacility = null;
    },
    async okClicked(bvModalEvt) {
      bvModalEvt.preventDefault();
      let call = shipmentApi.createShipmentFromManifest(
        this.manifestNo,
        this.shipmentTypeId,
        this.targetFacility,
      );
      let res = await this.showLoadingDuringAction(call);
      if (res) {
        let shipment = res.data.payload;
        if (shipment.facility === this.currentFacility) {
          await shipmentApi
            .loadShipmentSummary(shipment.id)
            .then((r) => this.shipmentSummaries.push(r.data.payload));
        }
        this.$toasted.success(
          `Shipment ${shipment.shipmentName} created from Manifest ${this.manifestNo}`,
        );
        this.$bvModal.hide("import-manifest-as-shipment-modal");
        let url = `${MANIFEST_URL}/IncomingShipmentView/Detail?incomingShipmentId=${shipment.id}`;
        window.open(url, "manifestFromMissy");
      }
    },
    shown() {
      this.targetFacility = this.defaultFacility;
      this.$refs.manifestNo.focus();
    },
  },
  mounted() {
    lookups
      .loadShipmentTypes()
      .then((res) => (this.shipmentTypes = res.data.payload));
  },
};
</script>
