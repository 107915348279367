<template>
  <b-container fluid class="h-100">
    <div class="card">
      <div class="card-header">
        <div v-show="errorMessage" class="alert alert-danger">
          {{ errorMessage }}
        </div>
        <div v-if="actionComplete" class="alert alert-success">
          {{ actionComplete }}
        </div>
        Parcels
      </div>
      <div class="card-body">
        <form>
          <div class="form-group row">
            <label for="facility" class="col-sm-2 col-form-label"
              >Facility</label
            >
            <b-form-select
              id="facility"
              ref="facility"
              v-model="facility"
              :options="facilityOptions"
              class="col-sm-2 form-control"
            ></b-form-select>
          </div>
          <div v-show="facility != null" class="form-group row">
            <label for="action" class="col-sm-2 col-form-label">Action</label>
            <b-form-select
              id="action"
              ref="action"
              v-model="action"
              :options="actionOptions"
              class="col-sm-2 form-control"
            ></b-form-select>
          </div>
          <div v-show="facility != null && action != null">
            <div v-show="action === 'setAtLocation'" class="form-group row">
              <label for="damaged" class="col-sm-2 col-form-label"
                >Damaged</label
              >
              <b-form-checkbox
                id="damaged"
                v-model="damaged"
                value="true"
                unchecked-value="false"
                class="col-sm-2"
                size="lg"
              ></b-form-checkbox>
            </div>
            <div class="form-group row">
              <label for="barcode" class="col-sm-2 col-form-label">{{
                barcodeLabel
              }}</label>
              <b-form-input
                v-on:keyup.native.enter="barcodeEntered"
                id="barcode"
                ref="barcode"
                v-model="barcode"
                class="col-sm-2"
                :formatter="formatter"
                trim
              ></b-form-input>
            </div>
            <div v-show="showTarget">
              <div class="form-group row">
                <label for="target" class="col-sm-2 col-form-label">{{
                  targetLabel
                }}</label>
                <b-form-input
                  v-on:keyup.native.enter="targetEntered"
                  id="target"
                  ref="target"
                  v-model="target"
                  class="col-sm-2"
                  :formatter="formatter"
                  trim
                ></b-form-input>
              </div>
              <div class="form-group row">
                <label for="lockTarget" class="col-sm-2 col-form-label"
                  >Lock {{ targetLabel }}</label
                >
                <b-form-checkbox
                  id="lockTarget"
                  v-model="lockTarget"
                  value="true"
                  unchecked-value="false"
                  class="col-sm-2"
                  size="lg"
                  :disabled="target == null"
                ></b-form-checkbox>
              </div>
            </div>
            <div class="form-group row">
              <b-button
                :disabled="!validInput"
                @click="performAction"
                variant="primary"
                >{{ actionText }}</b-button
              >
            </div>
          </div>
        </form>
      </div>
    </div>
    <LoadingSplash />
  </b-container>
</template>

<script>
import textutils from "../../../textutils";
import { mapActions, mapGetters } from "vuex";
import LoadingSplash from "../../LoadingSplash.vue";

export default {
  components: {
    LoadingSplash,
  },
  data() {
    return {
      facility: null,
      action: null,
      barcode: null,
      target: null,
      lockTarget: false,
      damaged: false,
      actionComplete: null,
      facilityOptions: [
        { value: null, text: "-- Select a Facility --" },
        { value: "ADC", text: "ADC" },
        { value: "MTJOY", text: "MTJOY" },
        { value: "ATIRI", text: "ATIRI" },
      ],
      actionOptions: [
        { value: null, text: "-- Select an Action --" },
        { value: "setAtLocation", text: "Set at Location" },
        { value: "addToBOL", text: "Add to BOL" },
        { value: "addToOP", text: "Add to OP" },
        { value: "addLocToOP", text: "Add Loc to OP" },
        { value: "removeFromLocation", text: "Remove from Location" },
        { value: "removeFromBOL", text: "Remove from BOL" },
        { value: "removeFromOP", text: "Remove from OP" },
      ],
    };
  },
  computed: {
    ...mapGetters("common", ["errorMessage", "defaultFacility"]),
    barcodeLabel() {
      let r = "Barcode";
      if (this.action === "addLocToOP") {
        r = "Location";
      }
      return r;
    },
    targetLabel() {
      let r = "?";
      switch (this.action) {
        case "setAtLocation":
          r = "Location";
          break;
        case "addToBOL":
          r = "BOL";
          break;
        case "addToOP":
        case "addLocToOP":
          r = "Overpack";
          break;
      }
      return r;
    },
    showTarget() {
      return !(this.action == null || this.action.indexOf("remove") > -1);
    },
    actionText() {
      let r = "?";
      if (this.action != null) {
        let obj = this.actionOptions.find((e) => e.value == this.action);
        if (obj != null) {
          r = obj.text;
        }
      }
      return r;
    },
    validInput() {
      let r = false;
      switch (this.action) {
        case "setAtLocation":
        case "addToBOL":
        case "addToOP":
        case "addLocToOP":
          r = this.barcode != null && this.target != null;
          break;
        case "removeFromLocation":
        case "removeFromBOL":
        case "removeFromOP":
          r = this.barcode != null;
          break;
      }
      return r;
    },
  },
  watch: {
    action() {
      this.actionChanged();
    },
  },
  methods: {
    ...mapActions("common", ["showLoadingDuringAction", "setErrorMessage"]),
    ...mapActions("parcels", [
      "setAtLocation",
      "addToBOL",
      "addToOP",
      "addLocToOP",
      "removeFromLocation",
      "removeFromBOL",
      "removeFromOP",
    ]),
    formatter(val) {
      return textutils.toUpper(val);
    },
    actionChanged() {
      this.damaged = false;
      this.barcode = null;
      this.target = null;
      this.lockTarget = false;
      this.actionComplete = null;
    },
    afterAction() {
      this.damaged = false;
      this.barcode = null;
      if (!this.lockTarget) {
        this.target = null;
      }
      this.doFocus(this.$refs.barcode);
    },
    barcodeEntered() {
      if (this.validInput) {
        this.performAction();
      } else {
        this.doFocus(this.$refs.target);
      }
    },
    targetEntered() {
      if (this.validInput) {
        this.performAction();
      }
    },
    performAction() {
      this.actionComplete = null;
      switch (this.action) {
        case "setAtLocation":
          this.setAtLocation({
            facility: this.facility,
            barcode: this.barcode,
            target: this.target,
            damaged: this.damaged,
          }).then((res) => {
            this.handleActionResult(
              res,
              `${this.barcode} set to location ${this.target}.`,
            );
          });
          break;
        case "addToBOL":
          this.addToBOL({
            facility: this.facility,
            barcode: this.barcode,
            target: this.target,
          }).then((res) => {
            this.handleActionResult(
              res,
              `${this.barcode} added to BOL ${this.target}.`,
            );
          });
          break;
        case "addToOP":
          this.addToOP({
            facility: this.facility,
            barcode: this.barcode,
            target: this.target,
          }).then((res) => {
            this.handleActionResult(
              res,
              `${this.barcode} added to overpack ${this.target}.`,
            );
          });
          break;
        case "addLocToOP":
          this.addLocToOP({
            facility: this.facility,
            barcode: this.barcode,
            target: this.target,
          }).then((res) => {
            this.handleActionResult(
              res,
              `Location ${this.barcode} added to overpack ${this.target}.`,
            );
          });
          break;
        case "removeFromLocation":
          this.removeFromLocation({
            facility: this.facility,
            barcode: this.barcode,
          }).then((res) => {
            this.handleActionResult(
              res,
              `${this.barcode} removed from location.`,
            );
          });
          break;
        case "removeFromBOL":
          this.removeFromBOL({
            facility: this.facility,
            barcode: this.barcode,
          }).then((res) => {
            this.handleActionResult(res, `${this.barcode} removed from BOL.`);
          });
          break;
        case "removeFromOP":
          this.removeFromOP({
            facility: this.facility,
            barcode: this.barcode,
          }).then((res) => {
            this.handleActionResult(res, `${this.barcode} removed from OP.`);
          });
          break;
      }
    },
    handleActionResult(res, completeText) {
      if (res) {
        this.actionComplete = completeText;
        this.afterAction();
      } else {
        this.doFocus(this.$refs.barcode);
      }
    },
    doFocus(focusMe) {
      setTimeout(() => {
        focusMe.focus();
      }, 100);
    },
  },
  mounted() {
    this.facility = this.defaultFacility;
    if (!this.facility) {
      this.doFocus(this.$refs.facility);
    } else {
      this.doFocus(this.$refs.action);
    }
  },
};
</script>

<style scoped></style>
