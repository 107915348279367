<template>
  <modal
    name="updateTrailerStatusModal"
    height="auto"
    :scrollable="true"
    @before-close="beforeClose"
    @before-open="beforeOpen"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ title }}</h5>
        <div
          class="close"
          @click="$modal.hide('updateTrailerStatusModal')"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </div>
      </div>
      <div class="modal-body">
        <div class="card-body">
          <button type="button" class="btn btn-primary" @click="toggleLoad">
            {{ loadUnloadLabel }}
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { trailers as trailerApi } from "../../api/movementapi";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      title: null,
      trailer: null,
      callback: null,
    };
  },
  computed: {
    loadUnloadLabel() {
      let l = null;
      if (this.trailer) {
        l = this.trailer.status === "LOADED" ? "Set Empty" : "Set Loaded";
      }
      return l;
    },
  },
  methods: {
    ...mapActions("common", ["showLoadingDuringAction"]),
    beforeOpen(event) {
      this.title = event.params.title;
      this.trailer = event.params.trailer;
      this.callback = event.params.callback;
    },
    beforeClose() {
      this.title = null;
      this.trailer = null;
      this.callback = null;
    },
    toggleLoad() {
      this.showLoadingDuringAction(
        trailerApi.setStatus(
          this.trailer.scac,
          this.trailer.id,
          this.trailer.status === "LOADED" ? "EMPTY" : "LOADED",
        ),
      ).then((res) => {
        if (this.callback != null) {
          this.callback(res);
        }
        this.$modal.hide("updateTrailerStatusModal");
      });
    },
  },
};
</script>

<style scoped></style>
