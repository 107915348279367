<template>
  <BaseModal
    :selectedItemForActions="selectedItemForActions"
    :selectedActionPanel="selectedActionPanel"
    :showItemActions="showItemActions"
    actionPanel="status"
    :title="title"
    id="bays-trailer-status-modal"
  >
    <div>
      <button type="button" class="btn btn-primary" @click="toggleLoad">
        {{ loadUnloadLabel }}
      </button>
    </div>
  </BaseModal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BaseModal from "../common/BaseModal.vue";

export default {
  components: {
    BaseModal,
  },
  computed: {
    ...mapGetters("bays", [
      "selectedItemForActions",
      "selectedActionPanel",
      "stayForBay",
    ]),
    title() {
      return `${this.scac} ${this.id}`;
    },
    trailer() {
      let r = null;
      if (this.selectedItemForActions != null) {
        let s = this.stayForBay(this.selectedItemForActions);
        if (s) {
          r = s.trailer;
        }
      }
      return r;
    },
    scac() {
      return this.trailer != null ? this.trailer.scac : null;
    },
    id() {
      return this.trailer != null ? this.trailer.id : null;
    },
    loadUnloadLabel() {
      let l = null;
      if (this.trailer != null) {
        l = this.trailer.status === "LOADED" ? "Set Empty" : "Set Loaded";
      }
      return l;
    },
  },
  methods: {
    ...mapActions("common", ["updateTrailerStatus"]),
    ...mapActions("bays", ["showItemActions"]),
    close() {
      this.showItemActions({ i: null, a: null });
    },
    toggleLoad() {
      this.updateTrailerStatus({
        trailer: this.trailer,
        status: this.trailer.status === "LOADED" ? "EMPTY" : "LOADED",
      }).then(() => this.close());
    },
  },
};
</script>

<style scoped></style>
