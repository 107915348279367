<template>
  <b-modal
    id="line-search-result-modal"
    title="Incoming Line Search Results"
    size="xl"
    ok-only
  >
    <b-row class="table-responsive">
      <b-table :items="lines" :fields="headers" responsive="md" striped small>
        <template #cell(inc_shipment_name)="data">
          <a
            :href="
              manifestSystemLink(
                `/IncomingShipmentView/Detail?incomingShipmentId=${data.item.inc_id}&incomingShipmentLineId=${data.item.line_id}`,
              )
            "
            target="manifestFromMissy"
          >
            {{ data.item.inc_shipment_name }}</a
          >
        </template>
        <template #cell(manifest_no)="data">
          <a
            :href="
              manifestSystemLink(
                `/ManifestView/Index?manifest=${data.item.manifest_no}&initialLineId=${data.item.line_id}`,
              )
            "
            target="manifestFromMissy"
          >
            {{ data.item.manifest_no }}</a
          >
        </template>
        <template #cell(inc_date_shipped)="data">
          {{ displayDate(data.item.inc_date_shipped) }}
        </template>
        <template #cell(inc_date_arrived)="data">
          {{ displayDate(data.item.inc_date_arrived) }}
        </template>
        <template #cell()="data">
          {{ upper(data.value) }}
        </template>
      </b-table>
    </b-row>
  </b-modal>
</template>

<script>
import { MANIFEST_URL } from "../../../config";
import dateutils from "../../../dateutil";
import textutils from "../../../textutils";

export default {
  props: {
    lines: Array,
  },
  data() {
    return {
      headers: [
        { key: "facility", label: "Facility" },
        { key: "inc_shipment_name", label: "Shipment" },
        { key: "inc_date_shipped", label: "Shipped" },
        //        { key: "inc_date_arrived", label: "Arrived" },
        { key: "inc_trailer_no", label: "Trailer" },
        //        { key: "inc_tracking_id", label: "Tracking" },
        { key: "dest_code", label: "Dealer" },
        { key: "line_orders", label: "Order" },
        { key: "line_pieces", label: "PCS", class: "text-right" },
        { key: "line_weight", label: "WGT", class: "text-right" },
        { key: "manifest_no", label: "Manifest", class: "text-right" },
        { key: "shipto_code", label: "Stop" },
      ],
    };
  },
  methods: {
    upper(s) {
      return textutils.toUpper(s);
    },
    displayDate(date) {
      return date != null ? dateutils.getLocalDateTime(date, "YY-MM-DD") : null;
    },
    manifestSystemLink(path) {
      return `${MANIFEST_URL}${path}`;
    },
  },
};
</script>

<style scoped>
a {
  color: blue;
}
</style>
