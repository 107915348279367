<template>
  <div>
    <div v-if="!hideMakeEligibleForDetention">
      Make make eligible for detention
      <input type="checkbox" v-model="makeEligibleForDetention" />
    </div>
    <button type="button" class="btn btn-primary" @click="toggleLoad">
      {{ loadUnloadLabel }}
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      makeEligibleForDetention: false,
    };
  },
  computed: {
    loadUnloadLabel() {
      let l = null;
      if (this.selectedTrailerForActions) {
        l =
          this.selectedTrailerForActions.status === "LOADED"
            ? "Set Empty"
            : "Set Loaded";
      }
      return l;
    },
    hideMakeEligibleForDetention() {
      return (
        this.selectedTrailerForActions == null ||
        this.selectedTrailerForActions.externalLocation == null ||
        this.selectedTrailerForActions.status == "LOADED"
      );
    },
    ...mapGetters("trailers", ["selectedTrailerForActions"]),
  },
  methods: {
    ...mapActions("trailers", ["showTrailerActions", "updateStatus"]),
    close() {
      this.showTrailerActions({ t: null, a: null });
    },
    toggleLoad() {
      this.updateStatus({
        status:
          this.selectedTrailerForActions.status === "LOADED"
            ? "EMPTY"
            : "LOADED",
        eligibleForDetention: this.makeEligibleForDetention,
      });
      this.makeEligibleForDetention = false;
      this.close();
    },
    doDelete() {},
  },
};
</script>
