<template>
  <div>
    <template
      v-if="
        selectedTrailerForActions &&
        selectedTrailerForActions.facilityLocation == null
      "
    >
      <button
        @click="clearLocation"
        :disabled="!selectedTrailerForActions.externalLocation"
        type="button"
        class="btn btn-primary"
      >
        Clear Location
      </button>
      <hr />
    </template>
    <div
      v-if="
        selectedTrailerForActions &&
        selectedTrailerForActions.facilityLocation != null
      "
    >
      <h5>Facility Location</h5>
      <select disabled v-model="selectedFacility" class="form-control">
        <option :value="null" disabled>Select Facility</option>
        <option v-for="f in facilities" :key="f">{{ f }}</option>
      </select>
      <select
        :disabled="!selectedFacility"
        v-model="selectedFacilityGroup"
        class="form-control"
      >
        <option :value="null" disabled>Select Group</option>
        <option v-for="g in facilityGroups(selectedFacility)" :key="g">
          {{ g }}
        </option>
      </select>
      <select
        :disabled="!selectedFacilityGroup"
        v-model="selectedFacilityLocation"
        class="form-control"
      >
        <option :value="null" disabled>Select Location</option>
        <option
          v-for="l in facilityGroupLocations(
            selectedFacility,
            selectedFacilityGroup,
          )"
          :key="l.code"
          :value="l"
        >
          {{ l.code }}
        </option>
      </select>
    </div>
    <div
      v-if="
        selectedTrailerForActions &&
        selectedTrailerForActions.facilityLocation == null
      "
    >
      <h5>External Location</h5>
      <input
        class="form-control text-uppercase"
        type="text"
        v-model.trim="externalLocation"
      />
    </div>
    <br />
    <b-button
      @click="saveLocation"
      :disabled="
        (!externalLocation && !selectedFacilityLocation) ||
        (externalLocation && selectedFacilityLocation)
      "
      variant="primary"
      >Update Location</b-button
    >
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters("trailers", [
      "selectedTrailerForActions",
      "facilities",
      "facilityGroups",
      "facilityGroupLocations",
    ]),
  },
  data() {
    return {
      externalLocation: null,
      selectedFacility: null,
      selectedFacilityGroup: null,
      selectedFacilityLocation: null,
    };
  },
  watch: {
    selectedTrailerForActions(newVal) {
      if (!newVal) {
        this.resetData();
      } else {
        if (newVal.facilityLocation) {
          this.selectedFacility = newVal.facilityLocation.facility;
        }
      }
    },
  },
  mounted() {
    if (this.selectedTrailerForActions.facilityLocation) {
      this.selectedFacility =
        this.selectedTrailerForActions.facilityLocation.facility;
    }
  },
  methods: {
    ...mapActions("trailers", [
      "showTrailerActions",
      "updateExternalLocation",
      "updateFacilityLocation",
      "setErrorMessage",
    ]),
    saveLocation() {
      if (this.externalLocation && this.selectedFacilityLocation) {
        this.setErrorMessage(
          "A trailer can be set to either an external location or a facility location, not both.",
        );
      } else {
        if (this.externalLocation) {
          this.updateExternalLocation(this.externalLocation.toUpperCase());
        }
        if (this.selectedFacilityLocation) {
          this.updateFacilityLocation(this.selectedFacilityLocation);
        }
      }
      this.resetData();
      this.close();
    },
    close() {
      this.showTrailerActions({ t: null, a: null });
    },
    clearLocation() {
      if (this.selectedTrailerForActions.externalLocation) {
        this.updateExternalLocation(null);
      }
      if (this.selectedTrailerForActions.facilityLocation) {
        this.updateFacilityLocation(null);
      }
      this.resetData();
      this.close();
    },
    resetData() {
      this.externalLocation = null;
      this.selectedFacility = null;
      this.selectedFacilityGroup = null;
      this.selectedFacilityLocation = null;
    },
  },
};
</script>
