import { render, staticRenderFns } from "./BundleStorageManagementPage.vue?vue&type=template&id=5b8c9dae&scoped=true"
import script from "./BundleStorageManagementPage.vue?vue&type=script&lang=js"
export * from "./BundleStorageManagementPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b8c9dae",
  null
  
)

export default component.exports