<template>
  <div>
    <div class="card">
      <div class="card-header">Find Bundle</div>
      <div class="card-body">
        <form>
          <div class="form-group row">
            <label for="bundle" class="col-sm-2 col-form-label">Bundle</label>
            <div class="col-sm-8">
              <b-form-input
                type="text"
                :formatter="upper"
                v-model.trim="find"
                id="find"
                placeholder="Bundle"
                v-on:keyup.enter="doSearch"
              />
            </div>
            <div class="col-sm-2">
              <button type="button" @click="doSearch" class="btn btn-primary">
                Search
              </button>
            </div>
          </div>
        </form>
        <b-row>
          <b-col>
            {{ searchMessage }}
          </b-col>
        </b-row>
        <b-row class="table-responsive" v-if="inventory.length > 0">
          <b-table
            :items="inventory"
            :fields="headers"
            responsive="sm"
            primary-key="location"
            striped
            small
          >
          </b-table>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { bundleInventory } from "../../api/bundleinventory";
import { mapGetters, mapActions } from "vuex";
import textutils from "../../textutils";

export default {
  data() {
    return {
      find: null,
      bundle: null,
      inventory: [],
      searchMessage: null,
      headers: [
        { key: "bundle", label: "Bundle" },
        { key: "location", label: "Location" },
        ,
        { key: "quantity", label: "Qty" },
      ],
    };
  },
  computed: {
    ...mapGetters("invBundles", ["facility"]),
  },
  methods: {
    ...mapActions("common", ["showLoadingDuringAction"]),
    doSearch() {
      this.searchMessage = null;
      let i = bundleInventory.findInventory(this.facility, this.find);
      this.showLoadingDuringAction(i).then((res) => {
        this.inventory = res.data.payload;
        this.searchMessage = `Found ${this.inventory.length} records for ${this.find}`;
        this.find = null;
      });
    },
    upper(t) {
      return textutils.toUpper(t);
    },
  },
};
</script>

<style scoped></style>
