import axios from "axios";
import store from "../store";

var API_URL = null;
try {
  // eslint-disable-next-line no-undef
  API_URL = ATTACHMENT_API_URL;
} catch (e) {
  API_URL = "http://attachmentapi.home";
}

export default {
  async uploadFile(type, id, data, filename) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/${type}/${id}/upload?by=${store.getters["security/userName"]}`;
    let formData = new FormData();
    formData.append("file", data, filename);
    return axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  async getFileList(type, id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/${type}/${id}`;
    return axios.get(url);
  },
  async deleteFile(type, id, fileName) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/${type}/${id}/${fileName}?by=${store.getters["security/userName"]}`;
    return axios.delete(url);
  },
  downloadLink(type, id, fileName) {
    const url = `${API_URL}/${type}/${id}/${fileName}`;
    return url;
  },
};
