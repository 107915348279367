import axios from "axios";
import store from "../store";

var API_URL = null;
try {
  API_URL = MISC_API_URL;
} catch (e) {
  API_URL = "http://miscapi.home";
}

export default {
  async saveForkTruckAudit(forktruckaudit) {
    await store.dispatch("security/refreshSecurity");
    const url = API_URL + "/api/forktruckaudit";
    return axios.post(url, forktruckaudit, {
      headers: { "Content-Type": "application/json" },
    });
  },

  async createOrUpdateBundleStorage(bundleStorage) {
    await store.dispatch("security/refreshSecurity");
    const url = API_URL + "/api/bundlestorage";
    return axios.post(url, bundleStorage, {
      headers: { "Content-Type": "application/json" },
    });
  },

  async findBundleStorage(bundle) {
    await store.dispatch("security/refreshSecurity");
    const url = API_URL + `/api/bundlestorage/${bundle}`;
    return axios.get(url);
  },

  async loadVaxEmployees() {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/vax/employees`;
    return axios.get(url);
  },

  async loadVaxEmployeesWithDocs(cutoff) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/vax/employees?loadDocs=true&cutoff=${cutoff}`;
    return axios.get(url);
  },

  async loadLastVaxTestForEmployee(empId) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/vax/employees/${empId}/docs/latest`;
    return axios.get(url);
  },

  async createVaxEmployee(emp) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/vax/employees`;
    return axios.post(url, emp, {
      headers: { "Content-Type": "application/json" },
    });
  },

  async createVaxDoc(empId, ext, coverageDate) {
    await store.dispatch("security/refreshSecurity");
    let obj = { docExt: ext, docCoverageDate: coverageDate };
    const url = `${API_URL}/vax/employees/${empId}/docs`;
    return axios.post(url, obj, {
      headers: { "Content-Type": "application/json" },
    });
  },

  async inactivateVaxEmployee(empId) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/vax/employees/${empId}`;
    return axios.delete(url);
  },

  async setVaxDocVerified(id, verified) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/vax/docs/${id}/verify/${verified}`;
    return axios.post(url);
  },

  async setVaxDocCoverage(id, coverageDate) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/vax/docs/${id}/coveragedate/${coverageDate}`;
    return axios.post(url);
  },
};
