<template>
  <div class="container-fluid h-100" style="overflow: hidden; overflow-y: auto">
    <div class="card">
      <div class="card-header">
        <div v-show="errorMessage" class="alert alert-danger">
          {{ errorMessage }}
        </div>
        Find Bundle
      </div>
      <div class="card-body">
        <form>
          <div class="form-group row">
            <label for="bundle" class="col-sm-2 col-form-label">Bundle</label>
            <div class="col-sm-10">
              <input
                type="text"
                v-model.trim="bundle"
                id="bundle"
                class="form-control text-uppercase"
                required
                placeholder="Bundle"
                autofocus
              />
            </div>
          </div>
          <div class="form-group row">
            <button type="button" @click="doSearch" class="btn btn-primary">
              Search
            </button>
          </div>
        </form>
      </div>
    </div>
    <div v-if="lastSearched" class="card">
      <div class="card-header">Bundle Found!</div>
      <div class="card-body">
        <div class="row">
          <label class="col-sm-2 col-form-label">Bundle:</label>
          <div class="col-sm-10">
            {{ lastSearched.bundle }}
          </div>
        </div>
        <div class="row">
          <label class="col-sm-2 col-form-label">Store Outside?</label>
          <div class="col-sm-10">
            {{ lastSearched.storeOutside }}
          </div>
        </div>
        <div class="row">
          <label class="col-sm-2 col-form-label">Protection:</label>
          <div class="col-sm-10">
            {{ lastSearched.protectionDesc }}
          </div>
        </div>
      </div>
    </div>
    <LoadingSplash />
  </div>
</template>

<script>
import LoadingSplash from "../LoadingSplash.vue";
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    LoadingSplash,
  },
  data() {
    return {
      bundle: null,
    };
  },
  validations() {
    let v = {
      bundle: {
        required,
      },
    };
    return v;
  },
  computed: {
    ...mapGetters("common", ["loading", "errorMessage"]),
    ...mapGetters("bundlestorage", ["lastSearched"]),
  },
  watch: {
    loading(newVal) {
      if (!newVal) {
        if (!this.errorMessage) {
          this.reset();
        }
      }
    },
  },
  destroyed() {
    this.resetStore();
  },
  methods: {
    ...mapActions("common", ["setErrorMessage"]),
    ...mapActions("bundlestorage", ["search", "resetStore"]),
    reset() {
      this.bundle = null;
    },
    doSearch() {
      this.resetStore();
      this.setErrorMessage(null);
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.setErrorMessage(
          "Check that all required fields are filled out with valid data",
        );
      } else {
        this.bundle = this.bundle.toUpperCase();
        this.search(this.bundle);
      }
    },
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
