<template>
  <BaseModal
    :selectedItemForActions="selectedItemForActions"
    :selectedActionPanel="selectedActionPanel"
    :showItemActions="showItemActions"
    :title="title"
    actionPanel="workers"
    id="bays-assigned-workers-modal"
    @shown="doShown"
  >
    <div>
      <div class="container">
        <div class="row">
          <div class="col">Workers:</div>
          <div class="col col-10">
            <div class="input-group mb-3">
              <input
                class="form-control text-uppercase"
                ref="focusMe"
                type="text"
                v-model.trim="workersInput"
              />
              <div class="input-group-append">
                <button
                  @click="doClearWorkers"
                  :disabled="
                    workersInput == null || workersInput.trim().length == 0
                  "
                  class="btn btn-outline-secondary"
                  type="button"
                >
                  &times;
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col">
            <button
              @click="doUpdateWorkers"
              type="button"
              class="btn btn-primary"
            >
              Update Workers
            </button>
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BaseModal from "../common/BaseModal.vue";

export default {
  components: {
    BaseModal,
  },
  data() {
    return {
      workersInput: null,
    };
  },
  computed: {
    ...mapGetters("bays", ["selectedItemForActions", "selectedActionPanel"]),
    title() {
      return `${this.facility} ${this.type} ${this.code}`;
    },
    facility() {
      return this.selectedItemForActions != null
        ? this.selectedItemForActions.facility
        : null;
    },
    type() {
      return this.selectedItemForActions != null
        ? this.selectedItemForActions.type
        : null;
    },
    code() {
      return this.selectedItemForActions != null
        ? this.selectedItemForActions.code
        : null;
    },
  },
  watch: {
    selectedItemForActions(newVal) {
      if (newVal != null) {
        this.workersInput = newVal.assignedWorkers;
      }
    },
    workersInput(newVal) {
      if (newVal != null) {
        this.workersInput = newVal.toUpperCase();
      }
    },
  },
  methods: {
    ...mapActions("bays", ["showItemActions", "updateBayWorkers"]),
    doUpdateWorkers() {
      this.updateBayWorkers(this.workersInput);
    },
    doClearWorkers() {
      this.workersInput = null;
    },
    doShown() {
      window.setTimeout(() => this.$refs.focusMe.focus(), 100);
    },
  },
};
</script>

<style scoped></style>
