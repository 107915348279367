<template>
  <div class="container-fluid h-100">
    <div v-if="initialFacility == null" class="card">
      <div class="card-header">
        Yard - Facility:
        <select v-model="facility">
          <option :value="null" disabled>Select Facility</option>
          <option v-for="f in facilities" :key="f">{{ f }}</option>
        </select>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div v-show="errorMessage" class="alert alert-danger">
          {{ errorMessage }}
        </div>
        <div
          class="row"
          v-bind:style="{ 'max-height': contentHeight + 'px' }"
          style="overflow-y: auto"
        >
          <div class="col">
            <div v-for="group in col1Groups" v-bind:key="group">
              <GroupPanel :group="group" />
            </div>
          </div>
          <div class="col">
            <div v-for="group in col2Groups" v-bind:key="group">
              <GroupPanel :group="group" />
            </div>
          </div>
          <div class="col">
            <div v-for="group in col3Groups" v-bind:key="group">
              <GroupPanel :group="group" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <BayModal />
    <TrailerStatusModal />
    <BayNoteModal />
    <BayAssignedWorkersModal />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import messaging from "../../messaging";
import GroupPanel from "./GroupPanel.vue";
import BayModal from "./BayModal.vue";
import TrailerStatusModal from "./TrailerStatusModal.vue";
import BayNoteModal from "./BayNoteModal.vue";
import BayAssignedWorkersModal from "./BayAssignedWorkersModal.vue";
import dateutil from "../../dateutil";

export default {
  components: {
    GroupPanel,
    BayModal,
    TrailerStatusModal,
    BayNoteModal,
    BayAssignedWorkersModal,
  },
  props: {
    initialFacility: String,
  },
  data() {
    return {
      facility: null,
      windowHeight: window.innerHeight || 0,
      facilities: ["ADC", "MTJOY", "MTDRAY", "ATIRI", "MTJOPEN"],
      groupings: {
        ADC: [
          ["LOWER NORTH", "UPPER NORTH"],
          ["WEST", "LOWER EAST", "UPPER EAST"],
          ["SOUTH", "PIT"],
        ],
        MTJOY: [["EAST", "OPENDECK"], ["NORTH"], ["WEST"]],
        MTJOPEN: [["EAST"]],
      },
      col1Groups: null,
      col2Groups: null,
      col3Groups: null,
    };
  },
  computed: {
    ...mapGetters("security", ["inRole"]),
    ...mapGetters("common", ["errorMessage", "loading"]),
    ...mapGetters("bays", [
      "bays",
      "selectedFacility",
      "selectedItemForActions",
      "selectedActionPanel",
      "groups",
    ]),
    contentHeight: function () {
      let allowance = this.initialFacility != null ? 120 : 160;
      return this.windowHeight - allowance || 0;
    },
  },
  watch: {
    initialFacility(newVal) {
      if (newVal) {
        this.facility = newVal;
      }
    },
    selectedFacility(newVal) {
      if (newVal) {
        this.facilitySelected();
      }
    },
    facility(newVal) {
      if (newVal) {
        this.$cookies.set("bays_facility", newVal);
        this.selectFacility(newVal);
      }
    },
  },
  methods: {
    ...mapActions("bays", [
      "selectFacility",
      "loadInitialData",
      "updateTrailer",
      "updateTrailerStay",
      "updateBay",
      "showItemActions",
    ]),
    handleTrailerMutations: function (msg) {
      let mutation = JSON.parse(msg.body);
      if (mutation != null && mutation.current != null) {
        this.updateTrailer(mutation.current);
      }
    },
    handleFacilityLocationMutation: function (msg) {
      let mutation = JSON.parse(msg.body);
      if (mutation != null && mutation.current != null) {
        this.updateBay(mutation);
      }
    },
    handleTrailerStayMutations: function (msg) {
      let mutation = JSON.parse(msg.body);
      if (mutation != null) {
        this.updateTrailerStay(mutation);
      }
    },
    facilitySelected: function () {
      let self = this;
      this.loadInitialData().then(function () {
        let ga = self.groupings[self.selectedFacility];
        if (ga != null) {
          self.col1Groups = ga[0];
          self.col2Groups = ga[1];
          self.col3Groups = ga[2];
        } else {
          self.col1Groups = self.col2Groups = self.col3Groups = null;
        }
        messaging.unsubscribe("BayDoorPage.TrailerMutations");
        messaging.unsubscribe("BayDoorPage.FacilityLocationMutations");
        messaging.unsubscribe("BayDoorPage.TrailerStayMutations");
        messaging.subscribe(
          "BayDoorPage.TrailerMutations",
          "/topic/Topic.TrailerMutations",
          self.handleTrailerMutations,
        );
        messaging.subscribe(
          "BayDoorPage.FacilityLocationMutations",
          "/topic/Topic.FacilityLocationMutations",
          self.handleFacilityLocationMutation,
        );
        messaging.subscribe(
          "BayDoorPage.TrailerStayMutations",
          "/topic/Topic.TrailerStayMutations",
          self.handleTrailerStayMutations,
        );
        messaging.restart();
      });
    },
    formatDate(d) {
      return dateutil.getLocalDateTime(d, "YYYY-MM-DD hh:mma z");
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.facility =
      this.initialFacility != null
        ? this.initialFacility
        : this.$cookies.get("bays_facility");
    if (this.selectedFacility != null) {
      this.facilitySelected();
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  destroyed() {
    messaging.unsubscribe("BayDoorPage.TrailerMutations");
    messaging.unsubscribe("BayDoorPage.FacilityLocationMutations");
    messaging.unsubscribe("BayDoorPage.TrailerStayMutations");
  },
};
</script>

<style scoped>
.card {
  font-size: smaller;
  padding: 0em;
}
.card-body {
  padding: 0em;
}
</style>
