var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"bayRow"},[_c('td',{staticClass:"cell bayempty",class:{ bayfull: _vm.trailer != null },on:{"click":function($event){return _vm.emitItemSelected(_vm.bay, 'bay')}}},[_vm._v("  "+_vm._s(_vm.bay.code)+"  ")]),_c('td',{staticClass:"cell trailer",attrs:{"title":_vm.hoverTrailer},on:{"click":function($event){return _vm.emitItemSelected(_vm.bay, 'bay')}}},[_vm._v("  "+_vm._s(_vm.trailerScac)+" "+_vm._s(_vm.trailerCode)+"  "),_c('font-awesome-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.trailer != null && (_vm.trailer.redTag || _vm.trailer.yellowTag)),expression:"trailer != null && (trailer.redTag || trailer.yellowTag)"}],class:{
        redTag: _vm.trailer != null && _vm.trailer.redTag,
        yellowTag: _vm.trailer != null && _vm.trailer.yellowTag,
      },attrs:{"icon":"tag"}}),(_vm.showInbound)?_c('div',{staticClass:"smaller"},[_vm._v("  O: "+_vm._s(_vm.inbOrigin)+" "),_c('br'),_vm._v("  D: "+_vm._s(_vm.inbDest)+" "),_c('br'),_vm._v("  C: "+_vm._s(_vm.inbContent)+"  ")]):_vm._e(),(_vm.showOutbound)?_c('div',{staticClass:"smaller"},[_vm._v("  "),_c('router-link',{staticClass:"clickable",attrs:{"to":{
          name: 'bol',
          params: { billOfLadingNo: _vm.stay.outboundBol },
        }}},[_vm._v(" "+_vm._s(_vm.outInfo)+" ")]),_vm._v("  ")],1):_vm._e()],1),_c('td',{staticClass:"cell statusCol",on:{"click":function($event){return _vm.emitTrailerSelected('status')}}},[_c('TrailerLoadedDisplay',{directives:[{name:"show",rawName:"v-show",value:(_vm.trailer),expression:"trailer"}],attrs:{"pr":8,"pl":8,"trailer":_vm.trailer}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.trailer == null),expression:"trailer == null"}]},[_vm._v(" ")])],1),_c('td',{staticClass:"cell note",on:{"click":function($event){return _vm.emitItemSelected(_vm.bay, 'note')}}},[_vm._v("   "),_c('em',{directives:[{name:"show",rawName:"v-show",value:(_vm.trailerNote),expression:"trailerNote"}]},[_vm._v(_vm._s(_vm.trailerNote))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.trailerNote && _vm.bay.note),expression:"trailerNote && bay.note"}]},[_vm._v(" - ")]),_vm._v(" "+_vm._s(_vm.bay.note)+"   ")]),_c('td',{staticClass:"cell",on:{"click":function($event){return _vm.emitItemSelected(_vm.bay, 'workers')}}},[_vm._v(" "+_vm._s(_vm.bay.assignedWorkers)+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.bay.assignedWorkers == null),expression:"bay.assignedWorkers == null"}]},[_vm._v("     ")])]),_c('td',{staticClass:"cell timeCol",attrs:{"title":_vm.fullTimeInBay}},[_vm._v("  "+_vm._s(_vm.timeInBay)+"  ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }