import { render, staticRenderFns } from "./LadingShipmentModal.vue?vue&type=template&id=d23bcba4&scoped=true"
import script from "./LadingShipmentModal.vue?vue&type=script&lang=js"
export * from "./LadingShipmentModal.vue?vue&type=script&lang=js"
import style0 from "./LadingShipmentModal.vue?vue&type=style&index=0&id=d23bcba4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d23bcba4",
  null
  
)

export default component.exports