import axios from "axios";
import store from "../store";
import textutils from "../textutils";

var API_URL = null;
try {
  // eslint-disable-next-line no-undef
  API_URL = BUNDLEINVENTORY_API_URL;
} catch (e) {
  API_URL = "http://bundleinventoryapi.home";
  //API_URL = "http://localhost:9090";
}

const bundleDefinition = {
  async createBundleDefinition(bundleDef) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/bundle?by=${store.getters["security/userName"]}`;
    return axios.post(url, bundleDef, {
      headers: { "Content-Type": "application/json" },
    });
  },
  async updateBundleDefinition(bundleDef) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/bundle/${bundleDef.bundle}?by=${store.getters["security/userName"]}`;
    return axios.put(url, bundleDef, {
      headers: { "Content-Type": "application/json" },
    });
  },
  async deleteBundleDefinition(bundle) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/bundle/${bundle}?by=${store.getters["security/userName"]}`;
    return axios.delete(url);
  },
};

const bundleInventory = {
  async findInventory(facility, bundle) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/bundleinventory?facility=${facility}&bundle=${bundle}`;
    return axios.get(url);
  },
  async findInventoryByLocation(facility, location) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/bundleinventory?facility=${facility}&location=${location}`;
    return axios.get(url);
  },
  async addToInventory(facility, bundle, location, inboundId) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/bundleinventory/${bundle}/${facility}/${location}?inboundId=${inboundId}&by=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
  async removeFromInventory(facility, bundle, location, inboundId) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/bundleinventory/${bundle}/${facility}/${location}?inboundId=${inboundId}&by=${store.getters["security/userName"]}`;
    return axios.delete(url);
  },
  async moveInventory(facility, bundle, fromLoc, toLoc) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/bundleinventory/${bundle}/${facility}?fromLoc=${fromLoc}&toLoc=${toLoc}&by=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
};

const inventoryLocation = {
  async createLocation(locationData) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/facilitylocation?by=${store.getters["security/userName"]}`;
    return axios.post(url, locationData, {
      headers: { "Content-Type": "application/json" },
    });
  },
  async deleteLocation(locationData) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/facilitylocation/${locationData.facility}/${locationData.location}?by=${store.getters["security/userName"]}`;
    return axios.delete(url);
  },
};

const inboundInvShipment = {
  async getIncompleteShipments(facility) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/inboundshipment?facility=${facility}`;
    return axios.get(url);
  },
  async searchShipments(facility, date, supplier, trackingId) {
    date = textutils.blankIfNull(date);
    supplier = textutils.blankIfNull(supplier);
    trackingId = textutils.blankIfNull(trackingId);
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/inboundshipment/search?facility=${facility}&date=${date}&supplier=${supplier}&trackingId=${trackingId}`;
    return axios.get(url);
  },
  async getShipmentBundles(inboundId) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/inboundshipment/${inboundId}/bundles`;
    return axios.get(url);
  },
  async completeShipment(inboundId) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/inboundshipment/${inboundId}/complete?by=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
  async createInboundFromRawData(facility, rawData) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/inboundshipment?facility=${facility}&by=${store.getters["security/userName"]}`;
    return axios.post(url, rawData, {
      headers: { "Content-Type": "text/plain" },
    });
  },
};

export {
  bundleDefinition,
  bundleInventory,
  inventoryLocation,
  inboundInvShipment,
};
